import { put, select, call } from 'redux-saga/effects';
import { change } from 'redux-form';
import {
  BOOK_TRIP_FORM,
  UPGRADES_FIELD_NAME,
} from 'app/constants';
import {
  getTripPrice,
  getTripUpgrades,
  getAddedUpgrades
} from 'app/sagas/selectors';
import { reportAmenityAdded } from 'app/sagas/logging/google';
import { updatePrice } from 'app/actions/book-trip';
import waitForPriceUpdateFinished from '../watchUpdatePrice/waitForPriceUpdateFinished';

/**
 * Toggle value of the "included" property for amenity in
 * the upgrades list
 */
function updateUpgrades(upgrades, amenityId) {
  const newUpgrades = upgrades.map(upgrade => (
    upgrade.id === amenityId
      ? { ...upgrade, included: !upgrade.included }
      : upgrade
  ));
  return newUpgrades;
}

function mergeUpgrades(returnedAmenities, localAmenities) {
  const amenitiesMap = returnedAmenities.reduce(
    (acc, a) => ({ ...acc, [a.id]: a }), {})

  return localAmenities.map(amenity => ({
    ...amenity,
    included: amenitiesMap[amenity.id]?.selected || amenitiesMap[amenity.id]?.included || false
  }))
}

export default function* toggleAmenity(action) {
  const { payload: amenityId } = action;

  const upgradesBeforeChange = yield select(getTripUpgrades);

  try {
    const newUpgrades = updateUpgrades(upgradesBeforeChange, amenityId);

    const priceParams = {
      optional_amenities: getAddedUpgrades(newUpgrades)
    };

    const sourceActionId = action.type;
    yield put(updatePrice({ priceParams, sourceActionId }));

    // here we wait for update fail or success. Fail throws error
    yield call(waitForPriceUpdateFinished, sourceActionId);
    const pricing = yield select(getTripPrice)
    const finalUpgrades = mergeUpgrades(pricing.amenities, newUpgrades)

    yield put(change(BOOK_TRIP_FORM, UPGRADES_FIELD_NAME, finalUpgrades));

    const amenityAdded = finalUpgrades.find(upgrade => upgrade.id === amenityId && upgrade.included);
    if (amenityAdded) {
      yield call(reportAmenityAdded, amenityId);
    }

    yield call(action.resolvePromise);
  } catch (err) {
    yield put(change(BOOK_TRIP_FORM, UPGRADES_FIELD_NAME, upgradesBeforeChange));
    yield call(action.rejectPromise, err);
  }
}
