/**
 * Get a list of providers object and returns that list alphabetically sorted
 * @param {Object} providers
 * @return {Object} sorted providers
 */
export const setProviders = (providers) => Object.keys(providers)
    .sort((a, b) => {
      const textA = a.toLowerCase();
      const textB = b.toLowerCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0; //eslint-disable-line
    })
    .reduce((r, k) => (r[k] = providers[k], r), {}); //eslint-disable-line