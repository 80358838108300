import React from 'react';
import importWithRetry from 'mz-utils/importWithRetry';
import { getActiveLanguage, getActiveCurrency } from 'app/sagas/selectors';
import { useSelector } from 'react-redux';

// Constants
const TA_TOOL = 'travel-agent';

// Lazy loaded pages
const TADashboardPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/TADashboardPage'))
);
const TAAgentProfilePage = React.lazy(() =>
  importWithRetry(() => import('app/pages/TAAgentProfilePage'))
);
const TACompanyInfoPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/TACompanyInfoPage'))
);
const RegisterPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/RegisterPage'))
);
const TACustomerProfilesPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/TACustomerProfilesPage'))
);
const TAPaymentsPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/TAPaymentsPage'))
);
const TASalesBalancePage = React.lazy(() =>
  importWithRetry(() => import('app/pages/TASalesBalancePage'))
);
const TAWithdrawalsHistoryPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/TAWithdrawalsHistoryPage'))
);
const MFAPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/MFA'))
);

const ENABLE_MFA = process.env.CONFIG_ENV !== 'production';

const RedirectToNewDashboard = (newPath) => {
  const Redirect = () => {
    const activeLanguage = useSelector(getActiveLanguage);
    const activeCurrency = useSelector(getActiveCurrency);
    const redirectionUrl = `/v2/user${newPath}?locale=${activeLanguage}&currency=${activeCurrency}`;

    window.location.href = redirectionUrl;

    return null;
  };

  return Redirect;
};

const routes = [
  {
    path: `/${TA_TOOL}/register`,
    component: RegisterPage,
  },
  {
    path: `/${TA_TOOL}/account/update-profile`,
    component: TAAgentProfilePage,
    wrapper: TADashboardPage,
  },
  {
    path: `/${TA_TOOL}/account/update-company-information`,
    component: TACompanyInfoPage,
    wrapper: TADashboardPage,
  },
  {
    path: `/${TA_TOOL}/account/info`,
    component: RedirectToNewDashboard('/info'),
    wrapper: TADashboardPage,
  },
  {
    path: `/${TA_TOOL}/account`,
    component: RedirectToNewDashboard('/my-reservations'),
    wrapper: TADashboardPage,
  },
  {
    path: `/${TA_TOOL}/manage-withdrawal-accounts`,
    component: TAPaymentsPage,
    wrapper: TADashboardPage,
  },
  {
    path: `/${TA_TOOL}/sales-balance`,
    component: TASalesBalancePage,
    wrapper: TADashboardPage,
  },
  {
    path: `/${TA_TOOL}/withdrawals-history`,
    component: TAWithdrawalsHistoryPage,
    wrapper: TADashboardPage,
  },
  {
    path: `/${TA_TOOL}/manage-profiles/customer`,
    component: TACustomerProfilesPage,
    wrapper: TADashboardPage,
  },
  ENABLE_MFA && {
    path: `/${TA_TOOL}/mfa`,
    component: MFAPage,
    wrapper: TADashboardPage,
  },
].filter(Boolean);

export default () => routes;
