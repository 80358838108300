import { call } from 'redux-saga/effects';
import { user } from 'mz-sdk';
import { showErrorNotification, showSuccessNotification } from 'app/sagas/utils';
import getUser from './getUser';
import { getHistory } from 'app/history';
import config from 'config';


export default function* doConfirmVerificationCode(action) {
  try {
    // Verify email
    const { key, domain } = action.payload;
    yield call(user.confirmEmail, { key, domain });
    yield call(getUser);
    yield call(showSuccessNotification, {
      messageId: 'USER.EMAIL_VERIFY.EMAIL_VERIFIED'
    });
    yield call(action.resolvePromise);

    // Redirect to search page
    const history = yield call(getHistory);
    yield call(history.push, config.SEARCH_REDIRECT_PATH);
  } catch (error) {
    yield call(showErrorNotification, {
      messageId: 'USER.EMAIL_VERIFY.INVALID_EMAIL_VALIDATION_LINK'
    });
    yield call(action.rejectPromise);
  }
}
