import React from 'react';
import { FormattedMessage } from 'translations';
import { Layout } from 'app/components/base/Layout';
import CheckedText from 'app/components/base/CheckedText';
import { InfoIcon } from 'app/components/base/TripIcons';
import { isPriceEstimated } from 'app/utils/trip';

import style from '../style.css';


const messages = {
  freeCancellation: { id: 'SEARCH_RESULTS.FREE_CANCELLATION' },
  taxesIncluded: { id: 'SEARCH_RESULTS.TAXES_INCLUDED' },
  estimatedPrice: { id: 'SEARCH_RESULTS.ESTIMATED_PRICE' }
};

const Price = ({
  slashPrice,
  tagline,
  ridePrice,
  type,
  freeCancellation,
  taxesAndFeesIncluded
}) => {
  const isEstimated = isPriceEstimated(type);

  return (
    <Layout className={style['price-container']} align="space-between end" nowrap>
      <Layout direction="column" className={style.guarantees} nowrap>
        {tagline &&
          <Layout align="end center" nowrap className={style['icon-container']}>
            <InfoIcon />
            <span>{tagline}</span>
          </Layout>
        }
        {freeCancellation &&
          <CheckedText
            align="end center"
            checked
            text={<FormattedMessage {...messages.freeCancellation} />}
          />
        }
        {taxesAndFeesIncluded &&
          <span className={style['price-notice']}>
            <FormattedMessage {...messages.taxesIncluded} />
          </span>
        }
      </Layout>

      <Layout align="end end">
        {slashPrice && <span className={style['slash-price']}>{slashPrice}</span>}
        <div>
          <div className={style.price}>{ridePrice}</div>
          {isEstimated && <div><FormattedMessage {...messages.estimatedPrice} /></div>}
        </div>
      </Layout>
    </Layout>
  );
};

// TODO: move to Flow types
// Price.propTypes = {
//   price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   slashPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   tagline: PropTypes.string,
//   currencySymbol: PropTypes.string,
//   freeCancellation: PropTypes.bool,
//   taxesAndFeesIncluded: PropTypes.bool
// };

export default Price;
