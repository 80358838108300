import _ from 'lodash';
import config from 'config';
import { call, select, fork, put } from 'redux-saga/effects';
import { hook } from 'app/utils/hook';
import { reservations } from 'mz-sdk';
import { getHistory } from 'app/history';
import { formatString } from 'app/utils/format';
import objectToQueryString from 'mz-utils/objectToQueryString';
import { setTripError, incrementBookingAttempts } from 'app/actions/book-trip';
import { createPaymentReservationData } from 'mz-ui-kit/form/PaymentButton';
import {
  reportTransaction,
  reportPartnerBookingComplete,
} from 'app/sagas/logging/google';
import { showErrorNotification, handleRefParam } from 'app/sagas/utils';

import { trackSales, trackFailedBooking } from 'app/sagas/logging/loggly';
import {
  AFTER_CONFIRM_BOOKING_HOOK,
  TRIP_UPDATED_ERROR_CODE,
  DEPARTING_TRIP_FIELD_NAME,
  PRICE_FIELD_NAME,
} from 'app/constants';
import { getBookTripForm, getLocation } from 'app/sagas/selectors';
import getReservationData from './getReservationData';
import prepareTripUpdatedDialog from '../../watchChangePickupTime/prepareTripUpdatedDialog';
import getSearchParamsFromTrip from '../../watchLoadBookTrip/processResearchTrip/getSearchParamsFromTrip'; // eslint-disable-line
import processResearchTrip from '../../watchLoadBookTrip/processResearchTrip';
import posthog from 'posthog-js';

const allowedParams = [
  'ta',
  'wl',
  'gid',
  'guid',
  'partner_tracking_id',
  'staging_api',
  'testing_api',
];

// Constants
const STRIPE_ERROR_CODES = [
  'account_closed',
  'account_country_invalid_address',
  'account_error_country_change_requires_additional_steps',
  'account_information_mismatch',
  'account_invalid',
  'account_number_invalid',
  'acss_debit_session_incomplete',
  'alipay_upgrade_required',
  'amount_too_large',
  'amount_too_small',
  'api_key_expired',
  'application_fees_not_allowed',
  'authentication_required',
  'balance_insufficient',
  'balance_invalid_parameter',
  'bank_account_bad_routing_numbers',
  'bank_account_declined',
  'bank_account_exists',
  'bank_account_restricted',
  'bank_account_unusable',
  'bank_account_unverified',
  'bank_account_verification_failed',
  'billing_invalid_mandate',
  'bitcoin_upgrade_required',
  'capture_charge_authorization_expired',
  'capture_unauthorized_payment',
  'card_decline_rate_limit_exceeded',
  'card_declined',
  'cardholder_phone_number_required',
  'charge_already_captured',
  'charge_already_refunded',
  'charge_disputed',
  'charge_exceeds_source_limit',
  'charge_expired_for_capture',
  'charge_invalid_parameter',
  'charge_not_refundable',
  'clearing_code_unsupported',
  'country_code_invalid',
  'country_unsupported',
  'coupon_expired',
  'customer_max_payment_methods',
  'customer_max_subscriptions',
  'customer_tax_location_invalid',
  'debit_not_authorized',
  'email_invalid',
  'expired_card',
  'financial_connections_account_inactive',
  'financial_connections_no_successful_transaction_refresh',
  'idempotency_key_in_use',
  'incorrect_address',
  'incorrect_cvc',
  'incorrect_number',
  'incorrect_zip',
  'instant_payouts_config_disabled',
  'instant_payouts_currency_disabled',
  'instant_payouts_limit_exceeded',
  'instant_payouts_unsupported',
  'insufficient_funds',
  'intent_invalid_state',
  'intent_verification_method_missing',
  'invalid_card_type',
  'invalid_characters',
  'invalid_charge_amount',
  'invalid_cvc',
  'invalid_expiry_month',
  'invalid_expiry_year',
  'invalid_number',
  'invalid_source_usage',
  'invalid_tax_location',
  'invoice_no_customer_line_items',
  'invoice_no_payment_method_types',
  'invoice_no_subscription_line_items',
  'invoice_not_editable',
  'invoice_on_behalf_of_not_editable',
  'invoice_payment_intent_requires_action',
  'invoice_upcoming_none',
  'livemode_mismatch',
  'lock_timeout',
  'missing',
  'no_account',
  'not_allowed_on_standard_account',
  'out_of_inventory',
  'ownership_declaration_not_allowed',
  'parameter_invalid_empty',
  'parameter_invalid_integer',
  'parameter_invalid_string_blank',
  'parameter_invalid_string_empty',
  'parameter_missing',
  'parameter_unknown',
  'parameters_exclusive',
  'payment_intent_action_required',
  'payment_intent_authentication_failure',
  'payment_intent_incompatible_payment_method',
  'payment_intent_invalid_parameter',
  'payment_intent_konbini_rejected_confirmation_number',
  'payment_intent_mandate_invalid',
  'payment_intent_payment_attempt_expired',
  'payment_intent_payment_attempt_failed',
  'payment_intent_unexpected_state',
  'payment_method_bank_account_already_verified',
  'payment_method_bank_account_blocked',
  'payment_method_billing_details_address_missing',
  'payment_method_configuration_failures',
  'payment_method_currency_mismatch',
  'payment_method_customer_decline',
  'payment_method_invalid_parameter',
  'payment_method_invalid_parameter_testmode',
  'payment_method_microdeposit_failed',
  'payment_method_microdeposit_verification_amounts_invalid',
  'payment_method_microdeposit_verification_amounts_mismatch',
  'payment_method_microdeposit_verification_attempts_exceeded',
  'payment_method_microdeposit_verification_descriptor_code_mismatch',
  'payment_method_microdeposit_verification_timeout',
  'payment_method_not_available',
  'payment_method_provider_decline',
  'payment_method_provider_timeout',
  'payment_method_unactivated',
  'payment_method_unexpected_state',
  'payment_method_unsupported_type',
  'payout_reconciliation_not_ready',
  'payouts_limit_exceeded',
  'payouts_not_allowed',
  'platform_account_required',
  'platform_api_key_expired',
  'postal_code_invalid',
  'processing_error',
  'product_inactive',
  'progressive_onboarding_limit_exceeded',
  'rate_limit',
  'refer_to_customer',
  'refund_disputed_payment',
  'resource_already_exists',
  'resource_missing',
  'return_intent_already_processed',
  'routing_number_invalid',
  'secret_key_required',
  'sepa_unsupported_account',
  'setup_attempt_failed',
  'setup_intent_authentication_failure',
  'setup_intent_invalid_parameter',
  'setup_intent_mandate_invalid',
  'setup_intent_setup_attempt_expired',
  'setup_intent_unexpected_state',
  'shipping_calculation_failed',
  'sku_inactive',
  'state_unsupported',
  'status_transition_invalid',
  'stripe_tax_inactive',
  'tax_id_invalid',
  'taxes_calculation_failed',
  'terminal_location_country_unsupported',
  'terminal_reader_busy',
  'terminal_reader_hardware_fault',
  'terminal_reader_offline',
  'terminal_reader_timeout',
  'testmode_charges_only',
  'tls_version_unsupported',
  'token_already_used',
  'token_card_network_invalid',
  'token_in_use',
  'transfer_source_balance_parameters_mismatch',
  'transfers_not_allowed',
  'url_invalid',
];

/**
 * Build confirmation page URL and redirect the user
 * @return {Generator}          [description]
 */
function* redirectToConfirmation({ ondemand, id, returnId }) {
  const history = yield call(getHistory);
  const location = yield select(getLocation);
  const query = _.pick(location.query, allowedParams);
  const ref = yield call(handleRefParam);

  const params = { ...ref, ...query, id };
  if (ondemand) params.ondemand = 1;
  if (returnId) params.return_id = returnId;

  let finalUrl = `/confirmation${objectToQueryString(params)}`

  if (config.SEPARATE_CONFIRMATION_PAGE) {
    const finalParams = {
      ondemand: 0,
      return_id: '', // to replace {return_id} to empty string for one way trip
      ...params,
    };
    const separtePageUrl = formatString(
      config.SEPARATE_CONFIRMATION_PAGE,
      finalParams
    )

    // Set final URL to the custom page URL only if there was no un-used
    // variables in the formatter string (all needed variables was provided
    // to create a final URL)
    if (separtePageUrl.indexOf('{') < 0) {
      finalUrl = separtePageUrl
    }
  }

  if (finalUrl.startsWith('/')) {
    yield call(history.push, finalUrl);
  } else {
    window.location.href = finalUrl
  }
}

/**
 * Show trip price changed error lightbox
 * @param {object} form
 */
export function* showPriceUpdatedError(trip) {
  const searchParams = yield call(getSearchParamsFromTrip, trip);
  const newBookTripUrl = `/book${window.location.search}`;

  yield call(prepareTripUpdatedDialog, { updatedTrip: trip });
  yield put(
    setTripError({
      errorCode: TRIP_UPDATED_ERROR_CODE,
      errorPopup: true,
      errorProps: { newBookTripUrl, searchParams },
    })
  )
}

function* doPaidBooking(action, reservationData) {
  const form = yield select(getBookTripForm);
  const couponCode = reservationData.coupon_code;
  const trip = form[DEPARTING_TRIP_FIELD_NAME];
  const price = form[PRICE_FIELD_NAME];

  const { createPayment } = action.payload;
  const paymentObj = yield call(createPayment);
  Object.assign(reservationData, createPaymentReservationData(paymentObj))

  const res = yield call(reservations.create, reservationData);

  // For some unkonwn errors when server returns nothing
  if (!res || !res.length) {
    throw new Error(
      'Sorry, we did not make your reservation for some unknown reason. ' +
        'Please try again or contact support.'
    );
  }

  yield call(action.resolvePromise);
  yield fork(trackSales, res, trip, price);
  yield fork(reportTransaction, res, couponCode);
  yield fork(reportPartnerBookingComplete, res);
  yield hook(AFTER_CONFIRM_BOOKING_HOOK, res);

  yield call(redirectToConfirmation, {
    ondemand: reservationData.ondemand,
    id: res[0].trip.id,
    returnId: res.length > 1 ? res[1].trip.id : null
  })
}

function* doSendBookingLink(action, reservationData) {
  yield call(reservations.sendLink, reservationData);
  yield call(action.resolvePromise);
}

/**
 * Saga for confirmation booking
 */
export default function* confirmBooking(action) {
  const form = yield select(getBookTripForm);
  const trip = form[DEPARTING_TRIP_FIELD_NAME];
  const price = form[PRICE_FIELD_NAME];
  const reservationData = yield call(getReservationData);

  try {
    if (action.payload.createPayment) {
      yield call(doPaidBooking, action, reservationData)
    } else {
      yield call(doSendBookingLink, action, reservationData)
    }
  } catch (error) {
    if (STRIPE_ERROR_CODES.includes(error.code?.toLowerCase())) {
      posthog.capture('Payment Error', {
        payment_error_code: error.code,
        payment_error_message_displayed: error?.i18n?.defaultMessage,
        payment_error_message: error.message,
        total_amount_usd: price?.finalPriceUsd
      });
    }

    yield put(incrementBookingAttempts());
    yield call(trackFailedBooking, reservationData, trip, price, error)

    // FIXME: re-search until we do not have a safe way to check tht the
    // trip was expired
    if (Date.now() > trip.expiresAt * 1000) {
      yield call(showErrorNotification, { error });
      yield call(processResearchTrip, trip);
    } else if (error && error.errorCode === 'trip_price_changed') {
      yield call(showPriceUpdatedError, form[DEPARTING_TRIP_FIELD_NAME]);
    } else {
      yield call(showErrorNotification, { error });
    }
    yield call(action.rejectPromise);
  }
}
