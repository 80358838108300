export default [
  {
    'name': 'FALLBACK_DOMAIN',
    'type': 'string',
    'default': '',
    'description': 'When the whitelabel loaded as a subdirectory of mozio.com this value will be sent to a reservation endpoint in "domain" field, so the confirmation emails will have correct domain instead of "mozio.com"',
    'editable': false
  },
  {
    'name': 'BASENAME',
    'type': 'string',
    'default': '', // Set dynamically in `scripts/utils/awsUtils#uploadToBucket`
    'description': 'Basename of the app (prefix for all paths)',
    'editable': false
  },
  {
    'name': 'PUBLIC_PATH',
    'type': 'string',
    'default': '/', // Set dynamically in `scripts/utils/awsUtils#uploadToBucket`
    'description': 'Public path for site modules resolving',
    'editable': false
  },
  {
    'name': 'VERSION',
    'type': 'string',
    'default': '', // Set dynamically in `scripts/utils/awsUtils#uploadToBucket`
    'description': 'Version of the application',
    'editable': false
  },
  {
    'name': 'ENV',
    'type': 'select',
    'options': ['prod', 'testing', 'dev'],
    'default': 'prod',
    'description': 'Build environment of the app',
    'editable': false
  },
  {
    'name': 'BASE_URL',
    'type': 'string',
    'default': 'https://api.mozio.com',
    'description': 'API v1 endpoints root',
    'editable': false
  },
  {
    'name': 'BASE_URL_V2',
    'type': 'string',
    'default': 'https://api.mozio.com/v2',
    'description': 'API v2 endpoints root',
    'editable': false
  },
  {
    'name': 'AUTH_TOKEN_ID',
    'type': 'string',
    'default': 'mozio-auth-token',
    'description': 'Token for authentication in Mozio APIs',
    'editable': false
  },
  {
    'name': 'STRIPE_PUBLISHABLE_KEY',
    'type': 'object',
    'default': {
      'EUR': 'pk_live_ieitqyidJnvl3o6cOl9Cw8lL',
      'USD': 'pk_yI63sSPVe2rRrmszOOcnVvPSz3Adh',
      'GBP': 'pk_live_ieitqyidJnvl3o6cOl9Cw8lL'
    },
    'description': 'Stripe key for getting credit cards',
    'editable': false
  },
  {
    'name': 'STRIPE_DEVELOPMENT_MODE',
    'type': 'bool',
    'default': false,
    'description': 'Production mode for payment button',
    'editable': false
  },
  {
    'name': 'GOOGLE_ANALYTICS_KEY',
    'type': 'string',
    'default': 'UA-34281435-1',
    'description': 'Google Analytics project key',
    'editable': false
  },
  {
    'name': 'SENTRY_URL',
    'type': 'string',
    'default': 'https://512235fb91b348d487ca03b42475ee72@sentry.io/265238',
    'description': 'Sentry production URL',
    'editable': false
  },
  {
    'name': 'LOGGLY_KEY',
    'type': 'string',
    'default': 'e2e338de-9fdf-44e7-bc07-f5d825e176b6',
    'description': 'Loggly API key',
    'editable': false
  },
  {
    'name': 'GOOGLE_MAPS_API_KEY',
    'type': 'string',
    'default': 'AIzaSyAVEpI4gszfNkp6mDi_Pjwcb1dycL4zUCA',
    'description': 'Google Maps API key, for ride tracking',
    'editable': false
  }
];
