import React from 'react';
import { Layout, Flex } from 'app/components/base/Layout';
import { FormattedMessage } from 'translations';
import classnames from 'classnames';

import style from './style.css';


const messages = {
  departure: { id: 'TRIP_DETAILS.DEPARTURE' },
  arrival: { id: 'TRIP_DETAILS.ARRIVAL' },
  transfers: { id: 'TRIP_DETAILS.TRANSFERS' },
  duration: { id: 'TRIP_DETAILS.DURATION' }
};

const DepartureTimeTable = ({ children, className, compactHeader, ticketTypes }) => {
  const ticketTypeNames = ticketTypes.map(t => t.ticketName);
  return (
    <Layout
      className={className}
      nowrap size="noshrink" direction="column" align="start stretch"
    >
      <Layout
        nowrap
        size="noshrink"
        direction="row"
        align="start center"
        className={classnames(style.table_header, { [style.compact_header]: compactHeader })}
      >
        <Flex size="nogrow">
          <div className={style.table_header__field}>
            <span> <FormattedMessage {...messages.departure} /> </span>
          </div>
        </Flex>
        <Flex size="nogrow">
          <div className={style.table_header__field}>
            <span> <FormattedMessage {...messages.arrival} /> </span>
          </div>
        </Flex>
        <Flex size="nogrow">
          <div className={style.table_header__field}>
            <span> <FormattedMessage {...messages.transfers} /> </span>
          </div>
        </Flex>
        <Flex size="nogrow">
          <div className={classnames(style.table_header__field, style.table_header__duration)}>
            <span> <FormattedMessage {...messages.duration} /> </span>
          </div>
        </Flex>
        {ticketTypeNames.map((name, index) => (
          <Flex size="auto" key={index} className={style.table_header__ticket}>
            <div className={style.table_header__ticket__title}>
              <span>{name}</span>
            </div>
          </Flex>
        ))}
      </Layout>
      <Layout
        className={style.table_children}
        nowrap size="noshrink" direction="column" align="start stretch"
      >
        {children}
      </Layout>
    </Layout>
  );
};

// TODO: move to Flow types
// DepartureTimeTable.propTypes = {
//   children: PropTypes.node,
//   className: PropTypes.string,
//   compactHeader: PropTypes.bool,
//   ticketTypes: PropTypes.array
// };

export default DepartureTimeTable;
