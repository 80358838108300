import { createReducer } from '../utils/redux';

import {
  SHOW_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
  NOTIFICATION_INFO
} from '../constants';

const initialState = {
  message: null,
  messageId: null,
  messageIntl: null,
  title: null,
  titleId: null,
  type: NOTIFICATION_INFO
};

const actionHandlers = {
  [CLEAR_NOTIFICATIONS]: () => Object.assign({}, initialState),
  [SHOW_NOTIFICATION]: (_, action) => {
    const { type, messageIntl, message, messageId, title, titleId } = action;
    return {
      type,
      message,
      messageId,
      messageIntl,
      title,
      titleId
    };
  }
};

export default createReducer(initialState, actionHandlers);
