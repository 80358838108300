import { call, select } from 'redux-saga/effects';
import { getMeasure } from 'app/utils/stats';
import { getSearchId } from 'app/sagas/selectors';
import { tracking } from 'mz-sdk';
import {
  TRACK_SEARCH_FIRST_RESULT_TIME,
  TRACK_SEARCH_RESULTS_LOAD,
  TRACK_SEARCH_POLL_TIME,
  TRACK_INIT_PAGE_LOAD
} from 'app/constants';


/**
 * Getting SRP measures of page load time and polling time and if measures
 * is correct send it to the backend.
 * @return {Generator}
 */
export default function* sendSearchPerf() {
  const searchId = yield select(getSearchId);
  const initMeasure = yield call(getMeasure, TRACK_INIT_PAGE_LOAD);
  const pollMeasure = yield call(getMeasure, TRACK_SEARCH_POLL_TIME);
  const searchMeasure = yield call(getMeasure, TRACK_SEARCH_RESULTS_LOAD);
  let firstResultMeasure = yield call(getMeasure, TRACK_SEARCH_FIRST_RESULT_TIME)
  firstResultMeasure = firstResultMeasure || pollMeasure

  if (!initMeasure || !pollMeasure || !searchMeasure || !searchId) {
    return;
  }

  // Get SRP load_time start point. It is a bit hacky, but works.
  // It works because `searchMeasure` started inside `componentWillMount`
  // of SearchResultsPage, which is a child of AppPage, and `initMeasure`
  // ended in `componentDidMount` of AppPage. So `componentWillMount` of
  // SearchResultsPage invoked before `componentDidMount` of AppPage.
  let loadStart = searchMeasure.start;
  if (searchMeasure.start - initMeasure.end < 0) {
    loadStart = initMeasure.start;
  }

  const loadTime = searchMeasure.end - loadStart;
  const pollTime = pollMeasure.end - pollMeasure.start;
  const firstResultTime = firstResultMeasure.end - firstResultMeasure.start;

  if (process.env.NODE_ENV === 'development') {
    console.log(`SRP load time: ${loadTime / 1000.0}`); // eslint-disable-line
    console.log(`SRP results polling time: ${pollTime / 1000.0}`); // eslint-disable-line
    console.log(`SRP first result time: ${firstResultTime / 1000.0}`); // eslint-disable-line
  }

  yield call(tracking.trackSearchQuality, {
    search_id: searchId,
    load_time: loadTime,
    poll_time: pollTime,
    first_result_time: firstResultTime,
  });
}
