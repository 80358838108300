import React from 'react';
import { FormattedMessage } from 'translations';
import Helmet from 'react-helmet';
import config from 'config';
import _ from 'lodash'


/**
 * Remove from given url Mozio word of partner ref enabled
 * @param  {string} title
 * @return {string}
 */
const prepareTitle = (title) => {
  if (title && config.PARTNER_REF) {
    const unbrandedTitle = _.trim(title.replace(/Mozio/i, ''), ' |-–');
    return `${unbrandedTitle} | ${config.EMAIL_SITE_NAME}`;
  }
  return title;
};

/**
 * Adds internationalized metatag information for a Mozio page.
 * Supports title, meta and link tags.
 * Children pages can specify metadata and overwrite the parent's view.
 *
 * @uses Helmet react-helmet is a document head information manager
 * @see https://github.com/nfl/react-helmet
 *
 * @param {Object} titleMessage The message object for the title meta element.
 * @param {Array} metaTags Array of key-value objects containing the property
 *                         and content for a meta tag.
 * @param {Array} links Contains objects to be displayed as links.
 */
const MetaInfo = ({
  titleMessage,
  metaTags,
}) => (
  <div>
    <FormattedMessage {...titleMessage}>
      {title => <Helmet><title>{prepareTitle(title)}</title></Helmet>}
    </FormattedMessage>
    {metaTags.map(metaInfo => {
      if (!metaInfo.value || !metaInfo.value.id) {
        return (
          <Helmet key={metaInfo.name}>
            <meta property={metaInfo.name} content={metaInfo.value} />
          </Helmet>
        );
      }
      return (
        <FormattedMessage key={metaInfo.name} {...metaInfo.value}>
          {(metaVal) => (
            <Helmet key={metaInfo.name}>
              <meta property={metaInfo.name} content={metaVal} />
            </Helmet>
          )}
        </FormattedMessage>
      );
    })}
  </div>
);

// TODO: move to Flow types
// MetaInfo.propTypes = {
//   titleMessage: PropTypes.object,
//   metaTags: PropTypes.array,
//   links: PropTypes.array
// };

export default MetaInfo;
