import thunk from 'redux-thunk';
import camelize from 'camelize';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { promiseMiddleware, deferredMiddleware } from './utils/redux';
import { hook } from './utils/hook';
import { composeWithDevTools } from 'redux-devtools-extension';
import { REDUCERS_HOOK } from 'app/constants';


// Create combined reducers
let reducersContext = null;
const createCombinedReducer = () => {
  reducersContext = require.context('./reducers/', true, /^\.\/[^\/]+\.js$/);
  const reducers = {};

  reducersContext.keys().forEach(x => {
    const reducerName = camelize(x.substr(x.lastIndexOf('/') + 1, x.length - 5));
    reducers[reducerName] = reducersContext(x).default;
  });

  return combineReducers({
    ...reducers,
    ...hook(REDUCERS_HOOK)
  });
};


// Finally store configurator function
export default function configureStore(initialState, middlewares = []) {
  const reducer = createCombinedReducer();
  const finalCreateStore = composeWithDevTools(
    applyMiddleware(
      thunk,
      promiseMiddleware,
      deferredMiddleware,
      ...middlewares
    )
  )(createStore);

  const store = finalCreateStore(reducer, initialState);

  if (module.hot) {
    module.hot.accept(reducersContext.id, () => {
      const nextReducer = createCombinedReducer();
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}
