import {
  INIT_APP,

  DEPARTING_FLIGHT_FIELD_NAME,
  RETURNING_FLIGHT_FIELD_NAME,

  FIRST_NAME_FIELD_NAME,
  LAST_NAME_FIELD_NAME,
  EMAIL_FIELD_NAME,
  EMAIL_CONFIRMATION_FIELD_NAME,
  PHONE_CODE_FIELD_NAME,
  PHONE_NUMBER_FIELD_NAME,
  COUPON_CODE_FIELD_NAME,

  SESSION_ID_FIELD_NAME,
  DEPARTING_TRIP_FIELD_NAME,

  RETURN_TRIP_FIELD_NAME,
  RETURN_BOOK_STATE_FIELD_NAME,
  RETURN_TRIP_BOOKED,
  RETURN_DATE_SELECTED,

  RETURNING_PICKUP_FIELD_NAME,
  RETURN_DATETIME_FIELD_NAME,
  DEPARTING_PICKUP_FIELD_NAME,

  TRIP_PICKUP_TIMES_ORIGIN_TZ_FIELD_NAME,
  ALTERNATIVE_TIME_INDEX,
  RETURN_ALTERNATIVE_TIME_INDEX,
  SELECTED_TICKET_FIELD_NAME,
  SELECTED_RETURN_TICKET_FIELD_NAME,
  RIDE_DURATION_FIELD_NAME,
  SPECIAL_INSTRUCTIONS_FIELD_NAME,

  CUSTOMIZE_BOOKING_FORM_DATA_HOOK,
  ON_DEMAND_CATEGORY
} from 'app/constants';
import config from 'config'
import { hook } from 'app/utils/hook';
import { call, select, take, put } from 'redux-saga/effects';
import {
  isLoading,
  getFieldsFromUser,
  getFieldsFromCustomer,
  getChangedModeTrip,
  getBookTripForm,
  isTravelAgentCredits,
  getActiveLanguage,
  getSearchResultsTrips,
  getUser,
} from 'app/sagas/selectors';
import { initialValues } from 'app/pages/BookTripPage/builder';
import { findLanguage } from 'mz-intl/core/languages'
import getTrip from './getTrip';
import { parseUrlParams } from 'app/sagas/utils';
import { getMainStep } from 'app/utils/trip';
import { setChangedModeTrip } from 'app/actions/book-trip';
import {
  getTripTimeParam,
  getTripReturnTimeParam
} from './processResearchTrip/getSearchParamsFromTrip';
import {
  getDefaultTicket
} from 'app/components/search-results/TimeTableSelect/utils/getTripScheduleProps';
import _ from 'lodash';
import moment from 'moment';
import { countryCodeToRawSuggestion } from 'app/utils/types';
import { favouritePlaces } from 'mz-sdk';
import { formatMessage } from 'app/translationsManager';
import favouritesMessages from 'app/pages/UserFavouriteLocationsPage/messages.intl';
import { createFlight } from 'mz-sdk/services/flights'
import { resolveFlight } from '../../watchSearchForm/resolveFormValues'

export const getFieldsFromLink = (values) => {
  return !values ? {} : _.omitBy({
    // flight values
    [DEPARTING_FLIGHT_FIELD_NAME]: createFlight(values.airline, values.flight_number),
    [RETURNING_FLIGHT_FIELD_NAME]: createFlight(values.airline2, values.flight_number2),

    // user values
    [FIRST_NAME_FIELD_NAME]: values.first_name,
    [LAST_NAME_FIELD_NAME]: values.last_name,
    [EMAIL_FIELD_NAME]: values.email,
    [EMAIL_CONFIRMATION_FIELD_NAME]: values.email,
    [PHONE_CODE_FIELD_NAME]: countryCodeToRawSuggestion(values.dial_code, true),
    [PHONE_NUMBER_FIELD_NAME]: values.phone_number,
    [COUPON_CODE_FIELD_NAME]: values.coupon_code,
    [SPECIAL_INSTRUCTIONS_FIELD_NAME]: values.customer_special_instructions,
  }, _.isNil);
};

/**
 * Resolves flights provided in the URL to real flight objects if possible,
 * otherwise empty the flight fields
 *
 * @param {*} values
 */
async function resolveFlights(values) {
  const shouldResolveFlight = (flightField) => {
    const currFlight = values[flightField];
    return currFlight && !currFlight.flightDatetime;
  }

  const doResolveFlight = async (flightField, tripField, pickupDateField) => {
    if (!shouldResolveFlight(flightField)) {
      return;
    }

    const { airlineCode, flightNumber } = values[flightField]
    const trip = values[tripField]

    const resolvedFlight = await resolveFlight({
      airline: airlineCode,
      flightNumber,
      pickupDate: values[pickupDateField],
      startLocation: trip.startLocation,
      endLocation: trip.endLocation,
    })

    // Keep the unresolved flight if the user forcefully selected it
    if (resolvedFlight) {
      values[flightField] = resolvedFlight
    }
  }

  await Promise.all([
    doResolveFlight(DEPARTING_FLIGHT_FIELD_NAME, DEPARTING_TRIP_FIELD_NAME, DEPARTING_PICKUP_FIELD_NAME),
    doResolveFlight(RETURNING_FLIGHT_FIELD_NAME, RETURN_TRIP_FIELD_NAME, RETURNING_PICKUP_FIELD_NAME),
  ]);
}

/**
 * Creates object with form fields. If trip_id and session_id are not set - parses them from url.
 * It is also trying to fill the form with fields from logged in user object if it is
 * defined.
 * @param {Object}  options.trip_id    trip object
 * @param {String}  options.trip_id    requested trip id
 * @param {String}  options.session_id  session_id of requested trip
 * @param {Boolean} options.reloadTrip  Flag to load only price relatd fields
 * @return {Object} object with form values
 */
export default function* resolveFormValues({
  updatedTrip,
  trip_id,
  session_id,
  ondemand,
  reloadTrip = false
}) {
  if (yield select(isLoading)) {
    yield take(INIT_APP);
  }

  const user = yield select(getUser)
  const parsed = yield call(parseUrlParams);
  const tripId = (updatedTrip && updatedTrip.id) || trip_id || parsed.trip_id;
  const sessionId = (updatedTrip && updatedTrip.searchId) || session_id || parsed.session_id;
  const isOndemand = (updatedTrip && updatedTrip.type === ON_DEMAND_CATEGORY) || ondemand || parsed.ondemand; // eslint-disable-line

  // on first load formValues - same as initial,
  // during trip reloading - previous entered by user and others
  const formValues = reloadTrip
    ? yield select(getBookTripForm)
    : initialValues;

  // in case of trip reload from "TripUpdated" modal, we skip
  const trip = !updatedTrip
    ? yield call(getTrip, tripId, sessionId, isOndemand)
    : updatedTrip;

  const {
    alternativeTimes: departureAlternativeTimes,
    alternativeTimesIndex: departureAlternativeTimesIndex = 0,
    ticketTypes = [],
    bookingDetails
  } = getMainStep(trip);

  const isRoundtrip = !_.isEmpty(trip.returnSteps);
  let returnBookState = isRoundtrip ? RETURN_TRIP_BOOKED : null;

  if (reloadTrip) {
    const changedModeTrip = yield select(getChangedModeTrip);
    const bookingRoundTrip = !_.isEmpty(changedModeTrip && changedModeTrip.returnSteps);
    returnBookState = returnBookState || (bookingRoundTrip && RETURN_DATE_SELECTED || null);
  } else {
    yield put(setChangedModeTrip(null));
  }

  const departurePickupDatetimeString = departureAlternativeTimes[departureAlternativeTimesIndex];
  const departurePickupTimeTripZone = departurePickupDatetimeString
    ? moment.parseZone(departurePickupDatetimeString)
    : getTripTimeParam(trip).value;

  const locale = yield select(getActiveLanguage);

  const localePhoneCode = findLanguage(locale).code;
  const defaultPhoneCode = config.DEFAULT_PHONE_COUNTRY.toLowerCase() || localePhoneCode

  let departureTicket = null;
  const searchResults = yield select(getSearchResultsTrips);
  const tripFromSRP = searchResults && searchResults[tripId];
  const defaultDepartureTicket = getDefaultTicket(ticketTypes);

  // chek if ticket was selected on SRP. If no - then get default
  if (tripFromSRP && tripFromSRP.departureTicket) {
    departureTicket = tripFromSRP.departureTicket;
  } else if (ticketTypes.length > 0) {
    departureTicket = defaultDepartureTicket;
  }

  // set special instructions
  let specialInstructions = '';
  if (user && trip.startLocation.favoriteId) {
    const pickupFavouritePlace = yield call(favouritePlaces.get, {
      id: trip.startLocation.favoriteId,
      favoriteSource: trip.startLocation.favoriteSource
    });

    const instructions = (pickupFavouritePlace.special_instructions || '').trim();

    if (instructions) {
      specialInstructions = formatMessage(favouritesMessages.PICKUP_INSTRUCTIONS, { instructions });
    }
  }

  if (user && trip.endLocation && trip.endLocation.favoriteId) {
    const dropOffFavouritePlace = yield call(favouritePlaces.get, {
      id: trip.endLocation.favoriteId,
      favoriteSource: trip.endLocation.favoriteSource
    });
    const instructions = (dropOffFavouritePlace.special_instructions || '').trim();
    let dropOffInstructions = '';
    if (instructions) {
      dropOffInstructions = formatMessage(
        favouritesMessages.DROPOFF_INSTRUCTIONS,
        { instructions }
      );
      // split pickup and drop-off with empty line
      specialInstructions = specialInstructions
        ? `${specialInstructions}\n${dropOffInstructions}`
        : dropOffInstructions;
    }
  }

  const values = {
    ...formValues,
    [PHONE_CODE_FIELD_NAME]: formValues[PHONE_CODE_FIELD_NAME]
      || countryCodeToRawSuggestion(defaultPhoneCode),
    [DEPARTING_TRIP_FIELD_NAME]: trip,
    [SESSION_ID_FIELD_NAME]: sessionId,
    [RETURN_BOOK_STATE_FIELD_NAME]: returnBookState,
    [DEPARTING_PICKUP_FIELD_NAME]: departurePickupTimeTripZone,
    [ALTERNATIVE_TIME_INDEX]: departureAlternativeTimesIndex,
    [RETURN_DATETIME_FIELD_NAME]: departurePickupTimeTripZone.clone().add(1, 'days'),
    [TRIP_PICKUP_TIMES_ORIGIN_TZ_FIELD_NAME]: {
      [DEPARTING_PICKUP_FIELD_NAME]: departurePickupTimeTripZone
    },
    [SELECTED_TICKET_FIELD_NAME]: (
      formValues[SELECTED_TICKET_FIELD_NAME] || departureTicket
    ),
    [RIDE_DURATION_FIELD_NAME]: bookingDetails.hourlyDetails
      ? bookingDetails.hourlyDetails.hoursRequested
      : 0,
    [SPECIAL_INSTRUCTIONS_FIELD_NAME]: formValues[SPECIAL_INSTRUCTIONS_FIELD_NAME] || specialInstructions || ''
  };

  // during reloading trip (research after pickup time changed)
  // we only change form fields related to trip
  if (!reloadTrip) {
    const isTACredits = yield select(isTravelAgentCredits);

    if (!isTACredits) {
      const userFields = yield select(getFieldsFromUser);
      const customerFields = yield select(getFieldsFromCustomer, parsed.customer_id);

      Object.assign(values, {
        ...userFields,
        ...customerFields
      });

      const customizedValues = yield hook(CUSTOMIZE_BOOKING_FORM_DATA_HOOK, values);
      Object.assign(values, customizedValues);
    }

    const linkFields = getFieldsFromLink(parsed);
    Object.assign(values, { ...linkFields });
  }

  if (isRoundtrip) {
    const returnTrip = {
      ...trip,
      steps: trip.returnSteps
    };

    const {
      alternativeTimes: returnAlternativeTimes,
      alternativeTimesIndex: returnAlternativeTimesIndex = 0,
      ticketTypes: returnTicketTypes = []
    } = getMainStep(returnTrip);

    const returnPickupDatetimeString = returnAlternativeTimes[returnAlternativeTimesIndex];

    const returnPickupTimeTripZone = returnPickupDatetimeString
      ? moment.parseZone(returnPickupDatetimeString)
      : getTripReturnTimeParam(trip).value;

    let returnTicket = null;
    const defaultReturnTicket = getDefaultTicket(returnTicketTypes);
    // chek if ticket was selected on SRP. If no - then get default
    if (tripFromSRP && tripFromSRP.returnTicket) {
      returnTicket = tripFromSRP.returnTicket;
    } else if (returnTicketTypes.length > 0) {
      returnTicket = defaultReturnTicket;
    }

    Object.assign(values, {
      [RETURN_TRIP_FIELD_NAME]: returnTrip,
      [RETURNING_PICKUP_FIELD_NAME]: returnPickupTimeTripZone,
      [RETURN_ALTERNATIVE_TIME_INDEX]: returnAlternativeTimesIndex,
      [TRIP_PICKUP_TIMES_ORIGIN_TZ_FIELD_NAME]: {
        ...values[TRIP_PICKUP_TIMES_ORIGIN_TZ_FIELD_NAME],
        [RETURNING_PICKUP_FIELD_NAME]: returnPickupTimeTripZone
      },
      [SELECTED_RETURN_TICKET_FIELD_NAME]: (
        formValues[SELECTED_RETURN_TICKET_FIELD_NAME] || returnTicket
      )
    });
  }

  if (!reloadTrip) {
    const resolvedFlights = yield call(resolveFlights, values);
    Object.assign(values, { ...resolvedFlights });
  }

  return values;
}
