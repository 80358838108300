import config from 'config'
import omitEmptyFields from 'mz-utils/omitEmptyFields'
import { call, select } from 'redux-saga/effects';
import { getHistory } from 'app/history';
import { showErrorNotification, handleRefParam, parseUrlParams } from 'app/sagas/utils';
import { auth, travelagent } from 'mz-sdk';
import { getActiveLanguageObject, getCurrency } from 'app/sagas/selectors'
import doLogin from './doLogin';
import posthog from 'posthog-js';

export default function* doRegister(action) {
  const { payload } = action
  const basicInfo = {
    captcha_token: payload.captcha_token,
    email: payload.contacts.email,
    password: payload.password
  }

  const contactInfo = {
    first_name: payload.contacts.first_name,
    last_name: payload.contacts.last_name,
    country_code_name: payload.contacts.phone.country.code.toUpperCase(),
    phone_number: payload.contacts.phone.number,
  }

  const currencyLanguage = yield select(state => ({
    language: getActiveLanguageObject(state).value,
    currency: getCurrency(state),
  }))

  let travelAgentInfo = null
  if (payload.travel_agent?.enabled) {
    const companyValue = payload.travel_agent.company
    const companyFieldName = companyValue.company_code
      ? { company_code: companyValue.company_code }
      : { company_name: companyValue.name }

    travelAgentInfo = {
      ...companyFieldName,
      currency: payload.travel_agent.currency.code,
      country: contactInfo.country_code_name.substr(0, 2),
    }

  } else if (config.TRAVEL_AGENT_COMPANY) {
    travelAgentInfo = {
      company_code: config.TRAVEL_AGENT_COMPANY,
      country: contactInfo.country_code_name.substr(0, 2),

      // FIXME: we are sending 0 here because it is needed by LaPremiere,
      // the only WL that need TA tool whitelabeled. If it will be necessary
      // we can replace it with WLF config field with default to 0
      commission: 0,
    }
  }

  let refParams = null
  if (travelAgentInfo) {
    const refObj = yield call(handleRefParam)
    const { referral, commission } = yield call(parseUrlParams)
    refParams = omitEmptyFields({
      ...refObj,
      referral,
      commission,
    })
  }

  let extrasFields = null
  if (payload.extras) {
    extrasFields = omitEmptyFields({
      extra_data: Object.entries(payload.extras)
        .map(pair => ({
          name: pair[0],
          value: (pair[1] || '').trim(),
        }))
        .filter(item => item.value)
    }, { array: true })
  }

  const registerMethod = travelAgentInfo ? travelagent.register : auth.register
  const registerRegusts = {
    ...basicInfo,
    ...contactInfo,
    ...currencyLanguage,
    ...travelAgentInfo,
    ...refParams,
    ...extrasFields,
  }

  try {
    if(travelAgentInfo){
      posthog.capture('Old Flow: TA Sign UP Form Submitted', {
        currency: payload.travel_agent?.currency?.code,
        company: payload.travel_agent?.company?.name,
        country_region_code: payload.contacts?.phone?.country?.code
      });
    } else {
      posthog.capture('Old Flow: Sign Up Form Submitted', {
        country_region_code: payload.contacts?.phone?.country?.code
      });
    }

    const data = yield call(registerMethod, registerRegusts);

    if(data){
      posthog.capture('Old Flow: User Account Created', { sign_up_method: 'email' });
    }

    if (config.REQUIRE_VERIFIED_EMAIL) {
      const history = yield select(getHistory);
      history.push('/user/check-your-email');
    } else {
      yield call(doLogin, { payload: basicInfo }, false);
    }

    yield call(action.resolvePromise);
  } catch (error) {
    yield call(showErrorNotification, {
      error,
      titleId: 'USER.REGISTER_ERROR_TITLE'
    });
    yield call(action.rejectPromise, error);
  }
}
