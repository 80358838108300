const PALETTE = 'palette';

export default [
  {
    'name': 'FIRST_BACKGROUND_COLOR',
    'type': 'color',
    'default': '#0D84EB',
    'description': 'Primary color',
    'category': PALETTE
  },
  {
    'name': 'SECOND_BACKGROUND_COLOR',
    'type': 'color',
    'default': '#0D84EB',
    'description': 'Second color',
    'category': PALETTE
  },
  {
    'name': 'THIRD_BACKGROUND_COLOR',
    'type': 'color',
    'default': '#0D84EB',
    'description': 'Third color',
    'category': PALETTE
  },
  {
    'name': 'FOURTH_BACKGROUND_COLOR',
    'type': 'color',
    'default': '#FCD012',
    'description': 'Fourth color',
    'category': PALETTE
  },
  {
    'name': 'CTA_BACKGROUND_COLOR',
    'type': 'color',
    'default': '#FCD012',
    'description': 'CTA color',
    'category': PALETTE
  },
  {
    'name': 'FIRST_CONTRAST_COLOR',
    'type': 'color',
    'default': '#FFFFFF',
    'description': 'Primary contrast text color',
    'category': PALETTE
  },
  {
    'name': 'SECOND_CONTRAST_COLOR',
    'type': 'color',
    'default': '#FFFFFF',
    'description': 'Second contrast text color',
    'category': PALETTE
  },
  {
    'name': 'THIRD_CONTRAST_COLOR',
    'type': 'color',
    'default': '#000000',
    'description': 'Third contrast text color',
    'category': PALETTE
  },
  {
    'name': 'FOURTH_CONTRAST_COLOR',
    'type': 'color',
    'default': '#000000',
    'description': 'Fourth contrast text color',
    'category': PALETTE
  },
  {
    'name': 'CTA_CONTRAST_COLOR',
    'type': 'color',
    'default': '#000000',
    'description': 'CTA contrast text color',
    'category': PALETTE
  }
];
