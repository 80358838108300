import React, { Component } from 'react';
import GoogleMap from 'google-map-react';
import config from 'config';
import StartPoint from './StartPoint';
import EndPoint from './EndPoint';
import TransferPoint from './TransferPoint';
import mapStyles from 'app/components/tracking/BaseMap/mapStyles';
import Polyline from './Polyline';
import { fitBounds } from 'google-map-react/utils';
import _ from 'lodash';


/**
 * Component for displaying directions of selected trip
 */
class SrpMap extends Component {

  mapContainerDOM = null

  constructor(props) {
    super(props);

    this.state = {
      zoom: props.zoom,
      center: [props.startCoords?.lat, props.startCoords?.lng],
      drawRoute: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const directionsChanged = !_.isEqual(nextProps.directions, this.props.directions);
    const mapSizeChanged = nextProps.mapSizeChanged !== this.props.mapSizeChanged;

    if (directionsChanged || mapSizeChanged) {
      this.fitMap(nextProps.directions);
    }
  }


  // called when changed zoom or center of map
  onChange = ({ center, zoom }) => {
    this.setState({
      zoom,
      center
    });
  }

  // returns transip points of route
  getTransferPoints() {
    const { directions } = this.props;

    if (!directions || directions.length === 1) {
      return [];
    }

    const points = directions
      .filter((step, index) => index !== 0)
      .map(step => step.directions[0]);

    return points;
  }

  // returns components for rendering
  getRouteLegs = () => {
    if (!this.hasDirections) return [];

    return this.props.directions
      .map(step => {
        return step.category === 'walk'
          ? {
            path: step.directions || [],
            strokeColor: '#444444',
            strokeOpacity: 0,
            icons: [{
              icon: {
                path: 'M 0,-1 0,1',
                strokeOpacity: 1,
                scale: 3
              },
              offset: '0',
              repeat: '15px'
            }]
          }
          : {
            path: step.directions || [],
            strokeColor: step.lineColor || '#0D84EB',
            strokeWeight: 5,
          };
      });
  }

  getShiftedCoords({ lat, lng }, isNW = false) {
    const { maps, map } = this;

    let pointCoords = new maps.LatLng(lat, lng);
    const offsetY = 50; // height of marker
    const offsetX = 50; // width of marker
    const scale = Math.pow(2, this.state.zoom);

    const pointProjection = map.getProjection().fromLatLngToPoint(pointCoords);
    const pixelOffset = new maps.Point((offsetX / scale) || 0, (offsetY / scale) || 0);

    const shiftedPoint = new maps.Point(
        pointProjection.x + (isNW ? - 1 : 1) * pixelOffset.x,
        pointProjection.y + (isNW ? - 1 : 1) * pixelOffset.y
    );

    pointCoords = this.map.getProjection().fromPointToLatLng(shiftedPoint);

    return {
      lat: pointCoords.lat(),
      lng: pointCoords.lng()
    };
  }

  // called when map initialized
  initMap = ({ map, maps }) => {
    this.map = map; // Map instance
    this.maps = maps; // Google Maps API handle.

    this.fitMap(this.props.directions);
  }

  get hasDirections() {
    const { directions } = this.props;
    return directions && directions.filter(x => x.directions).length > 0;
  }

  fitMap = (tripDirections) => {
    const tripSteps = _.flatten(tripDirections.map(step => step.directions))
      .filter(x => !_.isNil(x));
    if (!tripSteps.length) return;

    const { maps } = this;

    if (!maps || !this.mapContainerDOM) return;

    const mapBounds = new maps.LatLngBounds();

    tripSteps.forEach(point => mapBounds.extend(new maps.LatLng(point.lat, point.lng)));

    const NE = mapBounds.getNorthEast();
    const SW = mapBounds.getSouthWest();

    const originalNW = { lat: NE.lat(), lng: SW.lng() };
    const originalSE = { lat: SW.lat(), lng: NE.lng() };

    // shift bounds to make sure that markers (start/end) will fit into map
    const bounds = {
      nw: this.getShiftedCoords(originalNW, true),
      se: this.getShiftedCoords(originalSE)
    };

    const mapSize = {
      width: this.mapContainerDOM.offsetWidth, // Map width in pixels
      height: this.mapContainerDOM.offsetHeight // Map height in pixels
    };

    const { center, zoom } = fitBounds(bounds, mapSize);

    this.setState({
      zoom,
      center,
      drawRoute: true
    });
  }

  googleMapLoaderHandler = (bootstrapURLKeys) => {
    return new Promise((resolve, reject) => {
      GoogleMap.googleMapLoader(bootstrapURLKeys)
        .then(maps => resolve(maps))
        .catch(err => {
          reject(err);
          // TODO: handle error here
        });
    });
  }

  render() {
    const { directions: tripDirections } = this.props;

    const startLocation = this.hasDirections && tripDirections[0].directions[0];
    const endLocation = this.hasDirections && _.last(_.last(tripDirections).directions);

    return (
      <div
        ref={el => this.mapContainerDOM = el}
        style={{ height: '100%' }}
      >
        <GoogleMap
          center={this.state.center}
          zoom={this.state.zoom}
          bootstrapURLKeys={{ key: config.GOOGLE_MAPS_API_KEY }}
          onChange={this.onChange}
          onGoogleApiLoaded={this.initMap}
          yesIWantToUseGoogleMapApiInternals
          googleMapLoader={this.googleMapLoaderHandler}
          options={{
            styles: mapStyles,
            zoomControlOptions: {
              position: 3 // google.maps.ControlPosition.TOP_RIGHT
            }
          }}
          style={{ position: 'relative', width: '100%', height: '100%' }}
        >
          {startLocation && <StartPoint {...startLocation} />}
          {endLocation && <EndPoint {...endLocation} />}
          {this.getTransferPoints().map((point, index) => (
            <TransferPoint key={index} {...point} />
          ))}

          {this.state.drawRoute && this.map && (
            this.getRouteLegs().map((polyline, i) => (
              <Polyline
                key={i}
                maps={this.maps}
                map={this.map}
                {...polyline}
              />
            ))
          )}
        </GoogleMap>
      </div>
    );
  }
}

// TODO: move to Flow types
// SrpMap.propTypes = {
//   startCoords: PropTypes.object,
//   endCoords: PropTypes.object,
//   directions: PropTypes.array,
//   zoom: PropTypes.number,
//   mobile: PropTypes.bool,
//   mapSizeChanged: PropTypes.number
// };

SrpMap.defaultProps = {
  zoom: 12
};

export default SrpMap;
