import createMessages from 'mz-intl/create';
import createMessagesObject from '../createMessagesObject'
import nextSources from '../sources/next-base.sources-en_US.json'

export const DOMAIN = 'NEXT_BASE'

export default createMessages(
  {
    domain: DOMAIN,
    resource: 'next-base',
    allowUnreviewed: true,
    resourceWithoutDomainPrefix: true,
  },
  createMessagesObject(nextSources)
)
