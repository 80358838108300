import _ from 'lodash'
import {
  IS_FLIGHT_TIME_FIELD_NAME,
  IS_RETURN_FLIGHT_TIME_FIELD_NAME
} from 'app/constants';
import formConfig from 'app/forms/search-form';
import { call, select, put } from 'redux-saga/effects';
import { getSearchForm } from 'app/sagas/selectors';
import { change } from 'redux-form';
import { outdateSearch } from 'app/actions/search-results';
import resolvePickupDatetime from './resolvePickupDatetime';


export default function* convertFlightToPickup() {
  const searchForm = yield select(getSearchForm);
  if (!searchForm) return;
  
  const { start_address, end_address, pickup_datetime, return_pickup_datetime } = searchForm
  let somethingChanged = false

  if (
    pickup_datetime && searchForm[IS_FLIGHT_TIME_FIELD_NAME] &&
    !_.isEmpty(start_address) && !_.isEmpty(end_address)
  ) {
    const newPickupTime = yield call(
      resolvePickupDatetime,
      pickup_datetime,
      start_address,
      end_address,
      end_address.type === 'airport'
    )
    yield put(change(formConfig.form, IS_FLIGHT_TIME_FIELD_NAME, false));
    yield put(change(formConfig.form, 'pickup_datetime', newPickupTime));
    somethingChanged = true
  }

  if (
    return_pickup_datetime && searchForm[IS_RETURN_FLIGHT_TIME_FIELD_NAME] &&
    !_.isEmpty(start_address) && !_.isEmpty(end_address)
  ) {
    const newPickupTime = yield call(
      resolvePickupDatetime,
      return_pickup_datetime,
      start_address,
      end_address,
      start_address.type === 'airport'
    );
    yield put(change(formConfig.form, IS_RETURN_FLIGHT_TIME_FIELD_NAME, false));
    yield put(change(formConfig.form, 'return_pickup_datetime', newPickupTime));
    somethingChanged = true
  }

  if (somethingChanged) {
    yield put(outdateSearch(false))
  }
}
