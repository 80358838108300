import languages from 'mz-intl/core/languages'


const toOldFormat = locale => locale.replace(/(-.*$)/, match => match.replace('-', '_').toUpperCase())

const API_LOCALES_MAP = {
  'es-mx': 'es-es',
  'es-ar': 'es-es',
  'en-ca': 'en-us',
}

const langsInOldFormat = Object.values(languages)
  .map(lang => ({
    text: lang.name,
    value: lang.value,
    code: lang.code,
    intlLocale: lang.intl,
    momentLocale: lang.moment,
    zendeskLocale: lang.zendeskLocale,
    apiLocale: API_LOCALES_MAP[lang.value] || lang.value
  }))
  .reduce(
    (obj, lang) => {
      const oldLangKey = toOldFormat(lang.value);
      return {
        ...obj,
        [oldLangKey]: lang,
      };
    },
    {}
  );

export default langsInOldFormat;
