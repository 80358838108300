import { createReducer } from 'app/utils/redux';
import {
  DO_BOOK_TRIP,
  SET_TRIP_ERROR,
  CLEAR_TRIP_ERROR,
  START_UPDATE_REQUEST,
  END_UPDATE_REQUEST,
  VALIDATE_FLIGHT_NUMBER,
  CLEAR_FLIGHT_VALIDATION,
  SET_CHANGED_MODE_TRIP,
  SHOW_PICKUP_TIME_CHANGED_NOTIFICATION,
  HIDE_PICKUP_TIME_CHANGED_NOTIFICATION,
  SET_CHARGE_IN_USD_MESSAGE,
  BOOKING_EMAIL_TAKEN,
  SET_TERMS_AND_CONDITIONS,
  UPDATE_PRICE,
  INCREMENT_BOOKING_ATTEMPTS
} from 'app/constants';

const initialState = {
  loading: false,
  changed_mode_trip: null,
  pickupTimeChangedInfo: {},
  showChargeInUsdMessage: false,
  emailTaken: false,
  termsAndConditions: {},
  cardBrand: '',
  bookingAttempts: 0,
};

const actionHandlers = {
  [INCREMENT_BOOKING_ATTEMPTS]: (state) => ({
    bookingAttempts: state.bookingAttempts + 1,
  }),
  [BOOKING_EMAIL_TAKEN]: (state, taken = false) => ({
    emailTaken: taken
  }),
  [START_UPDATE_REQUEST]: () => ({
    loading: true
  }),
  [END_UPDATE_REQUEST]: () => ({
    loading: false
  }),
  [SET_CHANGED_MODE_TRIP]: (_, changed_mode_trip) => ({
    changed_mode_trip
  }),
  [DO_BOOK_TRIP]: (state, { trip_id, session_id, provider, from, to, mode, ondemand }) => ({
    booking: {
      trip_id,
      session_id,
      provider,
      from,
      to,
      mode,
      ondemand
    }
  }),
  [SET_TRIP_ERROR]: (state, { errorCode, errorMessage, errorPopup, errorProps }) => {
    return {
      ...state,
      errorCode,
      errorMessage,
      errorPopup,
      errorProps
    };
  },
  [CLEAR_TRIP_ERROR]: () => ({
    errorCode: null,
    errorMessage: null,
    errorPopup: false,
    errorProps: null
  }),
  [VALIDATE_FLIGHT_NUMBER]: (state, { result, id }) => {
    const validation = result ? { ...result } : null;

    return {
      ...state,
      validations: {
        ...state.validations,
        [id]: validation
      }
    };
  },
  [CLEAR_FLIGHT_VALIDATION]: (state, { id }) => {
    return {
      ...state,
      validations: {
        ...state.validations,
        [id]: null
      }
    };
  },
  [SHOW_PICKUP_TIME_CHANGED_NOTIFICATION]: (state, { field, originTime, newTime }) => {
    return {
      ...state,
      pickupTimeChangedInfo: {
        ...state.pickupTimeChangedInfo,
        [field]: {
          originTime,
          newTime
        }
      }
    };
  },
  [HIDE_PICKUP_TIME_CHANGED_NOTIFICATION]: () => ({
    pickupTimeChangedInfo: {}
  }),
  [SET_CHARGE_IN_USD_MESSAGE]: (_, showMessage) => ({
    showChargeInUsdMessage: showMessage
  }),
  [SET_TERMS_AND_CONDITIONS]: (_, termsAndConditions) => ({
    termsAndConditions
  }),
  [UPDATE_PRICE]: (state, payload) => ({
    cardBrand: payload?.cardBrand ?? state.cardBrand
  })
};

export default createReducer(initialState, actionHandlers);
