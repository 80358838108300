import React from 'react';
import OptionPicker from '../OptionPicker';
import { getMonthOptions, isSameOption, getOptionDisplay, getOptionValue } from 'app/utils/types';

const MonthSelect = ({
  className,
  ...props
}) => {
  const monthOptions = getMonthOptions();
  return (
    <OptionPicker
      {...props}
      className={className}
      options={monthOptions}
      template={getOptionDisplay}
      transform={getOptionValue}
      comparator={isSameOption}
      noarrow
    />
  );
};

// TODO: move to Flow types
// MonthSelect.propTypes = {
//   className: PropTypes.string
// };

export default MonthSelect;
