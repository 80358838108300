/**
 * Custom config definition function. Accepts store and should return
 * an object with routes definition
 * @param {object} store
 * @type {object}
 */
export const ROUTES_CONFIG_HOOK = 'ROUTES_CONFIG_HOOK';

/**
 * Hook for adding extra reducers. Should return an object where
 * key is a name of reducer and value is reducer itself.
 * @type {object}
 */
export const REDUCERS_HOOK = 'REDUCERS_HOOK';

/**
 * Hook for extending root saga. Should return an effect or anything
 * that could be `yield`ed in a root saga
 * @return {effect|promise}
 */
export const ROOT_SAGA_HOOK = 'ROOT_SAGA_HOOK';

/**
 * Hook for making additional actions after getting user
 * data in user session watching sagas.
 * @type {String}
 */
export const GET_USER_SAGA_HOOK = 'GET_USER_SAGA_HOOK';

/**
 * Hook for adding new search arguments to the object for search request.
 * Should return an object, that will be `Object.assign` to current
 * search request. So, that way you can add or replace search fields.
 * As second argument it is also accepting current search request object,
 * so you can even delete some search fields if you like (but not recommended).
 * @param {object} state
 * @param {object} currSearchObj
 * @return {object}
 */
export const ADDITION_SEARCH_ARGUMENTS_HOOK = 'ADDITION_SEARCH_ARGUMENTS_HOOK';

/**
 * Hook for adding params to get relevant place suggestions for gbt users
 */
export const PLACE_SUGGESTIONS_PARAMS_HOOK = 'PLACE_SUGGESTIONS_PARAMS_HOOK';

/**
 * Hook for overriding config fields. Should return an object with config
 * fields, which will be `Object.assign` to `app-config` module
 * @param {object} config
 * @return {object}
 */
export const CONFIG_OVERRIDES_HOOK = 'CONFIG_OVERRIDES_HOOK';

/**
 * Decide should we show profile selector for the user or not
 * @param {object} user
 */
export const SHOW_PROFILE_SELECTOR_HOOK = 'SHOW_PROFILE_SELECTOR_HOOK';

/**
 * Decide should we show TA email confirmation or not
 * @param {object} user
 * @param {object} defaultDecision
 */
export const SHOW_TA_CONFIRMATION_EMAIL_HOOK = 'SHOW_TA_CONFIRMATION_EMAIL_HOOK';

export const SHOW_BOOK_FEE_HOOK = 'SHOW_BOOK_FEE_HOOK';

export const REQUIRE_CC_CVV_HOOK = 'REQUIRE_CC_CVV_HOOK';

export const ALLOW_CHANGE_FEE_HOOK = 'ALLOW_CHANGE_FEE_HOOK';

export const FOOTER_COMPONENT_HOOK = 'FOOTER_COMPONENT_HOOK';

export const LOAD_SEARCH_FORM_HOOK = 'LOAD_SEARCH_FORM_HOOK';

export const CONFIRMATION_PAGE_LAYOUT_HOOK = 'CONFIRMATION_PAGE_LAYOUT_HOOK';

export const OVERRIDE_USER_FIELDS_HOOK = 'OVERRIDE_USER_FIELDS_HOOK';

export const CUSTOM_GET_PRICE_SAGA_HOOK = 'CUSTOM_GET_PRICE_SAGA_HOOK';

export const CUSTOMIZE_RESERVATION_DATA_HOOK = 'CUSTOMIZE_RESERVATION_DATA_HOOK';

export const AFTER_CONFIRM_BOOKING_HOOK = 'AFTER_CONFIRM_BOOKING_HOOK';

export const CUSTOMIZE_BOOKING_FORM_DATA_HOOK = 'CUSTOMIZE_BOOKING_FORM_DATA_HOOK';

export const AFTER_CANCEL_RESERVATION_HOOK = 'AFTER_CANCEL_RESERVATION_HOOK';

export const USER_ROUTES_HOOK = 'USER_ROUTES_HOOK';

export const MAIN_ROUTES_HOOK = 'MAIN_ROUTES_HOOK';

export const APP_LOGO_IMAGE_HOOK = 'APP_LOGO_IMAGE_HOOK';

export const LANGUAGES_GETTER_HOOK = 'LANGUAGES_GETTER_HOOK';

export const INIT_SAGA_HOOK = 'INIT_SAGA_HOOK';

export const TRANSLATION_OVERRIDE_HOOK = 'TRANSLATION_OVERRIDE_HOOK';

export const CORP_OVERRIDE_FORCE_3DS_HOOK = 'CORP_OVERRIDE_FORCE_3DS_HOOK';

export const EXTRA_CONTACT_FIELDS_HOOK = 'EXTRA_CONTACT_FIELDS_HOOK';

export const AFTER_USER_UPDATE_HOOK = 'AFTER_USER_UPDATE_HOOK';

export const PROVIDER_DISCLAIMER_HOOK = 'PROVIDER_DISCLAIMER_HOOK';

export const REDIRECT_AFTER_LOGIN_HOOK = 'REDIRECT_AFTER_LOGIN_HOOK';

export const SHOW_MANY_RECOMMENDATIONS_HOOK = 'SHOW_MANY_RECOMMENDATIONS_HOOK';

export const UNDER_SEARCH_BANNER_HOOK = 'UNDER_SEARCH_BANNER_HOOK';

export const OVERRIDE_DEFAULT_APPEARANCES = 'OVERRIDE_DEFAULT_APPEARANCES';
