import { call, put } from 'redux-saga/effects';
import { showErrorNotification } from 'app/sagas/utils';
import { setReservations, addReservations } from 'app/actions/reservations';
import getFilteredReservations from './getFilteredReservations'


export default function* getReservations(action) {
  try {
    const { payload: { page } } = action;

    const data = yield call(getFilteredReservations, {
      withCancelled: true,
      page
    });

    if (!page || page <= 1) {
      yield put(setReservations([]));
    }
    yield put(addReservations(data));
    yield call(action.resolvePromise);
  } catch (error) {
    yield put(addReservations({ results: [], next: null }));
    yield call(action.rejectPromise);
    // NOTE If this saga called right after registration, then
    //      server returns 502 gateway error. But new users do not have
    //      reservations at all, so this error notification is not necessary
    //      in this case. But in other cases it would be great to show it.
    if (error.status !== 502) {
      yield call(showErrorNotification, { error });
    }
  }
}
