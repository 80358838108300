import React from 'react';
import { FormattedMessage } from 'translations';
import classnames from 'classnames';
import MobileTrip from 'app/components/search-results/Trip/Mobile';
import { Layout, Flex } from 'app/components/base/Layout';
import Button from 'app/components/base/Button';

import style from './style.css';

const messages = {
  bookReturn: { id: 'BOOK_TRIP.BOOK_ROUNDTRIP' },
  roundTripPrice: { id: 'BOOK_TRIP.ROUND_TRIP_PRICE' }
};

/**
 * Responsive Trip card component used for the ReturnTrip card of the BookTrip page.
 * Uses the mobile version of the Trip card.
 */
const TripCard = ({
  trip,
  mobile,
  onClickBook
}) => {
  const alignSizes = mobile ? 'center stretch' : 'space-between start';
  const directionSizes = mobile ? 'column' : 'row';

  const modifiers = {
    [style['container--mobile']]: mobile
  };

  return (
    <Layout
      className={classnames(style.container, modifiers)}
      align={alignSizes}
      direction={directionSizes}
      size="noshrink"
      nowrap
    >
      <MobileTrip
        {...trip}
        minimal
        showDescription
        priceLabel={<FormattedMessage {...messages.roundTripPrice} />}
      />
      <div className={style.separator} />
      <Flex size="noshrink">
        <Button className={style.button} nocaps rounded freeWidth={mobile} onClick={onClickBook}>
          <FormattedMessage {...messages.bookReturn} />
        </Button>
      </Flex>
    </Layout>
  );
};

// TODO: move to Flow types
// TripCard.propTypes = {
//   trip: PropTypes.object.isRequired,
//   mobile: PropTypes.bool,
//   onClickBook: PropTypes.func
// };

export default TripCard;
