import React from 'react';
import classnames from 'classnames';

import style from './style.css';

// Size params
const SIZES = {
  normal: 'normal',
  small: 'small',
  large: 'large'
};
const SVG_SIZES = {
  [SIZES.normal]: { width: '30', height: '30' },
  [SIZES.small]: { width: '20', height: '20' },
  [SIZES.large]: { width: '50', height: '50' }
};
const CIRCLE_SIZES = {
  [SIZES.normal]: { cx: '15', cy: '15', r: '13' },
  [SIZES.small]: { cx: '10', cy: '10', r: '9' },
  [SIZES.large]: { cx: '25', cy: '25', r: '20' }
};


/**
 * Loading circle component. Can be showed with three
 * sizes: small, normal and large
 * @param  {String} size      size of the circle
 * @return {Component}
 */
const LoadingSpinner = ({
  className,
  size = 'normal',
  transparent,
  inline,
  nomargin,
  white,
  center,
  ...props
}) => {
  const modifiers = {
    [style.transparent]: transparent,
    [style.inline]: inline,
    [style.nomargin]: nomargin,
    [style.white]: white,
    [style.center]: center
  };

  return (
    <svg
      {...props}
      {...SVG_SIZES[size]}
      className={classnames(
        className,
        style[`circular-size-${size}`],
        modifiers
      )}
    >
      <circle
        {...CIRCLE_SIZES[size]}
        fill="none"
        strokeWidth="3"
        strokeMiterlimit="10"
        className={style[`path-size-${size}`]}
      />
    </svg>
  );
};

// TODO: move to Flow types
// LoadingSpinner.propTypes = {
//   className: PropTypes.string,
//   size: PropTypes.oneOf(Object.keys(SIZES)),
//   transparent: PropTypes.bool,
//   center: PropTypes.bool,
//   inline: PropTypes.bool,
//   nomargin: PropTypes.bool,
//   white: PropTypes.bool
// };

export default LoadingSpinner;
