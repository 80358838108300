const TOGGLERS = 'togglers';

export default [
  {
    name: 'SHOW_ADS',
    type: 'bool',
    default: false,
    description: 'Show ads (Google AdSense)',
    category: TOGGLERS
  },
  {
    name: 'SHOW_PACE_LOADER',
    type: 'bool',
    default: true,
    description: 'Show pace loading bar at the top of the page',
    category: TOGGLERS
  },
  {
    name: 'ONLY_MEET_N_GREET_AMENITY',
    type: 'bool',
    default: false,
    description: 'Show only meet and greet amenity in amenities list',
    category: TOGGLERS
  },
  {
    name: 'SHOW_SIGNIN_BUTTON',
    type: 'bool',
    default: false,
    description: 'Enable signin button',
    category: TOGGLERS
  },
  {
    name: 'ALLOW_ONLY_REGISTERED',
    type: 'bool',
    default: false,
    description: 'Allow only registered users to book',
    category: TOGGLERS
  },
  {
    name: 'SHOW_COUPON_FIELD',
    type: 'bool',
    default: true,
    description: 'Show coupon field on BTP',
    category: TOGGLERS
  },
  {
    name: 'ZENDESK_ENABLED',
    type: 'bool',
    default: true,
    description: 'If set to true will enable the zendesk chat in the client window',
    category: TOGGLERS
  },
  {
    name: 'AGENT_IATA_AND_ID_ENABLED',
    type: 'bool',
    default: false,
    description: 'Allow TAs to enter IATA code and Agent ID',
    category: TOGGLERS
  },
  {
    name: 'SHOW_SOCIAL_LOGIN',
    type: 'bool',
    default: false,
    description: 'Enable login with socials',
    editable: true,
    category: TOGGLERS
  },
  {
    name: 'POPULAR_RIDE_SHOW',
    type: 'bool',
    default: false,
    description: 'Show popular rides',
    editable: true,
    category: TOGGLERS
  },
  {
    name: 'MOZIO_RATINGS_SHOW',
    type: 'bool',
    default: false,
    description: 'Show mozio ratings (trustpilot, tripadvisor, reviews.io)',
    editable: true,
    category: TOGGLERS
  },
  {
    name: 'TRAVELER_OPTIONS_SHOW',
    type: 'bool',
    default: false,
    description: 'Show traveler options',
    editable: false,
    category: TOGGLERS
  },
  {
    name: 'USER_NAVIGATION_SHOW',
    type: 'bool',
    default: false,
    description: 'Show navigation for the user',
    editable: false,
    category: TOGGLERS
  },
  {
    name: 'REGISTER_TA_SHOW',
    type: 'bool',
    default: false,
    description: 'Show TA registration option',
    category: TOGGLERS
  },
  {
    name: 'ASSURANCE_SHOW',
    type: 'bool',
    default: true,
    description: 'Show assurance on SRP and BTP',
    category: TOGGLERS
  },
  {
    name: 'SHOW_SRP_MAP',
    type: 'bool',
    default: false,
    description: 'Enable/Disable "Show map" button on SRP',
    category: TOGGLERS
  },
  {
    name: 'AVAILABLE_TIME_MESSAGE_SHOW',
    type: 'bool',
    default: true,
    description: 'Enable/Disable "Available time message" for flights in BTP',
    category: TOGGLERS
  },
  {
    name: 'RIDE_DURATION_SHOW',
    type: 'bool',
    default: true,
    description: 'Shows/Hide Ride Duration in SRP',
    category: TOGGLERS
  },
  {
    name: 'MOZIO_TERMS_AND_CONDITIONS_SHOW',
    type: 'bool',
    default: true,
    description: 'Shows/Hide mozio terms and conditions below the booking button',
    category: TOGGLERS
  },
  {
    name: 'COOKIES_MESSAGE_SHOW',
    type: 'bool',
    default: true,
    description: 'Shows/Hide Cookies message',
    category: TOGGLERS
  },
  {
    name: 'UPGRADE_YOUR_TRIP_SHOW',
    type: 'bool',
    default: true,
    description: 'Shows/Hide Upgrade your trip section',
    category: TOGGLERS
  },
  {
    name: 'PHONE_SERVICE_SHOW',
    type: 'bool',
    default: true,
    description: 'Shows/Hide phone service checkbox in BTP',
    category: TOGGLERS
  },
  {
    name: 'HINT_PLACEHOLDERS_SHOW',
    type: 'bool',
    default: false,
    description: 'Shows/Hide Show hints on BTP placeholders',
    category: TOGGLERS
  },
  {
    name: 'YOUR_TRIP_IN_PRICE_BREAKDOWN',
    type: 'bool',
    default: false,
    description: 'Shows/Hide Your trip section in price breackdown',
    category: TOGGLERS
  },
  {
    name: 'REGULAR_YOUR_TRIP_SHOW',
    type: 'bool',
    default: true,
    description: 'Shows/Hide Your trip section BTP',
    category: TOGGLERS
  },
  {
    name: 'PICKUP_TIME_IN_PRICE_BREAKDOWN',
    type: 'bool',
    default: false,
    description: 'Shows/Hide pickup time section in pricebreakdown',
    category: TOGGLERS
  },
  {
    name: 'WAITING_TIME_SHOW',
    type: 'bool',
    default: true,
    description: 'Shows/Hide waiting time in SRP and BTP',
    category: TOGGLERS
  },
  {
    name: 'OVERWRITE_AIRPORT_DISPLAY',
    type: 'bool',
    default: false,
    description: 'Use {value} : {name} for airports instead of {display}',
    category: TOGGLERS
  },
  {
    name: 'SRP_EXTENDED_TRIP_DETAILS',
    type: 'bool',
    default: false,
    description: 'Show info about bags and passengers each on new line, with text description',
    category: TOGGLERS
  },
  {
    name: 'SHOW_SRP_PRICE_LABEL',
    type: 'bool',
    default: false,
    description: 'Show/Hide label "Price for all passengers" on SRP',
    category: TOGGLERS
  },
  {
    name: 'SHOW_PARKCLOUD_BANNER',
    type: 'bool',
    default: false,
    description: 'Show/Hide the parkcloud banner on CP',
    category: TOGGLERS
  },
  {
    name: 'FULLSTORY_ENABLED',
    type: 'bool',
    default: true,
    description: 'Enable/Disable fullstory integration',
    category: TOGGLERS
  },
  {
    name: 'SHOW_LEGAL_ADDRESS_IN_FOOTER',
    type: 'bool',
    default: false,
    description: 'Show/Hide Mozio legal info in the footer',
    category: TOGGLERS
  },
  {
    name: 'SHOW_MOZIO_GROUP_HOMEPAGE',
    type: 'bool',
    default: false,
    description: 'Show Moziogroup homepage',
    category: TOGGLERS
  },
  {
    name: 'SHOW_PROVIDER_RATING',
    type: 'bool',
    default: true,
    description: 'Show ratings of the providers',
    category: TOGGLERS
  },
  {
    name: 'SHOW_VEHICLE_DETAILS',
    type: 'bool',
    default: true,
    description: 'Show vehicle model and make',
    category: TOGGLERS
  },
  {
    name: 'SHOW_MEET_AND_GREET_FILTER',
    type: 'bool',
    default: true,
    description: 'Show meet and greet filter in SRP',
    category: TOGGLERS
  },
  {
    name: 'SHOW_RIDE_TRACKING_FILTER',
    type: 'bool',
    default: true,
    description: 'Show ride tracking filter in SRP',
    category: TOGGLERS
  },
  {
    name: 'SHOW_SORT_BUTTONS_IN_SRP',
    type: 'bool',
    default: true,
    description: 'Show sort buttons in SRP',
    category: TOGGLERS
  },
  {
    name: 'SHOW_VEHICLE_CLASS_TOOLTIP',
    type: 'bool',
    default: true,
    description: 'Show vehicle class tooltips in SRP',
    category: TOGGLERS
  },
  {
    name: 'SHOW_PROVIDER_NAME_IN_PRICE_BREAKDOWN',
    type: 'bool',
    default: true,
    description: 'Show/Hide provider name in BTP price breakdown',
    category: TOGGLERS
  },
  {
    name: 'SPECIFY_IF_WAITING_TIME_IS_FROM_AIRPORT_IN_BTP',
    type: 'bool',
    default: false,
    description: 'Show/Hide the message that specifies if waiting time is from aiport or to another location in price breakdown',
    category: TOGGLERS
  },
  {
    name: 'COLLECT_EMAIL_IN_NO_RESULTS',
    type: 'bool',
    default: true,
    description: 'Show/Hide the emails form in no results',
    category: TOGGLERS
  },
  {
    name: 'SHOW_TESTIMONIALS_IN_HP',
    type: 'bool',
    default: true,
    description: 'Show/hide testimonials section in HP',
    category: TOGGLERS
  },
  {
    name: 'SHOW_VEHICLE_CATEGORY',
    type: 'bool',
    default: true,
    description: 'Show/hide vehicle category on SRP',
    category: TOGGLERS
  },
  {
    name: 'STATIC_VEHICLE_IMAGES_BASED_IN_VEHICLE_CLASS',
    type: 'bool',
    default: false,
    description: 'Use static vehicle images based in the vehicle class',
    category: TOGGLERS
  },
  {
    name: 'CHANGE_BOOKING_FEATURE',
    type: 'bool',
    default: true,
    description: 'Show/hide button to change the booking',
    category: TOGGLERS
  },
  {
    name: 'AUTODETECT_CURRENCY_BY_IP',
    type: 'bool',
    default: false,
    description: 'Enable/disable currency autodetection',
    category: TOGGLERS
  },
  {
    name: 'SHOW_HOURLY_SEARCH',
    type: 'bool',
    default: true,
    description: 'Show/hide hourly tab in HP search form',
    category: TOGGLERS
  },
  {
    name: 'SHOW_PARKING_SEARCH',
    type: 'bool',
    default: false,
    description: 'Show/hide parking tab in HP search form',
    category: TOGGLERS
  },
  {
    name: 'INCLUDE_PLATFORM_FEE',
    type: 'bool',
    default: true,
    description: 'Include platform fee to the price',
    category: TOGGLERS
  },
  {
    name: 'COVERAGE_MAP_SHOW',
    type: 'bool',
    default: true,
    description: 'Show/Hide airports coverage map on HP',
    category: TOGGLERS
  },
  {
    name: 'SHOW_BTP_PICKUP_TIMELINE',
    type: 'bool',
    default: true,
    description: 'Show/Hide pickup timeline in BTP',
    category: TOGGLERS
  },
  {
    name: 'SHOW_CP_PICKUP_TIMELINE',
    type: 'bool',
    default: true,
    description: 'Show/Hide pickup timeline in CP',
    category: TOGGLERS
  },
  {
    name: 'EDITABLE_BOOKING_FEE',
    type: 'bool',
    default: true,
    description: 'Enable/disable booking fee editor on BTP for TAs',
    category: TOGGLERS
  },
  {
    name: 'EDITABLE_TA_WITHDRAWAL',
    type: 'bool',
    default: true,
    description: 'Enable/disable withdrawal, payment settings, sales balance for TAs',
    category: TOGGLERS
  },
  {
    name: 'EDITABLE_MY_INFORMATION',
    type: 'bool',
    default: true,
    description: 'Enable/disable my information editing',
    category: TOGGLERS
  },
  {
    name: 'EDITABLE_CUSTOMER_PROFILES',
    type: 'bool',
    default: true,
    description: 'Enable/disable customer profiles editing',
    category: TOGGLERS
  },
  {
    name: 'EDITABLE_CUSTOMER_NOTIFICATIONS',
    type: 'bool',
    default: true,
    description: 'Enable/disable customer notifications editing',
    category: TOGGLERS
  },
  {
    name: 'ENABLE_TRAIN_WORDING',
    type: 'bool',
    default: false,
    description: 'Enable/disable wording changes to feel like the service is for train station transfers',
    category: TOGGLERS
  },
  {
    name: 'SHOW_TRAIN_SELECTION',
    type: 'bool',
    default: false,
    description: 'Enable/disable train selection on BTP',
    category: TOGGLERS
  },
  {
    name: 'SHOW_YOU_EARNED',
    type: 'bool',
    default: true,
    description: 'Enable/disable "you earned" on BTP for TAs',
    category: TOGGLERS
  },
  {
    name: 'SHOW_RESEND_BOOKING',
    type: 'bool',
    default: true,
    description: 'Enable/disable "Resend Booking" link in header',
    category: TOGGLERS
  },
  {
    name: 'REQUIRED_TRAIN_FIELD',
    type: 'bool',
    default: true,
    description: 'Enable/disable requried train field on BTP',
    category: TOGGLERS
  },
  {
    name: 'SHOW_TICKET_PASSES_SELECTION',
    type: 'bool',
    default: false,
    description: 'Enable/disable the tickets and passses tab selction on home page',
    category: TOGGLERS
  },
  {
    name: 'SHOW_TOTAL_PRICE_ON_CP',
    type: 'bool',
    default: true,
    description: 'Show/hide total price row in PriceBreakdown on Confirmation Page',
    category: TOGGLERS
  },
  {
    name: 'DENY_GUEST_USERS',
    type: 'bool',
    default: false,
    description: 'Deny any not-authorized access to the website by showing a splash page',
    category: TOGGLERS
  },
  {
    name: 'ISSUE_COUPON_ON_CANCEL',
    type: 'bool',
    default: true,
    description: 'Enable/disable issue a coupon instead of refund',
    category: TOGGLERS
  },
  {
    name: 'SHOW_EXTRA_TRIP_OFFER_PROMO',
    type: 'bool',
    default: true,
    description: 'Enable/disable return trip coupon on confirmation page',
    category: TOGGLERS
  },
  {
    name: 'TUI_CS_ADJUSTMENTS',
    type: 'bool',
    default: false,
    description: 'Enable/disable TUI CS whitelabel functions',
    category: TOGGLERS
  },
  {
    name: 'SHOW_TA_REPORT_BUTTON',
    type: 'bool',
    default: true,
    description: 'Enable/disable a button to download TA monthly report (affect only TA users)',
    category: TOGGLERS
  },
  {
    name: 'PASSWORD_RESET_ABILITY',
    type: 'bool',
    default: true,
    description: 'Enable/disable to reset the password',
    category: TOGGLERS
  },
  {
    name: 'EMAIL_CHANGE_ABILITY',
    type: 'bool',
    default: true,
    description: 'Enable/disable an ability to change the email',
    category: TOGGLERS
  },
  {
    name: 'NO_COMPACT_PRICE',
    type: 'bool',
    default: false,
    description: 'Do not show compact price, only full price without rounding',
    category: TOGGLERS
  },
  {
    name: 'NO_USER_DEFAULT_LANGUAGE',
    type: 'bool',
    default: false,
    description: 'Do not set user\'s default language after login',
    category: TOGGLERS
  },
  {
    name: 'TUI_PARTNER_TRACKING_REQUIRED',
    type: 'bool',
    default: false,
    description: 'Show a screen that blocks the user if there is no partner_tracking_id',
    category: TOGGLERS
  },
  {
    name: 'USE_TOTAL_PRICE_ON_CP',
    type: 'bool',
    default: false,
    description: 'Use total price instead of gross revenue on CP',
    category: TOGGLERS
  },
  {
    name: 'SHOW_ALL_INCLUSIVE_TAG',
    type: 'bool',
    default: true,
    description: 'Show ALL INCLUSIVE tag in search results',
    category: TOGGLERS
  },
  {
    name: 'SHOW_AGREE_WITH_TERMS_CHECKBOX',
    type: 'bool',
    default: false,
    description: 'Show a checkbox that the user should click to book',
    category: TOGGLERS
  },
  {
    name: 'GOTRANSIT_SEPARATE_SERVICE',
    type: 'bool',
    default: false,
    description: 'Use GO transit separate service',
    category: TOGGLERS
  },
  {
    name: 'REQUIRE_VERIFIED_EMAIL',
    type: 'bool',
    default: false,
    description: 'Do not login user after registration and ask to verify the password instead',
    category: TOGGLERS
  },
  {
    name: 'REQUIRED_CRUISE_SHIP_NAME',
    type: 'bool',
    default: false,
    description: 'Show cruise ship name',
    category: TOGGLERS
  },
  {
    name: 'HIDE_PAYMENT_SECTION',
    type: 'bool',
    default: false,
    description: 'Hide payment section in the checkout form',
    category: TOGGLERS
  }
];
