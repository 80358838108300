import { call, put } from 'redux-saga/effects';
import { travelagent } from 'mz-sdk';
import { apiCall } from 'app/sagas/utils';
import { setWithdrawals } from 'app/actions/travel-agent';


/**
 * Get TA withdrawals and save them in the state
 */
export default function* getWithdrawals(action) {
  const responseData = yield call(apiCall, action, travelagent.getWithdrawals);
  if (responseData) {
    yield put(setWithdrawals(responseData.withdrawals));
  }
}
