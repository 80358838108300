import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { connectSubpages } from 'app/hocs/subpageManagable';
import { FormattedMessage, FormattedHTMLMessage } from 'translations';
import { Layout } from 'app/components/base/Layout';
import Button from 'app/components/base/Button';
import * as bookTripActions from 'app/actions/book-trip';
import { isMobileBrowser } from 'app/utils/browser';
import MobileScreen from 'app/components/base/MobileScreen';
import get3DSTechnology from './utils';
import classnames from 'classnames';

import style from './style.css';


const ShieldIcon = require('-!babel-loader!@mozioinc/svg-react-loader!./assets/shield.svg');
const messages = {
  title: { id: 'BOOK_TRIP.THREE_DS_REQUIRED_NOTIFICATION.TITLE' },
  message: { id: 'BOOK_TRIP.THREE_DS_REQUIRED_NOTIFICATION.MESSAGE' },
  useAnotherCard: { id: 'BOOK_TRIP.THREE_DS_REQUIRED_NOTIFICATION.USE_ANOTHER_CARD' },
  continue: { id: 'SEARCH_RESULTS.BOOK_RIDE_3' }
};

class ThreeDSError extends React.Component {

  handleClose = () => {
    const {
      clearTripError,
      subpages,
      errorPopup
    } = this.props;

    if (errorPopup) {
      subpages.popPage();
    }

    clearTripError();
  }

  renderContent() {
    const {
      errorProps: { cardBrand }
    } = this.props;
    return (
      <Layout
        align="center center"
        className={style.content}
      >
        <ShieldIcon className={style.icon} />
        <span className={classnames(style.title, style.shrinked)}>
          <FormattedMessage {...messages.title} />
        </span>
        <FormattedHTMLMessage
          {...messages.message}
          className={classnames(style.message, style.shrinked)}
          values={{ technology: get3DSTechnology(cardBrand) }}
        />
        <span className={classnames(style.notice, style.shrinked)}>
          <FormattedMessage {...messages.useAnotherCard} />
        </span>
      </Layout>
    );
  }

  renderButton() {
    return (
      <Layout
        className={style.buttonsContainer}
        align="end end"
      >
        <Button
          className={style.button}
          onClick={this.handleClose}
          nocaps
          rounded
        >
          <FormattedMessage {...messages.continue} />
        </Button>
      </Layout>
    );
  }

  render() {
    if (isMobileBrowser) {
      return (
        <MobileScreen
          title={<FormattedMessage {...messages.title} />}
          onClickBack={this.handleClose}
        >
        {this.renderContent()}
        {this.renderButton()}
        </MobileScreen>
      );
    }

    return (
      <Layout
        className={classnames(style.container, style.shrinked)}
        direction="column"
        align="start center"
      >
        {this.renderContent()}
        {this.renderButton()}
      </Layout>
    );
  }
}

// TODO: move to Flow types
// ThreeDSError.propTypes = {
//   errorPopup: PropTypes.bool,
//   subpages: PropTypes.object,
//   errorProps: PropTypes.object,
//   clearTripError: PropTypes.func.isRequired
// };

ThreeDSError.defaultProps = {
  cardType: 'VISA'
};

const mapDispatchToProps = {
  clearTripError: bookTripActions.clearTripError
};

export default compose(
  connectSubpages(),
  connect(null, mapDispatchToProps)
)(ThreeDSError);
