// @flow
import * as React from 'react'
import { FormattedMessage } from 'translations'
import { constants } from 'mz-sdk'
import Icon from 'app/components/base/Icon'
import style from './style.css'

const TripCategory = ({ categoryName, vehicleTypeId }) => (
  <div className={style.container}>
    <Icon
      family="mozio"
      name={constants.CATEGORIES_FILTERS[categoryName]}
      className={style.icon}
    />
    <div className={style.categoryName}>
      <FormattedMessage id={`VEHICLES.ID_${vehicleTypeId}.NAME`} />
    </div>
  </div>
)

export default TripCategory