import React from 'react';
import classnames from 'classnames';
import { Layout } from 'app/components/base/Layout';
import TripProviderLogo from 'app/components/search-results/Trip/commons/TripProviderLogo';

import style from './style.css';

/**
 * Container component for the price breakdown box. It handles the position of the provider logo
 * for the different platforms (desktop, mobile and tablet).
 */
const Container = ({
  imageUrl,
  mobile,
  horizontal,
  children
}) => {
  const align = mobile ? 'start stretch' : null;
  const modifiers = {
    [style.mobile]: mobile && !horizontal,
    [style.desktop]: !mobile,
    [style.horizontal]: !mobile && horizontal
  };

  return (
    <Layout
      nowrap
      className={classnames(style.container, modifiers)}
      direction="column"
      size="100"
      align={align}
    >
      <Layout className={style.logo} align="center center">
        <TripProviderLogo imageUrl={imageUrl} norating compact />
      </Layout>
      {children}
    </Layout>
  );
};

// TODO: move to Flow types
// Container.propTypes = {
//   imageUrl: PropTypes.string.isRequired,
//   mobile: PropTypes.bool,
//   horizontal: PropTypes.bool,
//   children: PropTypes.array
// };

export default Container;
