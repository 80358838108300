import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'translations';
import { Layout, Flex } from 'app/components/base/Layout';
import Button from 'mz-ui-kit/core/Button';
import Icon from 'app/components/base/Icon';
import { Link } from 'react-router-dom';
import builder from './builder';

import style from './style.css';


// Constants
const messages = {
  bookingNumber: { id: 'CONFIRMATION_PAGE.CONFIRMATION_NUMBER' },
  changeTrip: { id: 'CONFIRMATION_PAGE.CHANGE_TRIP' },
  cancelTrip: { id: 'CONFIRMATION_PAGE.CANCEL_TRIP' },
  cancelled: { id: 'CONFIRMATION_PAGE.CANCELLED' }
};

/**
 * Component to show reservation number and reservation cancel button
 * @param {String} direction
 * @param {String} confirmationNumber
 */
export const ReservationActions = ({
  mobile,
  direction,
  reservationId,
  returnReservationId,
  canCancel,
  cancelled,
  isBefore,
  isAllowedToChange
}) => {
  if (!reservationId) return null;

  const modifiers = {
    [style[`block--${direction}`]]: true
  };

  const showActions = !cancelled && !isBefore;

  return (
    <Layout
      className={classnames(style.block, modifiers)}
      direction="row"
      size="noshrink"
      align="start start"
      nowrap
    >
      {cancelled && (
        <Flex size="noshrink">
          <Button color="red" className={style.cancelled} disabled>
            <Icon small>do_not_disturb_alt</Icon>
            <FormattedMessage {...messages.cancelled} />
          </Button>
        </Flex>
      )}
      {!cancelled && canCancel && showActions && (
        <>
          <Button $as={Link} to={`/cancel-booking?id=${reservationId}&return_id=${returnReservationId}`} className={style.cancel}>
            <Icon small>do_not_disturb_alt</Icon>
            <FormattedMessage {...messages.cancelTrip} />
          </Button>
          {isAllowedToChange && !mobile && (
            <Button $as={Link} to={`/change-booking?id=${reservationId}&return_id=${returnReservationId}`} className={style.change}>
              <FormattedMessage {...messages.changeTrip} />
            </Button>
          )}
        </>
      )}
    </Layout>
  );
};


export default builder(ReservationActions);
