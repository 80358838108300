import React from 'react';
import classnames from 'classnames';
import { Layout } from 'app/components/base/Layout';

import style from './style.css';

/**
 * Presentational component to show the price of an upgrade item in the book-trip page
 */
const Price = ({
  slashPrice,
  price,
  currencySymbol,
  horizontal,
  mobile
}) => {
  const direction = mobile || horizontal ? 'row' : 'column';
  const modifiers = {
    [style.horizontal]: horizontal,
    [style['price--mobile']]: mobile
  };
  return (
    <Layout
      className={classnames(style.price, modifiers)}
      align="start center"
      direction={direction}
      size="noshrink"
    >
      <Layout className={style['slash-price']}>
        <span>{currencySymbol}</span>
        <span>{slashPrice}</span>
      </Layout>
      <Layout>
        <span>{currencySymbol}</span>
        <span>{price}</span>
      </Layout>
    </Layout>
  );
};

// TODO: move to Flow types
// Price.propTypes = {
//   slashPrice: PropTypes.string,
//   currencySymbol: PropTypes.string,
//   price: PropTypes.string,
//   horizontal: PropTypes.bool,
//   mobile: PropTypes.bool
// };

export default Price;
