/* eslint-disable max-len */
import createMessages from 'mz-intl/create';

export default createMessages('FAVOURITE_LOCATIONS_PAGE', {
  SAVED_LOCATIONS: 'Saved Locations',
  FAVOURITE_LOCATIONS: 'Favorite Locations',
  FAVOURITE_LOCATIONS_TITLE: 'Do you want to save these places to your favorites locations?',
  FAVOURITE_LOCATIONS_TITLE_BOOKED: 'Congratulations! You just booked a trip to one of your favorite locations.',
  FAVOURITE_LOCATIONS_SUBTITLE: 'You will be able to book faster next time.',
  FAVOURITE_LOCATIONS_SUBTITLE_BOOKED: 'Click the link below to edit your favorites',
  FAVOURITE_LOCATIONS_LINK: 'Edit Favorites in Your Profile',
  PLACE_FROM_LABEL: 'From:',
  PLACE_TO_LABEL: 'To:',
  SAVE_LOCATION: 'Save Location',
  EDIT_LOCATION: 'Edit Location',
  ADD_NEW_LOCATION: 'Add New Location',
  FAVOURITE_LOCATION: 'Favourite Location',
  LOCATION_NAME: 'Location name',
  LOCATION_TYPE: 'Location type',
  SPECIAL_INSTRUCTIONS_PLACEHOLDER: 'Add Special Instructions (optional)',
  FILL_WITH_TRIP_INSTRUCTIONS: 'Fill with trip instructions',
  GENERAL_ERROR: 'Can\'t load saved locations. Please try again.',
  PICKUP_INSTRUCTIONS: 'Pickup: {instructions}',
  DROPOFF_INSTRUCTIONS: 'Drop-off: {instructions}',
  HOME_TYPE: 'Home',
  WORK_TYPE: 'Work',
  AIRPORT_TYPE: 'Airport',
  PLACE_TYPE: 'Place',
  NO_LOCATIONS_MESSAGE: 'There is no favorite places yet. Do not hesitate to create one.'
});
/* eslint-enable max-len */
