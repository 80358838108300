import { call, put } from 'redux-saga/effects';
import { setUser } from 'app/actions/user-session';
import { redirectBack, showErrorNotification } from 'app/sagas/utils';
import getUserData from './getUserData';
import posthog from 'posthog-js';


export default function* doLoginSocial(action) {
  try {
    posthog.capture('Old Flow: User Successfully Signed In', { sign_in_method: action.payload.socialLoginType });
    
    yield put(setUser(action.payload.data));
    yield call(getUserData);

    // On login, we identify the user with the Chameleon api
    if (typeof chmln !== 'undefined') { // eslint-disable-line
      chmln.identify(window.mozioUser); // eslint-disable-line
    }

    yield call(redirectBack);
  } catch (error) {
    yield put(setUser());
    yield call(showErrorNotification, {
      error,
      titleId: 'USER.LOGIN_ERROR_TITLE'
    });
  }
}
