import React from 'react'

/**
 * Simple component that renders nothing but show a polyline on the Google map
 * using their API
 * @extends React
 */
class Polyline extends React.PureComponent {
  UNSAFE_componentWillUpdate() {
    this.line && this.line.setMap(null)
  }

  componentWillUnmount() {
    this.line && this.line.setMap(null)
  }

  render() {
    const { maps, map, ...props } = this.props
    const Polyline = maps.Polyline
    this.line = new Polyline({ ...props })
    this.line.setMap(map)
    return null
  }
}

export default Polyline
