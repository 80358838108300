import { styled } from 'mz-ui-kit'

export const LoadingWrapper = styled('div', {
  textAlign: 'center',
})

export const LoadingText = styled('div', {
  marginTop: '10px'
})

export const PageLoadingWrapper = styled('div', {
  padding: '20px 10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexFlow: 'column nowrap',
  flexGrow: 1,
})
