import { call, put } from 'redux-saga/effects';
import { travelagent } from 'mz-sdk';
import { apiCall } from 'app/sagas/utils';
import { setReservations } from 'app/actions/travel-agent';


const mapPayloadToParams = payload => ({
  year: payload.get('year'),
  // moment.js uses numbers from 0 to 11 for month while the API uses
  // numbers from 1 to 12
  month: payload.get('month') + 1
});

/**
 * Get TA reservations and save them in the state
 */
export default function* getReservations(action) {
  const reservations = yield call(apiCall, action, travelagent.getReservations, mapPayloadToParams);
  if (reservations) {
    yield put(setReservations(reservations));
  }
}
