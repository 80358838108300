import React from 'react';
import getIcon from 'app/utils/icons';
import { FormattedHTMLMessage } from 'translations';
import { Layout, Flex } from 'app/components/base/Layout';
import Icon from 'app/components/base/Icon';
import classnames from 'classnames';
import { overwriteAirportDisplay } from 'app/utils/togglers';

import styles from '../style.css';


/**
 * Item of the results list that handles click on it and
 * pass given item object to the handler
 */
class SuggestionItem extends React.Component {

  // shouldComponentUpdate(nextProps) {
  //   return nextProps.selected !== this.props.selected ||
  //     nextProps.focused !== this.props.focused ||
  //     nextProps.item.display !== this.props.item.display;
  // }

  handleClick = () => {
    const { item, onClick } = this.props;
    if (onClick) {
      onClick(item);
    }
  }

  render() {
    const { item, selected, focused, desktop, type } = this.props;
    const modifiers = {
      [styles['item--selected']]: selected,
      [styles['item--focused']]: focused,
      [styles.desktop]: desktop
    };

    const icon = getIcon(item, type);

    return (
      <Layout
        nowrap
        align="start center"
        direction="row"
        onClick={this.handleClick}
        className={classnames(styles.item, modifiers)}
      >
        {icon && (
          <Icon {...icon} small className={styles.item__icon} />
        )}
        <Flex size="auto" component="label" className={styles.item__label} id={item.id}>
          {overwriteAirportDisplay(item)}
          {item.type === 'airport' && (
            <div className={styles.item__subtitle}>
              <FormattedHTMLMessage id="SEARCH_FORM.AIRPORT_PLACEHOLDER" />
            </div>
          )}
          {item.type === 'port' && (
            <div className={styles.item__subtitle}>
              <FormattedHTMLMessage id="SEARCH_FORM.CRUISE_PORT_PLACEHOLDER" />
            </div>
          )}
        </Flex>
      </Layout>
    );
  }
}

// TODO: move to Flow types
// SuggestionItem.propTypes = {
//   item: PropTypes.object,
//   type: PropTypes.string,
//   style: PropTypes.any,
//   onClick: PropTypes.func,
//   selected: PropTypes.bool,
//   desktop: PropTypes.bool,
//   focused: PropTypes.bool
// };

export default SuggestionItem;
