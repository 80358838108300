import _ from 'lodash';
import { call, select } from 'redux-saga/effects';
import {
  getSearchArguments,
  getSearchForm,
  getLocation
} from 'app/sagas/selectors';
import formConfig from 'app/forms/search-form';
import { handleRefParam } from 'app/sagas/utils';
import { HOURLY_MODE_VALUE } from 'app/constants';

const allowedParams = [
  'ta',
  'wl',
  'gid',
  'guid',
  'staging_api',
  'testing_api',
  'campaign',
  'branch',
  'utm_campaign',
  'utm_source',
  'utm_medium',
  'start_lat',
  'start_lng',
  'end_lat',
  'end_lng',
  'airline',
  'airline2',
  'flight_number',
  'flight_number2',
  'customer_special_instructions',
  'partner_tracking_id',
  'flight_datetime',
  'return_flight_number',
  'return_flight_datetime',
  'polling_limit',
  'coupon_code',
  'start_is_hotel',
  'end_is_hotel'
];

const convertToBoolean = (value) => value === 'true'

const normalizeAddress = (address) => {
  return {
    ...address,
    // if place_id is empty string it should be undefined instead
    place_id: address.place_id || undefined
  };
};


export default function* getRequestParams() {
  // We detect Travel Agent param.
  const location = yield select(getLocation);
  let urlParams = _.pick(location.query, allowedParams);
  const ref = yield call(handleRefParam);

  // if the values are different than the default then the user changed the form
  const searchForm = yield select(getSearchForm);
  if (searchForm === formConfig.initialValues) {
    return null;
  }

  const params = yield select(getSearchArguments);
  const isHourlyRide = params && params.booking_mode === HOURLY_MODE_VALUE;

  if (params && params.start_address.display === location.query.start_name) {
    urlParams.start_name = location.query.start_name;
  }
  if (
    (params?.start_address?.value
    && params?.start_address?.display !== params?.start_address?.value)
    || params?.start_address?.place_id
  ) {
    urlParams.start_name = params.start_address.display;
  }

  if (!isHourlyRide && params && params.end_address.display === location.query.end_name) {
    urlParams.end_name = location.query.end_name;
  }
  if (
    (params?.end_address?.value
    && params?.end_address?.display !== params?.end_address?.value)
    || params?.end_address?.place_id
  ) {
    urlParams.end_name = params.end_address.display;
  }

  if (urlParams && urlParams.start_is_hotel) {
    urlParams.start_is_hotel = convertToBoolean(urlParams.start_is_hotel);
  }

  if (urlParams && urlParams.end_is_hotel) {
    urlParams.end_is_hotel = convertToBoolean(urlParams.end_is_hotel);
  }

  let resultParams = {
    ...urlParams,
    ...params,
    ...ref
  };

  if (params) {
    resultParams = {
      ...resultParams,
      start_address: normalizeAddress(params.start_address),
      end_address: isHourlyRide ? null : normalizeAddress(params.end_address),
      mode: isHourlyRide
        ? HOURLY_MODE_VALUE
        : resultParams.mode
    };
  }

  if (isHourlyRide) {
    resultParams = _.omit(
      resultParams,
      ['booking_mode', 'end_address', 'return_pickup_datetime', 'return_flight_datetime']
    );
  } else {
    resultParams = _.omit(resultParams, ['booking_mode', 'hourly_booking_duration']);
  }

  // Make sure that we do NOT return any flight datetime
  return _.omit(resultParams, ['flight_datetime', 'return_flight_datetime']);
}
