import * as React from 'react'
import config from 'config'
import { getLocaleFreePathname } from 'app/history'
import { useLocation } from "react-router-dom";
import queryStringToObject from 'mz-utils/queryStringToObject'
import { styled } from 'mz-ui-kit'

const Container = styled('div', {
  backgroundColor: '#eee',
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  height: '100vh',
})

export default function PartnerTrackingBoundary({ children }) {
  if (!config.TUI_PARTNER_TRACKING_REQUIRED) return children

  const location = useLocation()
  const query = queryStringToObject(location.search)
  const pathname = getLocaleFreePathname(location.pathname)
  const affectedPage = pathname.startsWith('/search') || pathname.startsWith('/book')

  if (affectedPage && !query.partner_tracking_id) {
    return (
      <Container>
        <h2>Unauthorized access</h2>
        <p>Seems like you opened this website directly.<br />Please visit <a href="https://gotui.com">gotui.com</a> and click on the transfers button.</p>
      </Container>
    )
  }

  return children
}
