import React from 'react';
import classnames from 'classnames';
import { Layout } from 'app/components/base/Layout';
import VehicleModel from 'app/components/common/VehicleModel';

import style from './style.css';


/**
 * Stateless component to show provider name, description and waiting time.
 */
const ProviderInfo = ({
  className,
  compact,
  names,
  description,
  make,
  model
}) => {
  return (
    <Layout
      className={classnames(className, style.container, { [style.compact]: compact })}
      direction="column"
    >
      <span className={style.name}>
        {names.map((name, index) => (
          <React.Fragment key={index}>
            <span>{name}</span>
            {index !== names.length - 1 ? <span className={style.separator} /> : null}
          </React.Fragment>
        ))}
      </span>
      {!!description && <span>{description}</span>}
      <VehicleModel
        className={style.model}
        make={make}
        model={model}
        showSimilarOnNewLine
      />
    </Layout>
  );
};

// TODO: move to Flow types
// ProviderInfo.propTypes = {
//   className: PropTypes.string,
//   compact: PropTypes.bool,
//   names: PropTypes.array.isRequired,
//   description: PropTypes.string,
//   make: PropTypes.string,
//   model: PropTypes.string
// };

export default ProviderInfo;
