import React from 'react';

import style from './style.css';


class Tooltip extends React.Component {

  textContainerDOM = null
  state = {
    leftOffset: 0
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      leftOffset: -2 + (this.textContainerDOM || {}).offsetWidth / -2
    });
  }

  render() {
    const { text } = this.props;

    return (
      <div
        className={style.tooltip}
        style={{
          visibility: this.textContainerDOM ? 'visible' : 'hidden', // to avoid shifting
          left: this.state.leftOffset
        }}
      >
        <span
          ref={el => this.textContainerDOM = el}
          className={style.tooltipText}
        >
          {text}
        </span>
      </div>
    );
  }
}

// TODO: move to Flow types
// Tooltip.propTypes = {
//   text: PropTypes.string
// };

export default Tooltip;
