import { put } from 'redux-saga/effects';
import { change } from 'redux-form';
import {
  BOOK_TRIP_FORM,
  SELECTED_CUSTOMER_PROFILE,
  SELECTED_CREDIT_CARD_ID
} from 'app/constants';


export default function* deselectProfile() {
  yield put(change(BOOK_TRIP_FORM, SELECTED_CUSTOMER_PROFILE, null));
  yield put(change(BOOK_TRIP_FORM, SELECTED_CREDIT_CARD_ID, null));
}

