import { styled } from 'mz-ui-kit'

export const Title = styled('div', {
  marginBottom: '20px'
});

export const Header = styled('div', {
  color: 'white'
});

export const TitleContent = styled('span', ({ $palette }) => ({
  marginLeft: '5px',
  color: `${$palette('TEXT')}`
}));
