import {
  LOAD_SEARCH_FORM,
  LOAD_SEARCH_RESULTS,
  DO_SEARCH,
  RESET_SEARCH,
  SWAP_LOCATIONS,
  NO_RESULTS_SUBMIT_EMAIL

} from 'app/constants';
import { takeLatest } from 'redux-saga';
import { fork, cancel } from 'redux-saga/effects';
import { isUserEmailBlur } from 'app/sagas/patterns';
import watchSearchFormChange from './watchSearchFormChange';
import { takeLast } from 'app/sagas/utils';
import loadSearchForm from './loadSearchForm';
import loadSearchResults from './loadSearchResults';
import userEntersEmail from './userEntersEmail';
import doSearch from './doSearch';
import resetSearch from './resetSearch';
import swapLocations from './swapLocations';
import noResultsSubmitEmails from './noResultsForm';


export function* doWatchSearchForm() {
  yield fork(watchSearchFormChange);
  yield fork(takeLatest, isUserEmailBlur, userEntersEmail);
  yield fork(takeLatest, LOAD_SEARCH_FORM, loadSearchForm);
  yield fork(takeLatest, LOAD_SEARCH_RESULTS, loadSearchResults);
  yield fork(takeLast, DO_SEARCH, doSearch);
  yield fork(takeLatest, RESET_SEARCH, resetSearch);
  yield fork(takeLatest, SWAP_LOCATIONS, swapLocations);
  yield fork(takeLatest, NO_RESULTS_SUBMIT_EMAIL, noResultsSubmitEmails);
}

let currSearch = null;
export function* cancelLatestSearch() {
  if (currSearch) {
    yield cancel(currSearch);
  }
  currSearch = yield fork(doWatchSearchForm); // eslint-disable-line require-atomic-updates
}

export default function* watchSearchForm() {
  yield fork(cancelLatestSearch);
}
