import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { change as changeFieldValue } from 'redux-form';
import { FormattedMessage } from 'mz-intl';
import Lightbox from 'mz-ui-kit/core/Lightbox';
import Button from 'mz-ui-kit/core/Button';
import { styled } from 'mz-ui-kit'
import Form from 'mz-ui-kit/form/Form'
import PlaceField from 'mz-ui-kit/form/PlaceField/field';
import { doSearch } from 'app/actions/search-results';
import { getSearchForm, getTripToBook } from 'app/sagas/selectors'
import { getMainStep } from 'mz-sdk/models/step'
import { SEARCH_FORM_NAME } from 'app/constants'
import messages from '../messages.intl'

export const LightboxWrapper = styled('div', {
  padding: '20px',
  maxWidth: '500px',
})

export const LoadingWrapper = styled('div', {
  textAlign: 'center',
})

export const ButtonWrapper = styled('div', {
  marginTop: '20px'
})

function IncompleteAddressForm({ tripId, onSubmit }) {
  const dispatch = useDispatch()
  const { start_address: startAddress, end_address: endAddress } = useSelector(getSearchForm)
  const trip = useSelector((state) => getTripToBook(state, tripId))
  const { bookingDetails } = getMainStep(trip.steps) ?? {}
  const hasIncompleteAddress = bookingDetails.startAddressIncomplete || bookingDetails.endAddressIncomplete

  function handleSubmitForm(values) {
    if (values.startAddress) {
      dispatch(changeFieldValue(SEARCH_FORM_NAME, 'start_address', values.startAddress))
    }
    if (values.endAddress) {
      dispatch(changeFieldValue(SEARCH_FORM_NAME, 'end_address', values.endAddress))
    }
    dispatch(doSearch())
    onSubmit()
  }

  return (
    <>
      <FormattedMessage tagName="p" message={messages.INCOMPLETE_ADDRESS_PARAGRAPH} />
      <Form
        initValue={{ startAddress, endAddress }}
        onSubmit={handleSubmitForm}
        render={({ submit, value }, nest) => (
          <form onSubmit={submit}>
            {(!hasIncompleteAddress || bookingDetails.startAddressIncomplete) && (
              <PlaceField
                {...nest('startAddress', { dependsOnValue: true })}
                bias={value.endAddress}
                label={<FormattedMessage message={messages.SEARCH_FORM_FROM_TITLE} />}
                required
              />
            )}
            {(!hasIncompleteAddress || bookingDetails.endAddressIncomplete) && (
              <PlaceField
                {...nest('endAddress', { dependsOnValue: true })}
                bias={value.startAddress}
                label={<FormattedMessage message={messages.SEARCH_FORM_TO_TITLE} />}
                required
              />
            )}
            <ButtonWrapper>
              <Button type="submit">
                <FormattedMessage message={messages.INCORRECT_ADDRESS_SUBMIT_BUTTON} />
              </Button>
            </ButtonWrapper>
          </form>
        )}
      />
    </>
  )
}

const IncompleteAddressLightbox = ({
  tripId,
  children,
}) => {
  const [showed, setShowed] = React.useState(false)

  return (
    <>
      <Lightbox
        title="Incomplete Address"
        showed={showed}
        onClose={() => setShowed(false)}
        render={() => (
          <LightboxWrapper>
            <IncompleteAddressForm
              tripId={tripId}
              onSubmit={() => setShowed(false)}
            />
          </LightboxWrapper>
        )}
      />
      {children({ showForm: () => setShowed(true) })}
    </>
  )
}

export default IncompleteAddressLightbox
