import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import Star from './Star';
import CN from 'classnames';

import style from './style.css';


class RatingStars extends Component {
  state = {
    hoverIndex: -1,
    activeIndex: -1
  }

  onHover = (index) => {
    this.setState({ hoverIndex: index });
  }

  onLeave = () => {
    this.setState({ hoverIndex: -1 });
  }

  setInactive = debounce(() => {
    this.setState({
      activeIndex: -1
    });
  }, 150)

  setActive = (index) => {
    this.setState({
      activeIndex: index
    });
  }

  getStarIconName = (index) => {
    const { rating } = this.props;
    const baseRating = parseInt(rating, 10);
    let icon;

    if (index <= this.state.hoverIndex || index < baseRating) {
      icon = 'star';
    } else if (index === baseRating && rating !== baseRating) {
      icon = 'star_half';
    } else {
      icon = 'star_border';
    }

    return icon;
  }

  handleClick = (index) => {
    return Promise.resolve(this.props.onChange(index + 1))
      .then(this.setInactive)
      .catch(this.setInactive);
  }

  render() {
    const { className, amount, rating, editable } = this.props;

    const stars = Array(amount).fill().map((_, i) => (
      <Star
        key={i}
        index={i}
        on={i < rating}
        hover={i <= this.state.hoverIndex}
        active={i <= this.state.activeIndex}
        editable={editable}
        onClick={this.handleClick}
        onHover={this.onHover}
        onLeave={this.onLeave}
        setActive={this.setActive}
        iconName={this.getStarIconName(i)}
      />
    ));

    return (
      <div className={CN(style.rating, className)}>
        {stars}
      </div>
    );
  }
}

// TODO: move to Flow types
// RatingStars.propTypes = {
//   className: PropTypes.string,
//   rating: PropTypes.number.isRequired,
//   amount: PropTypes.number,
//   editable: PropTypes.bool,
//   onChange: PropTypes.func
// };

RatingStars.defaultProps = {
  amount: 5,
  editable: false
};

export default RatingStars;
