import {
  GET_TERMS_AND_CONDITIONS
} from 'app/constants';
import { takeLatest } from 'redux-saga';
import getTermsAndConditions from './getTermsAndConditions';


export default function* watchTermsAndConditions() {
  yield takeLatest(GET_TERMS_AND_CONDITIONS, getTermsAndConditions);
}
