import * as React from 'react'
import { styled } from 'mz-ui-kit'
import Tooltip from 'mz-ui-kit/core/Tooltip'
import tinycolor from 'tinycolor2'

function getTextColor(bgColor, colors) {
  return tinycolor.mostReadable(bgColor, colors)
}

const Container = styled('div', ({ $palette, $color }) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  boxShadow: `0 0 0 2px ${$palette($color)}`,
  borderRadius: '3px',
  alignItems: 'stretch',
  justifyContent: 'center',
  minWidth: '120px',
  height: '2.2em',
  overflow: 'hidden',
  position: 'relative',
  margin: '2px',
}))

const BadgeLeft = styled('span', ({ $palette, $color }) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: `${$palette($color)}`,
  color: `${getTextColor($palette($color).darken(17), [$palette('text'), $palette('white')])}`,
  padding: '1px 4px',
  minWidth: '2.2em',
  textAlign: 'center',
  textWeight: 'bold',
}))

const BadgeRight = styled('span', ({ $palette, $color }) => ({
  backgroundColor: `${$palette('white')}`,
  color: `${getTextColor($palette('white'), [$palette('white'), $palette('text'), $color])}`,
  padding: '0 4px',
  textAlign: 'center',
  flexGrow: 1,
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  justifyContent: 'center',
}))

// eslint-disable-next-line react/display-name
const BadgeContent = React.forwardRef(
  ({ leftPart, rightPart, color, targetProps, ...props }, ref) => {
    return (
      <Container {...props} {...targetProps} $color={color} ref={ref}>
        {leftPart && <BadgeLeft $color={color}>{leftPart}</BadgeLeft>}
        {rightPart && <BadgeRight $color={color}>{rightPart}</BadgeRight>}
      </Container>
    )
  }
)

export default function Badge({ tooltipContent, ...badgeProps }) {
  if (tooltipContent) {
    return (
      <Tooltip
        width="200px"
        target={(innerRef, targetProps) => (
          <BadgeContent {...badgeProps} targetProps={targetProps} ref={innerRef} />
        )}
        content={tooltipContent}
      />
    )
  }
  return <BadgeContent {...badgeProps} />
}
