import { select } from 'redux-saga/effects';
import { getHistory, getLocaleFreePathname } from 'app/history';
import { getLocation, getUser, isTravelAgent } from '../selectors';


export default function* redirectLoggedInUser() {
  const { pathname } = yield select(getLocation);
  const history = yield select(getHistory);
  const user = yield select(getUser);
  const isTAUser = yield select(isTravelAgent)

  if (getLocaleFreePathname(pathname).startsWith('/user/login') && user) {
    const prefix = isTAUser ? 'travel-agent' : 'user'
    history.replace(`/${prefix}/account`);
  }
}
