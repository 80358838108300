import React from 'react';
import { FormattedMessage } from 'translations';
import CN from 'classnames';

import style from './style.css';


const messages = {
  similarVehicle: { id: 'SEARCH_RESULTS.VEHICLE_MODEL_SIMILAR' }
};

const VehicleModel = ({
  className,
  make,
  model,
  withSeparator,
  showSimilarOnNewLine
}) => {
  const vehicleModel = make && model ? `${make} ${model}` : '';

  if (!vehicleModel) return null;

  const separator = showSimilarOnNewLine
    ? <br />
    : ' ';

  return (
    <div className={CN(style.container, className)}>
      {withSeparator && <span className={style.separator} />}
      <span>{vehicleModel}</span>
      {separator}
      <FormattedMessage {...messages.similarVehicle} />
    </div>
  );
};

// TODO: move to Flow types
// VehicleModel.propTypes = {
//   className: PropTypes.string,
//   make: PropTypes.string,
//   model: PropTypes.string,
//   withSeparator: PropTypes.bool,
//   showSimilarOnNewLine: PropTypes.bool
// };

export default VehicleModel;
