import { call, put, select } from 'redux-saga/effects';
import { trips } from 'mz-sdk';
import moment from 'moment';
import { parseUrlParams } from 'app/sagas/utils';
import objectToQueryString from 'mz-utils/objectToQueryString';
import { getMainStep, isScheduledTrip } from 'app/utils/trip';
import { clearNotifications } from 'app/actions/notifications';
import getOriginalTripPrice from './selectors/getOriginalTripPrice';
import { showErrorNotification } from 'app/sagas/utils';
import restoreOriginalPickupTime from './restoreOriginalPickupTime';
import prepareTripUpdatedDialog from './prepareTripUpdatedDialog';
import getResearchPickupTime from './getResearchPickupTime';
import {
  getActiveCurrency,
  getBookTripFormValue,
  getDepartingFlight,
  getReturningFlight,
} from 'app/sagas/selectors';
import {
  setTripError,
  updateTrip,
  startUpdateRequest,
  endUpdateRequest,
  hidePickupTimeChangedNotification
} from 'app/actions/book-trip';
import {
  BOOK_TRIP_UPDATED_MODAL_FORM,
  DEPARTING_PICKUP_FIELD_NAME,
  TRIP_UPDATED_ERROR_CODE,
  TRIP_PICKUP_TIMES_ORIGIN_TZ_FIELD_NAME,
  DEPARTING_TRIP_FIELD_NAME,
  RETURN_TRIP_FIELD_NAME,
} from 'app/constants';
import resolveFormValues from '../watchLoadBookTrip/resolveFormValues';

const HOLIDAY_TAXIS_PROVIDER_NAME = 'Holiday Taxis'

/**
 * This saga executed every time used changes pickup time (departure or return)
 * @param {Object} action Action fired by redux-form. Contains information about field and value
 */
export default function* handleChangePickupTime(action) {
  // clear previous error
  yield put(clearNotifications());
  yield put(hidePickupTimeChangedNotification());
  yield put(startUpdateRequest());

  // prepare research params
  const {
    payload: pickupTime,
    meta: { field },
  } = action;

  const flight =
    field === DEPARTING_PICKUP_FIELD_NAME
      ? yield select(getDepartingFlight)
      : yield select(getReturningFlight);

  const trip = yield select(getBookTripFormValue, 
    field === DEPARTING_PICKUP_FIELD_NAME ?
    DEPARTING_TRIP_FIELD_NAME : RETURN_TRIP_FIELD_NAME);
  
  const step = getMainStep(trip);

  // NOTE: Temporary workaround for the current checkout flow. 
  // This kind of hardcoded rule is not ideal/recommended and will be
  // replaced with the new checkout page soon.
  const isHolidayTaxis = step.provider.name === HOLIDAY_TAXIS_PROVIDER_NAME

  const newFlightTime = (trip?.fltSupport && flight) || (isHolidayTaxis && flight) ? 
    moment.parseZone(flight.flightDatetime) : null;

  const pickupTimeProp =
    field === DEPARTING_PICKUP_FIELD_NAME
      ? 'pickup_datetime'
      : 'return_pickup_datetime';

  const flightTimeProp =
    field === DEPARTING_PICKUP_FIELD_NAME
      ? 'flight_datetime'
      : 'return_flight_datetime';

  const newPickupTimeOriginTZ = yield call(getResearchPickupTime, { field, pickupTime });

  const currency = yield select(getActiveCurrency);

  // sdk now accept either moment obj or string in old SEARCH_PARAM_DATE_FORMAT
  const researchParams = {
    [pickupTimeProp]: newPickupTimeOriginTZ,
    ...(newFlightTime ? { [flightTimeProp]: newFlightTime } : {}),
    currency,
  };

  const { trip_id, session_id, ondemand, ...restUrlParams } = yield call(parseUrlParams);

  try {
    const updatedTrip = yield call(trips.research, {
      session_id,
      trip_id,
      ondemand,
      params: researchParams
    });

    yield put(endUpdateRequest());

    if (updatedTrip) {
      const updatedTripUrlParams = {
        session_id: updatedTrip.searchId,
        trip_id: updatedTrip.id,
        ...restUrlParams,
        currency
      };

      let newPrice = parseFloat(updatedTrip.price.raw);
      const originalPrice = yield select(getOriginalTripPrice);

      let tripUpdatedDialogInitialized = false;
      if (isScheduledTrip(updatedTrip)) {
        // prepare dialog by filling form values to show pricebreakdown
        // passed updatedTtip object to avoid next calls to retrieve trip
        yield call(prepareTripUpdatedDialog, { updatedTrip });

        newPrice = yield select(getOriginalTripPrice, BOOK_TRIP_UPDATED_MODAL_FORM);

        tripUpdatedDialogInitialized = true;
      }

      const newBookTripUrl = `/book${objectToQueryString(updatedTripUrlParams)}`;

      // prepare props for notification about changed time
      const newTripFormValues = yield call(resolveFormValues, { updatedTrip, reloadTrip: true });
      // select departure or return depending on what field was changed
      const originTime = newPickupTimeOriginTZ.format('hh:mm A');
      const newTime = newTripFormValues[field].format('hh:mm A');
      const isTimeChanged = originTime !== newTime;
      const newDateTimeTripTZ = newTripFormValues[TRIP_PICKUP_TIMES_ORIGIN_TZ_FIELD_NAME][field];
      // we pass newDateTimeTripTZ for later comparison in updateSelectFlight of new pickup time
      // without wating for loadBookTrip to be loaded
      const timeChangedProps = {
        isTimeChanged,
        field,
        originTime,
        newTime,
        newDateTime: newDateTimeTripTZ
      };

      if (newPrice !== originalPrice) {
        if (!tripUpdatedDialogInitialized) {
          yield call(prepareTripUpdatedDialog, { updatedTrip });
        }

        yield put(setTripError({
          errorCode: TRIP_UPDATED_ERROR_CODE,
          errorPopup: true,
          errorProps: { newBookTripUrl, changedFieldName: field, timeChangedProps }
        }));
      } else {
        yield put(updateTrip({ newBookTripUrl, updatedTrip, timeChangedProps }));
      }
    } else {
      yield call(showErrorNotification, { messageId: 'BOOK_TRIP.CANT_FIND_TRIP_FOR_NEW_TIME_ERROR' });
      yield call(restoreOriginalPickupTime, field);
    }
  } catch (error) {
    yield put(endUpdateRequest());
    yield call(showErrorNotification, { error, messageId: 'BOOK_TRIP.CHANGE_PICKUP_TIME_ERROR' });
    yield call(restoreOriginalPickupTime, field);
  }
}
