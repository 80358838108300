import {
  SET_SCHEDULED_DEPARTURE,
  VIEW_EARLIER_TIMES,
  VIEW_LATER_TIMES,
  START_SCHEDULE_RESEARCH,
  END_SCHEDULE_RESEARCH,
  UPDATE_SCHEDULE,
  SET_SCHEDULE_UPDATE_ERROR,
  UPDATE_SCHEDULED_TRIP_PRICE
} from 'app/constants';
import { createAction } from '../utils/redux';

export const scheduleDepartureTime = createAction(
  SET_SCHEDULED_DEPARTURE
);

export const viewEarlierTimes = createAction(
  VIEW_EARLIER_TIMES
);

export const viewLaterTimes = createAction(
  VIEW_LATER_TIMES
);

export const startScheduleResearch = createAction(
  START_SCHEDULE_RESEARCH
);

export const endScheduleResearch = createAction(
  END_SCHEDULE_RESEARCH
);

export const updateSchedule = createAction(
  UPDATE_SCHEDULE
);

export const setScheduleUpdateError = createAction(
  SET_SCHEDULE_UPDATE_ERROR
);

export const updateScheduledTripPrice = createAction(
  UPDATE_SCHEDULED_TRIP_PRICE
);
