import {
  SEARCH_FORM_NAME,
  IS_FLIGHT_TIME_FIELD_NAME,
  IS_RETURN_FLIGHT_TIME_FIELD_NAME,
  HOURLY_MODE_VALUE,
  PARKING_MODE_VALUE,
} from 'app/constants';
import moment from 'moment';

// Constants
const initialValues = {
  mode: 'one_way',
  num_passengers: 1,
  booking_mode: 'point_to_point',
  hourly_booking_duration: 1,
  [IS_FLIGHT_TIME_FIELD_NAME]: false,
  [IS_RETURN_FLIGHT_TIME_FIELD_NAME]: false,
};

const validate = (values) => {
  const errors = {};
  const isHourlyRide = values.booking_mode === HOURLY_MODE_VALUE;
  const isParkingSearch = values.booking_mode === PARKING_MODE_VALUE;

  if (isParkingSearch) {
    if (!values.parking_spot || !values.parking_spot.display) {
      errors.parking_spot = 'VALIDATION.REQUIRED';
    }
    return errors;
  }

  const startAddress = values.start_address;
  // TODO: remove check for empty display name (which is because of old autocomplete)
  if (!startAddress || startAddress.display === '') {
    errors.start_address = 'VALIDATION.REQUIRED';
  }

  const endAddress = values.end_address;
  // TODO: remove check for empty display name (which is because of old autocomplete)
  if (!isHourlyRide && (!endAddress || endAddress.display === '')) {
    errors.end_address = 'VALIDATION.REQUIRED';
  }

  if (!isHourlyRide && values.mode === 'round_trip') {
    const returnTime = values.return_pickup_datetime;
    if (
      values.pickup_datetime &&
      returnTime &&
      returnTime.isBefore(values.pickup_datetime)
    ) {
      errors.pickup_datetime = 'VALIDATION.INVALID_DATES';
      errors.return_pickup_datetime = 'VALIDATION.INVALID_DATES';
    }
  }

  if (!values.pickup_datetime) {
    errors.pickup_datetime = 'VALIDATION.REQUIRED';
  }
  if (
    !isHourlyRide &&
    values.mode === 'round_trip' &&
    !values.return_pickup_datetime
  ) {
    errors.return_pickup_datetime = 'VALIDATION.REQUIRED';
  }

  if (moment().isAfter(values.pickup_datetime)) {
    errors.pickup_datetime = 'VALIDATION.PICKUP_DATE_IN_THE_PAST';
  }

  return errors;
};

export default {
  form: SEARCH_FORM_NAME,
  destroyOnUnmount: false,
  touchOnBlur: false,
  touchOnChange: true,
  initialValues,
  validate,
};
