import React from 'react';
import CheckBox from 'app/components/base/CheckBox';

import style from './style.css';


const CheckBoxInput = ({
  input,
  placeholder,
  refInput,
  ...props
}) => (
  <CheckBox
    {...props}
    ref={refInput}
    onChange={input.onChange}
    value={input.value}
  >
    <input
      {...input}
      type="checkbox"
      className={style.input}
    />
    {placeholder}
  </CheckBox>
);

// TODO: move to Flow types
// CheckBoxInput.propTypes = {
//   input: PropTypes.object.isRequired,
//   placeholder: PropTypes.string,
//   refInput: PropTypes.func
// };

export default CheckBoxInput;
