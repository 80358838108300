import { put, select } from 'redux-saga/effects';
import formConfig from 'app/forms/search-form';
import { getSearchForm } from 'app/sagas/selectors';
import { change, touch } from 'redux-form';


/**
 * Saga for swapping location values in the search form, either in
 * the homepage or the search results page form instances.
 */
export default function* swapLocations() {
  const values = yield select(getSearchForm);

  const { start_address, end_address } = values;

  yield put(change(formConfig.form, 'start_address', end_address));
  yield put(change(formConfig.form, 'end_address', start_address));
  yield put(touch(formConfig.form, 'start_address', 'end_address'));
}
