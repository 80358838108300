import { styled } from 'mz-ui-kit'

export const Feature = styled('div', {
  fontSize: '14px',
  ':not(:last-child)': {
    marginBottom: '10px',
  }
})

export const CheckIconWrapper = styled('div', ({ $palette }) => ({
  color: `${$palette('GREEN')}`,
  display: 'inline-block',
  marginRight: '9px',
}))
