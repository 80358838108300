import React, { Component } from 'react';
import TimePicker from '../TimePicker';
import OptionPicker from '../OptionPicker';
import NumberInput from '../NumberInput';
import Switch from '../Switch';
import CreditCardInput from 'app/components/base/CreditCardInput';
import CheckBoxInput from '../CheckBoxInput';
import MonthSelect from 'app/components/base/MonthSelect';
import BaseAutocomplete from 'app/components/base/BaseAutocomplete/Desktop';
import AutocompleteInput, {
  DecoupledAutocompleteInput
} from 'app/components/base/AutocompleteInput/Desktop';
import YearSelect from 'app/components/base/YearSelect';
import StandardInput from 'app/components/base/StandardInput';
import TextArea from 'app/components/base/TextArea';
import Radio from 'app/components/base/Radio';
import _ from 'lodash';
import DatePicker from 'app/components/base/DatePicker';
import { MAX_YEAR_COUNT_FROM_CURRENT, AUTOCOMPLETE_TYPES } from 'app/constants';


const onlyNumbersRegex = /^[\d\s]*$/;

class FormInput extends Component {
  componentDidMount() {
    if (this.input && this.input.focus) {
      this.focus = this.input.focus;
    }
  }

  focus = _.noop;

  refInput = (node) => {
    this.input = node;
  }

  render() {
    const {
      bias,
      minDate,
      type = 'text',
      ...props
    } = this.props;
    switch (type) {
      case 'phone_code': return (
        <BaseAutocomplete
          type={type}
          target={AUTOCOMPLETE_TYPES.PHONE_CODE}
          {...props}
        />
      );
      case 'time': return (
        <TimePicker
          {...props}
          minDate={minDate}
        />
      );
      case 'options': return (
        <OptionPicker
          {...props}
        />
      );
      case 'number': return (
        <NumberInput
          {...props}
        />
      );
      case 'switch': return (
        <Switch
          {...props}
        />
      );
      case 'checkbox': return (
        <CheckBoxInput
          {...props}
        />
      );
      case 'monthSelect': return (
        <MonthSelect
          {...props}
        />
      );
      case 'yearSelect': return (
        <YearSelect
          maxYearCount={MAX_YEAR_COUNT_FROM_CURRENT}
          {...props}
        />
      );
      case 'creditCardNumber':
      case 'creditCardExpiry':
      case 'creditCardCVC': return (
        <CreditCardInput
          {...props}
          type={type}
        />
      );
      case 'date': return (
        <DatePicker
          {...props}
          minDate={minDate || bias}
        />
      );
      case 'autocomplete': return (
        <AutocompleteInput
          ref={this.refInput}
          {...props}
        />
      );
      case 'decoupledautocomplete': return (
        <DecoupledAutocompleteInput
          ref={this.refInput}
          {...props}
        />
      );
      case 'textarea': return (
        <TextArea
          ref={this.refInput}
          {...props}
        />
      );
      case 'radio': return (
        <Radio
          ref={this.refInput}
          type={type}
          {...props}
        />
      );
      case 'select': {
        return (
          <select
            {..._.omit(props, ['input'])}
            {...props.input}
          >
            {
              props.options.map(({ value, label }, index) => (
                <option
                  key={index}
                  value={value}
                >
                  {label}
                </option>
              ))
            }
          </select>
        );
      }
      case 'phone_number': return (
        <StandardInput
          ref={this.refInput}
          type="text"
          regex={onlyNumbersRegex}
          {...props}
        />
      );
      default: return (
        <StandardInput
          ref={this.refInput}
          type={type}
          {...props}
        />
      );
    }
  }
}

// TODO: move to Flow types
// FormInput.propTypes = {
//   type: PropTypes.string,
//   bias: PropTypes.object,
//   minDate: PropTypes.object,
//   error: PropTypes.string
// };

export default FormInput;
