import React from 'react';
import { FormattedMessage } from 'translations';
import { Layout, Flex } from 'app/components/base/Layout';
import Icon from 'app/components/base/Icon';
import LoadingSpinner from 'app/components/base/LoadingSpinner';
import InlineHint from 'app/components/base/InlineHint';
import classnames from 'classnames';

import style from './style.css';


const messages = {
  hintMessage: { id: 'BOOK_TRIP.DEFAULT_PRICE_UPDATE_ERROR' }
};

/**
 * Upgrade item component
 */
export class UpgradeItem extends React.Component {

  state = {
    editing: false,
    error: false,
    loading: false
  }

  onClickEditButton = () => {
    this.setState({ editing: true });
  }

  handleOnRemove = () => {
    this.setState({ loading: true });

    this.props.onRemove()
      .then(() => this.setState({ loading: false, error: false }))
      .catch(() => this.setState({ loading: false, error: true }));
  }

  get hintMessage() {
    return (
      <InlineHint
        message={<FormattedMessage {...messages.hintMessage} />}
        onClose={() => this.setState({ error: false })}
      />
    );
  }

  render() {
    const {
      name,
      caption,
      price,
      optional,
      notIncluded,
      mobile,
      readOnly,
      editComponent
    } = this.props;

    const modifiers = {
      [style['item--notIncluded']]: notIncluded,
      [style['item--mobile']]: mobile,
      [style['item--edit']]: this.state.editing
    };

    const showEditButton = !readOnly && editComponent && !this.state.editing;
    const showEditComponent = !readOnly && editComponent && this.state.editing;

    return (
      <Layout
        className={classnames(style.item, modifiers)}
        component="li"
        direction="column"
        nowrap
      >
        <Layout
          direction="row"
          align="start center"
          size="noshrink"
          nowrap
        >
          {/* Remove icon */}
          {optional && !readOnly && (
            <Flex size="noshrink">
              {!this.state.loading && (
                <Icon
                  className={style.item__iconRemove}
                  family="mozio"
                  onClick={this.handleOnRemove}
                >
                  close
                </Icon>
              )}
              {this.state.loading && (
                <div className={style.item__loading}>
                  <LoadingSpinner size="small" />
                </div>
              )}
            </Flex>
          )}
          {/* Name */}
          <Flex className={style.item__name} size="grow">
            {name}
            {caption && <span className={style.item__caption}>{caption}</span>}
          </Flex>
          {/* Edit button */}
          {showEditButton && (
            <Icon
              className={style.item__edit_button}
              small
              onClick={this.onClickEditButton}
            >
              mode_edit
            </Icon>
          )}
          {/* Price block */}
          {price && !notIncluded && (
            <Flex className={style.item__price} size="noshrink">
              {price}
            </Flex>
          )}
          {/* Availability icons */}
          {!price && (
            <Flex size="noshrink" className={style.item__iconAvailable}>
              <Icon family="mozio">
                {notIncluded ? 'sb-cross' : 'sb-check'}
              </Icon>
            </Flex>
          )}
        </Layout>
        {/* Edit component */}
        {showEditComponent && (
          <Layout direction="row" size="noshrink">
            {
              // React.createElement doesn't work here for some reason.
              // So component used as normal function
              editComponent({ onUpdate: () => this.setState({ editing: false }) })
            }
          </Layout>
        )}
        {this.state.error && this.hintMessage}
      </Layout>
    );
  }
}

// TODO: move to Flow types
// UpgradeItem.propTypes = {
//   name: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.object
//   ]).isRequired,
//   caption: PropTypes.string,
//   price: PropTypes.string,
//   optional: PropTypes.bool,
//   notIncluded: PropTypes.bool,
//   mobile: PropTypes.bool,
//   readOnly: PropTypes.bool,
//   editComponent: PropTypes.func,
//   // actions
//   onRemove: PropTypes.func
// };

export default UpgradeItem;
