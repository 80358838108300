import React from 'react';
import config from 'config';
import { hook } from 'app/utils/hook';
import { USER_ROUTES_HOOK } from 'app/constants';
import importWithRetry from 'mz-utils/importWithRetry';
import { getActiveLanguage, getActiveCurrency } from 'app/sagas/selectors';
import { useSelector } from 'react-redux';

// Constants
export const USER_TOOL = 'user';

// Lazy loaded routes
const LoginPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/LoginPage'))
);
const LoginMFAPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/LoginMFAPage'))
);
const GuestUserPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/GuestUserPage'))
);
const ForgotPasswordPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/ForgotPasswordPage'))
);
const ResetPasswordPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/ResetPasswordPage'))
);
const RegisterPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/RegisterPage'))
);
const ReactivateAccountPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/ReactivateAccountPage'))
);
const DashboardPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/DashboardPage'))
);
const UserCreditCardsPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/UserCreditCardsPage'))
);
const TACustomerProfilesPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/TACustomerProfilesPage'))
);
const UserFavouriteLocationsPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/UserFavouriteLocationsPage'))
);
const MFAPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/MFA'))
);
const UserCheckYourEmailPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/UserCheckYourEmailPage'))
);

const ENABLE_MFA = process.env.CONFIG_ENV !== 'production';

const RedirectToNewDashboard = (newPath) => {
  const Redirect = () => {
    const activeLanguage = useSelector(getActiveLanguage);
    const activeCurrency = useSelector(getActiveCurrency);
    const redirectionUrl = `/v2/user${newPath}?locale=${activeLanguage}&currency=${activeCurrency}`;

    window.location.href = redirectionUrl;

    return null;
  };

  return Redirect;
};

const routes = [
  {
    path: `/${USER_TOOL}/login`,
    component: LoginPage,
    wrapper: GuestUserPage,
  },
  ENABLE_MFA && {
    path: `/${USER_TOOL}/login/mfa`,
    component: LoginMFAPage,
    wrapper: GuestUserPage,
  },
  !config.PASSWORD_RESET_ABILITY
    ? null
    : {
        path: `/${USER_TOOL}/forgot-password`,
        component: ForgotPasswordPage,
        wrapper: GuestUserPage,
      },
  {
    path: `/reset-password/confirm/:token`,
    component: ResetPasswordPage,
    wrapper: GuestUserPage,
  },
  {
    path: `/${USER_TOOL}/register`,
    component: RegisterPage,
    wrapper: GuestUserPage,
  },
  {
    path: `/${USER_TOOL}/reactivate`,
    component: ReactivateAccountPage,
    wrapper: GuestUserPage,
  },
  {
    path: `/${USER_TOOL}/check-your-email`,
    component: UserCheckYourEmailPage,
    wrapper: GuestUserPage,
  },
  {
    path: `/${USER_TOOL}/account`,
    component: RedirectToNewDashboard('/my-reservations'),
    wrapper: DashboardPage,
  },
  {
    path: `/${USER_TOOL}/info`,
    component: RedirectToNewDashboard('/info'),
    wrapper: DashboardPage,
  },
  {
    path: `/${USER_TOOL}/credit-cards`,
    component: UserCreditCardsPage,
    wrapper: DashboardPage,
  },
  {
    path: `/${USER_TOOL}/favorite-locations`,
    component: UserFavouriteLocationsPage,
    wrapper: DashboardPage,
  },
  {
    path: `/${USER_TOOL}/customer-profiles`,
    component: TACustomerProfilesPage,
    wrapper: DashboardPage,
  },
  ENABLE_MFA && {
    path: `/${USER_TOOL}/mfa`,
    component: MFAPage,
    wrapper: DashboardPage,
  },
].filter(Boolean);

export default () =>
  hook({
    name: USER_ROUTES_HOOK,
    args: [routes],
    defaultHandler: () => routes,
  });
