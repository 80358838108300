import { call, put, select } from 'redux-saga/effects';
import { parseUrlParams } from 'app/sagas/utils';
import { updateTrip, setChangedModeTrip } from 'app/actions/book-trip';
import objectToQueryString from 'mz-utils/objectToQueryString';
import { getChangedModeTrip } from 'app/sagas/selectors';

/**
 * When changing current trip mode this saga
 * is called to reinitialize the BTP with the
 * trip stored as changed_mode_trip in state
 * (by getChangedModeTrip).
 */
export default function* bookChangedModeTrip() {
  const updatedTrip = yield select(getChangedModeTrip);
  const urlParams = yield call(parseUrlParams);

  if (updatedTrip) {
    const updatedTripUrlParams = {
      ...urlParams,
      session_id: updatedTrip.searchId,
      trip_id: updatedTrip.id
    };

    const newBookTripUrl = `/book${objectToQueryString(updatedTripUrlParams)}`;
    yield put(updateTrip({ newBookTripUrl, updatedTrip }));
  }
  yield put(setChangedModeTrip(null));
}
