import config from 'config'
import _ from 'lodash'
import shallowEqual from 'mz-utils/shallowEqual'
import omitEmptyFields from 'mz-utils/omitEmptyFields'
import { call, select, put } from 'redux-saga/effects';
import { setTravelAgent } from 'app/actions/travel-agent';
import { setUser } from 'app/actions/user-session';
import { getBookTripForm, behavesAsTravelAgent, getUser } from 'app/sagas/selectors';
import { TA_AGENT_ID_FIELD_NAME, TA_IATA_FIELD_NAME } from 'app/constants'
import updateProfile from 'mz-sdk/services/travelagent/updateProfile'


export default function* updateTravelAgent() {
  const isTravelAgent = yield select(behavesAsTravelAgent)
  if (!isTravelAgent) return

  const form = yield select(getBookTripForm);
  const user = yield select(getUser);
  let profileUpdate = null

  if (config.AGENT_IATA_AND_ID_ENABLED) {
    profileUpdate = omitEmptyFields({
      [TA_AGENT_ID_FIELD_NAME]: (form[TA_AGENT_ID_FIELD_NAME] || '').trim(),
      [TA_IATA_FIELD_NAME]: (form[TA_IATA_FIELD_NAME] || '').trim()
    }, { string: true })
  }

  const isProfileChanged = (
    !_.isEmpty(profileUpdate) &&
    !shallowEqual(user, profileUpdate, { include: {
      [TA_AGENT_ID_FIELD_NAME]: true,
      [TA_IATA_FIELD_NAME]: true
    }})
  )

  if (isProfileChanged) {
    const newTAProfile = yield call(updateProfile, profileUpdate)
    yield put(setUser({ ...user, ...newTAProfile }));
    yield put(setTravelAgent(newTAProfile));
  }
}
