import { styled } from 'mz-ui-kit'

export const DataContainer = styled('div', {
  background: 'white',
  minWidth: '60px',
  position: 'relative',
  marginBottom: '10px'
});

export const DataLabel = styled('div', ({ $palette }) => ({
  display: 'flex',
  width: '100%',
  fontWeight: 'bold',
  alignItems: 'center',
  fontSize: '11px',
  marginBottom: '4px',
  color: `${$palette('TEXT').lighten(60)}`
}));

export const ModeTitle = styled('div', {
  padding: '20px 10px 5px',
  borderBottom: '1px solid #e4e4e4'
});

export const RouteSection = styled('div', {
  display: 'flex',
  marginTop: '20px',
  padding: '0 10px 30px 10px',
  borderBottom: '1px solid #e4e4e4'
});

export const Column = styled('div', {
  width: '50%',
  maxWidth: '50%'
});

export const AddressWrapper = styled('div', {
  paddingLeft: '18px'
});

export const Circle = styled('div', ({ $palette }) => ({
  width: '10px',
  height: '10px',
  borderRadius: '50%',
  border: `2px solid ${$palette('PRIMARY')}`,
  marginRight: '8px'
}));
