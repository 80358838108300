import React from 'react';
import HelpTooltip from 'mz-ui-kit/core/HelpTooltip';
import { FormattedMessage } from 'mz-intl';
import { TooltipContainer, TooltipContent } from './elements';
import messages from '../../messages.intl';

const EstimatedTimeTooltip = () => {
  return (
    <TooltipContainer>
      <HelpTooltip
        size="inherit"
        width="260px"
        appearance="info"
        placement="bottom"
        content={() => (
          <TooltipContent>
            <FormattedMessage
              message={messages.TIMES_ARE_ESTIMATED}
            />
          </TooltipContent>
        )}
      />
    </TooltipContainer>
  );
};

export default EstimatedTimeTooltip;
