import React from 'react';
import classnames from 'classnames';
import { Layout } from 'app/components/base/Layout';
import connectScreenSize from 'app/hocs/connectScreenSize';
import Icon from 'app/components/base/Icon';

import style from './style.css';

const LabeledFrame = ({
  text,
  icon,
  noPadding,
  iconColor,
  children,
  primary,
  secondary,
  dark,
  compact,
  iconClassName,
  light,
  className,
  capitalize,
  ellipsis,
  wrap,
  size,
  fill,
  onMouseDown,
  onClick,
  small,
  screenSize,
  align = 'center center'
}) => {
  const iconProps = typeof icon === 'string'
    ? { name: icon }
    : icon;
  
  const isIconNode = React.isValidElement(icon)

  const hasIcon = iconProps && (iconProps.name || iconProps.src);

  const modifiers = {
    [style.wrap]: wrap || !screenSize['> small'],
    [style.small]: !!small,
    [style.capitalize]: capitalize,
    [style.noicon]: !hasIcon,
    [style.nopadding]: noPadding,
    [style.ellipsis]: ellipsis,
    [style.compact]: compact
  };

  return (
    <Layout
      fill={fill}
      align={align}
      size={size}
      onMouseDown={onMouseDown}
      onClick={onClick}
      direction="row"
      className={classnames(style.container, modifiers, className)}
      nowrap
    >
      {!isIconNode && hasIcon &&
        <Icon
          {...iconProps}
          label={!small}
          small={small}
          dark={dark || (small && !primary)}
          light={light}
          primary={primary}
          secondary={secondary}
          className={classnames(style.icon, iconClassName)}
          style={iconColor && { fill: iconColor }}
        />
      }
      {isIconNode && icon}
      {(text || children) && (
        <Layout size="auto" className={style.label} nowrap>
          {text || children}
        </Layout>
      )}
    </Layout>
  );
};

// TODO: move to Flow types
// LabeledFrame.propTypes = {
//   noPadding: PropTypes.bool,
//   wrap: PropTypes.bool,
//   compact: PropTypes.bool,
//   capitalize: PropTypes.bool,
//   ellipsis: PropTypes.bool,
//   small: PropTypes.bool,
//   primary: PropTypes.bool,
//   secondary: PropTypes.bool,
//   dark: PropTypes.bool,
//   light: PropTypes.bool,
//   icon: PropTypes.oneOfType([
//     PropTypes.object,
//     PropTypes.string
//   ]),
//   iconColor: PropTypes.string,
//   screenSize: PropTypes.object,
//   className: PropTypes.string,
//   iconClassName: PropTypes.string,
//   text: PropTypes.node,
//   children: PropTypes.node
// };

export default connectScreenSize(LabeledFrame);
