import React from 'react';
import { FormattedMessage } from 'translations';
import { Layout } from 'app/components/base/Layout';
import TripDirection from 'app/components/common/TripDirection/Mobile';
import DataField from './DataField';
import HourlySummary from './HourlySummary';
// import Button from 'app/components/base/Button';
import { ONE_WAY_MODE_VALUE } from 'app/constants';
import { ModeTitle, RouteSection, Column } from './elements';


// Constants
const messages = {
  departureLabel: { id: 'TRIP_DETAILS.DEPARTURE' },
  arrivalLabel: { id: 'TRIP_DETAILS.ARRIVAL' },
  changeDepartureLabel: { id: 'TRIP_DETAILS.MOBILE.CHANGE_DEPARTURE' },
  oneWay: { id: 'TRIP_DETAILS.MODE_ONEWAY' },
  roundtrip: { id: 'TRIP_DETAILS.MODE_ROUNDTRIP' }
};

const Route = ({
  fromAddress,
  toAddress,
  fromStation,
  toStation,
  departureTime,
  arrivalTime
}) => (
  <RouteSection>
    {/* Dates * TODO: Locailze dates. */}
    {departureTime && (
      <Column>
        <Layout direction="column" nowrap>
          <DataField
            renderValue={() => departureTime}
            renderLabel={() => <FormattedMessage {...messages.departureLabel} />}
          />
          <DataField
            renderValue={() => arrivalTime}
            renderLabel={() => <FormattedMessage {...messages.arrivalLabel} />}
          />
        </Layout>
      </Column>
    )}
    {/* Locations */}
    <Column>
      <TripDirection
        fromAddress={fromAddress}
        toAddress={toAddress}
        fromStation={fromStation}
        toStation={toStation}
      />
    </Column>
  </RouteSection>
);

/**
 * Renders the route details section that specifies dates and locations for
 * the trip. It can be used both for normal and return trip.
 * TODO: Add callbacks for changeDeparture and routeDetails buttons when connecting.
 */
const RouteSummary = ({
  fromAddress,
  toAddress,
  fromStation,
  toStation,
  tripMode,
  departureTime,
  arrivalTime,
  returnDepartureTime,
  returnArrivalTime,
  isHourlyRide,
  hourlyDetails
}) => {
  if (isHourlyRide) {
    return (
      <HourlySummary
        fromAddress={fromAddress}
        hourlyDetails={hourlyDetails}
        departureDatetime={departureTime}
      />
    );
  }

  const isRoundTrip = tripMode !== ONE_WAY_MODE_VALUE;

  const modeName = isRoundTrip
    ? messages.roundtrip
    : messages.oneWay;

  return (
    <Layout direction="column" nowrap>
      <ModeTitle>
        <FormattedMessage {...modeName} />
      </ModeTitle>
      <Route
        fromAddress={fromAddress}
        fromStation={fromStation}
        toAddress={toAddress}
        toStation={toStation}
        departureTime={departureTime}
        arrivalTime={arrivalTime}
      />
      {isRoundTrip && (
        <Route
          fromAddress={toAddress}
          fromStation={toStation}
          toAddress={fromAddress}
          toStation={fromStation}
          departureTime={returnDepartureTime}
          arrivalTime={returnArrivalTime}
        />
      )}
    </Layout>
  );
};

// TODO: move to Flow types
// RouteSummary.propTypes = {
//   departureTime: PropTypes.string,
//   arrivalTime: PropTypes.string,
//   fromAddress: PropTypes.string,
//   toAddress: PropTypes.string,
//   fromStation: PropTypes.object,
//   toStation: PropTypes.object,
//   tripMode: PropTypes.string,
//   onClickRouteDetails: PropTypes.func,
//   onClickChangeDeparture: PropTypes.func,
//   isHourlyRide: PropTypes.bool,
//   hourlyDetails: PropTypes.shape({
//     hoursRequested: PropTypes.number,
//     minimum: PropTypes.number
//   })
// };

export default RouteSummary;
