import { call, put } from 'redux-saga/effects';
import { setUser } from 'app/actions/user-session';
import { showErrorNotification } from 'app/sagas/utils';
import getUserData from './getUserData';
import client, { saveJWTToken } from 'mz-sdk/client';
import { LOGIN_ENDPOINT_V2 } from 'mz-sdk/services/auth/login';
import doLogin from './doLogin';

const loginMFA = (data) =>
  client
    .post(LOGIN_ENDPOINT_V2 + '/mfa-code/', { body: data })
    .then(saveJWTToken);

export default function* doLoginMFA(action) {
  try {
    const { data } = yield call(loginMFA, action.payload);

    yield put(setUser(data));
    yield call(getUserData);
    yield call(doLogin, { payload: action.payload }, false, true);
  } catch (error) {
    yield put(setUser());
    yield call(showErrorNotification, {
      error,
      titleId: 'USER.LOGIN_ERROR_TITLE',
    });
  }
}
