import React from 'react';
import Button from 'app/components/base/Button';
import { Layout } from 'app/components/base/Layout';
import classnames from 'classnames';
import moment from 'moment';
import { MONTH_DAY_FORMAT, HOUR_FORMAT } from 'app/utils/types';

import style from './style.css';


/**
 * Buttons to select given date (from `value` prop) or current date/time
 * ("Today, Now" button).
 */
export class SelectButtons extends React.Component {

  handleSelectActive = () => {
    const { onChange, value } = this.props;
    if (onChange) {
      onChange(value);
    }
  }

  render() {
    const {
      className,
      value,
      onChange, // eslint-disable-line
      notime,
      ...props
    } = this.props;

    return (
      <Layout
        nowrap
        direction="column"
        className={classnames(className, style.buttons, { [style.time]: notime })}
        {...props}
      >
        <Button
          wide
          nocaps
          rounded
          className={style.buttons__item}
          onClick={this.handleSelectActive}
        >
          {moment(value).format(notime ? MONTH_DAY_FORMAT : `${MONTH_DAY_FORMAT}, ${HOUR_FORMAT}`)}
        </Button>
      </Layout>
    );
  }
}

// TODO: move to Flow types
// SelectButtons.propTypes = {
//   value: PropTypes.any,
//   className: PropTypes.string,
//   onChange: PropTypes.func,
//   notime: PropTypes.bool
// };

export default SelectButtons;
