import { places } from 'mz-sdk';
import moment from 'moment';
import { call } from 'redux-saga/effects';

export default function* resolvePickupDatetime(
  flight_datetime,
  start_address = {},
  end_address = {},
  toAirport
) {
  try {
    return yield call(places.getPickupTime, {
      flight_datetime,
      start_lat: start_address.lat,
      start_lng: start_address.lng,
      end_lat: end_address.lat,
      end_lng: end_address.lng,
      service_type: toAirport ? 1 : 2
    });
  } catch (err) {
    if (toAirport) {
      return moment(flight_datetime).subtract(4, 'hours');
    }
    return moment(flight_datetime).add(30, 'minutes');
  }
}
