import React, { Component } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'translations';
import CompactInput from 'app/components/base/CompactInput';
import { Layout } from 'app/components/base/Layout';
import Button from 'app/components/base/Button';
import Icon from 'app/components/base/Icon';
import InlineHint from 'app/components/base/InlineHint';

import style from './style.css';


const messages = {
  add: { id: 'BOOK_TRIP.ADD' },
  hintMessage: { id: 'BOOK_TRIP.DEFAULT_PRICE_UPDATE_ERROR' },
  close: { id: 'CLOSE' }
};

/**
 * Simple component for editing coupon code
 */
export class CouponInput extends Component {
  setFocus = () => {
    if (this.inputField) {
      this.inputField.focus();
    }
  }

  handleBlurInput = (e) => {
    const { onAdd, input: { onBlur } } = this.props;

    if (onAdd) onAdd();
    if (onBlur) onBlur(e);
  }

  get validatedContent() {
    return (
      <Layout className={style.couponInput__label} align="center center">
        {this.props.coupon_code}
      </Layout>
    );
  }

  get input() {
    const { wrongCode, disabled } = this.props;
    return (
      <div className={style.couponInput__fieldWrapper} key="input">
        <CompactInput
          {...this.props}
          input={{
            ...this.props.input,
            onBlur: this.handleBlurInput
          }}
          refInput={f => this.inputField = f}
          className={style.couponInput__field}
          type="text"
          disabled={disabled}
          success={!wrongCode}
          shake={wrongCode}
          error={wrongCode}
          compact stretch
        />
      </div>
    );
  }

  get hintMessage() {
    return (
      <InlineHint
        message={<FormattedMessage {...messages.hintMessage} />}
        onClose={this.props.onHintClose}
      />
    );
  }

  get refreshButton() {
    return [
      <Button
        key="refresh"
        className={style.couponInput__refresh}
        onClick={this.props.onRefresh}
        clear
      >
        <Icon name="refresh" />
      </Button>,
      this.props.showHint && this.hintMessage
    ];
  }

  render() {
    const {
      wrongCode,
      showRefresh,
      readOnly
    } = this.props;

    const modifiers = {
      [style['couponInput--wrongCode']]: wrongCode
    };

    let content;
    if (showRefresh) {
      content = this.refreshButton;
    } else if (readOnly) {
      content = this.validatedContent;
    } else {
      content = this.input;
    }

    return (
      <Layout
        size="grow"
        className={classnames(style.couponInput, modifiers)}
        direction="row"
        nowrap
      >
        {content}
      </Layout>
    );
  }
 }

// TODO: move to Flow types
// CouponInput.propTypes = {
//   input: PropTypes.object,
//   active: PropTypes.bool,
//   wrongCode: PropTypes.bool,
//   showRefresh: PropTypes.bool,
//   showHint: PropTypes.bool,
//   disabled: PropTypes.bool,
//   onAdd: PropTypes.func,
//   onRefresh: PropTypes.func,
//   onHintClose: PropTypes.func,
//   meta: PropTypes.object,
//   // form props
//   coupon_code: PropTypes.string,
//   readOnly: PropTypes.bool
// };

export default CouponInput;
