const EMAIL = 'email';

export default [
  {
    'name': 'EMAIL_CUSTOM_LOGO',
    'type': 'image',
    'default': '',
    'description': 'Custom logo for email header',
    'category': EMAIL
  },
  {
    'name': 'EMAIL_CUSTOM_ENABLED',
    'type': 'bool',
    'default': false,
    'description': 'Enable custom emails',
    'category': EMAIL,
    'editable': false,
  },
  {
    'name': 'EMAIL_LOGO_POSITION',
    'type': 'select',
    'options': [
      { title: 'Center', value: 'middle' },
      { title: 'Top', value: 'top' },
      { title: 'Bottom', value: 'bottom' }
    ],
    'default': 'middle',
    'description': 'Logo vertical position',
    'category': EMAIL
  },
  {
    'name': 'EMAIL_LOGO_ALIGNMENT',
    'type': 'select',
    'options': [
      { title: 'Center', value: 'center' },
      { title: 'Left', value: 'left' },
      { title: 'Right', value: 'right' }
    ],
    'default': 'left',
    'description': 'Logo horizontal position',
    'category': EMAIL
  },
  {
    'name': 'EMAIL_ADDITIONAL_FOOTER',
    'type': 'string',
    'default': ' ',
    'description': 'Additional footer',
    'category': EMAIL
  },
  {
    'name': 'EMAIL_HEADS_TEXT_COLOR',
    'type': 'color',
    'default': '#0D84EB',
    'description': 'Color of headers text',
    'category': EMAIL
  },
  {
    'name': 'EMAIL_TITLE_TEXT_COLOR',
    'type': 'color',
    'default': '#000000',
    'description': 'Color of title text',
    'category': EMAIL
  },
  {
    'name': 'EMAIL_TITLE_TEXT_ALT_COLOR',
    'type': 'color',
    'default': '#FFFFFF',
    'description': 'Alternative color of title text',
    'category': EMAIL
  },
  {
    'name': 'EMAIL_LINK_COLOR',
    'type': 'color',
    'default': '#0D84EB',
    'description': 'Color of links',
    'category': EMAIL
  }
];
