import { fork } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga';
import isPickupTimeChanged from './actionPatterns/isPickupTimeChanged';
import handleChangePickupTime from './handleChangePickupTime';


/**
 * Handling changing pickup time on BTP
 */
export default function* watchChangePickupTime() {
  yield fork(takeLatest, isPickupTimeChanged, handleChangePickupTime);
}
