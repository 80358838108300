import React, { Component } from 'react';
import InlineLabel from 'app/components/base/InlineLabel';
import getIcon from 'app/utils/icons';
import CN from 'classnames';

import style from './style.css';

class Item extends Component {
  handleMouseDown = () => {
    this.props.onClick(this.props.option);
  }

  render() {
    const {
      option,
      active,
      template,
      focused,
      clear
    } = this.props;
    const modifiers = {
      [style.active]: !clear && active,
      [style.activeClear]: clear && active,
      [style.focused]: focused
    };
    return (
      <InlineLabel
        padding
        size="noshrink"
        icon={getIcon(option)}
        onMouseDown={this.handleMouseDown}
        className={CN(style.item, modifiers)}
      >
        {template(option)}
      </InlineLabel>
    );
  }
}

// TODO: move to Flow types
// Item.propTypes = {
//   onClick: PropTypes.func.isRequired,
//   active: PropTypes.bool.isRequired,
//   template: PropTypes.func,
//   focused: PropTypes.bool.isRequired,
//   clear: PropTypes.bool,
//   option: PropTypes.object.isRequired
// };

Item.defaultProps = {
  template: option => option.display
};

export default Item;
