import React from 'react';
import { eventToKey } from 'app/utils/listeners';
import { Layout } from 'app/components/base/Layout';
import Icon from 'app/components/base/Icon';
import LoadingSpinner from 'app/components/base/LoadingSpinner';
import classnames from 'classnames';
import { FormattedWithChildren } from 'translations'
import LabeledFrame from 'app/components/base/LabeledFrame';
import { isNull, getNull } from 'app/utils/types';
import style from './style.css';
import _ from 'lodash';


/**
 * An input wrapper that adds icon, label and clear button
 * to a regular `FormInput` component. Supports only `Field`'s component
 * interface (any component related props should be in `input` prop object)
 */
export class StandardInput extends React.Component {
  componentDidMount() {
    if (this.props.preselect && this.input) {
      this.input.select();
    }
  }

  refInput = (node) => {
    this.input = node;
    this.props.refInput(node);
  }

  handleChange = (event) => {
    if (this.props.regex && !this.props.regex.test(event.target.value)) {
      this.props.input.onChange(this.props.input.value);
    } else {
      this.props.input.onChange(event.target.value);
    }
  }

  handleClear = (event) => {
    event.stopPropagation();
    this.clearInput();
  }

  handleKeyDown = (event) => {
    if (this.props.autoClear && eventToKey(event) === 'Backspace') {
      this.clearInput();
      event.stopPropagation();
      event.preventDefault();
    } else if (this.props.input.onKeyDown) {
      this.props.input.onKeyDown(event);
    }
  }

  clearInput() {
    if (this.props.input.onChange) {
      this.props.input.onChange(getNull(this.props.input.value));
      this.input.focus();
    }
  }

  isValueEmpty() {
    return isNull(this.props.input.value, this.props.type);
  }

  focus() {
    this.input.focus();
  }

  renderAction() {
    const { loading, cleanable, disabled, validated } = this.props;

    if (disabled) return null;

    if (loading) {
      return (
        <Layout align="center center" direction="column" className={style.action}>
          <LoadingSpinner inline size="small" />
        </Layout>
      );
    }

    if (validated) {
      return (
        <Layout className={classnames(style.action, style.validated)} align="center center">
          <Icon family="mozio" small>sb-check</Icon>
        </Layout>
      );
    }

    if (cleanable) {
      const { circledCleanButton } = this.props;
      const iconName = circledCleanButton ? 'add_circle' : 'close';
      const modifiers = { [style.circled]: circledCleanButton };
      return (
        <Layout
          direction="column"
          className={style.action}
          onClick={this.handleClear}
          align="center center"
        >
          <Icon small interactive className={classnames(style.icon, modifiers)}>
            {iconName}
          </Icon>
        </Layout>
      );
    }

    return null;
  }

  render() {
    const {
      className,
      cleanable,
      prefix,
      loading,
      input,
      meta,
      disabled,
      placeholder,
      uppercase,
      autoFocus,
      ellipsis,
      readOnly,
      name,
      type,
      rectangled,
      borderless,
      material,
      ...props
    } = this.props;

    const { active } = meta;

    const modifiers = {
      [style.material]: material,
      [style.rectangled]: rectangled,
      [style.empty]: this.isValueEmpty(),
      [style.actionable]: (cleanable || loading) && !disabled,
      [style.uppercase]: uppercase,
      [style.ellipsis]: ellipsis,
      [style.borderless]: borderless,
      [style.mobile]: this.props.mobile
    };

    return (
      <LabeledFrame
        {...props}
        fill
        className={classnames(style.container, className, modifiers)}
        primary={active}
      >
        {prefix && <span className={style.prefix}>{prefix}</span>}
        <FormattedWithChildren message={placeholder}>
          {placeholderStr => (
            <input
              name={name}
              {...input}
              type={type}
              readOnly={readOnly || input.readOnly}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
              ref={this.refInput}
              disabled={disabled}
              placeholder={placeholderStr}
              autoFocus={autoFocus}
              className={classnames(style.input, input.className)}
              autoComplete={props.autoComplete}
            />
          )}
        </FormattedWithChildren>
        {this.renderAction()}
      </LabeledFrame>
    );
  }
}

// TODO: move to Flow types
// StandardInput.propTypes = {
//   refInput: PropTypes.func,
//   cleanable: PropTypes.bool, // Show clean button on the right side
//   circledCleanButton: PropTypes.bool, // Show circled clean button (only if cleanable is set)
//   mobile: PropTypes.bool, // Mobile format of the input (wide and well padded)
//   icon: PropTypes.any, // Icon on the left side of the input
//   rectangled: PropTypes.bool,
//   borderless: PropTypes.bool,
//   material: PropTypes.bool,
//   placeholder: PropTypes.string,
//   type: PropTypes.string,
//   prefix: PropTypes.string,
//   autoClear: PropTypes.bool,
//   className: PropTypes.string,
//   name: PropTypes.string,
//   input: PropTypes.object,
//   meta: PropTypes.object,
//   readOnly: PropTypes.bool,
//   uppercase: PropTypes.bool,
//   regex: PropTypes.any,
//   ellipsis: PropTypes.bool,
//   validated: PropTypes.bool,
//   loading: PropTypes.bool,
//   disabled: PropTypes.bool,
//   children: PropTypes.node,
//   autoFocus: PropTypes.bool,
//   preselect: PropTypes.bool
// };

StandardInput.defaultProps = {
  refInput: _.noop,
  type: 'text'
};

export default StandardInput;
