import memoize from 'mz-utils/memoize'
import BrowserMessagesManager from 'mz-intl/core/BrowserMessagesManager';
import { formatMessage as mzIntlFormatMessage } from 'mz-intl/core/formatMessage';
import queryStringToObject from 'mz-utils/queryStringToObject'

/**
 * Return instatnce of BrowserMessageManager that is use in app
 */
const getOrCreateTranslationsManager = memoize((params) => {
  const query = queryStringToObject(window.location.search)
  const additionalParams = query._translate == '1' ? { showMessageIds: true } : {}
  return new BrowserMessagesManager({ ...params, ...additionalParams });
});

export const getTranslationsManager = getOrCreateTranslationsManager;


/**
 * Wrapper over formatMessage from mz-intl
 */
export const formatMessage = (message, values) => {
  const manager = getOrCreateTranslationsManager()
  const translatorContext = { mzIntl: manager };
  return mzIntlFormatMessage(message, values, translatorContext);
};

export default getOrCreateTranslationsManager;
