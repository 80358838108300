import React from 'react';
import { FormattedMessage } from 'mz-intl';
import { Layout } from 'app/components/base/Layout';
import getTimeString from 'mz-utils/getTimeString';
import moment from 'moment';
import messages from '../../messages.intl';

import style from './style.css';

const messagesMap = {
  platformFee: messages.PLATFORM_FEE,
  ridePrice: messages.RIDE_PRICE,
  pricePerVehicle: messages.PRICE_PER_VEHICLE
};

/**
 * Price Label component
 */
const PriceLabel = ({ type, hourlyRideDuration }) => {
  if (hourlyRideDuration) {
    const hoursMessage = getTimeString({ m: moment(), hourDiff: hourlyRideDuration });
    return (
      <FormattedMessage message={messagesMap.ridePrice}>
        {msg => <span>{`${msg} - ${hoursMessage}`}</span>}
      </FormattedMessage>
    );
  }

  const message = messagesMap[type] || messages.ridePrice;
  return (
    <FormattedMessage message={message} />
  );
};

/**
 * Presentational component part of the BTP trip summary that shows the
 * ride price of a trip.
 */
const PriceItem = ({
  price,
  type,
  hourlyRideDuration
}) => (
  <Layout align="space-between center" className={style.container} nowrap >
    <span className={style.label}>
      <PriceLabel
        type={type}
        hourlyRideDuration={hourlyRideDuration}
      />
    </span>
    <span>
      {price}
    </span>
  </Layout>
);

// TODO: move to Flow types
// PriceItem.propTypes = {
//   price: PropTypes.Node,
//   type: PropTypes.oneOf([
//     'ridePrice',
//     'pricePerVehicle'
//   ]).isRequired,
//   hourlyRideDuration: PropTypes.number
// };

PriceItem.defaultProps = {
  type: 'ridePrice'
};

export default PriceItem;
