import React from 'react';
import { FormattedMessage } from 'translations';
import { Layout } from 'app/components/base/Layout';
import Icon from 'app/components/base/Icon';

import style from './style.css';


const messages = {
  waitingPrice: { id: 'BOOK_TRIP.EXTRA_WAITING_PRICE' }
};

/**
 * Presentational component part of the BTP trip summary that shows the
 * extra waiting hour price
 */
const ExtraWaitingPrice = ({ waitTime }) => {
  const iconName = waitTime.return && waitTime.direct ? 'round' : 'one-way';
  return !!waitTime.waitingPrice && (
    <Layout align="space-between start" className={style.container} nowrap>
      <span>
        <FormattedMessage {...messages.waitingPrice} />
      </span>
      <span>
        <Icon family="mozio" name={iconName} className={style.icon} />
        {waitTime.waitingPrice}
      </span>
    </Layout>
  );
};

// TODO: move to Flow types
// ExtraWaitingPrice.propTypes = {
//   waitTime: PropTypes.object.isRequired
// };


export default ExtraWaitingPrice;
