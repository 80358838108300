import React from 'react';

import style from './style.css';


const TransferPoint = () => (
  <div className={style.transferPoint} />
);

export default TransferPoint;
