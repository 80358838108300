import '@mozioinc/ui/dist/index.css'
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './logging';
import 'style/theme.css';
import 'style/vendor/normalize.css';
import 'style/modules/typography.css';
import 'style/modules/defaults.css';

import { setConfig } from 'mz-sdk';
import { TRACK_INIT_PAGE_LOAD, CONFIG_OVERRIDES_HOOK } from 'app/constants';
import { startMeasuring } from 'app/utils/stats';
import { loadPlugin, hook } from 'app/utils/hook';
import { loadWhitelabel } from 'app/utils/whitelabel';
import { getDomainConfig } from 'app/utils/browser';
import { pushLoggly } from 'app/utils/analytics';
import { setLogger } from 'mz-utils/logError'
import { initTrackers } from './integrations';
import config from 'config';
import renderApp from './render';
import { setWhiteLabel, setTranslation } from '@mozioinc/transfer-widgets';

if (module.hot) {
  module.hot.accept();
}

// Show app version for everyone who can open a console
console.log(`App version: ${process.env.APP_VERSION}`) // eslint-disable-line
window.GIT_COMMIT_SHA = process.env.GIT_COMMIT_SHA

// Load icons smoothly
require('webfontloader').load({
  custom: {
    families: ['Material Icons:400']
  }
});

// Set SDK configuration
setConfig(Object.assign({}, config, {
  PARTNER_REF: getDomainConfig().ref,
  AMENITIES_FILTER: amenity => (
    !config.ONLY_MEET_N_GREET_AMENITY ||
    amenity.id === 'meet_and_greet'
  ),
  DEFAULT_REQUEST_PARAMS: { api_key: config.MOZIO_API_KEY },
  STRIPE_PUBLISHABLE_KEY: config.STRIPE_PUBLISHABLE_KEY.USD,
  STRIPE_ORIGIN: 'USD',
}));

// Set the SDK error logger to Loggly
setLogger((error, contexts) => {
  const safeStringify = (val) => {
    try {
      return JSON.stringify(val)
    } catch (e) {
      return `Failed to stringify: ${e?.message}`
    }
  }
  pushLoggly({
    error: error?.asLoggableObject ? error.asLoggableObject() : error,
    contexts: safeStringify(contexts)
  })
})

// Start page load time measuring
const initStartTime = (
  typeof window !== 'undefined' && window.INIT_PAGE_LOAD_START
) || new Date();
startMeasuring(TRACK_INIT_PAGE_LOAD, true, initStartTime);

// Load site plugins if ref param provided
// right after whitelabel loaded (if required)
const tasks = [];
tasks.push(loadWhitelabel().then(() => {
  setWhiteLabel()
  setTranslation()
  const domainConfig = getDomainConfig();
  return domainConfig.ref
    ? loadPlugin(domainConfig.ref)
    : Promise.resolve();
}));

// Run the app when all prepare tasks will be finished
Promise.all(tasks).then(() => {
  initTrackers();

  Object.assign(config, hook(CONFIG_OVERRIDES_HOOK, config));
  setConfig(config);

  // NOTE: For temporary test only
  if (config.HERTZ_APP || config.NAME === "wltest") {
    return loadPlugin('hertz-app').then(renderApp)
  } else {
    return renderApp()
  }
});
