const ANALYTICS = 'analytics';

export default [
  {
    'name': 'FULLSTORY_PROJECT_ID',
    'type': 'string',
    'default': 'o-1J5N60-na1',
    'description': 'Fullstory ID (empty to disable)',
    'category': ANALYTICS
  },
  {
    'name': 'GOOGLE_ANALYTICS_KEY_PARTNER',
    'type': 'string',
    'default': '',
    'description': 'Google Analytics project partner key. Fill empty to disable',
    'placeholder': 'e.g. UA-12345678',
    'category': ANALYTICS
  },
  {
    'name': 'GOOGLE_ANALYTICS_ADDITIONAL_KEYS',
    'type': 'pairs',
    'default': [],
    'description': 'More GA keys to be tracked (key = name, value = GA key)',
    'placeholder': 'e.g. UA-12345678',
    'category': ANALYTICS
  },
  {
    'name': 'GOOGLE_ANALYTICS_LINKER_DOMAINS',
    'type': 'pairs',
    'default': [],
    'description': 'Autolink list of domains (regexp) for GA',
    'category': ANALYTICS
  },
  {
    'name': 'TAG_MANAGER_KEY',
    'type': 'string',
    'default': 'GTM-TZGXRC',
    'description': 'Google Tag Manager(GTM) production key. Fill empty to disable GTM',
    'placeholder': 'e.g. GTM-ABCDEF',
    'category': ANALYTICS
  },
  {
    'name': 'TAG_MANAGER_URL_PREFIX',
    'type': 'string',
    'default': '',
    'description': 'Prefix for all virtualUrl pushed to GTM',
    'category': ANALYTICS
  },
  {
    'name': 'TAG_MANAGER_PATHNAME_MAP',
    'type': 'pairs',
    'default': [],
    'description': 'Map prefixed pathname for GTM, (e.g /search => /BF/transfers/results/)',
    'category': ANALYTICS
  }
];
