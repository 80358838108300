// @flow
import React from 'react';
import RoundedHeader from 'app/components/base/RoundedHeader';
import { Title, Header, TitleContent } from './elements';

type Props = {
  icon: Object | Function,
  title: React.Node,
  subtitle: React.Node
};

const SectionHeader = ({ icon, title, subtitle }: Props) => (
  <Title>
    <Header $as={RoundedHeader} icon={icon}>
      {!!title && title}
      {!!subtitle && (
        <TitleContent>
          {subtitle}
        </TitleContent>
      )}
    </Header>
  </Title>
);

export default SectionHeader;
