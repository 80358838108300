import React from 'react';
import { Layout, Flex } from 'app/components/base/Layout';
import classnames from 'classnames';
import { getDateVariants, isDateSameDay, isDateSameMonth, getDateFromDateValue } from './utils';

import style from './style.css';


/**
 * Day component of a calendar that handles click on it. It
 * calls `onChange` with provided date (only if the date is not
 * in the past and not empty).
 */
export class DateItem extends React.Component {
  handleClick = () => {
    const { onChange, date, past, empty } = this.props;
    if (onChange && !past && !empty) {
      onChange(date);
    }
  }

  render() {
    const {
      empty,
      past,
      today,
      selected,
      dayNum
    } = this.props;

    if (empty) {
      return (
        <div className={classnames(style.date__day)} />
      );
    }
    return (
      <div
        onClick={this.handleClick}
        className={classnames(
          style.date__day,
          { [style['date__day--past']]: past,
            [style['date__day--today']]: today,
            [style['date__day--selected']]: selected }
      )}>
        {dayNum}
      </div>
    );
  }
}

// TODO: move to Flow types
// DateItem.propTypes = {
//   onChange: PropTypes.func,
//   dayNum: PropTypes.number,
//   past: PropTypes.bool,
//   today: PropTypes.bool,
//   empty: PropTypes.bool,
//   selected: PropTypes.bool,
//   date: PropTypes.object
// };

/**
 * Component for showing one month. It is optimised to update only when selected
 * value changed related to this month. So, rerendered only a month that contains
 * new selected date or previous selected date.
 */
// eslint-disable-next-line
export class MonthRow extends React.Component {
  shouldComponentUpdate(nextProps) {
    const someMonthDate = nextProps.month.days[1][0].date;
    return (
      isDateSameMonth(nextProps.value, someMonthDate) ||
      isDateSameMonth(this.props.value, someMonthDate)
    );
  }

  render() {
    const { month, value, onChange } = this.props;
    return (
      <Layout size="noshrink" nowrap direction="column" className={style.date__row}>
        <Flex size="noshrink" className={style.date__month}>{month.name}</Flex>
        <Layout nowrap direction="row" align="space-between center" size="noshrink">
          {month.dows.map((dow, j) => (
            <div key={j} className={style.date__dow}>{dow.name}</div>
          ))}
        </Layout>
        {month.days.map((row, j) => (
          <Layout key={j} nowrap direction="row" align="space-between center" size="noshrink">
            {row.map((day, k) => (
              <DateItem
                key={k}
                selected={isDateSameDay(value, day.date)}
                onChange={onChange}
                {...day}
              />
            ))}
          </Layout>
        ))}
      </Layout>
    );
  }
}

// TODO: move to Flow types
// MonthRow.propTypes = {
//   month: PropTypes.object,
//   value: PropTypes.any,
//   onChange: PropTypes.func
// };

/**
 * DatePicker componentn that shows all months as a list.
 * Call given `onChange` with Date object of selected date when
 * user click on some date which is not in the past.
 * @return {Component}
 */
// eslint-disable-next-line
export class DatePicker extends React.Component {
  constructor(props, context) {
    super(props, context);
    const { value, minDate, maxDate } = props;
    this.state = { variants: getDateVariants(value, minDate, maxDate) };
  }

  handleChangeDate = (selectedDate) => {
    const { onChange, value } = this.props;
    const newDate = getDateFromDateValue(value, selectedDate);
    if (onChange) {
      onChange(newDate);
    }
  }

  render() {
    const {
      className,
      value,
      onChange, // eslint-disable-line
      ...props
    } = this.props;
    const { variants } = this.state;

    return (
      <Layout
        fill
        nowrap
        size="noshrink"
        direction="column"
        className={classnames(className, style.date)}
        {...props}>
        {variants.map((month, i) => (
          <MonthRow
            key={i}
            month={month}
            value={value}
            onChange={this.handleChangeDate}
          />
        ))}
      </Layout>
    );
  }
}

// TODO: move to Flow types
// DatePicker.propTypes = {
//   className: PropTypes.string,
//   onChange: PropTypes.func,
//   value: PropTypes.any
// };

export default DatePicker;
