import { select, put, fork, call } from 'redux-saga/effects';
import { change } from 'redux-form';
import { updatePrice } from 'app/actions/book-trip';
import {
  BOOK_TRIP_FORM,
  GRATUITY_STATUS
} from 'app/constants';
import {
  getGratuityFieldValue,
  getGratuityStatus
} from 'app/sagas/selectors';
import { reportGratuityAdded } from 'app/sagas/logging/google';
import updateUpgrades from './updateUpgrades';
import waitForPriceUpdateFinished from '../watchUpdatePrice/waitForPriceUpdateFinished';


export const UPDATE_GRATUITY_ACTION_ID = 'UPDATE_GRATUITY';

/**
 * When the gratuity form field changes, we get price with
 * updated components and update the upgrades list.
 */
export default function* setGratuity() {
  const gratuityStatus = yield select(getGratuityStatus);

  // Reset the showHint property and set updating property
  yield put(change(BOOK_TRIP_FORM, GRATUITY_STATUS, {
    ...gratuityStatus,
    updating: true,
    showHint: false
  }));

  let newGratuityStatus;

  try {
    const gratuity = yield select(getGratuityFieldValue);
    const priceParams = { gratuity };

    yield put(updatePrice({ priceParams, sourceActionId: UPDATE_GRATUITY_ACTION_ID }));

    // here we wait for update fail or success. Fail throws error
    yield call(waitForPriceUpdateFinished, UPDATE_GRATUITY_ACTION_ID);

    newGratuityStatus = {
      ...gratuityStatus,
      showHint: false,
      savedValue: gratuity,
      updatedAt: Date.now()
    };
    yield call(reportGratuityAdded);
  } catch (e) {
    newGratuityStatus = {
      ...gratuityStatus,
      showHint: true
    };
    // TODO: Set the field value back to where it was before. The line below
    // sets it back initially, but then by clicking anywhere in the screen, the
    // value is set back to the value that caused the error
    // yield put(change(BOOK_TRIP_FORM, GRATUITY_FIELD_NAME, gratuityStatus.savedValue));
  } finally {
    yield put(change(BOOK_TRIP_FORM, GRATUITY_STATUS, {
      ...newGratuityStatus,
      updating: false
    }));
    yield fork(updateUpgrades);
  }
}
