import { fork } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga';
import getTravelAgentProfile from './getTravelAgentProfile';
import watchBookingsReport from './watchBookingsReport';
import submitTAProfile from './submitTAProfile';
import watchCustomerProfile from './watchCustomerProfile';
import watchPaymentAccounts from './watchPaymentAccounts';
import watchUpdateCompanyInfo from './watchUpdateCompanyInfo';
import watchSalesBalance from './watchSalesBalance';
import watchWithdrawalsHistory from './watchWithdrawalsHistory';
import {
  GET_TA_ACCOUNT,
  GET_REPORT,
  SUBMIT_TA_PROFILE,
  UPDATE_COMPANY_INFO,
} from 'app/constants';


export default function* watchTravelAgentTool() {
  yield fork(watchCustomerProfile);
  yield fork(watchPaymentAccounts);
  yield fork(watchSalesBalance);
  yield fork(watchWithdrawalsHistory);
  yield fork(takeLatest, GET_TA_ACCOUNT, getTravelAgentProfile);
  yield fork(takeLatest, GET_REPORT, watchBookingsReport);
  yield fork(takeLatest, SUBMIT_TA_PROFILE, submitTAProfile);
  yield fork(takeLatest, UPDATE_COMPANY_INFO, watchUpdateCompanyInfo);
}
