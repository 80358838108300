import { call, put, select } from 'redux-saga/effects';
import { change } from 'redux-form';
import { trips } from 'mz-sdk';
import omitEmptyFields from "mz-utils/omitEmptyFields";
import { getTripPrice, getPriceComponents, isForceSecurePayment } from 'app/sagas/selectors';
import { hook } from 'app/utils/hook';
import {
  CC_ADD_NEW_OPENED,
  SELECTED_CREDIT_CARD_ID,
  BOOK_TRIP_FORM,
  PRICE_FIELD_NAME,
  UPDATE_PRICE_SUCCESS,
  UPDATE_PRICE_FAIL,
  CUSTOM_GET_PRICE_SAGA_HOOK
} from 'app/constants';


export function* defaultGetPrice({ priceParams, sourceActionId }, formName = BOOK_TRIP_FORM) {
  const currentPrice = yield select(getTripPrice, formName);
  yield put(change(formName, PRICE_FIELD_NAME, {
    ...currentPrice,
    updating: true
  }));

  try {
    const priceComps = yield select(getPriceComponents, formName);
    const modifiers = omitEmptyFields({
      ...priceComps,
      ...priceParams
    });

    const price = yield call(trips.getPrice, modifiers);
    yield put(change(formName, PRICE_FIELD_NAME, {
      ...price,
      updating: false
    }));

    yield put({ type: UPDATE_PRICE_SUCCESS, payload: { sourceActionId } });
  } catch (err) {
    yield put({ type: UPDATE_PRICE_FAIL, payload: { sourceActionId }, error: err });
    yield put(change(formName, PRICE_FIELD_NAME, {
      ...currentPrice,
      updating: false
    }));

    // Bubble up all exceptions if price update was called not by dispatching action
    // but by direct call of 'getPrice'
    if (!sourceActionId) throw err;
  }
}

/**
 * Update BTP form values based on current price. Right now it handle
 * 3ds case when it is forced and logged in user can't use any stored card
 */
export function* updateFormValues(formName = BOOK_TRIP_FORM) {
  const forceThreeDS = yield select(isForceSecurePayment);

  if (forceThreeDS) {
    yield put(change(formName, CC_ADD_NEW_OPENED, true));
    yield put(change(formName, SELECTED_CREDIT_CARD_ID, null));
  } else {
    yield put(change(formName, CC_ADD_NEW_OPENED, false));
  }
}

/**
 * Get price components from the state and update the price using the
 * SDK getPrice method. Exceptions are bubbled up and should be handled
 * in the caller.
 */
export default function* getPrice(action = {}, formName = BOOK_TRIP_FORM) {
  // Try to use custom price getter saga if defined otherwise
  // use default proce getter
  const priceGetter = hook(CUSTOM_GET_PRICE_SAGA_HOOK) || defaultGetPrice;
  yield call(priceGetter, action, formName);
  yield call(updateFormValues);
}
