import _ from 'lodash';
import { put } from 'redux-saga/effects';
import { change } from 'redux-form';
import { countryCodeToRawSuggestion } from 'app/utils/types';

export default function* initPromoteAppForm(reservation) {
  const phone_number = _.get(reservation, 'trip.owner.phoneNumber', null);
  const country_code_name = _.get(reservation, 'trip.owner.countryCodeName', null);
  yield put(change('promoteApp', 'phone_number', phone_number));
  yield put(change(
    'promoteApp',
    'country_code_name',
    countryCodeToRawSuggestion(country_code_name)
  ));
}
