import { call, fork } from 'redux-saga/effects';
import { places } from 'mz-sdk';
import { trackGeocodeError } from 'app/sagas/logging/loggly';


/**
 * By given place information from deeplink try to get exact place
 * object from the backend. Returned object is in place suggestion format.
 */
export default function* findPlaceByName(query) {
  try {
    return yield call(places.findPlace, query);
  } catch (err) {
    yield fork(trackGeocodeError, `Could not geocode address "${query}"`);
    return { display: query, value: query };
  }
}
