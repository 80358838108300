import React, { Component } from 'react';
import { Layout } from 'app/components/base/Layout';
import { FormattedMessage } from 'translations';
import classnames from 'classnames';
import _ from 'lodash';

import style from './style.css';


class Switch extends Component {
  onClick = (event) => {
    event.stopPropagation();
  }

  handleChange = () => {
    const { yes, no, input } = this.props;
    input.onChange(input.value === yes ? no : yes);
  }

  id = _.uniqueId('on-off');

  render() {
    const {
      input,
      yes,
      verbose,
      className,
      disabled
    } = this.props;

    const labelModifiers = {
      [style.on]: input.value === yes,
      [style.disabled]: disabled
    };

    return (
      <Layout
        fill
        className={classnames(style.container, className)}
        onClick={this.onClick}
        align="center center"
      >
        <input
          type="checkbox"
          id={this.id}
          name={this.id}
          className={style.input}
          onClick={this.handleChange}
          disabled={disabled}
        />
        <label
          htmlFor={this.id}
          className={classnames(style.switcher, labelModifiers)}
        />
        {verbose && (
          <div className={style.verbose}>
            {
              (input.value === yes)
              ? <FormattedMessage id="YES" />
              : <FormattedMessage id="NO" />
            }
          </div>
        )}
      </Layout>
    );
  }
}

// TODO: move to Flow types
// Switch.propTypes = {
//   input: PropTypes.object.isRequired,
//   yes: PropTypes.any.isRequired,
//   no: PropTypes.any.isRequired,
//   className: PropTypes.string,
//   disabled: PropTypes.bool,
//   verbose: PropTypes.bool
// };

Switch.defaultProps = {
  yes: true,
  no: false
};

export default Switch;
