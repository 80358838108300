import _ from 'lodash';
import config from 'config';


export const addStyleString = (str) => {
  if (typeof document === 'undefined') {
    return;
  }

  const customStyleId = 'custom-styles';
  const customStyleElem = document.getElementById(customStyleId);

  if (!customStyleElem) {
    const style = document.createElement('style');
    style.id = customStyleId;
    style.type = 'text/css';
    style.innerHTML = str;
    document.head.appendChild(style);
  } else {
    customStyleElem.innerHTML = str;
  }
};

export const injectCustomCSS = () => {
  if (!_.isEmpty(config.CUSTOM_APP_CSS)) {
    addStyleString(config.CUSTOM_APP_CSS);
  }
};
