import { user } from 'mz-sdk';
import { call } from 'redux-saga/effects';
import { getHistory } from 'app/history';
import config from 'config';
import { showErrorNotification, showInfoNotification } from 'app/sagas/utils';


export default function* resetPassword(action) {
  try {
    yield call(user.resetPassword, action.payload);
    yield call(showInfoNotification, {
      messageId: 'USER.FORGOT_PASSWORD_SUCCESS_MESSAGE',
      titleId: 'SUCCESS'
    });
    const history = yield call(getHistory);
    const redirect = config.LOGIN_ROUTE_PATH;
    yield call(history.push, redirect);
  } catch (error) {
    yield call(showErrorNotification, { error });
  }
}
