import React from 'react';
import OptionPicker from '../OptionPicker';
import { getYearOptions, isSameOption, getOptionDisplay, getOptionValue } from 'app/utils/types';

const YearSelect = ({
  className,
  maxYearCount,
  yearFrom,
  ...props
}) => {
  const yearOptions = getYearOptions(maxYearCount, yearFrom);
  return (
    <OptionPicker
      {...props}
      className={className}
      options={yearOptions}
      comparator={isSameOption}
      transform={getOptionValue}
      template={getOptionDisplay}
      noarrow
    />
  );
};

// TODO: move to Flow types
// YearSelect.propTypes = {
//   className: PropTypes.string,
//   maxYearCount: PropTypes.number.isRequired,
//   yearFrom: PropTypes.number
// };

export default YearSelect;
