import React, { Component } from 'react';
import noop from 'lodash/noop';
import { FormattedMessage } from 'translations';
import { Layout } from 'app/components/base/Layout';
import CompactInput from 'app/components/base/CompactInput';
import Icon from 'app/components/base/Icon';
import InlineHint from 'app/components/base/InlineHint';
import Button from 'app/components/base/Button';
import LoadingSpinner from 'app/components/base/LoadingSpinner';

import style from './style.css';


const messages = {
  saveButton: { id: 'BOOK_TRIP.BOOKING_FEE_SAVE_LABEL' },
  hintMessage: { id: 'BOOK_TRIP.DEFAULT_PRICE_UPDATE_ERROR' }
};

/**
 * Simple component for editing booking fee
 */
export class BookingFeeInput extends Component {
  state = {
    editing: false
  }

  onSave = () => {
    const { onSave = noop } = this.props;
    onSave();
    this.setState({ editing: false });
  }

  onClickEditButton = () => {
    this.setState({ editing: true });
  }

  get loadingSpinner() {
    return (
      <Layout className={style['spinner-container']}>
        <LoadingSpinner size="small" nomargin className={style.spinner} />
      </Layout>
    );
  }

  get input() {
    return (
      <CompactInput
        {...this.props}
        key="bookingFee"
        type="text"
        compact stretch
        underlined
        autoFocus
        cleanable
        nolabel
        preselect
        prefix={this.props.valuePrefix}
        placeholder={this.props.defaultBookingFee}
        className={style.whiteBackground}
      />
    );
  }

  get saveButton() {
    return (
      <Button
        key="add"
        size="noshrink"
        onClick={this.onSave}
        className={style['save-button']}
        clear
      >
        <FormattedMessage {...messages.saveButton} />
      </Button>
    );
  }

  get savedContent() {
    const { bookingFeeOriginal = '...', bookingFee = '...', editable } = this.props;
    const showConvertedAmount = bookingFeeOriginal !== bookingFee;

    return (
      <Layout
        className={style['saved-content']}
        direction="column"
        align="start end"
      >
        <Layout direction="row">
          {editable && (
            <Icon
              className={style['edit-button']}
              small
              onClick={this.onClickEditButton}
            >
              mode_edit
            </Icon>
          )}
          <span>{bookingFeeOriginal}</span>
        </Layout>
        {showConvertedAmount &&
          <span className={style['converted-fee']}>{bookingFee}</span>
        }
      </Layout>
    );
  }

  render() {
    const { editing } = this.state;
    const {
      updating,
      showHint,
      onCloseHint
    } = this.props;
    let content;

    if (updating) {
      content = this.loadingSpinner;
    } else if (editing) {
      content = [
        this.input,
        this.saveButton
      ];
    } else {
      content = this.savedContent;
    }

    return (
      <Layout className={style.input} nowrap>
        {content}
        {showHint &&
          <InlineHint
            message={<FormattedMessage {...messages.hintMessage} />}
            onClose={onCloseHint}
          />
        }
      </Layout>
    );
  }
}

// TODO: move to Flow types
// BookingFeeInput.propTypes = {
//   bookingFee: PropTypes.string.isRequired, // Booking fee in the trip currency
//   defaultBookingFee: PropTypes.string.isRequired,
//   bookingFeeOriginal: PropTypes.string.isRequired, // Booking fee in the user currency
//   editable: PropTypes.bool,
//   updating: PropTypes.bool,
//   showHint: PropTypes.bool,
//   onSave: PropTypes.func,
//   onCloseHint: PropTypes.func,
//   valuePrefix: PropTypes.string
// };

export default BookingFeeInput;
