import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'translations';
import { FormattedMessage as NewFormatteMessage } from 'mz-intl';
import {
  NOTIFICATION_INFO,
  NOTIFICATION_WARNING,
  NOTIFICATION_ERROR,
  NOTIFICATION_SUCCESS
} from 'app/constants';

import { Layout, Flex } from 'app/components/base/Layout';
import Icon from 'app/components/base/Icon';

import style from './style.css';

const icons = {
  [NOTIFICATION_INFO]: 'info',
  [NOTIFICATION_WARNING]: 'warning',
  [NOTIFICATION_ERROR]: 'error',
  [NOTIFICATION_SUCCESS]: 'done'
};

function getDisplayValue(value, id, message) {
  if (message) {
    return <NewFormatteMessage message={message} />;
  } else if (id) {
    return <FormattedMessage id={id} />;
  }
  return value || '';
}

/**
 * Notification bar component that is absolutely positioned on top
 * and shows a notification that can be of info, warning and error nature.
 */
const NotificationBar = ({
  type,
  message,
  messageId,
  messageIntl,
  title,
  titleId,
  onClose
}) => {
  const displayTitle = getDisplayValue(title, titleId, null);
  const displayMessage = getDisplayValue(message, messageId, messageIntl);
  if (!displayMessage) return null;

  return (
    <Layout className={classnames(style.container, style[type])}>
      <Icon>{icons[type]}</Icon>
      <Flex className={style.message}>
        {displayTitle && <span className={style.title}>{displayTitle}: </span>}
        {displayMessage}
      </Flex>
      <Icon className={style.close} onClick={onClose} interactive>
        clear
      </Icon>
    </Layout>
  );
};

// TODO: move to Flow types
// NotificationBar.propTypes = {
//   type: PropTypes.oneOf([
//     NOTIFICATION_INFO,
//     NOTIFICATION_WARNING,
//     NOTIFICATION_ERROR,
//     NOTIFICATION_SUCCESS
//   ]).isRequired,
//   title: PropTypes.string,
//   titleId: PropTypes.string,
//   message: PropTypes.string,
//   messageId: PropTypes.string,
//   onClose: PropTypes.func
// };

export default NotificationBar;
