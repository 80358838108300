import * as React from 'react'
import config from 'config'


export default function GoogleAdsense(props) {
  if (!config.SHOW_ADS) return null

  React.useEffect(() => {
    try {
      if(window) (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      // eslint-disable-next-line
      console.error(e)
    }
  }, [])

  return (
    <ins className={`${props.className} adsbygoogle`}
      style={props.style}
      data-ad-client={props.client}
      data-ad-slot={props.slot}
      data-ad-layout={props.layout}
      data-ad-layout-key={props.layoutKey}
      data-ad-format={props.format}
      data-adtest={process.env.CONFIG_ENV === 'development' ? 'on' : 'off'}
      data-full-width-responsive={props.responsive}></ins>
  );
}

GoogleAdsense.defaultProps = {
  className: '',
  style: {display: 'block'},
  format: 'auto',
  layout: '',
  layoutKey: '',
  responsive: 'true'
};
