import React, { Component } from 'react';
import noop from 'lodash/noop';
import classnames from 'classnames';
import style from './style.css';

class TextArea extends Component {
  componentDidMount() {
    this.focus = () => this.textarea.focus();
  }

  focus = noop;

  refTextarea = (node) => {
    this.textarea = node;
  }

  render() {
    const {
      className,
      noresize,
      input
    } = this.props;

    const modifiers = {
      [style.noresize]: noresize
    };

    return (
      <textarea
        {...input}
        ref={this.refTextarea}
        className={classnames(className, style.textarea, modifiers)}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
      />
    );
  }
}

// TODO: move to Flow types
// TextArea.propTypes = {
//   input: PropTypes.object.isRequired,
//   className: PropTypes.string,
//   noresize: PropTypes.bool
// };

export default TextArea;
