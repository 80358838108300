/**
 * DEPRECATED!
 * Use `mz-intl` instead. This module exists only for backward compitibility
 * with the old `react-intl`.
 */
import React from 'react';
import memoize from 'mz-utils/memoize'
import { hook } from 'app/utils/hook';
import { TRANSLATION_OVERRIDE_HOOK } from 'app/constants';
import { FormattedMessage as NewFormattedMessage } from 'mz-intl';
import * as corporateMessages from './messages/corporate-messages.intl';
import * as currenciesMessages from './messages/currencies-messages.intl';
import * as gbtMessages from './messages/gbt-messages.intl';
import * as vehiclesMessages from './messages/vehicles-messages.intl';
import * as nextMessages from './messages/next-messages.intl';
import * as taMessages from './messages/ta-messages.intl';


const ALL_SOURCES = [
  nextMessages.default,
  vehiclesMessages.default,
  currenciesMessages.default,
  corporateMessages.default,
  gbtMessages.default,
  taMessages.default,
];

const findActualMessage = memoize((id) => {
  const overridesMap = hook(TRANSLATION_OVERRIDE_HOOK) || {};
  const actualId = overridesMap[id] || id;
  const source = ALL_SOURCES.find(x => x[actualId]);
  return (source && source[actualId]) || null;
}, id => id)

export { FormattedWithChildren } from 'mz-intl'

export function FormattedMessage({ id, defaultMessage, ...props }) {
  const message = findActualMessage(id)
  if (!message) {
    const text = defaultMessage || id || null
    return <span dangerouslySetInnerHTML={{ __html: text }} />
  }
  return (
    <NewFormattedMessage
      {...props}
      message={message}
    />
  );
}

export function FormattedHTMLMessage(props) {
  return <FormattedMessage withHTML {...props} />;
}

export function getDefaultMessage(message) {
  const intlMessage = findActualMessage(message.id);
  return intlMessage && intlMessage.defaultMessage || null;
}
