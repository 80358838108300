import { fork, put } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga';
import { toggleThreeDSecure } from 'app/actions/creditcards';
import {
  THREE_D_SECURE_SHOW_IFRAME,
  THREE_D_SECURE_HIDE_IFRAME
} from 'app/constants';


export function* watchShowLightbox(action) {
  yield put(toggleThreeDSecure(action.payload));
}


export function* watchHideLightbox() {
  yield put(toggleThreeDSecure(''));
}

/**
 * Wait for return trip request
 */
export default function* watchThreeDSecure() {
  yield fork(takeLatest, THREE_D_SECURE_SHOW_IFRAME, watchShowLightbox);
  yield fork(takeLatest, THREE_D_SECURE_HIDE_IFRAME, watchHideLightbox);
}
