const getIcon = (option, type) => {
  switch (type) {
    case 'phone_code': {
      return { family: 'flag', name: option.flagCode || option.value || option.code };
    }
    default: {
      if (!option) return null;
      if (option.type) {
        switch (option.type) {
          case 'airport': {
            return { name: 'local_airport', family: 'material' };
          }
          case 'port': {
            return { name: 'port', family: 'mozio' };
          }
          default: {
            return { name: 'place', family: 'material' };
          }
        }
      }
      return null;
    }
  }
};

export default getIcon;
