import * as React from 'react'
import Badge from '../Badge'
import VEHICLE_CLASS from 'mz-sdk/constants/vehicleClass';
import { FormattedMessage } from 'mz-intl';
import messages from '../../messages.intl';

const CLASS_TO_ICON = {
  [VEHICLE_CLASS.BUSINESS]: require('mz-icons/business').default,
  [VEHICLE_CLASS.FIRST]: require('mz-icons/crown').default,
  [VEHICLE_CLASS.ECONOMY]: require('mz-icons/coins').default,
  [VEHICLE_CLASS.STANDARD]: require('mz-icons/reward').default,
  [VEHICLE_CLASS.BUSINESS_VAN]: require('mz-icons/business').default,
  [VEHICLE_CLASS.GREEN_STANDARD]: require('mz-icons/leaf').default,
  [VEHICLE_CLASS.GREEN_PREMIUM]: require('mz-icons/tree').default
};

export default function VehicleClass({ vehicleClass, vehicleClassName }) {
  const IconComponent = CLASS_TO_ICON[vehicleClass];

  const vehicleClassTooltipMessage = vehicleClass &&
      messages[`VEHICLE_CLASS_${vehicleClass}_TOOLTIP`]

  if (vehicleClassTooltipMessage) {
    return (
      <Badge
        leftPart={<IconComponent />}
        rightPart={vehicleClassName}
        color="green"
        tooltipContent={() => (
          <FormattedMessage message={vehicleClassTooltipMessage} />
        )}
      />
    )
  }

  return (
    <Badge
      leftPart={<IconComponent />}
      rightPart={vehicleClassName}
      color="green"
    />
  )
}
