import noop from 'lodash/noop';
import medias from './medias';

const createMatchMedia = () => {
  const MIN_WIDTH_REGEXP = /min-width:\s*(\d+)px/;
  const MAX_WIDTH_REGEXP = /max-width:\s*(\d+)px/;
  const instances = [];

  function MediaQueryList(mediaQuery) {
    const matchMinWidth = mediaQuery.match(MIN_WIDTH_REGEXP);
    const matchMaxWidth = mediaQuery.match(MAX_WIDTH_REGEXP);
    this.minWidth = matchMinWidth && parseInt(matchMinWidth[1], 10);
    this.maxWidth = matchMaxWidth && parseInt(matchMaxWidth[1], 10);
    this.isMatch();
    this.callback = noop;
    instances.push(this);
  }

  MediaQueryList.prototype.addListener = function addListener(callback) {
    this.callback = callback;
  };

  MediaQueryList.prototype.isMatch = function isMatch() {
    const width = window.innerWidth;
    this.matches = (!this.minWidth || this.minWidth < width)
                && (!this.maxWidth || this.maxWidth > width);
    return this.matches;
  };

  window.addEventListener('resize', () => {
    instances.forEach(inst => {
      const prevMatch = inst.matches;
      if (prevMatch !== inst.isMatch()) {
        inst.callback(inst);
      }
    });
  });

  return (mediaQuery) => new MediaQueryList(mediaQuery);
};

if (typeof window !== 'undefined' && !window.matchMedia) {
  window.matchMedia = createMatchMedia();
}

const mediaQueryLists = {};
Object.keys(medias).forEach((query) => {
  mediaQueryLists[medias[query]] = window.matchMedia(query);
});

export default mediaQueryLists;
