import React from 'react';
import { Layout } from 'app/components/base/Layout';
import { FormattedMessage } from 'translations';

import HeaderInfo from './HeaderInfo';
import style from './style.css';

const messages = {
  serviceHours: { id: 'TRIP_DETAILS.SERVICE_HOURS' },
  from: { id: 'TRIP_DETAILS.FROM' },
  date: { id: 'TRIP_DETAILS.DEPARTURE' }
};

const HeaderBar = ({
  serviceHours,
  startLocation,
  date
}) => (
  <Layout nowrap size="noshrink" direction="row" align="start start">
    <HeaderInfo
      icon="date_range"
      caption={<FormattedMessage {...messages.date} />}
      info={date}
      className={style.header_item}
    />
    {!!serviceHours && (
      <HeaderInfo
        icon="access_time"
        caption={<FormattedMessage {...messages.serviceHours} />}
        info={serviceHours}
        className={style.header_item}
      />
    )}
    <HeaderInfo
      icon="pin_drop"
      caption={<FormattedMessage {...messages.from} />}
      info={startLocation}
      className={style.header_item}
    />
  </Layout>
);

// TODO: move to Flow types
// HeaderBar.propTypes = {
//   serviceHours: PropTypes.string,
//   startLocation: PropTypes.string,
//   date: PropTypes.string
// };

export default HeaderBar;
