import { call, put, select } from 'redux-saga/effects';
import { getActiveCurrency } from 'app/sagas/selectors';
import { showErrorNotification, normalizeErrorMessage } from 'app/sagas/utils';
import {
  startUpdateRequest,
  endUpdateRequest
} from 'app/actions/book-trip';
import { trips } from 'mz-sdk';


/**
 * Saga that makes sdk call and fetches updated trip
 * @param {String} searchId      Search ID
 * @param {string} tripId        Trip ID
 * @param {Object} params        Additional research params (optional)
 * @return {Object} Updated trip object or null if not found
 */
export default function* researchTrip(searchId, tripId, ondemand, params) {
  yield put(startUpdateRequest());

  const currency = yield select(getActiveCurrency);

  const researchParams = {
    ...params,
    currency
  };

  let updatedTrip = null;

  try {
    updatedTrip = yield call(trips.research, {
      trip_id: tripId,
      session_id: searchId,
      params: researchParams,
      ondemand
    });

    if (!updatedTrip) throw new Error();
    yield put(endUpdateRequest());
  } catch (error) {
    const message = error.response && normalizeErrorMessage(error.response);
    const notification = message
      ? { error: { message } }
      : { messageId: 'SEARCH_RESULTS.NO_RESULTS_MESSAGE' };
    yield call(showErrorNotification, notification);
    yield put(endUpdateRequest());
  }

  return updatedTrip;
}
