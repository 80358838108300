export default [{
  featureType: 'administrative.land_parcel',
  elementType: 'geometry.stroke',
  stylers: [{
    color: '#d0d9dd'
  }]
}, {
  featureType: 'administrative.locality',
  elementType: 'labels.text.fill',
  stylers: [{
    color: '#949aa0'
  }]
}, {
  featureType: 'administrative.neighborhood',
  elementType: 'geometry.fill',
  stylers: [{
    visibility: 'off'
  }]
}, {
  featureType: 'administrative.neighborhood',
  elementType: 'labels.text.fill',
  stylers: [{
    color: '#596673'
  }]
}, {
  featureType: 'landscape.man_made',
  elementType: 'geometry.fill',
  stylers: [{
    visibility: 'simplified'
  }]
}, {
  featureType: 'landscape.man_made',
  elementType: 'geometry.stroke',
  stylers: [{
    color: '#c5d0d6'
  }, {
    lightness: 5
  }]
}, {
  featureType: 'landscape.natural',
  elementType: 'geometry.fill',
  stylers: [{
    color: '#e2ebef'
  }]
}, {
  featureType: 'poi.attraction',
  elementType: 'geometry.fill',
  stylers: [{
    visibility: 'off'
  }]
}, {
  featureType: 'poi.attraction',
  elementType: 'geometry.stroke',
  stylers: [{
    visibility: 'simplified'
  }]
}, {
  featureType: 'poi.business',
  stylers: [{
    visibility: 'off'
  }]
}, {
  featureType: 'poi.government',
  stylers: [{
    visibility: 'off'
  }]
}, {
  featureType: 'poi.medical',
  stylers: [{
    visibility: 'off'
  }]
}, {
  featureType: 'poi.park',
  elementType: 'geometry.fill',
  stylers: [{
    color: '#d2e7c7'
  }]
}, {
  featureType: 'poi.park',
  elementType: 'labels.text',
  stylers: [{
    visibility: 'off'
  }]
}, {
  featureType: 'poi.place_of_worship',
  stylers: [{
    visibility: 'off'
  }]
}, {
  featureType: 'poi.school',
  stylers: [{
    visibility: 'off'
  }]
}, {
  featureType: 'poi.sports_complex',
  stylers: [{
    visibility: 'off'
  }]
}, {
  featureType: 'road.highway',
  elementType: 'geometry.fill',
  stylers: [{
    color: '#ffffff'
  }]
}, {
  featureType: 'road.highway',
  elementType: 'geometry.stroke',
  stylers: [{
    visibility: 'simplified'
  }]
}, {
  featureType: 'road.highway',
  elementType: 'labels.text.fill',
  stylers: [{
    color: '#404952'
  }]
}, {
  featureType: 'transit.station.airport',
  elementType: 'geometry.fill',
  stylers: [{
    saturation: -100
  }, {
    lightness: 10
  }]
}, {
  featureType: 'water',
  elementType: 'geometry.fill',
  stylers: [{
    color: '#bdd8ec'
  }]
}];
