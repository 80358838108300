import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { connectSubpages } from 'app/hocs/subpageManagable';
import { FormattedMessage } from 'translations';
import { Layout } from 'app/components/base/Layout';
import Button from 'app/components/base/Button';
import ReadOnlyPriceInfo from 'app/components/book-trip/PriceBreakdown/ReadOnlyPriceInfo';
import * as bookTripActions from 'app/actions/book-trip';
import { doSearch } from 'app/actions/search-results';
import classnames from 'classnames';
import { BOOK_TRIP_UPDATED_MODAL_FORM } from 'app/constants';
import { isMobileBrowser } from 'app/utils/browser';

import style from './style.css';


const NotificationIcon = require('-!babel-loader!@mozioinc/svg-react-loader!./assets/trip-details.svg');
const messages = {
  title: { id: 'BOOK_TRIP.TRIP_UPDATED_NOTIFICATION.TITLE' },
  message: { id: 'BOOK_TRIP.TRIP_UPDATED_NOTIFICATION.MESSAGE' },
  newSearch: { id: 'BOOK_TRIP.TRIP_UPDATED_NOTIFICATION.NEW_SEARCH' },
  keepOriginalTime: { id: 'BOOK_TRIP.TRIP_UPDATED_NOTIFICATION.KEEP_ORIGINAL_TIME' },
  updateTrip: { id: 'BOOK_TRIP.TRIP_UPDATED_NOTIFICATION.UPDATE' }
};

class TripUpdatedNotification extends React.Component {

  handleAction = (action, params) => {
    const {
      clearTripError,
      subpages,
      errorPopup
    } = this.props;

    if (errorPopup) {
      subpages.popPage();
    }
    action(params);
    clearTripError();
  }

  handleSearch = () => {
    const {
      onSearch,
      errorProps: { searchParams }
    } = this.props;

    this.handleAction(onSearch, { searchParams });
  };

  handleKeepOriginalTime = () => {
    const {
      onKeepOriginalTime,
      errorProps: { changedFieldName }
    } = this.props;

    const action = () => onKeepOriginalTime(changedFieldName);
    this.handleAction(action);
  };

  handleUpdate = () => {
    const {
      onUpdate,
      errorProps: { newBookTripUrl, timeChangedProps }
    } = this.props;

    const action = () => onUpdate({ newBookTripUrl, timeChangedProps });
    this.handleAction(action);
  };

  render() {
    const {
      errorMessage,
      errorProps: { changedFieldName }
    } = this.props;

    return (
      <Layout
        className={classnames(style.container, style.shrinked)}
        direction="column"
        align="start center"
      >
        <NotificationIcon className={style.icon} />
        <span className={classnames(style.title, style.shrinked)}>
          <FormattedMessage {...messages.title} />
        </span>
        <span className={classnames(style.message, style.shrinked)}>
          {errorMessage || <FormattedMessage {...messages.message} />}
        </span>
        <ReadOnlyPriceInfo
          formName={BOOK_TRIP_UPDATED_MODAL_FORM}
          mobile={isMobileBrowser}
        />
        <Layout
          className={style.buttonsContainer}
          direction={{ 'mobile': 'column', '> mobile': 'row' }}
          align="start center"
        >
          {!changedFieldName && (
            <Button
              className={style.button}
              onClick={this.handleSearch}
              nocaps
              bordered
              rounded
            >
              <FormattedMessage {...messages.newSearch} />
            </Button>
          )}
          {!!changedFieldName && (
            <Button
              className={style.button}
              onClick={this.handleKeepOriginalTime}
              nocaps
              bordered
              rounded
            >
              <FormattedMessage {...messages.keepOriginalTime} />
            </Button>
          )}
          <Button
            className={style.button}
            onClick={this.handleUpdate}
            nocaps
            rounded
          >
            <FormattedMessage {...messages.updateTrip} />
          </Button>
        </Layout>
      </Layout>
    );
  }
}

// TODO: move to Flow types
// TripUpdatedNotification.propTypes = {
//   linkLocation: PropTypes.object,
//   errorCode: PropTypes.any,
//   errorPopup: PropTypes.bool,
//   errorMessage: PropTypes.string,
//   errorProps: PropTypes.object,
//   subpages: PropTypes.object,
//   onSearch: PropTypes.func.isRequired,
//   onKeepOriginalTime: PropTypes.func.isRequired,
//   onUpdate: PropTypes.func.isRequired,
//   clearTripError: PropTypes.func.isRequired
// };

const mapDispatchToProps = {
  onSearch: doSearch,
  onKeepOriginalTime: bookTripActions.keepOriginalTime,
  onUpdate: bookTripActions.updateTrip,
  clearTripError: bookTripActions.clearTripError
};

export default compose(
  connectSubpages(),
  reduxForm({ form: BOOK_TRIP_UPDATED_MODAL_FORM }),
  connect(null, mapDispatchToProps)
)(TripUpdatedNotification);
