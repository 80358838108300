import React from 'react';
import { FormattedMessage } from 'translations';
import { Field } from 'redux-form';
import BookingFeeInput from './BookingFeeInput';
import { Layout } from 'app/components/base/Layout';
import { twoDecimals } from 'app/utils/normalizers';
import builder from './builder';
import { BOOKING_FEE_FIELD_NAME } from 'app/constants';

import style from './style.css';


// Constants
const messages = {
  bookingFee: { id: 'BOOK_TRIP.BOOKING_FEE_LABEL' }
};

/**
 * Input component to allow to add a booking fee in the BTP
 */
export const BookingFee = ({
  saveBookingFee,
  closeBookingFeeHint,
  allowChangeFee,
  ...props
}) => {
  return (
    <Layout direction="column" className={style.container} size="noshrink" nowrap>
      <Layout align="space-between start" nowrap size="100">
        <div className={style.label}>
          <FormattedMessage {...messages.bookingFee} />
        </div>

        <Field
          component={BookingFeeInput}
          name={BOOKING_FEE_FIELD_NAME}
          onSave={saveBookingFee}
          onCloseHint={closeBookingFeeHint}
          editable={allowChangeFee}
          normalize={twoDecimals}
          {...props}
        />
      </Layout>
    </Layout>
  );
};

// TODO: move to Flow types
// BookingFee.propTypes = {
//   allowChangeFee: PropTypes.bool,
//   bookingFee: PropTypes.string,
//   bookingFeeOriginal: PropTypes.string,
//   disabled: PropTypes.bool,
//   updating: PropTypes.bool,
//   showHint: PropTypes.bool,
//   saveBookingFee: PropTypes.func,
//   closeBookingFeeHint: PropTypes.func
// };

export default builder(BookingFee);
