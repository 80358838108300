import {
  LOAD_BOOK_TRIP,
  DO_BOOK_TRIP,
  SET_BOOK_TRIP_INIT_FLAG,
  CHANGE_TRIP_MODE,
  SET_CHANGED_MODE_TRIP,
  BOOK_CHANGED_MODE_TRIP,
  ADD_COUPON,
  CLOSE_COUPON_HINT,
  CLOSE_BOOKING_FEE_HINT,
  GET_CURRENT_TRIP,
  TOGGLE_OPTIONAL_AMENITY,
  UPDATE_PRICE,
  SAVE_BOOKING_FEE,
  CONFIRM_BOOKING,
  CLOSE_GRATUITY_HINT,
  SET_TRIP_ERROR,
  CLEAR_TRIP_ERROR,
  UPDATE_TRIP,
  KEEP_ORIGINAL_PICKUP_TIME,
  START_UPDATE_REQUEST,
  END_UPDATE_REQUEST,
  SHOW_PICKUP_TIME_CHANGED_NOTIFICATION,
  HIDE_PICKUP_TIME_CHANGED_NOTIFICATION,
  SET_CHARGE_IN_USD_MESSAGE,
  ADD_ACCOUNT_ID,
  REMOVE_ACCOUNT_ID,
  BOOKING_EMAIL_TAKEN,
  DESELECT_PROFILE,
  GET_TERMS_AND_CONDITIONS,
  SET_TERMS_AND_CONDITIONS,
  INCREMENT_BOOKING_ATTEMPTS
} from 'app/constants';
import { createAction, createDeferredAction } from '../utils/redux';


export const changeTripMode = createAction(
  CHANGE_TRIP_MODE
);

export const setChangedModeTrip = createAction(
  SET_CHANGED_MODE_TRIP
);

export const bookChangedModeTrip = createAction(
  BOOK_CHANGED_MODE_TRIP
);

export const addCoupon = createAction(
  ADD_COUPON
);

export const closeCouponHint = createAction(
  CLOSE_COUPON_HINT
);

export const closeBookingFeeHint = createAction(
  CLOSE_BOOKING_FEE_HINT
);

export const closeGratuityHint = createAction(
  CLOSE_GRATUITY_HINT
);

export const getCurrentTrip = createAction(
  GET_CURRENT_TRIP
);

export const toggleOptionalAmenity = createDeferredAction(
  TOGGLE_OPTIONAL_AMENITY
);

export const updatePrice = createAction(
  UPDATE_PRICE
);

export const saveBookingFee = createAction(
  SAVE_BOOKING_FEE
);

export const confirmBooking = createDeferredAction(
  CONFIRM_BOOKING
);

export const setTripError = createAction(
  SET_TRIP_ERROR
);

export const clearTripError = createAction(
  CLEAR_TRIP_ERROR
);

export const doBookTrip = createAction(
  DO_BOOK_TRIP
);

export const loadBookTrip = createAction(
  LOAD_BOOK_TRIP
);

export const updateTrip = createAction(
  UPDATE_TRIP
);

export const keepOriginalTime = createAction(
  KEEP_ORIGINAL_PICKUP_TIME
);

export const setBookTripInitFlag = createAction(
  SET_BOOK_TRIP_INIT_FLAG
);

export const startUpdateRequest = createAction(
  START_UPDATE_REQUEST
);

export const endUpdateRequest = createAction(
  END_UPDATE_REQUEST
);

export const showPickupTimeChangedNotification = createAction(
  SHOW_PICKUP_TIME_CHANGED_NOTIFICATION
);

export const hidePickupTimeChangedNotification = createAction(
  HIDE_PICKUP_TIME_CHANGED_NOTIFICATION
);

export const setChargeInUsdMessage = createAction(
  SET_CHARGE_IN_USD_MESSAGE
);

// Allows to book on behalf of another user
export const addAccountId = createAction(
  ADD_ACCOUNT_ID
);

export const removeAccountId = createAction(
  REMOVE_ACCOUNT_ID
);


export const emailTaken = createAction(
  BOOKING_EMAIL_TAKEN
);

export const deselectProfile = createAction(
  DESELECT_PROFILE
);

export const getTermsAndConditions = createDeferredAction(
  GET_TERMS_AND_CONDITIONS
);

export const setTermsAndConditions = createAction(
  SET_TERMS_AND_CONDITIONS
);

export const changeCardBrand = createAction(
  UPDATE_PRICE,
  (cardBrand) => ({ cardBrand })
)

export const incrementBookingAttempts = createAction(
  INCREMENT_BOOKING_ATTEMPTS
)
