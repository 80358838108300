import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import classnames from 'classnames';
import { FormattedMessage } from 'translations'
import { preprocessTripObject, getMainStep } from 'app/utils/trip';
import YourTrip from 'app/components/book-trip/YourTrip/Mobile';
import { Layout } from 'app/components/base/Layout';
import EstimatedTimeNotice from './commons/EstimatedTimeNotice';
import MilesEarnedLabel from '../MilesEarnedLabel'
import config from 'config';
import { getUser } from 'app/sagas/selectors';

import {
  BOOK_TRIP_FORM,
  PRICE_FIELD_NAME,
  DEPARTING_TRIP_FIELD_NAME,
  TRANSPORT_RAIL
} from 'app/constants';

// Import subcomponents
import Container from './Container';
import WaitingTime from '../WaitingTime';
import ProviderInfo from './commons/ProviderInfo';
import UserInfo from './commons/UserInfo';
import Details from './commons/Details';
import PriceItem from './commons/PriceItem';
import UpgradesList from './commons/UpgradesList';
import BookingFee from './commons/BookingFee';
import TotalPrice from './commons/TotalPrice';
import ExtraWaitingPrice from './commons/ExtraWaitingPrice';
import PickupTime from './commons/PickupTime';
import { MilesWrapper } from './elements'

import style from './style.css';
import { USER_TYPE_NORMAL } from '../../../constants/values';

export const mapStateToProps = (state, { formName = BOOK_TRIP_FORM }) => {
  const selector = formValueSelector(formName);
  const user = getUser(state);
  const trip = selector(state, DEPARTING_TRIP_FIELD_NAME);
  const price = selector(state, PRICE_FIELD_NAME);
  const preparedTrip = preprocessTripObject(trip);

  const mainStep = getMainStep(trip);
  const isTrain = mainStep && mainStep.type === TRANSPORT_RAIL;

  let hourlyRideDuration = 0;

  if (preparedTrip) {
    const { heading, owner, details, pricing, duration } = preparedTrip;

    hourlyRideDuration = details.hourlyDetails && details.hourlyDetails.hoursRequested;
    const rewardPoints = price?.rewardPoints || details.rewardPoints

    const newPricing = {
      ...pricing,
      pricePerVehicle: price?.pricePerVehicle,
      platformFee: price?.platformFee,
      ridePrice: price?.priceWithoutPlatformFee || price?.originalPrice,
      finalPrice: price?.finalPrice || pricing.price,
      updating: price?.updating,
      // clear currency symbol. The price string includes currency symbol already
      currencySymbol: null
    };

    return {
      pricing: newPricing,
      rewardPoints,
      heading,
      owner,
      duration,
      details,
      isTrain,
      hourlyRideDuration,
      // TODO: This is temporary until we have the new endpoint and a custom hook to validate it
      showPriceForUser: trip.userType !== undefined ? trip.userType === USER_TYPE_NORMAL || (user && user?.user_type !== USER_TYPE_NORMAL) : true
    };
  }
  return {};
};


/**
 * Price breakdown box component for desktop, tablet and mobile
 */
export const PriceBreakdown = ({
  heading,
  owner,
  details,
  pricing,
  duration,
  mobile,
  rewardPoints,
  horizontal,
  readOnly,
  formName,
  isTrain,
  hourlyRideDuration,
  showTotalPrice = true,
  showPriceForUser
}) => {
  if (!heading) return null;

  const modifiers = {
    [style.mobile]: mobile,
    [style.horizontal]: horizontal,
    [style.train]: isTrain
  };

  const waitTime = details.detailedWaitingTime;
  const childrenAlign = mobile ? 'start stretch' : 'space-between start';
  const description = heading.category.id
    ? <FormattedMessage id={`VEHICLES.ID_${heading.category.id}.NAME`} />
    : heading.type
  const providerProps = {
    names: heading.providerNames,
    make: heading.make,
    model: heading.model,
    description
  };

  return (
    <div>
      <Container
        mobile={mobile}
        horizontal={horizontal}
        imageUrl={heading.vehicleImageUrl}
      >
        <Layout
          nowrap
          align={childrenAlign}
          className={classnames(style.heading, modifiers)}
        >
          <div className={style['heading-container']}>
            {config.SHOW_PROVIDER_NAME_IN_PRICE_BREAKDOWN && (
              <ProviderInfo
                {...providerProps}
                className={style['provider-info']}
              />
            )}
          </div>
          {readOnly && !!owner && <UserInfo {...owner} />}
          <div className={style.subHeader}>
            {!readOnly && config.YOUR_TRIP_IN_PRICE_BREAKDOWN && (
              <div className={style.yourTrip}>
                <YourTrip header={false} locked />
              </div>
            )}
            {!readOnly && config.PICKUP_TIME_IN_PRICE_BREAKDOWN && (
              <div className={style.pickup} >
                <PickupTime formName={formName} />
              </div>
            )}
            <Layout className={style.capacity}>
              {!isTrain &&
                <Details
                  waitTime={details.detailedWaitingTime}
                  numVehicles={details.numVehicles}
                  bagsCount={details.totalBags}
                  bagsPerPerson={details.isMaxBagsPerPerson}
                  passengersCount={details.passengersCount}
                  mobile={mobile}
                />
              }
            </Layout>
          </div>
        </Layout>
        <Layout
          nowrap
          direction="column"
          className={classnames(style.breakdown, modifiers)}
        >
          {!readOnly && config.YOUR_TRIP_IN_PRICE_BREAKDOWN && (
            <div className={style.yourTrip}>
              <YourTrip header={false} locked />
            </div>
          )}
          {!readOnly && config.PICKUP_TIME_IN_PRICE_BREAKDOWN && (
            <div className={style.pickup} >
              <PickupTime formName={formName} />
            </div>
          )}
          {(waitTime.return || waitTime.direct) && (
            <div>
              <div className={style.waitingTime}>
                <WaitingTime waitTime={waitTime} />
              </div>
              <ExtraWaitingPrice waitTime={waitTime} />
            </div>
          )}
          {(!readOnly && heading.numVehicles > 1) &&
            <PriceItem price={pricing.pricePerVehicle} type="pricePerVehicle" />
          }
          {!readOnly && (
            <PriceItem price={pricing.ridePrice} hourlyRideDuration={hourlyRideDuration} />
          )}
          <UpgradesList
            formName={formName}
            readOnly={readOnly}
            mobile={mobile}
          />
          {!readOnly && config.SHOW_YOU_EARNED && (
            <BookingFee readOnly={false} formName={formName} />
          )}
          {!readOnly && !!duration && config.RIDE_DURATION_SHOW && (
            <div
              className={classnames(
                style['breakdown-item'],
                style.waitingTime
            )}>
              <EstimatedTimeNotice duration={duration} />
            </div>
          )}
          {(!readOnly && pricing.platformFee) &&
            <PriceItem price={pricing.platformFee} type="platformFee" />
          }
          {showTotalPrice && showPriceForUser && (
            <TotalPrice
              {...pricing}
              mobile={mobile}
              dark={horizontal}
              omitMeta={readOnly}
            />
          )}
        </Layout>
      </Container>
      {config.LOYALTY_PROGRAM_ENABLED && !!rewardPoints && (
        <MilesWrapper>
          <MilesEarnedLabel miles={rewardPoints} />
        </MilesWrapper>
      )}
    </div>
  );
};

// TODO: move to Flow types
// PriceBreakdown.propTypes = {
//   formName: PropTypes.string,
//   heading: PropTypes.object.isRequired,
//   owner: PropTypes.object,
//   pricing: PropTypes.object.isRequired,
//   details: PropTypes.object.isRequired,
//   mobile: PropTypes.bool,
//   horizontal: PropTypes.bool,
//   readOnly: PropTypes.bool,
//   isTrain: PropTypes.bool,
//   hourlyRideDuration: PropTypes.number
// };

export default connect(mapStateToProps)(PriceBreakdown);
