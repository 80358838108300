import React from 'react';
import { connect } from 'react-redux';
import { BOOK_TRIP_FORM, RETURN_TRIP_BOOKED, RETURN_BOOK_STATE_FIELD_NAME } from 'app/constants';
import { Field, formValueSelector } from 'redux-form';


export const mapStateToProps = (state, { formName = BOOK_TRIP_FORM }) => {
  const selector = formValueSelector(formName);
  const returnBookState = selector(state, RETURN_BOOK_STATE_FIELD_NAME);
  return { returnBookState };
};

export const builder = (ComposedComponent) => {
  const WrapperComponent = (props) => {
    // AB test showed that return trip booking reduce the conversion
    // We will probably try to re-do this component later, but for now
    // just do the hard disable
    if (props.returnBookState !== RETURN_TRIP_BOOKED) {
      return null;
    }

    return (
      <Field
        name={RETURN_BOOK_STATE_FIELD_NAME}
        component={ComposedComponent}
        {...props}
      />
    );
  };

  // TODO: move to Flow types
  // WrapperComponent.propTypes = {
  //   formName: PropTypes.string,
  //   returnBookState: PropTypes.string
  // };

  return connect(mapStateToProps)(WrapperComponent);
};

export default builder;
