import React from 'react';
import { FormattedMessage } from 'translations';
import { styled } from 'mz-ui-kit'
import config from 'config';

const messages = {
  address: { id: 'FOOTER.ADDRESS' },
  unitedStates: { id: 'FOOTER.UNITED_STATES' },
  registerLocation: { id: 'FOOTER.REGISTER_LOCATION' }
};

const Container = styled('div', ({ $mobile }) => ({
  marginTop: '20px !important',
  fontSize: '12px',
  textAlign: $mobile ? 'center' : 'left',
  padding: $mobile ? '0 10px' : '0',
  color: '#727272'
}));

const Title = styled('h1', {
  fontSize: '16px'
});

const LegalAddress = ({ $mobile }) => {
  if (!config.SHOW_LEGAL_ADDRESS_IN_FOOTER) return null;
  return (
    <Container $mobile={$mobile}>
      <Title> Mozio Inc. </Title>
      <div> <FormattedMessage {...messages.address} /> </div>
      <div> San Francisco, CA 94105 </div>
      <div> <FormattedMessage {...messages.unitedStates} /> </div>
      <div> Tel: +1 415 969 7669 </div>
      <div> Email: info@mozio.com </div>
      <p> <FormattedMessage {...messages.registerLocation} /> </p>
    </Container>
  );
};

// TODO: move to Flow types
// LegalAddress.propTypes = {
//   $mobile: PropTypes.bool
// };

export default LegalAddress;
