import React from 'react'
import config from 'config'
import importWithRetry from 'mz-utils/importWithRetry'
import { MAIN_ROUTES_HOOK } from 'app/constants'
import { isMobileBrowser } from 'mz-ui-kit/utils'
import { hook } from 'app/utils/hook';

// Define lazy loaded pages
const TravelerApp = React.lazy(() => importWithRetry(() => import('app/pages/TravelerApp')))
const TrackingPageV2 = React.lazy(() => importWithRetry(() => import('app/pages/TrackingPageV2')))
const CancelBookingPage = React.lazy(() => importWithRetry(() => import('app/pages/CancelBookingPage')))
const ChangeBookingPage = React.lazy(() => importWithRetry(() => import('app/pages/ChangeBookingPage')))
const CustomerFeedbackPage = React.lazy(() => importWithRetry(() => import('app/pages/CustomerFeedback')))
const TermsOfServicePage = React.lazy(() => importWithRetry(() => import('app/pages/TermsOfService')))
const PrivacyPolicyPage = React.lazy(() => importWithRetry(() => import('app/pages/PrivacyPolicy')))
const ImprintPage = React.lazy(() => importWithRetry(() => import('app/pages/ImprintPage')))
const VerifyEmailPage = React.lazy(() => importWithRetry(() => import('app/pages/VerifyEmailPage')))
const GetMobileAppPage = React.lazy(() => importWithRetry(() => import('app/pages/GetMobileApp')))
const SetPickupPointPage = React.lazy(() => importWithRetry(() => import('app/pages/SetPickupPoint')))
const NotFoundPage = React.lazy(() => importWithRetry(() => import('app/pages/NotFound')))
const HomePage = React.lazy(() =>
  config.SHOW_MOZIO_GROUP_HOMEPAGE
    ? importWithRetry(() => import(/* webpackChunkName: "hp-mozio" */ 'app/pages/HomePage/MozioGroupHomePage'))
    : importWithRetry(() => import(/* webpackChunkName: "hp" */ 'app/pages/HomePage/WhitelabelsHomePage'))
)
const ConfirmationPage = React.lazy(() =>
  isMobileBrowser()
    ? importWithRetry(() => import(/* webpackChunkName: "cp-mobile" */ 'app/pages/ConfirmationPage/Mobile'))
    : importWithRetry(() => import(/* webpackChunkName: "cp-desktop" */ 'app/pages/ConfirmationPage/Desktop'))
)
const SearchPage = React.lazy(() =>
  isMobileBrowser()
    ? importWithRetry(() => import(
        /* webpackChunkName: "srp-mobile" */
        /* webpackPrefetch: true */
        'app/pages/SearchResultsPage/Mobile'
      ))
    : importWithRetry(() => import(
        /* webpackChunkName: "srp-desktop" */
        /* webpackPrefetch: true */
        'app/pages/SearchResultsPage/Desktop'
      ))
)
const BookPage = React.lazy(() =>
  isMobileBrowser()
    ? importWithRetry(() => import(
        /* webpackChunkName: "btp-mobile" */
        /* webpackPrefetch: true */
        'app/pages/BookTripPage/Mobile'
      ))
    : importWithRetry(() => import(
        /* webpackChunkName: "btp-desktop" */
        /* webpackPrefetch: true */
        'app/pages/BookTripPage/Desktop'
      ))
)
const TrackingPage = React.lazy(() =>
  isMobileBrowser()
    ? importWithRetry(() => import('app/pages/TrackingPage/Mobile'))
    : importWithRetry(() => import('app/pages/TrackingPage/Desktop'))
)

const routes = [
  {
    path: '/',
    component: HomePage
  },
  {
    path: '/traveler-app',
    component: TravelerApp
  },
  {
    path: '/cancel-booking/:tripId?',
    component: CancelBookingPage
  },
  {
    path: '/change-booking',
    component: ChangeBookingPage
  },
  {
    path: '/customer-feedback',
    component: CustomerFeedbackPage
  },
  {
    path: '/terms-of-service',
    component: TermsOfServicePage
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicyPage
  },
  {
    path: '/imprint',
    component: ImprintPage
  },
  {
    path: '/search',
    component: SearchPage
  },
  {
    path: '/book',
    component: BookPage
  },
  {
    path: '/verify-email/:verification_key',
    component: VerifyEmailPage
  },
  {
    path: '/get-mobile-app',
    component: GetMobileAppPage
  },
  {
    path: '/set-pickup-point',
    component: SetPickupPointPage
  },
  {
    path: '/tracking/:trackingId',
    component: TrackingPage
  },
  {
    path: '/tracking/:trackingId/v2',
    component: TrackingPageV2
  },
  {
    path: '/confirmation',
    component: ConfirmationPage
  },
  {
    from: '/view-reservation',
    to: '/confirmation'
  },
  {
    component: NotFoundPage
  }
];

export default () =>
  hook({
    name: MAIN_ROUTES_HOOK,
    args: [routes],
    defaultHandler: () => routes
  });
