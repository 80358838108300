import {
 ONE_WAY_MODE_VALUE,
 ROUND_TRIP_MODE_VALUE,
 BOOK_TRIP_FORM,
 RETURN_BOOK_STATE_FIELD_NAME,
 START_RETURN_BOOKING
} from 'app/constants';
import moment from 'moment';
import { put, call, select } from 'redux-saga/effects';
import _ from 'lodash';
import { trips } from 'mz-sdk';
import { change } from 'redux-form';
import { parseUrlParams, showErrorNotification, normalizeErrorMessage } from 'app/sagas/utils';
import { getBookTripForm, getActiveCurrency } from 'app/sagas/selectors';
import {
  setChangedModeTrip,
  bookChangedModeTrip,
  startUpdateRequest,
  endUpdateRequest
} from 'app/actions/book-trip';

/**
 * This saga performs a research to change the
 * current trip mode and store result in state.
 * If changing round_trip -> one_way it cleans
 * the return trip fields by "bookin" one_way.
 * @param  {object}  action with mode string as payload
 */
export default function* getRoundTrip(action) {
  const mode = action.payload;
  yield put(startUpdateRequest());

  const { trip_id, session_id, ondemand } = yield call(parseUrlParams);
  const { return_datetime, departing_pickup_datetime } = yield select(getBookTripForm);
  const currency = yield select(getActiveCurrency);

  const params = _.omitBy({
    pickup_datetime: mode === ONE_WAY_MODE_VALUE && moment(departing_pickup_datetime),
    return_pickup_datetime: mode === ROUND_TRIP_MODE_VALUE && moment(return_datetime),
    mode,
    currency
  }, key => !key);

  try {
    const updatedTrip = yield call(trips.research, {
      trip_id,
      session_id,
      ondemand: !!ondemand,
      params
    });

    if (!updatedTrip) throw new Error();

    yield put(endUpdateRequest());
    yield put(setChangedModeTrip(updatedTrip));
    if (mode === ONE_WAY_MODE_VALUE) {
      yield put(bookChangedModeTrip(updatedTrip));
    }
  } catch (error) {
    const message = error.response && normalizeErrorMessage(error.response);
    const notification = message
      ? { error: { message } }
      : { messageId: 'SEARCH_RESULTS.NO_RESULTS_MESSAGE' };
    yield call(showErrorNotification, notification);
    yield put(endUpdateRequest());
    yield put(change(BOOK_TRIP_FORM, RETURN_BOOK_STATE_FIELD_NAME, START_RETURN_BOOKING));
  }
}
