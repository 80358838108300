import React, { Component } from 'react';
import classnames from 'classnames';
import Icon from 'app/components/base/Icon';
import style from './style.css';

class Star extends Component {
  constructor() {
    super();
    this.onHover = this.handleEditable('onHover');
    this.onLeave = this.handleEditable('onLeave');
    this.setActive = this.handleEditable('setActive');
    this.handleClick = this.handleEditable('onClick');
  }

  handleEditable = (fnName) => () => {
    const { editable, index } = this.props;
    if (!editable || !this.props[fnName]) return;

    this.props[fnName](index);
  }

  render() {
    const { on, hover, active, iconName } = this.props;

    const iconClassName = classnames(
      style.star__icon,
      {
        [style.on]: on,
        [style.hover]: hover,
        [style.active]: active
      }
    );

    return (
      <span
        className={style.star}
        onClick={this.handleClick}
        onMouseEnter={this.onHover}
        onMouseLeave={this.onLeave}
        onMouseDown={this.setActive}
      >
        <Icon className={iconClassName}>{iconName}</Icon>
      </span>
    );
  }
}

// TODO: move to Flow types
// Star.propTypes = {
//   index: PropTypes.number.isRequired,
//   iconName: PropTypes.string.isRequired,

//   // State related
//   on: PropTypes.bool,
//   active: PropTypes.bool,
//   hover: PropTypes.bool,
//   editable: PropTypes.bool,

//   // Callbacks
//   onClick: PropTypes.func,
//   onHover: PropTypes.func,
//   onLeave: PropTypes.func,
//   setActive: PropTypes.func
// };

export default Star;
