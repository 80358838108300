import { call } from 'redux-saga/effects';
import { showErrorNotification } from 'app/sagas/utils';
import { travelagent } from 'mz-sdk';
import {
  REPORT_START_MONTH,
  REPORT_START_YEAR,
  REPORT_END_MONTH,
  REPORT_END_YEAR,
  REPORT_IS_TRAVEL_DATE,
  REPORT_INCLUDE_WITHDRAWN
} from 'app/constants';

export default function* watchBookingsReport(action) {
  try {
    // If no errors occur, the SDK downloads the report file.
    const { payload } = action;

    yield call(travelagent.downloadReport, {
      start_month: payload[REPORT_START_MONTH].value,
      start_year: payload[REPORT_START_YEAR].value,
      end_month: payload[REPORT_END_MONTH].value,
      end_year: payload[REPORT_END_YEAR].value,
      travel_date: !!payload[REPORT_IS_TRAVEL_DATE],
      include_withdrawn: !!payload[REPORT_INCLUDE_WITHDRAWN]
    });

    yield call(action.resolvePromise);
  } catch (error) {
    yield call(showErrorNotification, { error });
    yield call(action.rejectPromise);
  }
}
