import {
  RESET_PASSWORD_USER,
  CHANGE_PASSWORD,
  SUSPEND_ACCOUNT,
  UPDATE_USER_INFORMATION
} from 'app/constants';

import { fork } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga';

import changePassword from './changePassword';
import resetPassword from './resetPassword';
import suspendAccount from './suspendAccount';
import updateUserInformation from './updateUserInformation';

export default function* watchUserData() {
  yield fork(takeLatest, CHANGE_PASSWORD, changePassword);
  yield fork(takeLatest, RESET_PASSWORD_USER, resetPassword);
  yield fork(takeLatest, SUSPEND_ACCOUNT, suspendAccount);
  yield fork(takeLatest, UPDATE_USER_INFORMATION, updateUserInformation);
}
