import { call } from 'redux-saga/effects';
import { auth } from 'mz-sdk';
import { showErrorNotification } from 'app/sagas/utils';

export default function* sendAppLink(action) {
  const { phone_number, country_code_name } = action.payload;
  try {
    yield call(auth.sendAppLink, {
      phone_number,
      country_code_name: country_code_name.code
    });
    yield call(action.resolvePromise);
  } catch (error) {
    yield call(showErrorNotification, { error });
    yield call(action.rejectPromise);
  }
}
