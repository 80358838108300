import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import * as format from 'app/utils/format';
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'app/sagas';
import configureStore from 'app/store';
import { hook } from 'app/utils/hook';
import { OVERRIDE_DEFAULT_APPEARANCES } from 'app/constants'
import { injectCustomCSS } from './utils/customCss';
import { listenToChanges } from './utils/whitelabel'
import { createHistory, watchHistoryChanges } from './history';
import getOrCreateTranslationsManager from './translationsManager';
import { setActiveStore } from 'blocks/common/ReduxState';
import { Provider as StyletronProvider } from 'styletron-react';
import { IntlProvider } from 'mz-intl';
import styletronClient from './styletronClient'
import SentryErrorBoundary from './components/common/SentryErrorBoundary'
import PartnerTrackingBoundary from './components/common/PartnerTrackingBoundary'
import LocaleRoute from './routes/LocaleRoute'
import Routes from './routes'
import { PaletteProvider } from 'mz-ui-kit/utils/withPalette'
import { Router } from 'react-router'
import config from 'config';


const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const setCssVars = (name, val) => {
  document.documentElement.style.setProperty(name, val);
}

const renderApp = () => {
  const store = configureStore({}, middlewares);
  const history = createHistory();
  const rootElement = document.getElementById('container');

  sagaMiddleware.run(rootSaga);
  format.setActiveStore(store);
  setActiveStore(store);
  watchHistoryChanges(history, store)

  const defaultAppearances = hook({
    name: OVERRIDE_DEFAULT_APPEARANCES,
    defaultHandler: () => null
  })

  const doRender = () => {
    injectCustomCSS();

    setCssVars('--mz-primary-color', config.FIRST_BACKGROUND_COLOR);
    setCssVars('--mz-secondary-color', config.SECOND_BACKGROUND_COLOR);
    setCssVars('--mz-yellow-color', config.FOURTH_BACKGROUND_COLOR);
    setCssVars('--mz-cta-color', config.CTA_BACKGROUND_COLOR);

    const root = createRoot(rootElement)

    root.render(
      <SentryErrorBoundary>
        <StyletronProvider value={styletronClient}>
          <PaletteProvider
            defaultAppearances={defaultAppearances}
            colors={{
              PRIMARY: config.FIRST_BACKGROUND_COLOR,
              SECONDARY: config.SECOND_BACKGROUND_COLOR,
              YELLOW: config.FOURTH_BACKGROUND_COLOR,
              CTA: config.CTA_BACKGROUND_COLOR,
            }}
          >
            <Provider store={store}>
              <Router history={history}>
                <PartnerTrackingBoundary>
                  <LocaleRoute>
                    {locale => (
                      <IntlProvider
                        locale={locale}
                        manager={getOrCreateTranslationsManager({
                          locale,
                          publicPath: process.env.ASSET_PATH
                        })}
                      >
                        <Routes />
                      </IntlProvider>
                    )}
                  </LocaleRoute>
                </PartnerTrackingBoundary>
              </Router>
            </Provider>
          </PaletteProvider>
        </StyletronProvider>
      </SentryErrorBoundary>,
    );
  };

  doRender()
  listenToChanges(doRender)
};

export default renderApp;
