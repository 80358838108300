import { call, select } from 'redux-saga/effects';
import { tracking } from 'mz-sdk';
import { getLocation, getCustomerFeedbackForm } from 'app/sagas/selectors';
import {
  CUSTOMER_FEEDBACK_COMMENT,
  CUSTOMER_FEEDBACK_RATING
} from 'app/constants';
import {
  showSuccessNotification,
  showErrorNotification
} from 'app/sagas/utils';


/**
 * Submit customer feedback
 * @param  {Object}    action
 * @return {Generator}
 */
export default function* submitFeedback(action) {
  try {
    const form = yield select(getCustomerFeedbackForm);
    const { query } = yield select(getLocation);
    const data = {
      rating_token: query.rating_token,
      description: form[CUSTOMER_FEEDBACK_COMMENT],
      score: parseInt(form[CUSTOMER_FEEDBACK_RATING], 10)
    };

    yield call(tracking.createRating, data);
    yield call(action.resolvePromise);
    yield call(showSuccessNotification, {
      messageId: 'CUSTOMER_FEEDBACK.FEEDBACK_SUBMITTED'
    });
  } catch (error) {
    yield call(showErrorNotification, { error });
    yield call(action.rejectPromise);
  }
}
