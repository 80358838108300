// @flow
import React from 'react';
import { AlertWrapper } from './elements';
import MinimumHint from './MinimumHint';
import Alert from 'mz-ui-kit/core/Alert';

// types
type Props = {
  minimum: number
};

const MinimumAlert = ({ minimum }: Props) => (
  <AlertWrapper>
    <Alert color="YELLOW">
      <MinimumHint minimum={minimum} />
    </Alert>
  </AlertWrapper>
);

export default MinimumAlert;
