import React, { Component } from 'react';
import classnames from 'classnames';
import Icon from 'app/components/base/Icon';
import { Layout } from 'app/components/base/Layout';
import Button from 'app/components/base/Button';
import noop from 'lodash/noop';

import style from './style.css';

const AUTO_CLOSE_TIMEOUT = 3200;


/**
 * The component for showing warnings, errors and other information blocks.
 * It also can show buttons for more interactions with the user.
 */
class InteractiveInfoBlock extends Component {

  constructor(props) {
    super(props);
    this.handleAutoClose();
  }

  state = {
    hidden: false
  }

  handleAutoClose() {
    if (this.props.autohide) {
      setTimeout(this.handleClose, AUTO_CLOSE_TIMEOUT);
    }
  }

  handleClose = () => {
    const { autohide, onClickClose = noop } = this.props;
    onClickClose();

    if (autohide) {
      this.setState({ hidden: true });
    }
  }

  renderButtons() {
    const {
      buttons,
      buttonsOnNextLine
    } = this.props;

    const modifiers = {
      [style['block__buttons--bottom']]: buttonsOnNextLine,
      [style['block__buttons--right']]: !buttonsOnNextLine
    };
    return buttons && (
      <Layout
        className={classnames(style.block__buttons, modifiers)}
        size="noshrink"
        direction={buttonsOnNextLine ? 'row' : 'column'}
        align="center center"
        nowrap
      >
        {buttons.map((buttonProps, index) => {
          const btn = (
            <Button
              key={index}
              rounded nocaps
              className={style.block__button}
              onClick={buttonProps.onClick}
            >
              {buttonProps.title}
            </Button>
          );
          if (buttonProps.rightText) {
            return (
              <Layout>
                {btn}
                <Layout align="center center" key={`r${index}`}>
                  {buttonProps.rightText}
                </Layout>
              </Layout>
            );
          }
          return btn;
        })}
      </Layout>
    );
  }

  renderIcon() {
    const {
      icon,
      iconClassName,
      straightIcon
    } = this.props;
    const modifiers = {
      [style['iconContainer--rounded']]: !straightIcon
    };
    const iconStyle = iconClassName || classnames(style.iconContainer, modifiers);

    return icon && (
      <Layout
        align="center center"
        className={iconStyle}
      >
        <Icon {...this.props.icon} className={style.icon} large />
      </Layout>
    );
  }

  renderContent() {
    const {
      title,
      text,
      buttonsOnNextLine,
      contentAlign
    } = this.props;
    return (
      <Layout align={contentAlign} size="grow" direction="column">
        {title && (
          <Layout className={style.block__title}>
            {title}
          </Layout>
        )}
        {text && (
          <Layout size="noshrink" className={style.block__text}>
            {text}
          </Layout>
        )}
        {buttonsOnNextLine && this.renderButtons()}
      </Layout>
    );
  }

  renderRightButtons() {
    return !this.props.buttonsOnNextLine && this.renderButtons();
  }

  renderClosedButton() {
    const { autohide, onClickClose } = this.props;

    return (autohide || onClickClose) && (
      <Button
        clear transparent freeWidth
        onClick={this.handleClose}
        className={style.block__close}
      >
        <Icon small name="close" />
      </Button>
    );
  }

  render() {
    const {
      className,
      active,
      type,
      onClickClose,
      children,
      autohide,
      transparentIcon,
      colorless,
      onClick,
      renderContent,
      direction,
    } = this.props;

    const modifiers = {
      [style[`block--${type}`]]: true,
      [style.colorless]: colorless,
      [style['block--active']]: active && type === 'white',
      [style['block--closable']]: onClickClose,
      [style['block--autohide']]: autohide,
      [style['block--transparentIcon']]: transparentIcon
    };

    if (this.state.hidden) {
      return false;
    }

    return (
      <div
        className={classnames(className, modifiers, style.block)}
        onClick={onClick}
      >
        <div className={style.content}>
          {this.renderIcon()}
          <Layout nowrap size="auto" direction={direction}>
            {!children && this.renderContent()}
            {!children && this.renderRightButtons()}
            {children}
          </Layout>
          {this.renderClosedButton()}
        </div>
        {renderContent && renderContent()}
      </div>
    );
  }
}

InteractiveInfoBlock.defaultProps = {
  type: 'white',
  contentAlign: 'start start',
  autohide: false
};

// TODO: move to Flow types
// InteractiveInfoBlock.propTypes = {
//   className: PropTypes.string,
//   icon: PropTypes.shape({
//     name: PropTypes.string,
//     family: PropTypes.string
//   }),
//   colorless: PropTypes.bool,
//   transparentIcon: PropTypes.bool,
//   iconClassName: PropTypes.string,
//   straightIcon: PropTypes.bool,
//   type: PropTypes.oneOf(['green', 'red', 'blue', 'white', 'yellow']),
//   title: PropTypes.node,
//   text: PropTypes.node,
//   onClickClose: PropTypes.func,
//   onClick: PropTypes.func,
//   buttons: PropTypes.arrayOf(PropTypes.shape({
//     onClick: PropTypes.func,
//     title: PropTypes.string,
//     rightText: PropTypes.string
//   })),
//   active: PropTypes.bool,
//   children: PropTypes.node,
//   buttonsOnNextLine: PropTypes.bool,
//   contentAlign: PropTypes.string,
//   autohide: PropTypes.bool
// };

export default InteractiveInfoBlock;
