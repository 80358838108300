// @flow
import { styled } from 'mz-ui-kit';
import tinycolor from 'tinycolor2'


export const Container = styled('div', ({ $palette, $color }) => {
  const bgColor = $palette($color)
  const textColor = tinycolor.mostReadable(bgColor.clone().darken(17).toString(), [
    $palette('white').toString(),
    $palette('text').toString(),
  ])
  
  return {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexFlow: 'column',
    padding: '20px',
    fontSize: '16px',
    backgroundColor: `${bgColor}`,
    color: `${textColor}`,
  }
});

export const Miles = styled('div', {
  fontSize: '30px'
})
