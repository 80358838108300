import { select } from 'redux-saga/effects';
import moment from 'moment';
import { isScheduledTrip } from 'app/utils/trip';
import getTripPickupTimesOriginTZ from './selectors/getTripPickupTimesOriginTZ';
import {
  getBookTripFormValue,
  getDepartureTicket,
  getReturnTicket
} from 'app/sagas/selectors';
import {
  DEPARTING_PICKUP_FIELD_NAME,
  DEPARTING_TRIP_FIELD_NAME
} from 'app/constants';
import {
  getTicketInfo
} from 'app/components/search-results/TimeTableSelect/utils/getTripScheduleProps';


/**
 * Calculates correct pickup time in local timezone (pickup/dropoff zone)
 * @param {String} options.field      Name of the field thas was changed
 * @param {Object} options.pickupTime Value of the field
 * @yield {Moment} Moment obj with date
 */
export default function* getResearchPickupTime({ field, pickupTime }) {
  const isReturn = field !== DEPARTING_PICKUP_FIELD_NAME;

  // used moment because mobile time date picker return plain Date
  const newPickupTime = moment(pickupTime);

  let newPickupTimeOriginTZ = null;

  // TODO: get ticket and apply ticket departure to pickupTime in PATCH request
  const trip = yield select(getBookTripFormValue, DEPARTING_TRIP_FIELD_NAME);

  // for schduled ride we get departure time (in local timezone) of selected ticket as pickup time
  // for regular trips we use pickup time field and set original timezone
  if (isScheduledTrip(trip)) {
    const ticketSelector = isReturn ? getReturnTicket : getDepartureTicket;
    const selectedTicket = yield select(ticketSelector);

    const ticketInfo = getTicketInfo(trip, selectedTicket, isReturn);

    // ticket departure is already in local TZ, we just set new date selected by user
    newPickupTimeOriginTZ = ticketInfo.departureDatetime.clone().date(newPickupTime.date());
  } else {
    const originPickupTimesTZ = yield select(getTripPickupTimesOriginTZ);
    const originPickupTime = originPickupTimesTZ[field];

    // we are setting original timezone by shifting difference in offsets
    newPickupTimeOriginTZ = newPickupTime.clone();
    newPickupTimeOriginTZ.utcOffset(originPickupTime.utcOffset());
    const offsetDifference = newPickupTime.utcOffset() - newPickupTimeOriginTZ.utcOffset();
    newPickupTimeOriginTZ.add(offsetDifference, 'minutes');
  }

  return newPickupTimeOriginTZ;
}
