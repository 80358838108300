import style from './style.css';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';

/**
 * Appends the `-reverse` modifier to the given direction query or class
 * @param {object|string} direction
 * @returns {object|string}
*/
export const makeReverse = direction => (
  typeof direction === 'string'
    ? `${direction}-reverse`
    : mapValues(direction, makeReverse)
);

/**
 * Joins all parameters with a `-`
 * @returns {string}
 */
const snakeCase = (...args) => args.join('-');

export const propToClass = (prefix, prop, screen = {}) => {
  if (prop === undefined) return [];

  if (Array.isArray(prop)) {
    return [].concat(
      ...prop.map(item => propToClass(prefix, item, screen))
    );
  }

  if (typeof prop !== 'object') {
    const className = style[snakeCase(prefix, prop)];

    if (className === undefined) {
      const isProduction = process && process.env.NODE_ENV === 'production';
      if (!isProduction) throw new TypeError('Invalid layout');
      return [];
    }

    return [className];
  }

  return [].concat(
    ...map(prop, (value, query) => {
      if (!screen[query]) return [];
      return propToClass(prefix, value, screen);
    })
  );
};

/**
 * It joins the perpendicular and parallel align of the given query or class
 * into a single string separated by `-`
 * @param {object|string} align
 * @returns {object|string}
 */
export const parseAlign = (align) => {
  if (typeof align === 'object') return mapValues(align, parseAlign);
  const [parallel, perpendicular] = align.split(' ');
  return [
    `parallel-${parallel}`,
    `perpendicular-${perpendicular}`
  ];
};
