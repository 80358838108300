import { call } from 'redux-saga/effects';
import { showErrorNotification } from 'app/sagas/utils';
import { user } from 'mz-sdk';
import doLogin from './doLogin';


export default function* doReactivate(action) {
  try {
    yield call(user.activate, action.payload);
    yield call(doLogin, action);
  } catch (error) {
    yield call(showErrorNotification, {
      error,
      titleId: 'USER.REACTIVATE_ERROR_TITLE'
    });
  }
}
