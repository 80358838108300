import React, { Component } from 'react';
import Icon from 'app/components/base/Icon';
import classnames from 'classnames';

import style from './style.css';

class Label extends Component {
  handleClick = (event) => {
    event.preventDefault();
    this.props.onSelect(this.props.index);
  }

  render() {
    const {
      icon,
      label,
      className,
      isActive,
      component,
      ...props
    } = this.props;
    return (
      React.createElement(
        component,
        {
          ...props,
          className: classnames(
            style.label,
            className,
            { [style['label--active']]: isActive }
          ),
          onClick: this.handleClick
        },
        icon && (
          <Icon className={style.label__icon}>
            {icon}
          </Icon>
        ),
        label
      )
    );
  }
}

// TODO: move to Flow types
// Label.propTypes = {
//   onSelect: PropTypes.func.isRequired,
//   label: PropTypes.node,
//   icon: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   isActive: PropTypes.bool.isRequired,
//   className: PropTypes.string,
//   component: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.func
//   ])
// };

export default Label;
