import React from 'react';
import CN from 'classnames';
import { compose } from 'redux';
import { Layout } from 'app/components/base/Layout';
import ConnectedFooter from 'app/components/common/ConnectedFooter';
import { subpageManagable, RENDERERS } from 'app/hocs/subpageManagable';
import AppHeader from '../AppHeader';
import config from 'config';
import { HeaderWrapper, ContentWrapper } from './elements';
import style from '../style.css';


/**
 * Component for showing page content. Extracted from AppPage
 * to connect to `subpageManagable` because some renderers (MOBILE_SCREEN)
 * do not render main component when some subpage is in the stack.
 * So, we can't just connect `AppPage` to the `subpageManagable`.
 * @type {Component}
 */
export const AppContent = ({ content, header }) => {
  const modifiers = { [style['container--dark']]: config.COLORS_DARK_MODE };
  return (
    <Layout
      className={CN(style.container, modifiers)}
      direction="column"
      nowrap
    >
      {header && (
        <HeaderWrapper>
          <AppHeader />
        </HeaderWrapper>
      )}
      <ContentWrapper>
        {content}
      </ContentWrapper>
      <ConnectedFooter />
    </Layout>
  );
};

AppContent.defaultProps = {
  header: true
};

// TODO: move to Flow types
// AppContent.propTypes = {
//   content: PropTypes.any,
//   header: PropTypes.bool
// };

export default compose(
  subpageManagable(
    { renderer: RENDERERS.AUTODETECT }
  )
)(AppContent);
