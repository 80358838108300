import React from 'react';
import CN from 'classnames';
import { FormattedMessage } from 'translations';
import { Layout } from 'app/components/base/Layout';
import Icon from 'app/components/base/Icon';
import getStepsSummary from './utils/getStepsSummary';
import Step from '../Step';

import style from './style.css';


const messages = {
  milesDistance: { id: 'SEARCH_RESULTS.MILES_DISTANCE' },
  metersDistance: { id: 'SEARCH_RESULTS.METERS_DISTANCE' }
};

/**
 * Shows a collection on trip steps.
 */
const StepsSummary = ({
  steps,
  className,
  recommendedTrip,
  mobile
}) => {
  const stepsSummary = getStepsSummary(
    steps,
    {
      formatDistance: distance => { // eslint-disable-line react/display-name
        if (!distance) return null;
        const message = messages.milesDistance;
        return <FormattedMessage {...message} values={{ distance }} />;
      }
    }
  );

  if (!stepsSummary.length || stepsSummary.length < 2) {
    return null;
  }

  const modifiers = {
    [style['route--mobile']]: !!mobile
  };

  return (
    <Layout
      className={CN(style.route, className, modifiers)}
      align="start center"
      direction="row"
      wrap
    >
      {stepsSummary.map((step, i) => (
        <React.Fragment key={i}>
          <Step
            {...step}
            recommended={recommendedTrip}
            unit="meters"
            mobile={mobile}
          />
          {(i !== stepsSummary.length - 1) && (
            <Layout className={CN(style.arrow, { [style['arrow-mobile']]: mobile })}>
              <Icon small name="keyboard_arrow_right" />
            </Layout>
          )}
       </React.Fragment>
     ))}
    </Layout>
  );
};


// TODO: move to Flow types
// StepsSummary.propTypes = {
//   steps: PropTypes.array,
//   recommendedTrip: PropTypes.bool,
//   className: PropTypes.string,
//   mobile: PropTypes.bool
// };

export default StepsSummary;
