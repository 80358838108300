import createMessages from 'mz-intl/create';
import createMessagesObject from '../createMessagesObject'
import taSources from '../sources/ta-sources-en_US.json'

export const DOMAIN = 'TA_TOOL'

export default createMessages(
  {
    domain: DOMAIN,
    resource: 'ta-tool',
  },
  createMessagesObject(taSources)
)
