// @flow
import { styled } from 'mz-ui-kit';
import { getScreenStyles } from 'mz-ui-kit/utils'
import GoogleAdsense from 'app/components/base/GoogleAdsense'

export const BannerWrapper = styled('div', {
  width: '100%',
  margin: '20px auto 10px auto',
  maxWidth: '950px'
});

export const OverlayWrapper = styled('div', {
  position: 'relative',
  width: '100%'
});

export const OutdatedOverlay = styled('div', ({ $palette }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 9000,
  background: `${$palette('white').setAlpha(0.6)}`
}));

export const RecommendedLabel = styled('h2', {
  fontSize: '24px',
  color: '#0D84EB',
  padding: '0 20px',
  display: 'flex',
  flexFlow: 'row nowrap',
  marginBottom: '2px',
})

export const RecommendedIcon = styled('div', {
  display: 'flex',
  alignItems: 'center',
  fontSize: '29px',
  width: '29px',
  height: '29px',
  border: '1px solid #0D84EB',
  borderRadius: '50%',
  marginRight: '8px',
  color: '#0D84EB',
})

export const SearchAlertWrapper = styled('div', {
  width: '100%',
  ...getScreenStyles('xs', {
    padding: '10px 10px'
  }, {
    padding: '10px 0'
  })
})

export const FeaturesListWrapper = styled('div', {
  textAlign: 'left',
  alignSelf: 'flex-start',
  marginLeft: '10px',
  marginTop: '10px',
  marginBottom: '2rem',
})

export const StyledAdsense = styled(GoogleAdsense, ({ $mobile }) => ({
  boxSizing: 'content-box',
  borderTop: !$mobile ? 'none' : '1px solid #e4e4e4',
  borderBottom: !$mobile ? 'none' : '1px solid #e4e4e4',
  display: 'block',
  marginTop: '20px',
}));
