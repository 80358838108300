import { createReducer } from '../utils/redux';
import {
  SET_TA_ACCOUNT,
  TA_SELECT_CUSTOMER_PROFILE,
  TA_GET_CUSTOMER_PROFILE_LIST,
  TA_SET_CUSTOMER_PROFILE_LIST,
  TA_GET_CUSTOMER_PROFILE,
  TA_SET_CUSTOMER_PROFILE,
  TA_SELECT_PAYMENT_ACCOUNT,
  TA_PAYMENTS_ACCOUNT_TYPE_PAYPAL,
  TA_SET_RESERVATIONS,
  TA_SET_WITHDRAWALS,
  LOGOUT_USER
} from '../constants';

const initialState = {
  profileId: null,
  profileList: [],
  isProfileLoading: false,
  isProfileListLoading: true,
  profile: null,
  paypalAccounts: [],
  bankAccounts: [],
  paymentAccountId: null,
  paymentAccount: null,
  selectedMonthSalesBalance: {
    reservations: [],
    commissionTotal: null,
    reservationsTotal: null,
    alreadyWithdrawn: true
  },
  withdrawals: []
};

const actionHandlers = {
  [SET_TA_ACCOUNT]: (_, { paypal_accounts }) => ({
    paypalAccounts: paypal_accounts,
    bankAccounts: []
  }),
  [TA_SELECT_CUSTOMER_PROFILE]: (_, { profileId }) => ({
    profileId
  }),
  [TA_GET_CUSTOMER_PROFILE_LIST]: () => ({
    isProfileListLoading: true
  }),
  [TA_SET_CUSTOMER_PROFILE_LIST]: (_, profileList) => ({
    profileList,
    isProfileListLoading: false
  }),
  [LOGOUT_USER]: () => ({
      profileList: []
  }),
  [TA_GET_CUSTOMER_PROFILE]: () => ({
    isProfileLoading: true
  }),
  [TA_SET_CUSTOMER_PROFILE]: (_, { profile }) => ({
    profile,
    isProfileLoading: false
  }),
  [TA_SELECT_PAYMENT_ACCOUNT]: (state, { accountId, accountType }) => {
    if (!accountId) {
      return {
        paymentAccountId: null,
        paymentAccount: null
      };
    }
    let account;
    if (accountType === TA_PAYMENTS_ACCOUNT_TYPE_PAYPAL) {
      account = state.paypalAccounts.find(paypalAccount => {
        return paypalAccount.account_id === accountId;
      });
    }

    return {
      paymentAccount: account,
      paymentAccountId: account.account_id
    };
  },
  [TA_SET_RESERVATIONS]: (_, {
    reservations,
    reservations_total,
    commissions_total,
    withdrawn
  }) => ({
    selectedMonthSalesBalance: {
      reservations,
      monthSales: reservations_total,
      monthCommissions: commissions_total,
      alreadyWithdrawn: withdrawn
    }
  }),
  [TA_SET_WITHDRAWALS]: (_, withdrawals) => ({ withdrawals })
};

export default createReducer(initialState, actionHandlers);
