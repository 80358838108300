import { call } from 'redux-saga/effects';
import { travelagent } from 'mz-sdk';
import { apiCall, showSuccessNotification } from 'app/sagas/utils';
import { TA_PAYMENTS_ACCOUNT_TYPE_PAYPAL } from 'app/constants';

const mapPayloadToParams = payload => {
  return {
    account_id: payload.selectedAccount.value,
    year: payload.date.year(),
    // moment.js uses numbers from 0 to 11 for month while the API uses
    // numbers from 1 to 12
    month: payload.date.month() + 1
  };
};

/**
 * Withdraw sales commissions to an account.
 */
export default function* withdrawCommissions(action) {
  if (action.payload.selectedAccount.type === TA_PAYMENTS_ACCOUNT_TYPE_PAYPAL) {
    const data = yield call(apiCall, action, travelagent.withdrawToPaypalAccount,
      mapPayloadToParams);

    if (data) {
      yield call(showSuccessNotification, {
        messageId: 'TA_ACCOUNT.SALES_BALANCE.SUCCESSFUL_WITHDRAW_TEXT',
        titleId: 'TA_ACCOUNT.SALES_BALANCE.SUCCESSFUL_WITHDRAW_MESSAGE'
      });
    }
  }
}
