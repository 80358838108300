import { getUser, isTravelAgentCredits } from 'app/sagas/selectors';
import queryStringToObject from 'mz-utils/queryStringToObject'
import objectToQueryString from 'mz-utils/objectToQueryString'
import { showWarningNotification } from 'app/sagas/utils';
import { select, call } from 'redux-saga/effects';
import { getHistory } from 'app/history';
import {
  USER_TYPE_TA
} from 'app/constants';

export default function* checkTAUser() {
  const history = yield call(getHistory);
  const query = queryStringToObject(history.location.search)
  const travelAgentParam = query.ta;
  const user = yield select(getUser);
  const isTACredits = yield select(isTravelAgentCredits);

  if (user) {
    // If user is a TA, we need to make sure the ta=1 url param is there.
    if (user.user_type === USER_TYPE_TA && !travelAgentParam) {
      const { pathname } = history.location;
      yield call(history.replace, {
        pathname,
        search: objectToQueryString({
          ...query,
          ta: 1
        })
      });
    }

    // If not a TA, but we have ta=1, then inform the normal user.
    if (user.user_type !== USER_TYPE_TA && travelAgentParam && !isTACredits) {
      yield call(showWarningNotification, {
        messageId: 'SHOULD_LOGIN_AS_TRAVEL_AGENT'
      });
    }
  }
}
