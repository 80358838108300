import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { Layout } from 'app/components/base/Layout';
import classnames from 'classnames';
import _ from 'lodash';

import style from './style.css';


/**
 * Renderer for subpages that renders latest subpage in the stack
 * as a centered lightbox.
 */
export class LightboxRenderer extends React.Component {
  renderLightbox() {
    const { pagesStack } = this.props;
    const { SubpageComponent, pageProps } = _.last(pagesStack) || {};

    return !SubpageComponent ? <div /> : (
      <Layout nowrap align="center center" className={style.lightbox__wrapper}>
        <div className={style.lightbox__overlay} />
        <div className={style.lightbox__content}>
          <SubpageComponent {...pageProps} />
        </div>
      </Layout>
    );
  }

  render() {
    const {
      pagesStack, // eslint-disable-line no-unused-vars
      children,
      className,
    } = this.props;

    return (
      <Layout
        nowrap
        direction="column"
        className={classnames(className, style.lightbox)}
      >
        {children}
        <CSSTransition
          in={true}
          timeout={600}
          classNames={{
            enter: style['lightbox-enter'],
            enterActive: style['lightbox-enter-active'],
            exit: style['lightbox-leave'],
            exitActive: style['lightbox-leave-active']
          }}
        >
          {this.renderLightbox()}
        </CSSTransition>
      </Layout>
    );
  }
}

// TODO: move to Flow types
// LightboxRenderer.propTypes = {
//   children: PropTypes.node,
//   className: PropTypes.string,
//   pagesStack: PropTypes.array,
//   subpagesManager: PropTypes.object
// };

export default LightboxRenderer;
