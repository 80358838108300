import React from 'react';
import PlannedReturn from '../PlannedReturn/Mobile';
import ReturnDirection from '../ReturnDirection/Mobile';
import builder from './builder';
import { RETURN_TRIP_BOOKED } from 'app/constants';


/**
 * Just show `PlannedReturn` component if some return trip is
 * not booked.
 */
export const MobileBookReturnTrip = (props) => {
  const { readOnly, locked, returnBookState } = props;
  if ((readOnly || locked) && returnBookState !== RETURN_TRIP_BOOKED) return null;
  const FieldComponent = returnBookState === RETURN_TRIP_BOOKED
    ? ReturnDirection : PlannedReturn;

  return <FieldComponent {...props} />;
};

// TODO: move to Flow types
// MobileBookReturnTrip.propTypes = {
//   readOnly: PropTypes.bool,
//   locked: PropTypes.bool,
//   returnBookState: PropTypes.string
// };

export default builder(MobileBookReturnTrip);
