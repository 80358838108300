import { select } from 'redux-saga/effects';
import {
  getDepartureTicket,
  getReturnTicket
} from 'app/sagas/selectors';
import {
  SELECTED_TICKET_FIELD_NAME,
  SELECTED_RETURN_TICKET_FIELD_NAME
} from 'app/constants';
import { isScheduledTrip, isRoundTrip } from 'app/utils/trip';
import {
  getTicketInfo,
  getSelectedTicket
} from 'app/components/search-results/TimeTableSelect/utils/getTripScheduleProps';

/**
 * Returns correct ticket values for selected trip
 * @param {Object} options.updatedTrip Gived trip to compare with selected tickets
 * @yield {Object} Form fields values
 */
export default function* getUpdatedTickets({ updatedTrip }) {
  // compare selected tickets
  const updatedTickets = {};

  if (!isScheduledTrip(updatedTrip)) {
    return updatedTickets;
  }

  const oldDepartureTicket = yield select(getDepartureTicket);
  const defaultDepartureTicket = getSelectedTicket(updatedTrip);

  // we get ticket type from previous user selection
  // and scheduleId from API result, because BE calculates it based in pickup time
  const newDepartureTicket = {
    ticketId: oldDepartureTicket.ticketId,
    scheduleId: defaultDepartureTicket.scheduleId
  };
  const isDepartureTicketAvailable = !!getTicketInfo(updatedTrip, newDepartureTicket);

  // set either available ticket or default
  if (isDepartureTicketAvailable) {
    updatedTickets[SELECTED_TICKET_FIELD_NAME] = newDepartureTicket;
  } else {
    updatedTickets[SELECTED_TICKET_FIELD_NAME] = defaultDepartureTicket;
  }

  if (isRoundTrip(updatedTrip)) {
    const oldReturnTicket = yield select(getReturnTicket);
    const defaultReturnTicket = getSelectedTicket(updatedTrip, true);
    const newReturnTicket = {
      ticketId: oldReturnTicket.ticketId,
      scheduleId: defaultReturnTicket.scheduleId
    };
    const isReturnTicketAvailable = !!getTicketInfo(updatedTrip, newReturnTicket, true);

    // set either available ticket or default
    if (isReturnTicketAvailable) {
      updatedTickets[SELECTED_RETURN_TICKET_FIELD_NAME] = newReturnTicket;
    } else {
      updatedTickets[SELECTED_RETURN_TICKET_FIELD_NAME] = defaultReturnTicket;
    }
  }

  return updatedTickets;
}
