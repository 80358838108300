const Pane = ({ children }) => (
  children
);

// TODO: move to Flow types
// Pane.propTypes = {
//   children: PropTypes.node
// };

export default Pane;
