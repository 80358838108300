import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import MobileScreenRenderer from './renderers/MobileScreenRenderer';
import LightboxRenderer from './renderers/LightboxRenderer';
import AutodetectRenderer from './renderers/AutodetectRenderer';
import { withRouter } from 'react-router-dom';

// Constants
export const RENDERERS = {
  LIGHTBOX: LightboxRenderer,
  MOBILE_SCREEN: MobileScreenRenderer,
  AUTODETECT: AutodetectRenderer
};

/**
 * High-order component that adds subpages management to given page
 * component. Given `PageComponent` will be rendered by special `renderer`
 * component from options object (you can find different implementations
 * for lightbox and mobile screen in `RENDERERS` exported variable).
 * Also you can provide custom props for renderer component in `rendererProps`
 * field of options
 * @param  {Component} PageComponent  main page component
 * @param  {Object}    options.renderer Component that renders main page and subpages
 * @param  {Object}    options.rendererProps custom props which will be passed
 *                                           to the renderer
 * @return {Function} the HOC that could be used in `compose`
 */
export const subpageManagable = ({
  renderer = RENDERERS.LIGHTBOX,
  rendererProps = {}
} = {}) => (PageComponent) => {
  class SubpageManager extends React.Component {
    constructor(props, context) {
      super(props, context);

      // The state is not used because in one call stack `popPage` or `pushPage`
      // can be called multiple times. In this case first `popPage` will
      // not change the state because `setState` is debounced operation and
      // executed at the end of the call stack. So, the second call to `popPage`
      // will pop the same page what popped the first call because pages stack
      // is not changed after the first call.
      this.pagesStack = [];
    }

    getChildContext() {
      return { subpages: this };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      // close all modals if new action removeAll was called
      if (this.props.clearedAt !== nextProps.clearedAt) {
        this.removeAll();
      }
    }

    // Start listening to browser back button
    componentDidMount() {
      window.addEventListener('popstate', this.popPage);
    }

    componentWillUnmount() {
      window.removeEventListener('popstate', this.popPage)
    }

    /**
     * Adds new subpage to the stack and show it on next tick.
     * @param  {Component} SubpageComponent
     * @param  {Object}    pageProps
     */
    pushPage = (SubpageComponent, pageProps) => {
      const { location: { pathname, search }, history } = this.props;
      const nextStack = this.pagesStack.slice();
      nextStack.push({ SubpageComponent, pageProps });
      this.pagesStack = nextStack;

      // Add a new entry into history to handle the back button and
      // prevent homepage from re-rendering and dispatching LOAD_SEARCH_FORM, which resets the redux to
      // initial state
      history.push(`${pathname}${search}`);
      this.forceUpdate();
    }

    /**
     * Remove latest page from the stack and hide it on next tick.
     * @return {Boolean} true if some page removed
     */
    popPage = (event) => {
      const nextStack = this.pagesStack.slice();
      const removedElement = nextStack.pop();
      this.pagesStack = nextStack;

      // If event comes from browser this prevents an infinite loop
      // goBack only used when tapping on the back button nav
      if (!event) {
        this.props.history.goBack();
      }

      this.forceUpdate();
      return !!removedElement;
    }

    /**
     * Remove latest page from stack and hide it on next tick. Also
     * remove all other pages from the stack. Pages stack will be empty
     * after this operation.
     * @return {Boolean} true if some page removed
     */
    removeAll() {
      const isSomeRemoved = this.pagesStack.length > 0;
      this.pagesStack = [];
      this.forceUpdate();
      return isSomeRemoved;
    }

    render() {
      const { pagesStack } = this;
      const { children, ...props } = this.props;

      return (
        React.createElement(renderer, {
          ...rendererProps,
          pagesStack,
          subpagesManager: this
        }, (
          <PageComponent {...props} subpages={this}>
            {children}
          </PageComponent>
        ))
      );
    }
  }

  // TODO: move to Flow types
  // SubpageManager.propTypes = {
  //   children: PropTypes.node,
  //   clearedAt: PropTypes.number
  // };

  SubpageManager.childContextTypes = {
    subpages: PropTypes.object
  };

  const mapStateToProps = state => ({ clearedAt: state.subpages.clearedAt });

  return compose(
    connect(mapStateToProps),
    withRouter
  )(SubpageManager);
};

/**
 * Shorthand to get latest in components tree subpages manager from
 * React Context. It provide `subpages` prop to given `PageComponent`
 * that is the instance of latest subpages manager.
 * @param  {Component} PageComponent
 * @return {Function} the HOC that could be used in `compose`
 */
export const connectSubpages = () => (PageComponent) => {
  const SubpageManagerGetter = (props, context) => (
    <PageComponent {...props} subpages={context.subpages} />
  );
  SubpageManagerGetter.contextTypes = {
    subpages: PropTypes.object
  };
  return SubpageManagerGetter;
};
