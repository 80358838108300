import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import { trips } from 'mz-sdk';
import { parseUrlParams, showErrorNotification, normalizeErrorMessage } from 'app/sagas/utils';
import { getActiveCurrency } from 'app/sagas/selectors';
import {
  startUpdateRequest,
  endUpdateRequest,
  updateTrip
} from 'app/actions/book-trip';
import objectToQueryString from 'mz-utils/objectToQueryString';
import restoreOriginalRideDuration from './restoreOriginalRideDuration';

/**
 * This saga performs a research to change the
 * ride duration (hours number)
 * @param  {object}  action with mode string as payload
 */
export default function* changeRideDuration(action) {
  const hoursNumber = action.payload;
  yield put(startUpdateRequest());

  const urlParams = yield call(parseUrlParams);
  const { trip_id, session_id } = urlParams;
  const currency = yield select(getActiveCurrency);

  const params = _.omitBy({
    hourly_booking_duration: hoursNumber,
    currency
  }, key => !key);

  try {
    const updatedTrip = yield call(trips.research, { trip_id, session_id, params });
    if (!updatedTrip) throw new Error();

    yield put(endUpdateRequest());

    if (updatedTrip) {
      const updatedTripUrlParams = {
        ...urlParams,
        session_id: updatedTrip.searchId,
        trip_id: updatedTrip.id
      };

      const newBookTripUrl = `/book${objectToQueryString(updatedTripUrlParams)}`;
      yield put(updateTrip({ newBookTripUrl, updatedTrip }));
    }
  } catch (error) {
    const message = error.response && normalizeErrorMessage(error.response);
    const notification = message
      ? { error: { message } }
      : { messageId: 'SEARCH_RESULTS.NO_RESULTS_MESSAGE' };
    yield call(showErrorNotification, notification);
    yield put(endUpdateRequest());
    yield call(restoreOriginalRideDuration);
  }
}
