import { call, put } from 'redux-saga/effects';
import { showErrorNotification, ThirdPartyError } from 'app/sagas/utils';
import authorize3DSCard from './authorize3DSCard';
import { setTripError } from 'app/actions/book-trip';
import { THREEDS_REQUIRED_ERROR_CODE } from 'app/constants';


/**
 * Returns true if a trip requries 3DS processing for a given card.
 * @return {bool}
 */
const is3DSRequired = (card, price = {}) => {
  if (!card.three_d_secure) return false;

  return card.three_d_secure === 'required' ||
    (card.three_d_secure === 'optional' && price.forceThreeDSecure);
};

/**
 * Returns a Stripe source object.
 * @param  {Object} card Credit card information
 * @param  {string} card.name Credit card holder name
 * @return {Object} An object containig Stripe source information.
 */
export default function* createStripeSource(stripe, user, price) {
  const { error, source } = yield call(stripe.createSource, {
    type: 'card',
    owner: user
  });
  if (error) {
    const e = new ThirdPartyError(error.message, error.code, error.type);
    yield call(showErrorNotification, { error: e });
    return null;
  }

  if (price && !is3DSRequired(source.card, price) && price.forceThreeDSecure) {
    yield put(setTripError({
      errorCode: THREEDS_REQUIRED_ERROR_CODE,
      errorPopup: true,
      errorProps: { cardBrand: source.card.brand }
    }));

    return null;
  } else if (price && is3DSRequired(source.card, price)) {
    const trdsResult = yield call(authorize3DSCard, stripe, { card: source.id }, price);
    return trdsResult;
  }

  return source;
}
