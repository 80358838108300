import {
  GET_RESERVATIONS,
  GET_PAST_RESERVATIONS,
  SET_RESERVATIONS_FILTER
} from 'app/constants';

import { fork } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga';
import getReservations from './getReservations';
import getPastReservations from './getPastReservations';
import filterReservations from './filterReservations'


export default function* watchReservations() {
  yield fork(takeLatest, GET_RESERVATIONS, getReservations);
  yield fork(takeLatest, GET_PAST_RESERVATIONS, getPastReservations);
  yield fork(takeLatest, SET_RESERVATIONS_FILTER, filterReservations);
}
