import { GET_USER_SAGA_HOOK } from 'app/constants';
import { select, call } from 'redux-saga/effects';
import { isTravelAgent, getUser } from 'app/sagas/selectors';
import { doGetTravelAgent } from '../watchTravelAgentTool/getTravelAgentProfile';
import { hook } from 'app/utils/hook';
import _ from 'lodash';
import posthog from 'posthog-js';


export default function* getUserData() {
  const user = yield select(getUser);
  const isTA = yield select(isTravelAgent);

  if (_.isEmpty(user)) {
    return;
  }
  if (isTA) {
    yield call(doGetTravelAgent);
  } else {
    posthog.identify(user.id, {
      user_email: user.email,
      is_user_travel_agent: false,
      user_signup_up_date: user.date_joined,
      user_default_currency: user.currency,
      user_default_language: user.language,
    });
  }

  yield hook(GET_USER_SAGA_HOOK);
}
