import searchFormConfig from 'app/forms/search-form';
import { formConfig as bookTripFormConfig } from 'app/pages/BookTripPage/builder';
import { actionTypes } from 'redux-form';
import { LOCATION_CHANGE, getLocaleFreePathname } from 'app/history';
import { isInvalid } from 'app/utils/types';
import {
  SET_USER,
  GRATUITY_FIELD_NAME,
  AUTOCOMPLETE_TEXTING,
  AUTOCOMPLETE_RECEIVED,
  SELECTED_CUSTOMER_PROFILE,
  EMAIL_FIELD_NAME,
  DEPARTING_FLIGHT_FIELD_NAME,
  RETURNING_FLIGHT_FIELD_NAME
} from 'app/constants';
import _ from 'lodash';


export const isLocationChange = (action) => (
  action.type === LOCATION_CHANGE &&
  (action.payload.action === 'PUSH' ||
  action.payload.action === 'POP')
);

export const isEnterHomepage = (action) => {
  if (!isLocationChange(action)) return false
  return getLocaleFreePathname(action.payload.location.pathname) === '/'
};

export const isEnterSearchResults = (action) => (
  isLocationChange(action) &&
  getLocaleFreePathname(action.payload.location.pathname).startsWith('/search')
);

export const isEnterBookTrip = (action) => (
  isLocationChange(action) &&
  getLocaleFreePathname(action.payload.location.pathname).startsWith('/book')
);

export const isEnterConfirmation = (action) => (
  isLocationChange(action) &&
  getLocaleFreePathname(action.payload.location.pathname).startsWith('/confirmation')
);

export const isLeaveSearchResults = (action) => (
  isLocationChange(action) &&
  !getLocaleFreePathname(action.payload.location.pathname).startsWith('/search')
);

export const isLeaveTrackingPage = (action) => (
  isLocationChange(action) &&
  !getLocaleFreePathname(action.payload.location.pathname).startsWith('/tracking')
);

export const isSearchFormInitialize = (action) => (
  action.type === actionTypes.INITIALIZE &&
  action.meta.form === searchFormConfig.form
);

export const isSearchFormChange = (action) => (
  action.type === actionTypes.CHANGE &&
  action.meta.form === searchFormConfig.form
);

export const isBookTripGratuityChange = (action) => {
  return (
    action.type === actionTypes.CHANGE &&
    action.meta.form === bookTripFormConfig.form &&
    action.meta.field === GRATUITY_FIELD_NAME
  );
};

export const isBookTripSelectCustomer = (action) => {
  return (
    action.type === actionTypes.CHANGE &&
    action.meta.form === bookTripFormConfig.form &&
    action.meta.field === SELECTED_CUSTOMER_PROFILE
  );
};

export const isChangeBookingMode = (action) => {
  return (
    action.type === actionTypes.CHANGE &&
    action.meta.form === searchFormConfig.form &&
    action.meta.field === 'booking_mode'
  );
};

export const isBookTripSubmitError = (action) => {
  return (
    action.type === actionTypes.SET_SUBMIT_FAILED &&
    action.meta.form === bookTripFormConfig.form
  );
};

export const isAutocompleteTextingOrLoaded = (action) => (
  action.type === AUTOCOMPLETE_TEXTING ||
  action.type === AUTOCOMPLETE_RECEIVED
);

export const isFlightValidation = (action) => {
  return (
    action &&
    action.type === actionTypes.CHANGE &&
    action.meta && (
      action.meta.field === DEPARTING_FLIGHT_FIELD_NAME ||
      action.meta.field === RETURNING_FLIGHT_FIELD_NAME
    )
  );
};

export const isUserEmailBlur = (action) => {
  return (
    action.type === actionTypes.BLUR &&
    action.meta.form === bookTripFormConfig.form &&
    action.meta.field === EMAIL_FIELD_NAME &&
    !isInvalid(action.payload, 'email')
  );
};

export const isUserLoginSuccesful = (action) => (
  action.type === SET_USER &&
  !_.isEmpty(action.payload)
);

export const isPickupChange = (action) => {
  return (
    action.type === actionTypes.CHANGE &&
    action.meta.form === searchFormConfig.form &&
    action.meta.field === 'pickup_datetime'
  );
};
