import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import queryStringToObject from 'mz-utils/queryStringToObject';
import { withRouter } from 'react-router';
import { stopMeasuring } from 'app/utils/stats';
import { Layout } from 'app/components/base/Layout';
import { getDomainConfig } from 'app/utils/browser';
import { isMobileBrowser } from 'mz-ui-kit/utils';
import { TRACK_INIT_PAGE_LOAD } from 'app/constants';
import GlobalNotification from 'app/components/common/GlobalNotification';
import AppContent from './AppContent';
import config from 'config';
import { isUserDataReady, getLoggedIn } from 'app/sagas/selectors';
import { DenyContainer } from './elements';
import animationStyle from 'app/hocs/subpageManagable/animation/style.css';
import posthog from 'posthog-js';

const isPublicPath = () => {
  const publicPaths = ['/tracking', '/customer-feedback'];
  return publicPaths.some((path) => window.location.pathname.includes(path));
};

/**
 * Main layout of the app. It creates generatl layout with header,
 * footer and expanded content (hold footer in bottom of the page).
 * It also connects to the store to get `i18n` values.
 */
export class AppPage extends React.Component {
  showHeader = true;
  pathnameChanged = false;

  UNSAFE_componentWillMount() {
    const query = queryStringToObject(this.props.location.search);
    if (query.header === '0') {
      this.showHeader = false;
    }
  }

  componentDidMount() {
    stopMeasuring(TRACK_INIT_PAGE_LOAD);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { location } = this.props;
    const { location: nextLocation } = nextProps;

    const pathChanged = nextLocation.pathname !== location.pathname;

    if (pathChanged) {
      this.pathnameChanged = true;
    }
  }

  componentDidUpdate() {
    if (this.pathnameChanged) {
      this.pathnameChanged = false;
      this.scrollToTop();
    }
  }

  scrollToTop() {
    const { mobile } = this.props;
    setTimeout(() => {
      if (mobile) {
        document.getElementsByClassName(
          animationStyle.wrapper
        )[0].scrollTop = 0;
      } else {
        window.scrollTo(0, 0);
      }
    }, 0);
  }

  renderDenyPage() {
    return (
      <DenyContainer>
        Looks like you&apos;re not authorized to use this website
      </DenyContainer>
    );
  }

  render() {
    const {
      children,
      locale,
      rtl,
      mobile,
      loading,
      userDataReady,
      userLoggedIn,
    } = this.props;

    if (config.DENY_GUEST_USERS) {
      if (!userDataReady) return null;
      if (!userLoggedIn && !isPublicPath()) {
        return this.renderDenyPage();
      }
    }

    const domainClass = window.location.hostname.replace(/\W/g, '-');
    const modifiers = {
      rtl,
      loading,
      mobile,
      desktop: !mobile,
      [domainClass]: true,
      [`locale-${locale}`]: true,
    };

    const domainConfig = getDomainConfig();
    if (domainConfig.ref) {
      modifiers[domainConfig.ref] = true;
    }

    return (
      <Layout className={classnames(modifiers)} direction="column" nowrap>
        <GlobalNotification />
        <AppContent content={children} header={this.showHeader} />
      </Layout>
    );
  }
}

// TODO: move to Flow types
// AppPage.propTypes = {
//   children: PropTypes.element.isRequired,
//   locale: PropTypes.string.isRequired,
//   messages: PropTypes.object.isRequired,
//   rtl: PropTypes.bool.isRequired,
//   loading: PropTypes.bool.isRequired,
//   mobile: PropTypes.bool.isRequired,
//   location: PropTypes.object.isRequired
// };

const mapStateToProps = (state) => {
  const userLoggedIn = getLoggedIn(state);
  posthog.register({ is_user_logged_in: userLoggedIn });
  return {
    ...state.app,
    mobile: isMobileBrowser(),
    userDataReady: isUserDataReady(state),
    userLoggedIn,
  };
};

export default compose(connect(mapStateToProps), withRouter)(AppPage);
