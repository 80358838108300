import { call, put, select } from 'redux-saga/effects';
import { setUser, setEphemeralToken } from 'app/actions/user-session';
import { auth } from 'mz-sdk';
import { getHistory } from 'app/history';
import { getLocation } from '../selectors';
import { redirectBack, showErrorNotification } from 'app/sagas/utils';
import { getEphemeralToken } from 'app/sagas/selectors';
import getUserData from './getUserData';
import doSetLocaleCurrency from './doSetLocaleCurrency';
import { REDIRECT_AFTER_LOGIN_HOOK } from 'app/constants';
import { hook } from 'app/utils/hook';
import config from 'config';
import posthog from 'posthog-js';

export default function* doLogin(action, redirect = true, skipLogin = false) {
  try {
    posthog.capture('Old Flow: User Successfully Signed In', {
      sign_in_method: 'email',
    });
    const history = yield select(getHistory);

    if (!skipLogin) {
      const { data, ephemeral_token } = yield call(auth.login, action.payload);

      if (ephemeral_token) {
        yield put(setEphemeralToken(ephemeral_token));
        yield select(getEphemeralToken);
        yield call(history.push, '/user/login/mfa');
        return;
      }
      yield put(setUser(data));
      yield call(getUserData);
    }

    // On login, we identify the user with the Chameleon api
    if (typeof chmln !== 'undefined') {
      // eslint-disable-line
      chmln.identify(window.mozioUser); // eslint-disable-line
    }

    // Redirect user to the previous point
    // or to the point requested by `next` URL parameter
    const { query } = yield select(getLocation);
    const nextUrl = query.next;
    const returnPath = query.returnPath
      ? decodeURIComponent(query.returnPath)
      : null;

    if (nextUrl) {
      if (nextUrl.match(/^https?:\/\//)) {
        window.location.href = nextUrl; // eslint-disable-line require-atomic-updates
      } else {
        history.push(nextUrl);
      }
    } else if (redirect) {
      yield call(redirectBack);
    } else {
      const redirectUrl = hook({
        name: REDIRECT_AFTER_LOGIN_HOOK,
        defaultHandler: () => '/',
      });
      window.location.href = redirectUrl;
    }

    if (returnPath && !returnPath.match(/^https?:\/\//)) {
      window.location.href = returnPath;
    }

    yield call(doSetLocaleCurrency);
  } catch (error) {
    yield put(setUser());
    yield call(showErrorNotification, {
      error,
      titleId: 'USER.LOGIN_ERROR_TITLE',
    });

    if (
      config.PASSWORD_RESET_ABILITY &&
      error.errorCode &&
      error.errorCode === 'user_password_expired'
    ) {
      const history = yield select(getHistory);
      history.push('/user/forgot-password');
    }
  }
}
