import { call } from 'redux-saga/effects';
import { showErrorNotification, getAllPages } from 'app/sagas/utils';
import { travelagent } from 'mz-sdk';
import { setCustomerProfileList } from 'app/actions/travel-agent';


export default function* getCustomerProfileList(action) {
  try {
    const options = {
      fullDataAction: setCustomerProfileList
    };
    yield call(getAllPages, travelagent.getCustomerProfiles, {}, options);
    yield call(action.resolvePromise);
  } catch (error) {
    yield call(showErrorNotification, { error });
    yield call(action.rejectPromise);
  }
}
