import React from 'react';
import getIcon from 'app/utils/icons';
import Dropdown from 'app/components/base/Dropdown';
import StandardInput from 'app/components/base/StandardInput';
import classnames from 'classnames';
import OptionList from 'app/components/base/OptionList';
import style from './style.css';
import builder from './builder';
import decoupledBuilder from './decoupledBuilder';

class DesktopAutocompleteInput extends React.Component {
  handleBlur = (event) => {
    event.stopPropagation();
    this.props.input.onBlur(this.props.input.value);
  }

  render() {
    const {
      options,
      className,
      frozen,
      focused,
      onInputChange,
      inputValue,
      template = option => option.display,
      input,
      icon,
      ...props
    } = this.props;
    const inputProps = {
      ...input,
      autoComplete: 'off',
      autoCorrect: 'off',
      autoCapitalize: 'off',
      spellCheck: false,
      onBlur: this.handleBlur,
      onChange: onInputChange,
      value: inputValue
    };

    const inputIcon = getIcon(input.value) || icon;

    const origin = (
      <StandardInput
        {...props}
        icon={inputIcon}
        input={inputProps}
        className={classnames(className, style.input)}
      />
    );

    return (
      <Dropdown
        align="start stretch"
        noarrow
        dumb
        origin={origin}
        open={!!props.meta.active && options.length > 0 && !frozen}
      >
        <OptionList
          focused={focused}
          options={options}
          value={input.value}
          template={template}
          onChange={input.onChange}
          wrap
        />
      </Dropdown>
    );
  }
}

// TODO: move to Flow types
// DesktopAutocompleteInput.propTypes = {
//   input: PropTypes.object.isRequired,
//   icon: PropTypes.any,
//   meta: PropTypes.object.isRequired,
//   options: PropTypes.array.isRequired,
//   className: PropTypes.string,
//   frozen: PropTypes.bool,
//   focused: PropTypes.number.isRequired,
//   onInputChange: PropTypes.func.isRequired,
//   inputValue: PropTypes.string,
//   template: PropTypes.func.isRequired
// };

export const DecoupledAutocompleteInput = decoupledBuilder(
  DesktopAutocompleteInput
);
export default builder(DesktopAutocompleteInput);
