const CONTACTS = 'contacts';

export default [
  {
    'name': 'EMAIL_SITE_NAME',
    'type': 'string',
    'default': 'Mozio Inc.',
    'description': 'Site name',
    'category': CONTACTS
  },
  {
    'name': 'SUPPORT_PHONE_NUMBERS',
    'type': 'pairs',
    'default': [
      ['default', '+1 (855) 980 5669'],
      ['en_US', '+1 (855) 980 5669'],
      ['en_GB', '+44 (1456) 256 055'],
      ['fr', '+33 (184) 390 122'],
      ['pt_BR', '+351 (308) 806 068'],
      ['nl', '+31 (85) 888 3731']
    ],
    'description': 'Support phone number by active language. ' +
      '<key: any> – showed for any lang; ' +
      '<key: default> – showed when sopport phone not exists; ' +
      '<key: en_US> – only US phone number',
    'category': CONTACTS
  },
  {
    'name': 'SUPPORT_EMAIL',
    'type': 'string',
    'default': 'info@mozio.com',
    'description': 'Support email',
    'category': CONTACTS
  },
  {
    'name': 'SUPPORT_FORM_LINK',
    'type': 'string',
    'default': 'https://mozio.zendesk.com/hc/en-us/requests/new',
    'description': 'Support form link',
    'category': CONTACTS
  },
  {
    'name': 'ZENDESK_HOST',
    'type': 'string',
    'default': 'mozio.zendesk.com',
    'description': 'ZenDesk chat host',
    'category': CONTACTS
  },
  {
    'name': 'CUSTOM_ROOT_REDIRECT_URL',
    'type': 'string',
    'default': '',
    'description': 'Custom URL for redirection after trip cancellation',
    'category': CONTACTS
  }
];
