import React from 'react';
import CN from 'classnames';
import config from 'config'
import { Layout } from 'app/components/base/Layout';
import TagLabel from './TagLabel';
import style from './style.css';


// TODO: ClientPreferred does not come within tabs, but in a seaprate prop. Adapt component.
const Tags = ({
  tags,
  mobile,
  details,
  className,
  direction,
  showText
}) => {
  const displayed = tags.slice(0, 3).sort((tag1, tag2) => {
    return tag1.priority - tag2.priority;
  }).filter((tag) => {
    if (config.SHOW_ALL_INCLUSIVE_TAG) return true
    return tag.tag_type !== 'all_inclusive'
  }).filter((tag) => {
    if (!config.IGNORE_SEARCH_DISCOUNTS) return true
    return tag.tag_type !== 'discount'
  });

  if (displayed.length === 0) return null;

  const modifiers = {
    [style['tags--mobile']]: mobile,
    [style['tags--details']]: details
  };

  return (
    <Layout
      direction={direction}
      className={CN(style.tags, className, modifiers)}
      align="start center"
      nowrap
    >
      {displayed.map((tag, index) => {
        return (
          <TagLabel key={index} tag={tag} inline={!mobile} showText={showText} />
        );
      })}
    </Layout>
  );
};

// TODO: move to Flow types
// Tags.propTypes = {
//   mobile: PropTypes.bool,
//   details: PropTypes.bool,
//   tags: PropTypes.array.isRequired,
//   direction: PropTypes.string,
//   showText: PropTypes.bool,
//   className: PropTypes.string
// };

Tags.defaultProps = {
  showText: true
};

export default Tags;
