import { getBookTripForm } from 'app/sagas/selectors';
import { TRIP_PICKUP_TIMES_ORIGIN_TZ_FIELD_NAME } from 'app/constants';


/**
 * Returns object containing departure and return pickup times
 * (if round trip) in original timezone.
 * These values used to get original time zone and set offset when making research
 * after pickup time changed
 */
const getTripPickupTimesOriginTZ = state =>
  getBookTripForm(state)[TRIP_PICKUP_TIMES_ORIGIN_TZ_FIELD_NAME];

export default getTripPickupTimesOriginTZ;
