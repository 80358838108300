import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'translations';
import { Layout } from 'app/components/base/Layout';
import builder from './builder';

import style from './style.css';


// Constants
const messages = {
  bookingNumber: { id: 'CONFIRMATION_PAGE.CONFIRMATION_NUMBER' },
  partnerConfirmationNumber: { id: 'CONFIRMATION_PAGE.PARTNER_CONFIRMATION_NUMBER' },
};

/**
 * Component to show reservation number
 * @param {String} direction
 * @param {String} confirmationNumber
 * @param {?String} partnerTrackingId
 */
export const ReservationConfirmationNumber = ({
  direction,
  confirmationNumber,
  partnerTrackingId,
}) => {
  const modifiers = {
    [style[`block--${direction}`]]: true
  };

  return (
    <Layout
      className={classnames(style.block, modifiers)}
      direction="row"
      size="noshrink"
      align="start end"
      nowrap
    >
      <Layout direction="column" size="grow" nowrap>
        <div className={style.header}>
          <FormattedMessage {...messages.bookingNumber} />
        </div>
        <div className={style.number}>{confirmationNumber}</div>
      </Layout>
      {partnerTrackingId && (
        <Layout align="end end" direction="column" size="grow" nowrap>
          <div className={style.header}>
            <FormattedMessage {...messages.partnerConfirmationNumber} />
          </div>
          <div className={style.partnerConfirmationNumber}>{partnerTrackingId}</div>
        </Layout>
      )}

    </Layout>
  );
};

export default builder(ReservationConfirmationNumber);
