import moment from 'moment'
import { select } from 'redux-saga/effects';
import { user } from 'mz-sdk';
import { getReservationFilters } from 'app/sagas/selectors'
import {
  FILTER_FIRST_NAME,
  FILTER_LAST_NAME,
  FILTER_CUSTOMER_EMAIL,
  FILTER_AGENT_EMAIL,
  FILTER_RESERVATION_ID,
  FILTER_BOOKING_DATE,
  FILTER_SERVICE_DATE,
} from 'app/constants'

function buildDateRangeFilter(name, range) {
  const base = { [`${name}__from`]: moment(range[0]).format() }
  return range.length > 1
    ? { ...base, [`${name}__to`]: moment(range[1]).format() }
    : base
}

const QUERY_BUILDER = new Map([
  [FILTER_FIRST_NAME, (data) => ({ customer_first_name: data.query })],
  [FILTER_LAST_NAME, (data) => ({ customer_last_name: data.query })],
  [FILTER_CUSTOMER_EMAIL, (data) => ({ customer_email: data.query })],
  [FILTER_AGENT_EMAIL, (data) => ({ extra_email: data.query })],
  [FILTER_RESERVATION_ID, (data) => ({ confirmation_number: data.query })],
  [
    FILTER_BOOKING_DATE,
    (date) => buildDateRangeFilter('timestamp', date.range),
  ],
  [
    FILTER_SERVICE_DATE,
    (date) => buildDateRangeFilter('departure', date.range),
  ],
])

function buildFilterQuery(filters) {
  if (!filters) return null
  return Array.from(filters).reduce((acc, [title, filterParams]) => {
    const builder = QUERY_BUILDER.get(title)
    if (!builder) return acc
    return { ...acc, ...builder(filterParams) }
  }, {})
}

export default function* getFilteredReservations(params) {
  const filtersMap = yield select(getReservationFilters)
  const filterQuery = buildFilterQuery(filtersMap)

  const result = yield user.getReservations({
    ...params,
    ...filterQuery,
  })

  return result
}
