import React, { Component } from 'react';
import { Layout } from 'app/components/base/Layout';
import StandardInput from 'app/components/base/StandardInput';
import Controllers from './Controllers';
import classnames from 'classnames';

import style from './style.css';

class NumberInput extends Component {
  get input() {
    return (
      <StandardInput
        {...this.props}
        type="text"
        className={classnames(
          style.input,
          style.value,
          { [style.readonly]: this.props.readOnly }
        )}
      />
    );
  }

  render() {
    const { className, showValue } = this.props;
    return (
      <Layout
        align="space-between center"
        className={classnames(style.field, className)}
        nowrap
      >
        {showValue && this.input}
        <Controllers {...this.props} />
      </Layout>
    );
  }
}

// TODO: move to Flow types
// NumberInput.propTypes = {
//   className: PropTypes.string,
//   showValue: PropTypes.bool,
//   readOnly: PropTypes.bool
// };

NumberInput.defaultProps = {
  showValue: true,
  readOnly: true
};

export default NumberInput;
