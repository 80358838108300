const HERTZ = 'hertz';

export default [
  {
    name: 'HERTZ_APP',
    type: 'bool',
    default: false,
    description: 'Enable/disable Hertz App pages for the whitelabel',
    category: HERTZ
  },
  {
    name: 'HERTZ_AIRLINE_SUBTITLE',
    type: 'bool',
    default: false,
    description: 'Enable/disable HP subtitle for airlines "And the journy continues..."',
    category: HERTZ
  },
  {
    name: 'HERTZ_DESTINATIONS_AMOUNT',
    type: 'string',
    default: '2000',
    description: 'Amount of destinatios displayed on HP',
    category: HERTZ
  },
  {
    name: 'HERTZ_COMPANY_NAME',
    type: 'string',
    default: 'Hertz',
    description: 'Name of the company',
    category: HERTZ
  },
  {
    name: 'HERTZ_PRODUCT_NAME',
    type: 'string',
    default: 'Hertz DriveU',
    description: 'Name of the product',
    category: HERTZ
  },
  {
    name: 'HERTZ_FEATURES_IMAGE',
    type: 'image',
    default: '',
    description: 'Custom cover image for the features list',
    category: HERTZ
  },
  {
    name: 'HERTZ_GREEN_MODS',
    type: 'bool',
    default: false,
    description: 'Enable/disable green modification',
    category: HERTZ
  },
  {
    name: 'HERTZ_PARTNER_NAME',
    type: 'string',
    default: '',
    description: 'Name of Hertz partner',
    category: HERTZ
  },
  {
    name: 'HERTZ_PARTNER_LOGO',
    type: 'image',
    default: '',
    description: 'Logo of Hertz partner',
    category: HERTZ
  },
  {
    name: 'HERTZ_PARTNER_BOX_BACKGROUND',
    type: 'image',
    default: '',
    description: 'Custom cover image for the partner box',
    category: HERTZ
  },
  {
    name: 'HERTZ_PARTNER_BOX_TEXT_BACKGROUND',
    type: 'color',
    default: '#004078',
    description: 'Custom background color of a partner box text',
    category: HERTZ
  },
  {
    name: 'HERTZ_PARTNER_DESCRIPTION_MESSAGE_ID',
    type: 'string',
    default: 'PARTNER_DESCRIPTION',
    description: 'Partner description message ID',
    category: HERTZ
  },
]
