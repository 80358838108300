import { fork } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga';
import handleScheduleResearch from './handleScheduleResearch';
import { VIEW_EARLIER_TIMES, VIEW_LATER_TIMES } from 'app/constants';


export const actionPattern = action => {
  return (action.type === VIEW_EARLIER_TIMES || action.type === VIEW_LATER_TIMES);
};

export default function* watchResearchSchedule() {
  yield fork(takeLatest, actionPattern, handleScheduleResearch);
}
