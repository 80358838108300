import memoize from 'mz-utils/memoize'
import queryStringToObject from 'mz-utils/queryStringToObject'
import objectToQueryString from 'mz-utils/objectToQueryString'
import { createBrowserHistory } from 'history'
import { findLanguage } from 'mz-intl/core/languages'
import config from 'config';


// Constants
export const LOCALE_REGEX = /^\/?([a-z]{2}|[a-z]{2}-[a-z]{2})(\/|$)/i
export const LOCATION_CHANGE = '@@history/LOCATION_CHANGE'

// A list of URL parameters that have to be presented on all pages if
// they will be added to URL at least once
const PROPAGATE_PARAMS = [
  'ta',
  'wl',
  'ref',
  'gid',
  'guid',
  'partner_tracking_id',
  'utm_source',
  'utm_campaign',
  'utm_medium',
  'utm_content',
  'campaign',
  'branch',
  'staging_wl',
  'staging_api',
  'testing_api',
  'coupon_code',
  'dial_code',
  'go_corp_id',
  '_translate',
  'split_tickets',
  'first_name',
  'last_name',
  'email',
  'phone_number'
]

/**
 * Append currently active locale to the pathname if it is not already povided
 * @param  {string} path
 * @param  {History} history
 * @return {string}
 */
function appendCurrentLocale(path, history) {
  if (path.match(LOCALE_REGEX)) {
    return path
  }

  const currLocale = history.location.pathname.match(LOCALE_REGEX)
  if (currLocale) {
    return `/${currLocale[1]}/${path}`.replace(/\/{2,}/, '/')
  }

  return path
}

/**
 * Append to the given path or search string all the parameters from the
 * current search that should be propagated from page to page no matter what
 * @param  {string} searchOrPath
 * @param  {History} history
 * @return {string}
 */
export function appendPropagatedParameters(searchOrPath) {
  const history = getHistory()
  const questionMarkPos = searchOrPath.indexOf('?')
  const searchStartPos = questionMarkPos >= 0 ? questionMarkPos : searchOrPath.length
  const newQuery = questionMarkPos >= 0 ? queryStringToObject(searchOrPath) : {}
  const currQuery = queryStringToObject(history.location.search)

  PROPAGATE_PARAMS.forEach(param => {
    if (!newQuery[param] && currQuery[param]) {
      newQuery[param] = currQuery[param]
    }
  })

  const newSearchString = objectToQueryString(newQuery)
  return searchOrPath.substr(0, searchStartPos) + newSearchString
}

/**
 * Returns a new history location changer function that ensure that the
 * new location contains locale in the pathname to avoid unnecessary
 * locale redirections
 * @param  {Function} changer Original location change func (push, replace)
 * @param  {History} history
 * @return {Function}
 */
function createLocaleTolerantHistoryChanger(changer, history) {
  return function customChanger(path, state) {
    if (typeof path === 'string') {
      const newPath = appendPropagatedParameters(appendCurrentLocale(path, history))
      return changer(newPath, state)
    }

    return changer({
      ...path,
      search: appendPropagatedParameters(path?.search || ''),
      pathname: appendCurrentLocale(path?.pathname || '', history)
    }, state)
  }
}

/**
 * This function subscribes for changes in the history and dispatch
 * LOCATION_CHANGE event to the store
 * @param  {History} history
 * @param  {Store} store
 */
export function watchHistoryChanges(history, store) {
  const handleLocationChange = (location, action, isFirstRendering = false) => {
    store.dispatch({
      type: LOCATION_CHANGE,
      payload: {
        location,
        action,
        isFirstRendering
      }
    })
  }

  // Listen to history changes
  history.listen(handleLocationChange)

  // Dispatch a location change action for the initial location.
  // This makes it backward-compatible with react-router-redux.
  // But, we add `isFirstRendering` to `true` to prevent double-rendering.
  handleLocationChange(history.location, history.action, true)
}

/**
 * That function push a new item to history with a new locale passed
 * in the first argument
 * @param {string} localeString
 */
export function setLocale(localeString) {
  const history = getHistory()
  const localeFreePath = getLocaleFreePathname(history.location.pathname)
  const newLocaleValue = findLanguage(localeString).value
  const currentLocaleValue = getCurrentLocale()

  if (currentLocaleValue !== newLocaleValue) {
    history.replace({
      ...history.location,
      pathname: `/${newLocaleValue}/${localeFreePath}`.replace(/\/{2,}/, '/')
    })
  }
}

/**
 * Get active locale in the URL format, e.g. "en-us"
 * @return {string}
 */
export function getCurrentLocale() {
  const history = getHistory()
  const currLocale = history.location.pathname.match(LOCALE_REGEX)
  return findLanguage((currLocale && currLocale[1]) || '').value
}

/**
 * Remove locale prefix from given pathname and returns the result
 * @param  {string} pathname
 * @return {string}
 */
export function getLocaleFreePathname(pathname) {
  const currLocale = pathname.match(LOCALE_REGEX)
  if (!currLocale) return pathname
  return `/${pathname.substr(currLocale[0].length)}`
}

/**
 * Creates an enhanced history object that have the following additional things:
 *   1. Set BASENAME from confi
 *   2. Replace `push` and `replace` functions to enhanced versions that keep
 *      currently active locale in the path if locale is not defined in the url
 * @return {History}
 */
export const createHistory = memoize(() => {
  const history = createBrowserHistory({ basename: config.BASENAME })
  history.push = createLocaleTolerantHistoryChanger(history.push, history)
  history.replace = createLocaleTolerantHistoryChanger(history.replace, history)
  return history
});

export const getHistory = createHistory;
