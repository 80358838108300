import { put, select, call } from 'redux-saga/effects';
import { change } from 'redux-form';
import {
  getBookingFeeFieldValue,
  getBookingFeeStatus
} from 'app/sagas/selectors';
import getPrice from 'app/sagas/watchBookTrip/watchUpdatePrice/getPrice';
import {
  BOOK_TRIP_FORM,
  BOOKING_FEE_STATUS
} from 'app/constants';


export default function* saveBookingFee() {
  const bookingFee = yield select(getBookingFeeFieldValue);
  const bookingFeeStatus = yield select(getBookingFeeStatus);

  // Reset the updateErrored property and set updating property
  yield put(change(BOOK_TRIP_FORM, BOOKING_FEE_STATUS, {
    ...bookingFeeStatus,
    updating: true,
    showHint: false
  }));

  let newBookingFeeStatus = bookingFeeStatus;

  try {
    // Update price
    const priceParams = {
      booking_fee: bookingFee === '' ? null : bookingFee
    };
    yield call(getPrice, { priceParams });

    newBookingFeeStatus = {
      ...bookingFeeStatus,
      showHint: false,
      savedValue: bookingFee
    };
  } catch (e) {
    newBookingFeeStatus = {
      ...bookingFeeStatus,
      showHint: true
    };
  } finally {
    yield put(change(BOOK_TRIP_FORM, BOOKING_FEE_STATUS, {
      ...newBookingFeeStatus,
      updating: false
    }));
  }
}
