import { fork } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga';
import isRideDurationChanged from './actionPatterns/isRideDurationChanged';
import changeRideDuration from './changeRideDuration';


/**
 * Handling changing hourly ride duration time on BTP
 */
export default function* watchChangeRideDuration() {
  yield fork(takeLatest, isRideDurationChanged, changeRideDuration);
}
