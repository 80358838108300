import React from 'react';
import MobileScreenAnimator from '../animation/MobileScreenAnimator';


/**
 * Renderer for subpages that renders latest subpage in the stack
 * as a whole screen. So, if there is some page in a pages stack,
 * main page will not be rendered (will be rendered only latest page)
 */
// eslint-disable-next-line
export class MobileScreenRenderer extends React.Component {
  render() {
    const {
      pagesStack,
      children
    } = this.props;

    const pageElements = pagesStack.map(
      ({ SubpageComponent, pageProps }, i) => (
        <SubpageComponent key={i} {...pageProps} />
      )
    );

    return (
      <MobileScreenAnimator pages={[children, ...pageElements]} />
    );
  }
}

// TODO: move to Flow types
// MobileScreenRenderer.propTypes = {
//   children: PropTypes.node,
//   pagesStack: PropTypes.array
// };

export default MobileScreenRenderer;
