import { put, select } from 'redux-saga/effects';
import { initialize } from 'redux-form';
import { formConfig } from 'app/pages/BookTripPage/builder';
import { getBookTripForm, getFieldsFromCustomer } from 'app/sagas/selectors';


export default function* updateCustomerInfo(action) {
  if (!action.payload) return;

  const values = yield select(getFieldsFromCustomer, action.payload.id);
  const current = yield select(getBookTripForm);

  yield put(initialize(formConfig.form, {
    ...current,
    ...values
  }));
}
