import isNumber from 'lodash/isNumber';

export const onlyNumbers = (value) => {
  if (!value) {
    return value;
  }
  return value.replace(/[\D]/g, '');
};

export const twoDecimals = (value) => {
  if (!value) {
    return value;
  }
  if (isNumber(value)) {
    return parseFloat(value.toFixed(2));
  }
  const numeric = value.replace(/[^'.' \d]/g, '');
  if (numeric.indexOf('.') > -1) {
    const partials = numeric.split('.');
    return `${partials[0]}.${partials[1] && partials[1].substring(0, 2) || ''}`;
  }
  return numeric;
};
