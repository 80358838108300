import { fork } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import { trackPageView } from './loggly';
import { isLocationChange } from 'app/sagas/patterns';
import google from './google';


export default function* logging() {
  yield fork(takeEvery, isLocationChange, trackPageView);
  yield fork(google);
}
