// @flow
import * as React from 'react';
import { Container } from './elements';


// Types
export type Props = {
  color?: string,
  children: React.Node
};

const InfoLabel = ({ color, children }: Props) => (
  <Container $color={color} onClick={(e) => e.stopPropagation()}>
    {children}
  </Container>
);

export default InfoLabel;
