import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { FormattedMessage } from 'translations';
import { Layout } from 'app/components/base/Layout';
import Icon from 'app/components/base/Icon';
import classnames from 'classnames';

import {
  BOOK_TRIP_FORM,
  DEPARTING_PICKUP_FIELD_NAME,
  RETURNING_PICKUP_FIELD_NAME,
  RETURN_TRIP_FIELD_NAME
} from 'app/constants';
import { DATE_FORMAT, HOUR_FORMAT } from 'app/utils/types';
import moment from 'moment';

import style from './style.css';

const messages = {
  pickupTime: { id: 'BOOK_TRIP.PICKUP_TIME' },
  returnPickupTime: { id: 'BOOK_TRIP.RETURN_PICKUP_TIME' }
};

const PickupTime = ({
  departureDate,
  departureTime,
  returning,
  returningDepartureDate,
  returningDepartureTime
}) => (
  <div className={style.container}>
    <Layout
      nowrap
      direction="column"
      align="center center"
      className={style.section}
    >
      <Layout nowrap align="start center">
        <Icon family="mozio" name="clock" className={style.icon} />
        <FormattedMessage {...messages.pickupTime} />
      </Layout>
      <span className={style.date}> {departureDate} {departureTime} </span>
    </Layout>
    {returning && (
      <Layout
        nowrap
        direction="column"
        align="center center"
        className={classnames(style.section, style.return)}
      >
        <Layout nowrap align="start center">
          <Icon family="mozio" name="clock" className={classnames(style.icon, style.return)} />
          <FormattedMessage {...messages.returnPickupTime} />
        </Layout>
        <span className={style.date}> {returningDepartureDate} {returningDepartureTime} </span>
      </Layout>
    )}
  </div>
);

// TODO: move to Flow types
// PickupTime.propTypes = {
//   departureDate: PropTypes.any,
//   departureTime: PropTypes.any,
//   returning: PropTypes.bool,
//   returningDepartureDate: PropTypes.any,
//   returningDepartureTime: PropTypes.any
// };

const mapStateToProps = (state, { formName = BOOK_TRIP_FORM }) => {
  const selector = formValueSelector(formName);

  const departureDatetime = selector(state, DEPARTING_PICKUP_FIELD_NAME);
  const returnTrip = selector(state, RETURN_TRIP_FIELD_NAME);
  const returningDepartureDatetime = returnTrip && selector(state, RETURNING_PICKUP_FIELD_NAME);

  return {
    departureDate: departureDatetime && moment(departureDatetime).format(DATE_FORMAT),
    departureTime: departureDatetime && moment(departureDatetime).format(HOUR_FORMAT),
    returningDepartureDate: returningDepartureDatetime
      && moment(returningDepartureDatetime).format(DATE_FORMAT),
    returningDepartureTime: returningDepartureDatetime
      && moment(returningDepartureDatetime).format(HOUR_FORMAT),
    returning: !!returnTrip
  };
};

export default connect(mapStateToProps)(PickupTime);
