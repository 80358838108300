import { put, call, select } from 'redux-saga/effects';
import { showErrorNotification, parseUrlParams } from 'app/sagas/utils';
import { setLocale } from 'app/history'
import { setCurrency, criticalError } from 'app/actions/app';
import { getDomainConfig } from 'app/utils/browser';
import { getUser, getActiveCurrency } from 'app/sagas/selectors';
import getGeolocation from 'mz-sdk/services/user/getGeolocation';
import config from 'config';


export default function* doSetLocaleCurrency() {
  try {
    const query = yield call(parseUrlParams);
    const user = yield select(getUser);
    const domainCfg = yield call(getDomainConfig);
    const activeCurrency = yield select(getActiveCurrency);

    // Get currency from country based on the IP
    let countryCurrencyCode = '';
    if (config.AUTODETECT_CURRENCY_BY_IP) {
      const result = yield call(getGeolocation);
      countryCurrencyCode = result && result.currency_code;
    }

    // Set new currncy only if it is different than the active one
    const code = (
      countryCurrencyCode
        || query.currency
        || (user && user.currency)
        || domainCfg.currency
        || ''
    ).toUpperCase().slice(0, 3);

    const newCurrency = code ? code : config.DEFAULT_CURRENCY;
    if (activeCurrency !== newCurrency) {
      yield put(setCurrency({ currency: newCurrency }));
    }

    // Set new lang if it is exists in user's profile and different
    if (user && user.language && !config.TUI_CS_ADJUSTMENTS && !config.NO_USER_DEFAULT_LANGUAGE) {
      yield call(setLocale, user.language);
    }
  } catch (error) {
    yield put(criticalError(error));
    yield call(showErrorNotification, { error });
  }
}
