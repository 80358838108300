import config from 'config';
import { getFullStorySessionURL } from './utils/fullstory'
import * as Sentry from '@sentry/browser';

const ALLOWED_LOGGERS = new Set([
  'react-boundary',
  'mangojuice'
])

/**
 * Return true if given event shouldn't be sent to Sentry at all
 * @return {[type]} [description]
 */
function shouldFilterOut(/*event, hint*/) {
  // TODO: any ignore rules goes here
  return false
}

/**
 * Modify given event by setting a propper event fingerprint so all
 * events by defined rules will be groped in one instead of default
 * grouping by stacktrace, which produce a lot of noise.
 * @param  {Object} event
 * @param  {Object} hint
 */
function adjustEventFingerprint(event, hint) {
  const error = hint.originalException;

  if (error?.name && error.name.match(/ChunkLoadError/i)) {
    event.fingerprint = ['chunk-load-error'];
  } else if (error?.name && error.name.match(/SecurityError/i)) {
    event.fingerprint = ['security-error'];
  }
}

if (process.env.NODE_ENV !== 'development') {
  if (config.SENTRY_URL) {
    Sentry.init({
      dsn: config.SENTRY_URL,
      release: config.VERSION,
      environment: process.env.CONFIG_ENV,
      beforeSend: (event, hint) => {
        if (
          typeof window === 'undefined' ||
          !window.location || !window.navigator ||
          !ALLOWED_LOGGERS.has(event?.tags?.logger) ||
          shouldFilterOut(event, hint)
        ) {
          return null;
        }

        event.extra = event.extra || {}
        event.extra.fullstory = getFullStorySessionURL()
        adjustEventFingerprint(event, hint)
        return event;
      }
    })
  }
}
