import { fork } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga';
import createPaymentAccount from './createPaymentAccount';
import deletePaymentAccount from './deletePaymentAccount';
import {
  TA_ADD_PAYMENT_ACCOUNT,
  TA_DELETE_PAYMENT_ACCOUNT
} from 'app/constants';

export default function* watchCustomerProfile() {
  yield fork(takeLatest, TA_ADD_PAYMENT_ACCOUNT, createPaymentAccount);
  yield fork(takeLatest, TA_DELETE_PAYMENT_ACCOUNT, deletePaymentAccount);
}
