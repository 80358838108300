import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'translations';
import { Layout } from 'app/components/base/Layout';
import LoadingSpinner from 'app/components/base/LoadingSpinner';
import { isPriceEstimated } from 'app/utils/trip';
import config from 'config'

import style from './style.css';


const messages = {
  estimatedPrice: { id: 'SEARCH_RESULTS.ESTIMATED_PRICE' }
};

const TripPrice = ({
  totalWithoutPlatformFee,
  slash,
  mobile,
  priceLabel,
  loading,
  type
}) => {
  const slashedPrice = config.IGNORE_SEARCH_DISCOUNTS ? null : slash
  const totalPrice = config.IGNORE_SEARCH_DISCOUNTS
    ? slash || totalWithoutPlatformFee
    : totalWithoutPlatformFee

  const isEstimated = isPriceEstimated(type);
  const modifiers = {
    [style['price--mobile']]: mobile
  };

  return (
    <Layout
      className={classnames(style.price, modifiers)}
      direction={mobile ? 'column' : 'row'}
      align="end end"
      size="noshrink"
      reverse={mobile}
      nowrap
    >
      {!!slashedPrice && <span className={style.slash}>{slashedPrice}</span>}
      <div className={style.amount} data-testid="trip-price">
        {!loading && <span>{totalPrice}</span>}
        {isEstimated && (
          <div className={style.label}>
            <FormattedMessage {...messages.estimatedPrice} />
          </div>
        )}
        {!!priceLabel && <div className={style.label}>{priceLabel}</div>}
        {loading && <LoadingSpinner size="small" nomargin />}
      </div>
    </Layout>
  );
};

// TODO: move to Flow types
// TripPrice.propTypes = {
//   totalWithoutPlatformFee: PropTypes.string,
//   slash: PropTypes.string,
//   mobile: PropTypes.bool,
//   priceLabel: PropTypes.node,
//   loading: PropTypes.bool
// };

export default TripPrice;
