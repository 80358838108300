import moment from 'moment';
import _ from 'lodash';
import { HOUR_FORMAT } from 'app/utils/types';


/**
 * By given prev date and selected date create new date that have
 * the same date as in selectedDate and the same time as in prevDate
 * @param  {Date} prevDate
 * @param  {Date} selectedDate
 * @return {Date}
 */
export const getDateFromDateValue = (prevDate, selectedDate) => {
  const momDate = moment(prevDate);
  const newDate = moment(selectedDate)
    .hours(momDate.hours())
    .minutes(momDate.minutes())
    .toDate();

  return newDate;
};

/**
 * By given date and value from select option (minutes in a day) returns
 * new date with time set from given timeString
 * @param  {Date} date
 * @param  {String} timeString
 * @return {Date}
 */
export const getDateFromTimeValue = (date, timeString) => {
  const momDate = moment(date);
  return momDate.hours(0).minutes(parseInt(timeString, 10)).toDate();
};

/**
 * Return value from given date that can be used as value for "select"
 * HTML tag.
 * @param  {Date} date
 * @return {Number}
 */
export const getTimeSelectValue = (date) => {
  const momDate = moment(date);
  return momDate.hours() * 60 + momDate.minutes();
};

/**
 * Returns a list of time variants for given date. If given date
 * is today, then time will start from current time int the browsers.
 * Otherwise the list will contain all variants between 00:00 to 12:00
 * with given minutes step.
 * @param  {Date} date
 * @return {Array}
 */
export const getTimeVariants = (date, step = 15, minDate) => {
  const nowDate = moment(minDate || new Date());
  const selectedDate = moment(date);

  // Check should we start from 12:00AM or from current time (if the date is today)
  const result = [];
  const endMinutes = 24 * 60;
  let currMinutes = nowDate.isSame(selectedDate, 'day')
    ? nowDate.hours() * 60 + (Math.floor(nowDate.minutes() / step) * step)
    : 0;

  // Fill array with all time variants regarding to the step
  let selectedDateExists = false;
  const iterDate = selectedDate.clone();
  while (currMinutes < endMinutes) {
    iterDate.hours(0).minutes(currMinutes);
    result.push({
      name: iterDate.format(HOUR_FORMAT),
      time: currMinutes
    });
    currMinutes += step;
    selectedDateExists = selectedDateExists || iterDate.isSame(selectedDate);
  }

  // If current time is not presented in variants – add it at first position
  if (!selectedDateExists) {
    result.unshift({
      name: selectedDate.format(HOUR_FORMAT),
      time: getTimeSelectValue(selectedDate)
    });
  }

  return result;
};

/**
 * Returns true if given value is defined and it is the same date
 * as provided date value
 * @param  {Any} value
 * @param  {Date} date
 * @return {Boolean}
 */
export const isDateSameDay = (value, date) => (
  !!value && moment(value).isSame(date, 'day')
);

/**
 * Returns true if given value is defined and it is the same month
 * as provided date value
 * @param  {Any} value
 * @param  {Date} date
 * @return {Boolean}
 */
export const isDateSameMonth = (value, date) => (
  !!value && moment(value).isSame(date, 'month')
);

/**
 * Create an array of months and days to render in component based
 * on given currently selected date value.
 * @return {Array}
 */
export const getDateVariants = (value, minDate, maxDate) => {
  const selectedDate = moment(value);
  const nowDate = moment(minDate || new Date());
  const currMonth = nowDate.month();
  const months = new Array(6);
  const dows = new Array(7);

  // Get names of days of week
  const weekGenDate = nowDate.clone();
  for (let i = 1; i <= 7; i++) {
    dows[i - 1] = {
      name: weekGenDate.isoWeekday(i).format('ddd')
    };
  }

  // Go throuth 6 next months
  selectedDate.date(1).add('month', -1);
  for (let j = currMonth; j < currMonth + 6; j++) {
    const currMonthDate = selectedDate.date(1).add('month', 1);
    const days = new Array(currMonthDate.daysInMonth());

    // Add all days in month
    for (let k = 1; k <= currMonthDate.daysInMonth(); k++) {
      const dayDate = currMonthDate.date(k);
      days[k - 1] = {
        dayNum: k,
        date: dayDate.clone().toDate(),
        dow: dayDate.isoWeekday(),
        today: dayDate.isSame(nowDate, 'day'),
        past: (
          dayDate.isBefore(nowDate, 'day') ||
          (maxDate && dayDate.isAfter(maxDate, 'day'))
        )
      };
    }

    // Fill days to monday before first day of month to make the grid
    const firstDate = days[0];
    if (firstDate.dow > 1) {
      for (let l = 1; l < firstDate.dow; l++) {
        days.unshift({ empty: true });
      }
    }

    // Fill days to saturday after last day of month to make the grid
    const lastDate = _.last(days);
    if (lastDate.dow < 7) {
      for (let m = lastDate.dow + 1; m <= 7; m++) {
        days.push({ empty: true });
      }
    }

    // Split days by rows with 7 elements
    const rowedDates = days.reduce((accum, day, n) => {
      const currRow = _.last(accum);
      currRow.push(day);
      if ((n + 1) % 7 === 0) {
        accum.push([]);
      }
      return accum;
    }, [[]]);
    rowedDates.pop();

    months[j - currMonth] = {
      name: currMonthDate.format('MMMM YYYY'),
      days: rowedDates,
      dows
    };
  }

  return months;
};
