import has from 'lodash/has';

const isPromise = val => val && typeof val.then === 'function';
const isThunk = val => typeof val === 'function';

/**
 * Creates a reducer from an initial state and a map that matches the action
 * type with the correct action handler, the handler must be a function with the
 * signature `handler(state: object, action: Action): object` and it returns the
 * properties that override the previous state. The handler could be split into
 * an object that handles three different states of actions with a promise
 * payload (`request`, `success` and `failure`).
 *
 * NOTE: async operations that aren't splitted by the action handler will ignore
 * the `request` state of the request.
 *
 * ```
 * const todoReducer = createReducer({
 *   todos: [],
 *   fetching: false
 * }, {
 *   [ADD_TODO]: (state, text) => ({
 *     todos: [text, ...state.todos]
 *   }),
 *   [GET_TODOS]: {
 *     request: () => ({ fetching: true }),
 *     success: (_, payload) => ({ todos: payload.todos }),
 *     failure: () => {
 *       alert('connection error!')
 *       return { fetching: false };
 *     }
 *   }
 * });
 * ```
 *
 * The `childReducers` will delegate the action to reducers passed on to it
 * that matches the key of the reducer with the context of the action and
 * modifies the property of the state that matches the same name.
 *
 * ```
 * const todoListsReducer = createReducer({
 *   currentList: 'home'
 * }, {
 *   [SET_TODO_LIST]: (_, currentList) => ({ currentList })
 * }, {
 *   home: todoReducer,
 *   work: todoReducer
 * });
 *
 * dispatch(addTodo('home', 'buy groceries'));
 * dispatch(addTodo('work', 'review PRs'));
 *
 * // resulting state:
 * {
 *   currentList: 'home',
 *   home: { todos: ['buy groceries'] },
 *   work: { todos: ['review PRs'] }
 * }
 * ```
 *
 * @param {Object} initialState - the reducers default state
 * @param {Object} actionHandlers - an action handler map
 * @param {Object} childReducers - reducers that will handle child states
 */
export const createReducer = (
  initialState,
  actionHandlers
) => {
  return (state = initialState, action) => {
    let reduceFn = actionHandlers[action.type];

    if (reduceFn) {
      const isRequest = isPromise(action.payload);

      if (typeof reduceFn !== 'function') {
        if (isRequest) {
          reduceFn = reduceFn.request;
        } else if (action.error) {
          reduceFn = reduceFn.failure;
        } else {
          reduceFn = reduceFn.success;
        }
      }
    } else {
      return state;
    }

    if (!reduceFn) {
      return state;
    }

    return {
      ...state,
      ...reduceFn(state, has(action, 'payload') ? action.payload : action)
    };
  };
};

/**
 * Defines an action creator with a `type` and a `payloadCreator` function
 * that maps the result of its evaluation to the action's payload, if no
 * function is provided then the payload is equal to the first parameter passed
 * to the action creator.
 *
 * If the middleware parameter is present then you can transform the action
 * being dispatched, it will be passed the action and returns a new action that
 * could either be a Promise, a function or an action object.
 * Used in conjunction with the promise and/or thunk middleware there's the
 * possibility of handling complex async logic.
 *
 * ```
 * const addTodo = createAction(
 *   ADD_TODO,
 *   (text) => text,
 *   (list) => [list]
 * );
 *
 * const getTodos = createAction(
 *   GET_TODOS,
 *   api.getTodos,
 *   undefined,
 *   action => async (dispatch) => {
 *     const todos = await dispatch(action);
 *     return dispatch(markAsSeen(todos));
 *   }
 * );
 * ```
 * @param {string} type
 * @param {function(...params)} [payloadCreator]
 * @param {function(next)} [middleware]
 * @returns {ActionCreator}
 */
export const createAction = (
  type,
  payloadCreator = args => args,
  middleware = action => action
) => (...args) => {
  const nargs = args.length;
  const payload = payloadCreator(...args);
  const error = nargs.length === 1 && args[nargs - 1] instanceof Error;
  return middleware({
    type,
    payload,
    error
  });
};

export const promiseMiddleware = ({ dispatch }) => next => action => {
  const { payload, ...rest } = action;
  if (payload === undefined) {
    return isPromise(action)
      ? action.then(dispatch)
      : next(action);
  }

  const promise = isThunk(payload)
    ? dispatch(payload)
    : payload;

  const digested = next({
    ...rest,
    payload: promise
  });

  if (!isPromise(promise)) return digested;

  promise.then(
    (result) => dispatch({
      ...rest,
      payload: result
    }),
    (error) => dispatch({
      ...rest,
      payload: error,
      error: true
    })
  );

  return promise;
};

export const deferredMiddleware = () => next => action => {
  const { promise, ...rest } = action;
  next(rest);
  return promise || rest;
};

export const createDeferredAction = (actionType) => (payload) => {
  let resolvePromise;
  let rejectPromise;

  const promise = new Promise((resolve, reject) => {
    resolvePromise = resolve;
    rejectPromise = reject;
  });

  return {
    type: actionType,
    payload,
    promise,
    resolvePromise,
    rejectPromise
  };
};
