import React from 'react';
import { FormattedMessage } from 'translations';

import style from './style.css';

const messages = {
  label: 'ACCOUNT.TRIP_DETAILS.RESERVATIONS_UNDER'
};

const UserInfo = ({ firstName, lastName }) => (
  <div className={style.block}>
    <FormattedMessage
      id={messages.label}
    />
    <div className={style.name} data-testid="user-info">
      {`${firstName} ${lastName}`}
    </div>
  </div>
);

// TODO: move to Flow types
// UserInfo.propTypes = {
//   firstName: PropTypes.string,
//   lastName: PropTypes.string
// };

export default UserInfo;
