import moment from 'moment';
import {
  HOURLY_MODE_VALUE,
  TA_SET_CUSTOMER_PROFILE_LIST,
  SELECTED_CUSTOMER_PROFILE,
  IS_FLIGHT_TIME_FIELD_NAME,
  IS_RETURN_FLIGHT_TIME_FIELD_NAME
} from 'app/constants';
import _ from 'lodash';
import { call, select, take } from 'redux-saga/effects';
import { isProfileListLoading, getCustomerProfile } from 'app/sagas/selectors';
import findPlaceByName from './findPlaceByName';
import findPlaceById from './findPlaceById';
import findPlaceByCoords from './findPlaceByCoords';
import { parseUrlParams } from 'app/sagas/utils';
import { getFlights } from 'mz-sdk/services/flights'
import { flightToPickupTimeFromAirport } from 'app/utils/flight'


const COORDS_REGEXP = /^(\-?\d+(\.\d+)?),\s*(\-?\d+(\.\d+)?)$/;

const parseAddress = (address) => {
  if (!address) return {};
  const match = address.match(COORDS_REGEXP);

  if (!match) return call(findPlaceByName, address);

  return call(findPlaceByCoords, match[1], match[3]);
};

/**
 * getPlaceById endpoint is not handling
 * formatted_address properly when custon_name
 * so we keep the custom_name we have in params
 */
function* parseAddressById(address, id) {
  try {
    const placeById = yield call(findPlaceById, id);
    return {
      ...placeById,
      value: address || placeById.value
    };
  } catch (error) {
    return { value: address, place_id: id };
  }
}

export async function resolveFlight({
  startLocation, endLocation, airline, flightNumber, pickupDate,
}) {
  const direction = startLocation?.type === 'airport' ? 'arr' : 'dep'
  const airportCode = startLocation?.type === 'airport'
    ? startLocation.value ?? startLocation.iataCode
    : endLocation?.type === 'airport'
    ? endLocation.value ?? endLocation.iataCode
    : null

  if (!airportCode || !direction) {
    return null
  }

  try {
    const flights = await getFlights({
      airline_code: airline,
      airport_code: airportCode,
      flight_number: flightNumber,
      flight_datetime: pickupDate.format(),
      flight_direction: direction,
    })
    if (!flights?.length) return null
    return flights[0]
  } catch (e) {
    // TODO: log to sentry?
  }
}

/**
 * By given search string create an object with form parameters.
 * Also try to get latest used search parameters from session storage
 * if given search string is empty.
 */
export default function* resolveFormValues() {
  const parsed = yield call(parseUrlParams);

  // Get place objects to be used in the form
  const [start_address, end_address] = yield [(
    parsed.start_id
      ? call(parseAddressById, parsed.start_address, parsed.start_id)
      : parseAddress(parsed.start_address)
  ), (
    parsed.end_id
      ? call(parseAddressById, parsed.end_address, parsed.end_id)
      : parseAddress(parsed.end_address)
  )];

  let start_name = parsed.start_name;
  let end_name = parsed.end_name;

  if (parsed.start_id && parsed.start_address) {
    start_name = parsed.start_address;
  }

  if (parsed.end_id && parsed.end_address) {
    end_name = parsed.end_address;
  }

  if (start_name) {
    start_address.display = start_name;
  }

  if (end_name) {
    end_address.display = end_name;
  }

  const values = _.omit({
    ...parsed,
    start_address,
    end_address
  }, [
    'pickup_datetime',
    'flight_datetime',
    'return_pickup_datetime',
    'return_flight_datetime'
  ]);

  // Set flight datetime as pickup datetime if no pickup datetime defined
  // and indicate that the pickup datetime is actually a flight datetime
  if (!parsed.pickup_datetime && parsed.flight_datetime) {
    values.pickup_datetime = parsed.flight_datetime
    values[IS_FLIGHT_TIME_FIELD_NAME] = true
  } else if (parsed.pickup_datetime) {
    values.pickup_datetime = parsed.pickup_datetime
  }

  if (!parsed.return_pickup_datetime && parsed.return_flight_datetime) {
    values.return_pickup_datetime = parsed.return_flight_datetime
    values[IS_RETURN_FLIGHT_TIME_FIELD_NAME] = true
  } else if (parsed.return_pickup_datetime) {
    values.return_pickup_datetime = parsed.return_pickup_datetime
  }

  // If the stored date is in the past
  // If the stored dates are invalid for some reason
  if (
    (values.pickup_datetime && !values.pickup_datetime.isValid()) ||
    (values.return_pickup_datetime && !values.return_pickup_datetime.isValid())
  ) {
    delete values.pickup_datetime;
    delete values.return_pickup_datetime;
  }

  // Set return pickup time when it is not defined and the trip is roundtrip OR
  // when it is defined but the date is before the pickup_datetime
  if (
    (!values.return_pickup_datetime && values.pickup_datetime && values.mode === 'round_trip') ||
    (values.return_pickup_datetime && values.pickup_datetime && values.return_pickup_datetime.isBefore(values.pickup_datetime))
  ) {
    values.return_pickup_datetime = moment(values.pickup_datetime).add(9, 'days');
  }

  // Select deeplinked customer profile
  if (values.customer_id) {
    if (yield select(isProfileListLoading)) {
      yield take(TA_SET_CUSTOMER_PROFILE_LIST);
    }
    const customerProfile = yield select(getCustomerProfile, values.customer_id);
    if (customerProfile) {
      values[SELECTED_CUSTOMER_PROFILE] = customerProfile;
    }
  }

  // Init hourly tab
  if (values.mode === HOURLY_MODE_VALUE) {
    values.booking_mode = HOURLY_MODE_VALUE;
    values.mode = 'one_way';
  }

  // TODO: refactor this.
  // Temporary solution to make PlaceField placeholder and clean button workng correct
  if (_.isEmpty(start_address)) {
    values.start_address = null;
  }
  if (_.isEmpty(end_address)) {
    values.end_address = null;
  }

  // Resolve passed flights and update pickup datetime to flight datetimes
  if (!parsed.flight_datetime && values.airline && values.flight_number && values.pickup_datetime) {
    values.flight = yield call(resolveFlight, {
      airline: values.airline,
      flightNumber: values.flight_number,
      pickupDate: values.pickup_datetime,
      startLocation: values.start_address,
      endLocation: values.end_address,
    })
    if (values.flight) {
      values.pickup_datetime =
        flightToPickupTimeFromAirport(values.flight, values.pickup_datetime)
    }
  }
  if (!parsed.return_flight_datetime && values.airline2 && values.flight_number2 && values.return_pickup_datetime) {
    values.return_flight = yield call(resolveFlight, {
      airline: values.airline2,
      flightNumber: values.flight_number2,
      pickupDate: values.return_pickup_datetime,
      startLocation: values.start_address,
      endLocation: values.end_address,
    })
    if (values.return_flight) {
      values.return_pickup_datetime =
        flightToPickupTimeFromAirport(values.return_flight, values.return_pickup_datetime)
    }
  }

  return values;
}
