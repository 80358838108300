import { fork } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga';
import { UPDATE_TRIP } from 'app/constants';
import updateTrip from './updateTrip';


/**
 * Watch for UPDATE_TRIP action dispatch
 */
export default function* watchUpdateTrip() {
  yield fork(takeLatest, UPDATE_TRIP, updateTrip);
}
