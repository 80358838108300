import React, { Component } from 'react';
import FoldableDropdown from 'app/components/base/FoldableDropdown';
import StandardInput from 'app/components/base/StandardInput';
import Calendar from 'app/components/base/Calendar';
import { FormattedMessage } from 'translations';
import noop from 'lodash/noop';
import moment from 'moment';
import { getTZLessDate } from 'app/utils/types';


const messages = {
  DAY_WITH_TIME: { id: 'CALENDAR.DAY_WITH_TIME' },
  DAY_WITHOUT_TIME: { id: 'CALENDAR.DAY_WITHOUT_TIME' },
}

class DatePicker extends Component {
  handleClick = (event) => {
    if (!this.props.notime || !event.defaultPrevented) {
      event.stopPropagation();
    }
  }

  handleChange = (value) => {
    const {
      closeOnChange,
      notime,
      input
    } = this.props;

    if (closeOnChange && notime) {
      this.foldable.fold();
    }

    input.onChange(value);
  }

  handleFocus = () => {
    this.props.input.onFocus();
  }

  handleBlur = () => {
    this.props.input.onBlur(this.props.input.value);
  }

  renderLabel = () => {
    let timePostfix = '';
    let format = messages.DAY_WITHOUT_TIME;
    if (!this.props.notime) {
      format = messages.DAY_WITH_TIME;
      timePostfix = moment(this.props.input.value).format(' (hh:mm A)');
    }

    return (
      <FormattedMessage
        {...format}
        values={{ value: getTZLessDate(this.props.input.value) }}
      >
        {strVal => (
          <StandardInput
            {...this.props}
            icon="date_range"
            input={{
              value: strVal + timePostfix,
              readOnly: true
            }}
          />
        )}
      </FormattedMessage>
    );
  }

  render() {
    const {
      meta,
      target,
      notime,
      minDate,
      optionsNode,
      /* eslint-disable */
      input,
      icon,
      /* eslint-enable */
      ...props
    } = this.props;

    const inputProps = {
      ...input,
      onChange: this.handleChange
    };

    return (
      <FoldableDropdown
        {...props}
        ref={(e) => this.foldable = e}
        onBlur={this.handleBlur}
        onUnfold={this.handleFocus}
        onFold={this.handleBlur}
        label={this.renderLabel()}
      >
        {
          // here can be any component. Flight/pickup time toggler e.g.
          optionsNode
        }
        <Calendar
          meta={meta}
          input={inputProps}
          minDate={minDate}
          notime={notime}
          onClick={this.handleClick}
          target={target}
        />
      </FoldableDropdown>
    );
  }
}

// TODO: move to Flow types
// DatePicker.propTypes = {
//   input: PropTypes.object.isRequired,
//   meta: PropTypes.object.isRequired,
//   notime: PropTypes.bool,
//   target: PropTypes.object,
//   minDate: PropTypes.object,
//   closeOnChange: PropTypes.bool,
//   optionsNode: PropTypes.node
// };

DatePicker.defaultProps = {
  closeOnChange: false,
  onFocus: noop,
  onBlur: noop
};

export default DatePicker;
