import {
  RETURN_DATE_SELECTED,
  ROUND_TRIP_MODE_VALUE,
  RETURN_BOOK_STATE_FIELD_NAME
} from 'app/constants';
import {
  getLocation,
  isBookTripPage,
  isSearchResultsPage,
  getBookTripFormValue
} from 'app/sagas/selectors';
import queryStringToObject from 'mz-utils/queryStringToObject'
import objectToQueryString from 'mz-utils/objectToQueryString'
import { put, select, call } from 'redux-saga/effects';
import { doSearch } from 'app/actions/search-results';
import { updateTrip, changeTripMode } from 'app/actions/book-trip';
import { getHistory } from 'app/history';


export default function* handleChangeCurrency(action) {
  const { payload: { changedByUser, currency } } = action;
  const history = yield call(getHistory);
  const { pathname, search } = yield select(getLocation);

  // Append "currency=XXX" to the url params
  const newLocation = {
    pathname,
    search: objectToQueryString({
      ...queryStringToObject(search),
      currency
    })
  };

  // check if searchForm is initialized and rendered - in this case user is on search page
  const isSRP = yield select(isSearchResultsPage);
  if (changedByUser && isSRP) {
    yield call(history.replace, newLocation);
    yield put(doSearch());
    return;
  }

  // check if it's the book-trip page
  const isBTP = yield select(isBookTripPage);
  if (changedByUser && isBTP) {
    const newBookTripUrl = `/book${newLocation.search}`;

    // reinitialize BTP, update URL, load new trip info with new currency,
    // update prices. That will correctly start countdown for expiration.
    // becase race against "LOCATION_CHANGE" action used. So we can't change URL and not
    // call loadBookTrip to start countdown again
    yield put(updateTrip({ newBookTripUrl, reloadTrip: true }));

    // research round trip data if user was booking
    // the round trip from BTP
    const returnTripState = yield select(getBookTripFormValue, RETURN_BOOK_STATE_FIELD_NAME);
    if (returnTripState === RETURN_DATE_SELECTED) {
      yield put(changeTripMode(ROUND_TRIP_MODE_VALUE));
    }
    return;
  }

  // In all other cases just append the currency if requested by user
  if (changedByUser) {
    yield call(history.replace, newLocation);
  }
}
