import React from 'react';
import classnames from 'classnames';
import Icon from 'app/components/base/Icon';
import { Layout } from 'app/components/base/Layout';

import style from './style.css';


const RoundedHeader = ({ className, icon, mobile, largeIcon, children }) => {
  const modifiers = {
    [style['header--noicon']]: !icon,
    [style['header--mobile']]: mobile
  };

  const isReactElement = React.isValidElement(icon);

  return (
    <Layout
      nowrap
      align="start center"
      className={classnames(className, modifiers, style.header)}
      size="noshrink"
    >
      {icon && (
        <Layout align="center center" className={style.iconContainer}>
          {isReactElement && icon}
          {!isReactElement && (
            <Icon large={largeIcon} family={icon.family} className={style.icon}>{icon.name}</Icon>
          )}
        </Layout>
      )}

      <Layout size="noshrink" nowrap align="start center" className={style.title}>
        {children}
      </Layout>
    </Layout>
  );
};

// TODO: move to Flow types
// RoundedHeader.propTypes = {
//   className: PropTypes.string,
//   icon: PropTypes.object,
//   mobile: PropTypes.bool,
//   children: PropTypes.node,
//   largeIcon: PropTypes.bool
// };

export default RoundedHeader;
