import React from 'react'
import importWithRetry from 'mz-utils/importWithRetry'

// Lazy loaded pages
const ExectAssistSignupPage = React.lazy(() => importWithRetry(() => import('app/pages/TASignupSplashPage/ExecAssist')));
const TravelQuestSignupPage = React.lazy(() => importWithRetry(() => import('app/pages/TASignupSplashPage/TravelQuest')));
const SuperShuttleSignupPage = React.lazy(() => importWithRetry(() => import('app/pages/TASignupSplashPage/SuperShuttle')));
const SpeediShuttleSignupPage = React.lazy(() => importWithRetry(() => import('app/pages/TASignupSplashPage/SpeediShuttle')));
const KHMSignupPage = React.lazy(() => importWithRetry(() => import('app/pages/TASignupSplashPage/KHM')));
const WishupSignupPage = React.lazy(() => importWithRetry(() => import('app/pages/TASignupSplashPage/Wishup')));
const HikoryTravelSignupPage = React.lazy(() => importWithRetry(() => import('app/pages/TASignupSplashPage/HikoryTravel')));
const TravelExpertsSignupPage = React.lazy(() => importWithRetry(() => import('app/pages/TASignupSplashPage/TravelExperts')));
const SpanishTASignupPage = React.lazy(() => importWithRetry(() => import('app/pages/TASignupSplashPage/Spanish')));

const routes = [
  {
    path: '/exec-assist-ipad/register',
    component: ExectAssistSignupPage
  },
  {
    path: '/travelquest-travel-agents',
    component: TravelQuestSignupPage
  },
  {
    path: '/supershuttle-travel-agents',
    component: SuperShuttleSignupPage
  },
  {
    path: '/speedishuttle-travel-agents',
    component: SpeediShuttleSignupPage
  },
  {
    path: '/KHM-travel-agents',
    component: KHMSignupPage
  },
  {
    path: '/wishup-travel-agents',
    component: WishupSignupPage
  },
  {
    path: '/hikory-travel-agents',
    component: HikoryTravelSignupPage
  },
  {
    path: '/spanishTA-travel-agents',
    component: SpanishTASignupPage
  },
  {
    path: '/travelexperts-travel-agents',
    component: TravelExpertsSignupPage
  }
];

export default () => routes
