import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import noop from 'lodash/noop';
import { addClickOutsideListener } from 'app/utils/listeners';


export default (ComposedComponent) => {
  class Foldable extends Component {
    state = {
      open: false
    }

    UNSAFE_componentWillMount() {
      if (this.props.opened) {
        this.setState({ open: this.props.opened });
      }
    }

    componentDidMount() {
      document.addEventListener('focusin', this.handleFocus, false);
      this.unsusbcribe = addClickOutsideListener(
        this.node,
        this.handleClickOutside
      );
    }

    componentDidUpdate(prevProps, prevState) {
      if (!prevProps.opened && this.props.opened) {
        this.unfold();
      } else {
        if (prevState.open && !this.state.open) {
          this.props.onFold();
        }
        if (this.state.open && !prevState.open) {
          this.props.onUnfold();
        }
      }
    }

    componentWillUnmount() {
      if (this.unsusbcribe) {
        this.unsusbcribe();
      }
      document.removeEventListener('focusin', this.handleFocus, false);
    }

    fold = () => {
      this.setState({ open: false });
    }

    unfold = () => {
      this.setState({ open: true });
    }

    handleClickOutside = () => {
      if (this.state.open) {
        this.fold();
      }
    }

    handleFocusInside = () => {
      const { open } = this.state;
      const { disableFold, disabled } = this.props;

      if (!open && !disableFold && !disabled) {
        this.unfold();
      }
    }

    handleClickInside = (event) => {
      this.handleFocusInside(event);
      this.props.onClick(event);
    }

    handleFocus = (event) => {
      if (this.state.open && this.node && !this.node.contains(event.target)) {
        this.handleClickOutside();
      }
    }

    handleBlur = (event) => event.stopPropagation();

    render() {
      return (
        <ComposedComponent
          ref={node => this.node = findDOMNode(node)}
          {...this.props}
          onClick={this.handleClickInside}
          fold={this.fold}
          unfold={this.unfold}
          onFocus={this.handleFocusInside}
          onBlur={this.handleBlur}
          open={this.state.open}
        />
      );
    }
  }

  // TODO: move to Flow types
  // Foldable.propTypes = {
  //   className: PropTypes.string,
  //   onClick: PropTypes.func,
  //   onFold: PropTypes.func,
  //   onUnfold: PropTypes.func,
  //   disableFold: PropTypes.bool,
  //   disabled: PropTypes.bool,
  //   opened: PropTypes.bool
  // };

  Foldable.defaultProps = {
    onFold: noop,
    onUnfold: noop,
    onClick: noop
  };

  return Foldable;
};
