import React, { Component } from 'react';
import { FormattedMessage } from 'translations';
import Icon from 'app/components/base/Icon';
import Button from 'app/components/base/Button';
import { Layout } from 'app/components/base/Layout';
import MinimumAlert from '../../../commons/HourlyDuration/MinimumAlert';
import style from '../style.css';


const messages = {
  bookRide: { id: 'SEARCH_RESULTS.BOOK_RIDE' },
  departure: { id: 'SEARCH_RESULTS.DEPARTURE' },
  return: { id: 'SEARCH_RESULTS.RETURN' },
  changeTime: { id: 'SEARCH_RESULTS.CHANGE_TIME' },
  schedule: { id: 'TRIP_DETAILS.SCHEDULE' }
};

export class TripBottom extends Component {
  render() {
    if (!this.props.isBookable) return null;

    const {
      isHourlyRide,
      hourlyDetails,
      onClickChangeSchedule,
      departureTicketSchedule
    } = this.props;

    const isSuggestedHourly = isHourlyRide
      && (hourlyDetails?.minimum > hourlyDetails?.originalRequested);

    return (
      <div>
        {hourlyDetails && isSuggestedHourly && <MinimumAlert minimum={hourlyDetails.minimum} />}
        <Layout direction="row" nowrap className={style.section}>
          {departureTicketSchedule && (
            <Button
              className={style.button}
              nocaps
              rounded
              bordered
              size="noshrink"
              nowrap
              onClick={onClickChangeSchedule}
            >
              <FormattedMessage {...messages.schedule} />
              <Icon>chevron_right</Icon>
            </Button>
          )}

            <Button
              className={style.button}
              onClick={this.props.onClickBookTrip}
              nocaps
              rounded
              large
            >
              {<FormattedMessage {...messages.bookRide} />}
            </Button>
        </Layout>
      </div>
    );
  }
}

// TODO: move to Flow types
// TripBottom.propTypes = {
//   isBookable: PropTypes.bool,
//   departureTicketSchedule: PropTypes.object,
//   returnTicketSchedule: PropTypes.object,
//   onDemand: PropTypes.bool,
//   onClickBookTrip: PropTypes.func,
//   onClickChangeSchedule: PropTypes.func,
//   isHourlyRide: PropTypes.bool,
//   hourlyDetails: PropTypes.shape({
//     minimum: PropTypes.number,
//     originalRequested: PropTypes.number,
//     hoursRequested: PropTypes.number
//   })
// };

export default TripBottom;
