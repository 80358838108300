import { actionChannel, fork } from 'redux-saga/effects';
import { takeLatestChannelAction } from 'app/sagas/utils';
import { UPDATE_PRICE } from 'app/constants';
import updatePrice from './updatePrice';


/**
 * Watch for UPDATE_PRICE action dispatch
 */
export default function* watchUpdatePrice() {
  const requestChannel = yield actionChannel(UPDATE_PRICE);
  yield fork(takeLatestChannelAction, requestChannel, updatePrice);
}
