import { put, call, select } from 'redux-saga/effects';
import { change, getFormValues } from 'redux-form';
import {
  BOOK_TRIP_FORM,
  PRICE_FIELD_NAME,
  DEPARTING_TRIP_FIELD_NAME,
  RETURN_TRIP_FIELD_NAME,
  ROUND_TRIP_MODE_VALUE,
  GRATUITY_FIELD_NAME,
  UPGRADES_FIELD_NAME,
  UPGRADE_IDS
} from 'app/constants';
import { preprocessTripObject } from 'app/utils/trip';

const getTollsAndGratuityUpgradeData = (trip) => {
  const {
    details: {
      tollsIncluded,
      gratuityIncluded
    }
  } = trip;
  let id = (tollsIncluded && UPGRADE_IDS.TOLLS) || (gratuityIncluded && UPGRADE_IDS.GRATUITY);
  id = tollsIncluded && gratuityIncluded ? UPGRADE_IDS.TOLLS_AND_GRATUITY : id;

  return id ? { id, included: true } : null;
};

const getGratuityUpgradeData = (percentage) => {
  if (!parseInt(percentage, 10)) {
    return {
      id: UPGRADE_IDS.GRATUITY,
      included: false
    };
  }

  return {
    id: UPGRADE_IDS.GRATUITY,
    included: true,
    showInUpgradeYourTrip: false,
    caption: `${percentage}%`
  };
};

/**
 * Saga to setup upgrades that can be calculated directly from the
 * selected trip.
 */

/**
 * Saga to setup upgrades that can be calculated directly from the
 * selected trip.
 * @param {String}  formName      Redux-form name
 * @param {Boolean} reloadTrip    if false - take all values from new trip.
 *                                true - apply previously selected amenities
 */
export default function* doSetupUpgrades(formName = BOOK_TRIP_FORM, reloadTrip = false) {
  const formSelector = yield call(getFormValues, formName);
  const form = yield select(formSelector);
  const departingTrip = preprocessTripObject(form[DEPARTING_TRIP_FIELD_NAME]);
  const returnTrip = preprocessTripObject(form[RETURN_TRIP_FIELD_NAME], ROUND_TRIP_MODE_VALUE);
  const gratuityValue = form[GRATUITY_FIELD_NAME];
  const tripAmenities = [...(form[PRICE_FIELD_NAME]?.amenities || []), ...(departingTrip.details.amenities || [])]

  let upgrades = [];

  // Add insurance coverage
  upgrades.push({
    id: UPGRADE_IDS.INSURANCE_COVERAGE,
    included: true
  });

  // Add tolls & gratuity
  const tollsAndGratuityData = (
    (departingTrip && getTollsAndGratuityUpgradeData(departingTrip)) ||
    (returnTrip && getTollsAndGratuityUpgradeData(returnTrip))
  );
  if (tollsAndGratuityData) {
    upgrades.push(tollsAndGratuityData);
  }

  // Add gratuity upgrade item. Skip if gratuity included
  const gratuityItem = upgrades.find(upgrade => upgrade.id === UPGRADE_IDS.GRATUITY);
  if (!gratuityItem || !gratuityItem.included) {
    upgrades.push(getGratuityUpgradeData(gratuityValue));
  }

  // get previously selected upgrades
  const prevUpgrades = form[UPGRADES_FIELD_NAME];

  // debugger;
  const tripUpgrades = tripAmenities
    .map(amenity => {
      let isIncluded = amenity.included || amenity.selected;
      // check if previous amenity was added.
      // Safe way - only to add amenity if it's not added by default.
      // We shouldn't exclude amenity added by default
      if (reloadTrip && !isIncluded) {
        isIncluded = !!prevUpgrades.find(({ id, included }) => amenity.id === id && included);
      }

      const isDefaultIncluded = amenity.included;
      const amenityItem = {
        ...amenity,
        optional: !isDefaultIncluded, // this prop used in UpgradeItem
        included: isIncluded,
        showInUpgradeYourTrip: !isDefaultIncluded,
        includedByDefault: isDefaultIncluded,
        // adapt price to be shown in components
        price: !isDefaultIncluded && { price: amenity.price.total }
      };

      return amenityItem;
    })
    .sort(upgrade => !upgrade.includedByDefault);

  upgrades = [...upgrades, ...tripUpgrades];

  const uniqueUpgrades = Object.values(upgrades.reduce((res, upgrade) => {
    if ( res[upgrade['id']] ) return res
    return { ...res, [upgrade['id']]: upgrade }
  }, {}))

  const filteredUpgrades = uniqueUpgrades.filter(upgrade => upgrade.name)

  yield put(change(formName, UPGRADES_FIELD_NAME, filteredUpgrades));
}
