import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'translations';
import MobileScreen from 'app/components/base/MobileScreen';
import * as scheduledRidesActions from 'app/actions/scheduled-rides';
import getTripScheduleProps
from 'app/components/search-results/TimeTableSelect/utils/getTripScheduleProps';
import {
  getUpdatedScheduleTrip,
  getUpdatedScheduleError,
  getUpdatedScheduleLoading
} from 'app/sagas/selectors';
import MobileTimeTableSelect from 'app/components/search-results/TimeTableSelect/Mobile';
import style from './style.css';


const messages = {
  title: { id: 'SEARCH_RESULTS.CHANGE_TIME' }
};

export class ChangeScheduleScreen extends React.Component {
  handleConfirm = (item, returnItem) => {
    this.props.onConfirm(item, returnItem);
    this.props.onClose()
  };

  handleClickBack = () => {
    this.props.onClose()
  }

  render() {
    const {
      isReturn,
      originTrip,
      viewEarlierTimes,
      viewLaterTimes,
      isRoundTrip,
      selectedTicket,
      selectedReturnTicket
    } = this.props;

    let tripForRender = originTrip;
    // for SRP use trip with departure and return in one dialog.
    // for BTP - only departure or return
    if (!isRoundTrip) {
      tripForRender = isReturn
        ? { steps: originTrip.returnSteps }
        : { steps: originTrip.steps };
    }

    const scheduleProps = getTripScheduleProps(tripForRender, {
      isRoundTrip,
      selectedTicket,
      selectedReturnTicket
    });

    return (
      <MobileScreen
        className={style.screen}
        title={<FormattedMessage {...messages.title} />}
        onClickBack={this.handleClickBack}
      >
        <MobileTimeTableSelect
          {...this.props}
          {...scheduleProps}
          onConfirm={this.handleConfirm}
          viewEarlierTimes={isReturnTab =>
              viewEarlierTimes({ isReturn: isReturn || isReturnTab, trip: originTrip })
          }
          viewLaterTimes={isReturnTab =>
              viewLaterTimes({ isReturn: isReturn || isReturnTab, trip: originTrip })
          }
        />
      </MobileScreen>
    );
  }
}

// TODO: move to Flow types
// ChangeScheduleScreen.propTypes = {
//   onConfirm: PropTypes.func.isRequired,
//   viewEarlierTimes: PropTypes.func.isRequired,
//   viewLaterTimes: PropTypes.func.isRequired,
//   isRoundTrip: PropTypes.bool,
//   selectedTicket: PropTypes.object,
//   selectedReturnTicket: PropTypes.object,
//   originTrip: PropTypes.object,
//   subpages: PropTypes.object.isRequired,
//   trip: PropTypes.object.isRequired,
//   isReturn: PropTypes.bool,
//   ticketTypes: PropTypes.array
// };

const mapStateToProps = (state, { trip }) => {
  const updatedScheduleTrip = getUpdatedScheduleTrip(state);
  let originTrip = updatedScheduleTrip || trip;
  // swap start end end location for return part of trip
  originTrip = {
    ...originTrip,
    returnSteps: originTrip.returnSteps
  };

  return {
    updatedTrip: updatedScheduleTrip,
    originTrip,
    error: getUpdatedScheduleError(state),
    loading: getUpdatedScheduleLoading(state)
  };
};

const mapDispatchToProps = {
  viewEarlierTimes: scheduledRidesActions.viewEarlierTimes,
  viewLaterTimes: scheduledRidesActions.viewLaterTimes
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ChangeScheduleScreen);
