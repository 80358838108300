import { put, call } from 'redux-saga/effects';
import { showErrorNotification } from 'app/sagas/utils';
import { travelagent } from 'mz-sdk';
import { getTravelAgent } from 'app/actions/travel-agent';
import {
  TA_PAYMENTS_ACCOUNT_TYPE_PAYPAL
} from 'app/constants';


export default function* deletePaymentAccount(action) {
  const { payload: { type, id } } = action;
  const deleteAccount = type === TA_PAYMENTS_ACCOUNT_TYPE_PAYPAL
    ? travelagent.deletePaypalAccount
    : travelagent.deleteBankAccount;

  try {
    yield call(deleteAccount, { account_id: id });
    const getTravelAgentAction = yield call(getTravelAgent);
    yield put(getTravelAgentAction);
    yield call(action.resolvePromise);
  } catch (error) {
    yield call(showErrorNotification, { error });
    yield call(action.rejectPromise);
  }
}
