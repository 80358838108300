// @flow
import createMessages from 'mz-intl/create';

/* eslint-disable */
export default createMessages('TRIP_CARD', {
  VEHICLE_CLASS_1_TOOLTIP: 'Our most luxurious fleet of cars. Professional service & maximum comfort. Vehicles such as Mercedes S Class or similar.',
  VEHICLE_CLASS_2_TOOLTIP: 'High end cars for people who value comfort and class. Vehicles such as Mercedes E Class or similar.',
  VEHICLE_CLASS_3_TOOLTIP: 'Our most affordable rides, perfect when you travel on a budget.',
  VEHICLE_CLASS_4_TOOLTIP: 'Smaller affordable car, for people who value a good price. Vehicles such as Toyota Prius or similar.',
  VEHICLE_CLASS_6_TOOLTIP: 'Go green with Spacious and comfortable zero emission electric cars or emission reduced hybrids. Vehicles such as Nissan Leaf, Toyota Prius or similar.',
  VEHICLE_CLASS_7_TOOLTIP: 'Our most luxurious fleet of zero emission cars. Professional service & maximum comfort. Vehicles such as Tesla or similar.',
  VEHICLE_CLASS_5_TOOLTIP: 'High end cars for groups who value comfort and class. Vehicles such as Mercedes V Class or similar.',
  VEHICLE_CLASS_1: 'First Class',
  VEHICLE_CLASS_2: 'Business Class',
  VEHICLE_CLASS_3: 'Economy',
  VEHICLE_CLASS_4: 'Standard',
  TIMES_ARE_ESTIMATED: 'This is an estimated time. Actual ride time may vary depending on traffic conditions.',
  HOURLY: 'Hourly',
  PICKUP_TIME: 'Pickup Time',
  PICKUP_LOCATION: 'Pickup Location',
  RIDE_DURATION: 'Ride Duration',
  MINIMUM: 'Minimum',
  MIN: 'Min.',
  RIDESHARE: 'Rideshare',
  RIDESHARE_TOOLTIP: 'Rideshare trips depend on driver availability and are not guaranteed.',
  MOBILE_DATA_REQUIRED: 'Mobile Data Required',
  MOBILE_DATA_REQUIRED_TOOLTIP: 'This provider requires a mobile phone with data in order to book. You will have to use Mozio\'s mobile app or Mozio\'s website in order to meet your driver.',
  PRICE_FOR_MINIMUM: 'This provider requires a minimum of {minimumHours} to book, so the price shown is for {minimumHours}.',
  INCLUDED_KMS: 'Included: {distance} km',
  MILES_EARNED: 'Earn {miles} {company} miles',
  SUPPLIER_SCORE_9_TOOLTIP: 'Supplier with absolutely  exceptional performance in every possible way',
  SUPPLIER_SCORE_8_TOOLTIP: 'Supplier with great performance and only few minor exceptions',
  SUPPLIER_SCORE_7_TOOLTIP: 'Very reliable supplier with only minor issues, highly recommended',
  SUPPLIER_SCORE_6_TOOLTIP: 'Reliable supplier with a few remarks, recommended',
  SUPPLIER_SCORE_5_TOOLTIP: 'Supplier is ok but has more complains than average',
  SUPPLIER_SCORE_4_TOOLTIP: 'Supplier has high rate of complains and caution is adviced',
  SUPPLIER_SCORE_NEW_TOOLTIP: 'Supplier is new and has not yet been rated',
  SUPPLIER_SCORE_9: 'Exceptional',
  SUPPLIER_SCORE_8: 'Superb',
  SUPPLIER_SCORE_7: 'Very good',
  SUPPLIER_SCORE_6: 'Good',
  SUPPLIER_SCORE_5: 'Acceptable',
  SUPPLIER_SCORE_4: 'Poor',
  SUPPLIER_SCORE_NEW: 'New Supplier',
});
