const PARKING = 'parking';

export default [
  {
    name: 'PARKING_ENABLE',
    type: 'bool',
    default: false,
    description: 'Enable parking',
    category: PARKING,
  },
  {
    name: 'PARKING_COVER_IMAGE',
    type: 'image',
    default: '',
    description: 'Custom form cover image',
    category: PARKING,
  },
  {
    name: 'PARKING_SHOW_HEADER_ESIM',
    type: 'bool',
    default: false,
    description: 'Shows/Hide eSIM link in header',
    category: PARKING,
  },
  {
    name: 'PARKING_SHOW_HEADER_TRANSFER',
    type: 'bool',
    default: false,
    description: 'Shows/Hide transfers link in header',
    category: PARKING,
  },
  {
    name: 'PARKING_SHOW_ESIM_BANNER',
    type: 'bool',
    default: false,
    description: 'Shows/Hide eSIM banner',
    category: PARKING,
  },
  {
    name: 'PARKING_SHOW_TRANSFER_BANNER',
    type: 'bool',
    default: false,
    description: 'Shows/Hide transfers banner',
    category: PARKING,
  },
  {
    name: 'PARKING_SHOW_LOUNGES_BANNER',
    type: 'bool',
    default: false,
    description: 'Shows/Hide lounges banner',
    category: PARKING,
  },
];
