import React from 'react';
import { FormattedMessage } from 'translations';
import moment from 'moment';
import { copyDateTimeFromTripTZ } from 'app/utils/types';
import { Layout } from 'app/components/base/Layout';
import Icon from 'app/components/base/Icon';

import style from './style.css';

const messages = {
  date: { id: 'TRIP_DETAILS.DATE' },
  departure: { id: 'TRIP_DETAILS.DEPARTURE' },
  return: { id: 'TRIP_DETAILS.RETURN' }
};

/**
 * Information about departure, return and ticket classes
 */
class ScheduleInfo extends React.Component {

  formatDatetime = value => {
    const tripTZValue = copyDateTimeFromTripTZ(value);
    const timePostfix = moment(tripTZValue).format(' (hh:mm A)');
    return (
      <span>
        <FormattedMessage {...messages.date} values={{ value: tripTZValue }} />
        {timePostfix}
      </span>
    );
  }

  render() {
    const {
      departureTicketInfo,
      returnTicketInfo
    } = this.props;

    return (
      <Layout>
        <Layout className={style.scheduleLeg} align="space-between center" nowrap size="grow">
          <Icon family="mozio" name="schedule-arrow-icon" className={style.departureArrow} />
          <Layout direction="column" align="start end" nowrap>
            <div>
              <span className={style.ticketLabel}>
                <FormattedMessage {...messages.departure} />
              </span>
              <span className={style.ticketDatetime}>
                {this.formatDatetime(departureTicketInfo.departureDatetime)}
              </span>
            </div>
            <span className={style.ticketType}>{departureTicketInfo.ticketName}</span>
          </Layout>
        </Layout>
        {returnTicketInfo && (
          <Layout className={style.scheduleLeg} align="space-between center" nowrap size="grow">
            <Icon family="mozio" name="schedule-arrow-icon" className={style.returnArrow} />
            <Layout direction="column" align="start end" nowrap>
              <div>
                <span className={style.ticketLabel}>
                  <FormattedMessage {...messages.return} />
                </span>
                <span className={style.ticketDatetime}>
                  {this.formatDatetime(returnTicketInfo.departureDatetime)}
                </span>
              </div>
              <span className={style.ticketType}>{returnTicketInfo.ticketName}</span>
            </Layout>
          </Layout>
        )}
      </Layout>
    );
  }
}

// TODO: move to Flow types
// ScheduleInfo.propTypes = {
//   departureTicketInfo: PropTypes.object,
//   returnTicketInfo: PropTypes.object
// };

export default ScheduleInfo;
