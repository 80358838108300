import React from 'react';
import { useSelector } from 'react-redux'
import { getActiveLanguage } from 'app/sagas/selectors'
import { Layout } from 'app/components/base/Layout';
import ConnectedTrip from 'app/components/search-results/ConnectedTrip';
import style from './style.css';


const TripList = ({ trips, vehicleType, showCategory, mobile }) => {
  const activeLang = useSelector(getActiveLanguage)
  const experimentNewSupplierLabel = (
    (vehicleType === 1 || vehicleType === 3) && // sedan or shared ride
    activeLang === 'en_US'
  )

  return (
    <Layout direction="column" className={style.container}>
      {trips.map((id, i) => (
        <ConnectedTrip
          key={`${id}${i}`}
          id={id}
          className={style.trip}
          showCategory={showCategory}
          mobile={mobile}
          experimentNewSupplierLabel={i == 0 && experimentNewSupplierLabel}
        />
      ))}
    </Layout>
  )
};

// TODO: move to Flow types
// TripList.propTypes = {
//   trips: PropTypes.array.isRequired
// };

export default TripList;
