import { call, select, put } from 'redux-saga/effects';
import { trips } from 'mz-sdk';
import { setSuggestedMeetAndGreetTrips } from 'app/actions/meet-and-greet';
import
  getSearchParamsFromTrip
from '../../watchBookTrip/watchLoadBookTrip/processResearchTrip/getSearchParamsFromTrip';
import {
  getBookTripFormValue,
  getSearchResultsTrips,
  getSearchExpirationTime
} from 'app/sagas/selectors';
import { preprocessTripObject } from 'app/utils/trip';
import {
  DEPARTING_TRIP_FIELD_NAME,
  ROUND_TRIP_MODE_VALUE
} from 'app/constants';
import _ from 'lodash';


const filterMeetAndGreetTrips = sdkTrips => {
  const filteredTrips = Object.values(sdkTrips)
    .filter(item => {
      if (item.type !== 'prebook') return false
      const directMnG = preprocessTripObject(item)?.details.meetAndGreetAvailable
      const returnMnG = preprocessTripObject(item, ROUND_TRIP_MODE_VALUE)?.details.meetAndGreetAvailable
      return directMnG || returnMnG
    });

  return filteredTrips;
};

export default function* handleScheduleResearch(action) {
  let resultTrips = [];

  try {
    const searchExpirationTime = yield select(getSearchExpirationTime);

    if (searchExpirationTime && (Date.now() < searchExpirationTime * 1000)) {
      const sdkTripsResponse = yield select(getSearchResultsTrips);
      resultTrips = filterMeetAndGreetTrips(sdkTripsResponse);
    } else {
      const trip = yield select(getBookTripFormValue, DEPARTING_TRIP_FIELD_NAME);

      const searchParams = yield call(getSearchParamsFromTrip, trip);

      const poller = yield call(trips.search, {
        ...searchParams,
        sources: ['prebook']
      });

      while (true) {
        const payload = yield call(poller);
        if (payload === null) {
          break;
        } else if (!_.isEmpty(payload.trips)) {
          const tripsWithMeetAndGreet = filterMeetAndGreetTrips(payload.trips);
          resultTrips = resultTrips.concat(tripsWithMeetAndGreet);
        }
      }
    }

    resultTrips.sort((a, b) => a.totalPrice - b.totalPrice)

    yield put(setSuggestedMeetAndGreetTrips({ trips: resultTrips }));
    action.resolvePromise();
  } catch (err) {
    yield put(setSuggestedMeetAndGreetTrips({ error: true, trips: [] }));
    action.rejectPromise();
  }
}
