import {
  SAVE_FAVOURITE_LOCATION,
  GET_FAVOURITE_LOCATIONS,
  SET_FAVOURITE_LOCATIONS,
  DELETE_FAVOURITE_LOCATION
} from 'app/constants';
import { createAction, createDeferredAction } from '../utils/redux';

export const saveFavouriteLocation = createDeferredAction(
  SAVE_FAVOURITE_LOCATION
);

export const getFavouriteLocations = createDeferredAction(
  GET_FAVOURITE_LOCATIONS
);

export const setFavouriteLocations = createAction(
  SET_FAVOURITE_LOCATIONS
);

export const deleteFavouriteLocation = createDeferredAction(
  DELETE_FAVOURITE_LOCATION
);
