import { fork, select, call, put } from 'redux-saga/effects';
import { emailTaken } from 'app/actions/book-trip';
import { takeLatest } from 'redux-saga';
import { isUserEmailBlur } from 'app/sagas/patterns';
import { getBookingEmail, getLoggedIn } from 'app/sagas/selectors';
import { auth } from 'mz-sdk';


export function* checkEmailExists() {
  const email = yield select(getBookingEmail);
  if (email) {
    const taken = yield call(auth.emailTaken, email);
    yield put(emailTaken(taken));
  }
}

export function* setFullstoryIdentifier() {
  const email = yield select(getBookingEmail);
  const user = yield select(getLoggedIn);
  if (!user && typeof window !== 'undefined' && window.FS) {
    window.FS.identify(email || false);
  }
}

export default function* watchEmailChange() {
  yield fork(takeLatest, isUserEmailBlur, checkEmailExists);
  yield fork(takeLatest, isUserEmailBlur, setFullstoryIdentifier);
}
