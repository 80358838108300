import React from 'react'
import { FormattedMessage } from 'mz-intl'
import { styled } from 'mz-ui-kit'
import Button from 'mz-ui-kit/core/Button'
import Lightbox from 'mz-ui-kit/core/Lightbox'
import messages from '../../../messages.intl'

const PolicyContainer = styled('div', {
  maxWidth: '500px',
  padding: '0 2rem 2rem 0',
})

function FlexibleCancelPolicy() {
  return (
    <PolicyContainer>
      <ol>
        {[...Array(7).keys()].map((index) => (
          <FormattedMessage
            key={index}
            tagName="li"
            message={messages[`FLEXIBLE_CANCEL_DETAILS_RULE_${index + 1}`]}
          />
        ))}
      </ol>
    </PolicyContainer>
  )
}

function LearnMoreLink() {
  const [moreShowed, setShowMore] = React.useState(false)
  return (
    <>
      <Button appearance="link" onClick={() => setShowMore(true)}>
        <FormattedMessage message={messages.FLEXIBLE_CANCEL_LEARN_MORE} />
      </Button>
      <Lightbox
        title={<FormattedMessage message={messages.FLEXIBLE_CANCEL_DETAILS_TITLE} />}
        showed={moreShowed}
        onClose={() => setShowMore(false)}
        render={() => <FlexibleCancelPolicy />}
      />
    </>
  )
}

export default function FlexibleCancelDescription() {
  return (
    <>
      <FormattedMessage message={messages.FLEXIBLE_CANCEL_DESCRIPTION} values={{ learnMoreLink: <LearnMoreLink /> }} />
    </>
  )
}
