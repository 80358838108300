import { createReducer } from '../utils/redux';
import {
  SET_USER,
  LOGIN_USER,
  LOGOUT_USER,
  SET_REDIRECT_BACK_URL,
  SET_USER_DATA_READY,
  UPDATE_USER_DATA,
  LOGIN_MFA,
  SET_EPHEMERAL_TOKEN,
} from '../constants';

const initialState = {
  user: null,
  ready: false,
  isLoading: true,
  creditCards: [],
  redirectBackUrl: { pathname: '/' },
  customerProfiles: [],
  ephemeralToken: null,
};

const actionHandlers = {
  [UPDATE_USER_DATA]: (state, newUserData) => ({
    user: {
      ...state.user,
      ...newUserData,
    }
  }),
  [SET_USER_DATA_READY]: () => ({
    ready: true,
  }),
  [LOGIN_USER]: () => ({
    isLoading: true
  }),
  [LOGIN_MFA]: () => ({
    isLoading: true
  }),
  [SET_USER]: (state, user) => ({
    isLoading: false,
    user: user || state.user
  }),
  [LOGOUT_USER]: () => ({
    user: null
  }),
  [SET_REDIRECT_BACK_URL]: (_, { pathname = '/', search }) => ({
    redirectBackUrl: { pathname, search }
  }),
  [SET_EPHEMERAL_TOKEN]: (state, ephemeralToken) => ({
    isLoading: false,
    ephemeralToken
  }),
};

export default createReducer(initialState, actionHandlers);
