import config from 'config';

import languages from 'app/languages';
import { getDomainConfig } from './browser';
import { removePrefix } from './format';
import { getAuthorizedUserEmail } from 'mz-sdk/services/user'


export function pushLoggly(logData) {
  const browser = window.navigator;
  const location = window.location;
  const baseLogData = {
    source: `web-${config.ENV}`,
    referrer: getDomainConfig().ref || 'www',
    user: getAuthorizedUserEmail(),
    browser: {
      appVersion: config.VERSION,
      language: browser.language,
      userAgent: browser.userAgent,
      platform: browser.platform,
      appCodeName: browser.appCodeName,
      hostname: location.hostname
    }
  };

  const completeLogObject = {
    ...logData,
    ...baseLogData
  }

  if (!window._LTracker || process.env.NODE_ENV === 'development') {
    console.log('LOGGLY', completeLogObject);
  } else {
    window._LTracker.push(completeLogObject);
  }
}

export function pushGa(method, ...params) {
  if (window.ga && window.gaTrackers) {
    window.gaTrackers.forEach(prefix => {
      window.ga(`${prefix}${method}`, ...params);
    });
  }
}

export const pushToDataLayer = (data) => {
  if (window.dataLayer) {
    window.dataLayer.push(data);
  } else if (process.env.NODE_ENV === 'development') {
    console.log('DATA LAYER', data); // eslint-disable-line
  }
};

export const pushData = (eventName, data) => {
  try {
    const newData = Object.assign({}, data, { event: eventName });
    pushToDataLayer(newData);
  } catch (e) {
    // Handle exception
  }
};

export const getVirtualUrl = (mapping, newPrefix, path, lang) => {
  // Get path without lang prefix
  const localePrefix = languages[lang] && languages[lang].value
    ? `/${languages[lang].value}` : '';
  const pathWoLang = path === localePrefix ? '/' : removePrefix(path, localePrefix);

  // Add custom prefix to all paths
  const normPrefixedPath = `${newPrefix}${pathWoLang}`;
  const orgPrefixedPath = `${newPrefix}${path}`;

  // Try to map prefixed paths
  return (
    mapping[orgPrefixedPath] ||
    mapping[normPrefixedPath] ||
    orgPrefixedPath
  );
};
