import React, { Component } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as userSessionActions from 'app/actions/user-session';


/**
 * Wrap a Route component with this HOC if you need to check for user session and
 * redirect when needed
 * @param {function(user, location)} options.shouldRedirect
 * @param {String|function(userSession, router)} options.redirectTo
 */
export default ({
  shouldRedirect = ({ user, isLoading }) => (!user && !isLoading),
  redirectTo = '/',
  saveCurrentPage = true
} = {}) => (ComposedRoute) => {
  class AllowOrRedirect extends Component {
    redirected = false

    componentDidMount() {
      this._checkAndRedirect();
    }

    componentDidUpdate() {
      this._checkAndRedirect();
    }

    get isRedirectNeeded() {
      const { location, userSession } = this.props;
      return (
        shouldRedirect(userSession, location) &&
        !this.redirected
      );
    }

    _checkAndRedirect() {
      const {
        history,
        userSession,
        location,
        saveRedirectBackUrl
      } = this.props;

      if (this.isRedirectNeeded) {
        if (redirectTo) {
          if (saveCurrentPage) {
            saveRedirectBackUrl(location);
          }
          this.redirected = true
          if (typeof redirectTo === 'function') {
            redirectTo(userSession, history);
          } else {
            history.replace(redirectTo);
          }
        }
      }
    }

    render() {
      return this.isRedirectNeeded || this.redirected
        ? null
        : <ComposedRoute {...this.props} />;
    }
  }

  // TODO: move to Flow types
  // AllowOrRedirect.propTypes = {
  //   userSession: PropTypes.object,
  //   history: PropTypes.object.isRequired,
  //   location: PropTypes.object.isRequired,
  //   saveRedirectBackUrl: PropTypes.func.isRequired
  // };

  const mapStateToProps = ({ userSession }) => ({
    userSession
  });

  return compose(
    connect(mapStateToProps, userSessionActions),
    withRouter
  )(AllowOrRedirect);
};
