module.exports = {
  'CURRENCY.POPULAR_CURRENCIES': 'Popular',
  'CURRENCY.AFN': 'Afghan Afghani',
  'CURRENCY.ALL': 'Albanian Lek',
  'CURRENCY.DZD': 'Algerian Dinar',
  'CURRENCY.AOA': 'Angolan Kwanza',
  'CURRENCY.ARS': 'Argentine Peso',
  'CURRENCY.AMD': 'Armenian Dram',
  'CURRENCY.AWG': 'Aruban Florin',
  'CURRENCY.AUD': 'Australian Dollar',
  'CURRENCY.AZN': 'Azerbaijani Manat',
  'CURRENCY.BSD': 'Bahamian Dollar',
  'CURRENCY.BDT': 'Bangladeshi Taka',
  'CURRENCY.BBD': 'Barbadian Dollar',
  'CURRENCY.BZD': 'Belize Dollar',
  'CURRENCY.BMD': 'Bermudian Dollar',
  'CURRENCY.BOB': 'Bolivian Boliviano',
  'CURRENCY.BAM': 'Bosnia & Herzegovina Convertible Mark',
  'CURRENCY.BWP': 'Botswana Pula',
  'CURRENCY.BRL': 'Brazilian Real',
  'CURRENCY.GBP': 'British Pound',
  'CURRENCY.BND': 'Brunei Dollar',
  'CURRENCY.BGN': 'Bulgarian Lev',
  'CURRENCY.BIF': 'Burundian Franc',
  'CURRENCY.KHR': 'Cambodian Riel',
  'CURRENCY.CAD': 'Canadian Dollar',
  'CURRENCY.CVE': 'Cape Verdean Escudo',
  'CURRENCY.KYD': 'Cayman Islands Dollar',
  'CURRENCY.XAF': 'Central African Cfa Franc',
  'CURRENCY.XPF': 'Cfp Franc',
  'CURRENCY.CLP': 'Chilean Peso',
  'CURRENCY.CNY': 'Chinese Renminbi Yuan',
  'CURRENCY.COP': 'Colombian Peso',
  'CURRENCY.KMF': 'Comorian Franc',
  'CURRENCY.CDF': 'Congolese Franc',
  'CURRENCY.CRC': 'Costa Rican Colón',
  'CURRENCY.HRK': 'Croatian Kuna',
  'CURRENCY.CZK': 'Czech Koruna',
  'CURRENCY.DKK': 'Danish Krone',
  'CURRENCY.DJF': 'Djiboutian Franc',
  'CURRENCY.DOP': 'Dominican Peso',
  'CURRENCY.XCD': 'East Caribbean Dollar',
  'CURRENCY.EGP': 'Egyptian Pound',
  'CURRENCY.ETB': 'Ethiopian Birr',
  'CURRENCY.EUR': 'Euro',
  'CURRENCY.FKP': 'Falkland Islands Pound',
  'CURRENCY.FJD': 'Fijian Dollar',
  'CURRENCY.GMD': 'Gambian Dalasi',
  'CURRENCY.GEL': 'Georgian Lari',
  'CURRENCY.GIP': 'Gibraltar Pound',
  'CURRENCY.GTQ': 'Guatemalan Quetzal',
  'CURRENCY.GNF': 'Guinean Franc',
  'CURRENCY.GYD': 'Guyanese Dollar',
  'CURRENCY.HTG': 'Haitian Gourde',
  'CURRENCY.HNL': 'Honduran Lempira',
  'CURRENCY.HKD': 'Hong Kong Dollar',
  'CURRENCY.HUF': 'Hungarian Forint',
  'CURRENCY.ISK': 'Icelandic Króna',
  'CURRENCY.INR': 'Indian Rupee',
  'CURRENCY.IDR': 'Indonesian Rupiah',
  'CURRENCY.ILS': 'Israeli New Sheqel',
  'CURRENCY.JMD': 'Jamaican Dollar',
  'CURRENCY.JPY': 'Japanese Yen',
  'CURRENCY.KZT': 'Kazakhstani Tenge',
  'CURRENCY.KES': 'Kenyan Shilling',
  'CURRENCY.KGS': 'Kyrgyzstani Som',
  'CURRENCY.LAK': 'Lao Kip',
  'CURRENCY.LBP': 'Lebanese Pound',
  'CURRENCY.LSL': 'Lesotho Loti',
  'CURRENCY.LRD': 'Liberian Dollar',
  'CURRENCY.MOP': 'Macanese Pataca',
  'CURRENCY.MKD': 'Macedonian Denar',
  'CURRENCY.MGA': 'Malagasy Ariary',
  'CURRENCY.MWK': 'Malawian Kwacha',
  'CURRENCY.MYR': 'Malaysian Ringgit',
  'CURRENCY.MVR': 'Maldivian Rufiyaa',
  'CURRENCY.MRO': 'Mauritanian Ouguiya',
  'CURRENCY.MUR': 'Mauritian Rupee',
  'CURRENCY.MXN': 'Mexican Peso',
  'CURRENCY.MDL': 'Moldovan Leu',
  'CURRENCY.MNT': 'Mongolian Tögrög',
  'CURRENCY.MAD': 'Moroccan Dirham',
  'CURRENCY.MZN': 'Mozambican Metical',
  'CURRENCY.MMK': 'Myanmar Kyat',
  'CURRENCY.NAD': 'Namibian Dollar',
  'CURRENCY.NPR': 'Nepalese Rupee',
  'CURRENCY.ANG': 'Netherlands Antillean Gulden',
  'CURRENCY.TWD': 'New Taiwan Dollar',
  'CURRENCY.NZD': 'New Zealand Dollar',
  'CURRENCY.NIO': 'Nicaraguan Córdoba',
  'CURRENCY.NGN': 'Nigerian Naira',
  'CURRENCY.NOK': 'Norwegian Krone',
  'CURRENCY.PKR': 'Pakistani Rupee',
  'CURRENCY.PAB': 'Panamanian Balboa',
  'CURRENCY.PGK': 'Papua New Guinean Kina',
  'CURRENCY.PYG': 'Paraguayan Guaraní',
  'CURRENCY.PEN': 'Peruvian Nuevo Sol',
  'CURRENCY.PHP': 'Philippine Peso',
  'CURRENCY.PLN': 'Polish Złoty',
  'CURRENCY.QAR': 'Qatari Riyal',
  'CURRENCY.RON': 'Romanian Leu',
  'CURRENCY.RUB': 'Russian Ruble',
  'CURRENCY.RWF': 'Rwandan Franc',
  'CURRENCY.STD': 'São Tomé and Príncipe Dobra',
  'CURRENCY.SHP': 'Saint Helenian Pound',
  'CURRENCY.SVC': 'Salvadoran Colón',
  'CURRENCY.WST': 'Samoan Tala',
  'CURRENCY.SAR': 'Saudi Riyal',
  'CURRENCY.RSD': 'Serbian Dinar',
  'CURRENCY.SCR': 'Seychellois Rupee',
  'CURRENCY.SLL': 'Sierra Leonean Leone',
  'CURRENCY.SGD': 'Singapore Dollar',
  'CURRENCY.SBD': 'Solomon Islands Dollar',
  'CURRENCY.SOS': 'Somali Shilling',
  'CURRENCY.ZAR': 'South African Rand',
  'CURRENCY.KRW': 'South Korean Won',
  'CURRENCY.LKR': 'Sri Lankan Rupee',
  'CURRENCY.SRD': 'Surinamese Dollar',
  'CURRENCY.SZL': 'Swazi Lilangeni',
  'CURRENCY.SEK': 'Swedish Krona',
  'CURRENCY.CHF': 'Swiss Franc',
  'CURRENCY.TJS': 'Tajikistani Somoni',
  'CURRENCY.TZS': 'Tanzanian Shilling',
  'CURRENCY.THB': 'Thai Baht',
  'CURRENCY.TOP': 'Tongan Paʻanga',
  'CURRENCY.TTD': 'Trinidad and Tobago Dollar',
  'CURRENCY.TRY': 'Turkish Lira',
  'CURRENCY.UGX': 'Ugandan Shilling',
  'CURRENCY.UAH': 'Ukrainian Hryvnia',
  'CURRENCY.AED': 'United Arab Emirates Dirham',
  'CURRENCY.USD': 'United States Dollar',
  'CURRENCY.UYU': 'Uruguayan Peso',
  'CURRENCY.UZS': 'Uzbekistani Som',
  'CURRENCY.VUV': 'Vanuatu Vatu',
  'CURRENCY.VND': 'Vietnamese Đồng',
  'CURRENCY.XOF': 'West African Cfa Franc',
  'CURRENCY.YER': 'Yemeni Rial',
  'CURRENCY.ZMW': 'Zambian Kwacha',
}
