import { put, call } from 'redux-saga/effects';
import { travelagent } from 'mz-sdk';
import { showErrorNotification, showSuccessNotification } from 'app/sagas/utils';
import { getTravelAgent } from 'app/actions/travel-agent';
import {
  TA_PAYMENTS_ACCOUNT_TYPE,
  TA_PAYMENTS_PAYPAL_EMAIL,
  TA_PAYMENTS_ACCOUNT_TYPE_PAYPAL
} from 'app/constants';


export default function* createPaymentAccount(action) {
  const { payload } = action;
  const type = payload[TA_PAYMENTS_ACCOUNT_TYPE].value;

  const createAccount = type === TA_PAYMENTS_ACCOUNT_TYPE_PAYPAL ?
    travelagent.addPaypalAccount : travelagent.addBankAccount;

  try {
    const accountData = type === TA_PAYMENTS_ACCOUNT_TYPE_PAYPAL ? {
      paypal_email: payload[TA_PAYMENTS_PAYPAL_EMAIL]
    } : {};

    yield call(
      createAccount,
      accountData
    );
    const getTravelAgentAction = yield call(getTravelAgent);
    yield put(getTravelAgentAction);
    yield call(showSuccessNotification, {
      messageId: 'TA_ACCOUNT.PAYMENTS.ACCOUNT_ADDED',
      titleId: 'SUCCESS'
    });
    yield call(action.resolvePromise);
  } catch (error) {
    yield call(showErrorNotification, { error });
    yield call(action.rejectPromise);
  }
}
