import {
  SET_CURRENCY,
  CRITICAL_ERROR,
  INIT_APP
} from '../constants';

import { createAction } from '../utils/redux';


export const setCurrency = createAction(
  SET_CURRENCY
);

export const criticalError = createAction(
  CRITICAL_ERROR
);

export const initApp = createAction(
  INIT_APP
);
