import {
  DO_BOOK_TRIP
} from 'app/constants';
import { takeLatest } from 'redux-saga';
import doBookTrip from './doBookTrip';


export default function* watchDoBookTrip() {
  yield takeLatest(DO_BOOK_TRIP, doBookTrip);
}
