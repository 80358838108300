import { put, call, select } from 'redux-saga/effects';
import { setStoredSearch } from 'app/utils/storage';
import { saveSearch } from 'app/actions/search-results';
import { getLocation } from 'app/sagas/selectors'


export default function* storeSearch(params) {
  const { start_address = {}, end_address = {} } = params;
  const storedParams = {
    ...params,
    start_address: start_address.value,
    end_address: end_address.value
  };
  yield call(setStoredSearch, storedParams);

  const { query } = yield select(getLocation);
  yield put(saveSearch(query));
}
