import moment from 'moment';
import _ from 'lodash';
import { call, put, select, take, fork } from 'redux-saga/effects';
import { showNotification } from 'app/actions/notifications';
import { getDomainConfig } from 'app/utils/browser';
import { getRedirectBackUrl, getLocation } from 'app/sagas/selectors';
import { deeplink, APIError, setConfig } from 'mz-sdk';
import { trackNotification } from 'app/sagas/logging/loggly';
import {
  getStoredSearch,
  getStoredRefParam,
  setStoredRefParam
} from 'app/utils/storage';

import {
  NOTIFICATION_ERROR,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_INFO,
  NOTIFICATION_WARNING
} from 'app/constants';

/**
 * Custom third-party Error for showing other APIs messages.
 * @param {string} message Error message.
 * @param {string} code Error code name.
 * @param {string} type Error type.
 */
export class ThirdPartyError extends Error {
  constructor(message, code, type) {
    super(message);
    this.type = type;
    this.code = code;
    this.message = message;
    this.name = 'ThirdPartyError';
  }
}


/**
 * Normalize error message from the backend to be always as string
 * for notification showing
 * @param  {any} msg
 * @return {string}
 */
export function normalizeErrorMessage(msg, nonField = false) {
  if (!msg) {
    return '';
  } else if (_.isString(msg)) {
    return msg;
  } else if (_.isPlainObject(msg)) {
    const rootError = msg.user_message || msg.message || msg.detail;
    if (rootError) return rootError;

    return _.toPairs(msg)
      .filter(p => p[1])
      .map(p => {
        const errors = Array.isArray(p[1]) ? p[1] : [p[1]];
        const errorMessages = errors
          .map(e => {
            return _.isPlainObject(e) ? e.user_message || e.message : e;
          })
          .join(', ');

        return nonField || p[0] === 'non_field_errors'
          ? errorMessages
          : `in ${p[0]}: ${errorMessages}`;
      })
      .join('; ');
  }
  return '';
}

/**
 * Put action to show error notification.
 * @param  {Object} obj.error        Error object from an exception
 * @param  {String} obj.messageId    Id of translation item to be shown if error
 *                                   object is not provided or if it is empty.
 */
export function* showErrorNotification({
  error = null,
  messageId = 'ERRORS.UNKNOWN_ERROR',
  titleId = 'ERROR'
}) {
  let errorMsg = null;
  if (error instanceof APIError) {
    errorMsg = normalizeErrorMessage(error.response);
  } else if (error instanceof ThirdPartyError) {
    errorMsg = normalizeErrorMessage(error.message);
  }

  const preparedErrMsg = normalizeErrorMessage(errorMsg);
  const notificationData = {
    type: NOTIFICATION_ERROR,
    message: preparedErrMsg,
    messageId: (!preparedErrMsg && messageId) || null,
    messageIntl: error && error.i18n || null,
    titleId
  };

  const errorAction = yield call(showNotification, notificationData);
  yield fork(trackNotification, notificationData);
  yield put(errorAction);
}

/**
 * Put an action to show success notification
 * @param  {Object}     message   { message|messageId }
 * @return {Generator}
 */
export function* showSuccessNotification(message) {
  const notificationData = {
    type: NOTIFICATION_SUCCESS,
    ...message
  };
  yield put(showNotification(notificationData));
  yield fork(trackNotification, notificationData);
}

/**
 * Put an action to show info notification
 * @param  {Object}     message   { message|messageId }
 * @return {Generator}
 */
export function* showInfoNotification(message) {
  const notificationData = {
    type: NOTIFICATION_INFO,
    ...message
  };
  yield put(showNotification(notificationData));
  yield fork(trackNotification, notificationData);
}

/**
 * Put an action to show warning notification
 * @param  {Object}     message   { message|messageId }
 * @return {Generator}
 */
export function* showWarningNotification(message) {
  const notificationData = {
    type: NOTIFICATION_WARNING,
    ...message
  };
  yield put(showNotification(notificationData));
  yield fork(trackNotification, notificationData);
}

/**
 * Redirect user to the url saved as back url in user-session reducer.
 * If back url is not defined user will be redirected to home page
 * @return {Generator}
 */
export function* redirectBack() {
  const url = yield select(getRedirectBackUrl);

  window.location.href = `${url?.pathname}${url?.search || ''}`
}

export function* parseUrlParams() {
  const { search } = yield select(getLocation);
  let query = search;

  if (!query) {
    const storedSearch = yield call(getStoredSearch);
    query = storedSearch || '';
  }

  // dates in search url in 'en' locale
  const tempLocale = moment.locale();
  moment.locale('en');
  const parsed = yield call(deeplink.parse, query);
  moment.locale(tempLocale);
  return parsed;
}

/**
 * Shortcut to call an SDK method and catch APIError exceptions
 * @param  {Object} action A redux action object
 * @param  {Func} sdkMethod An SDK method involving an API call
 * @param {Func} mapPayloadToParams A function that maps the action payload to
 *               the SDK method parameters.
 * @return {Object} The API response
 */
export function* apiCall(action, sdkMethod, mapPayloadToParams = () => ({})) {
  try {
    const data = yield call(sdkMethod, mapPayloadToParams(action.payload));
    yield call(action.resolvePromise);
    return data;
  } catch (error) {
    if (error instanceof APIError) {
      const notifyErrorAction = yield call(
        showNotification, {
          type: NOTIFICATION_ERROR,
          message: error.message,
          titleId: 'ERROR'
        });
      yield put(notifyErrorAction);
      yield call(action.rejectPromise);
      return null;
    }
    throw error;
  }
}

/**
 * Try to get a ref value from query, app config or saved session data.
 * Update ref value in SDK. Always returns an object with ref param if
 * it is not empty, otherwise just empty object.
 * @returns {Object}
 */
export function* handleRefParam() {
  const { query } = yield select(getLocation);
  const storedRef = yield call(getStoredRefParam);
  const ref = query.ref || getDomainConfig().ref || (storedRef && storedRef.ref);

  setConfig({ PARTNER_REF: ref });

  if (ref) {
    const param = { ref };
    yield call(setStoredRefParam, param);
    return param;
  }
  return {};
}

/**
 * Helper to for handling and queuing action requests
 * @param {Object} requestChannel Channel descriptor
 * @param {Function} handleRequest Saga or function that will be called when action dispatched
 */
export function* takeLatestChannelAction(requestChannel, handleRequest) {
  while (true) {
    const action = yield take(requestChannel);
    yield call(handleRequest, action);
  }
}

export function* getPartnerTrackingParam() {
  let param;

  const { query } = yield select(getLocation);

  if (query.partner_tracking_id || query.gid || query.guid) {
    param = { partner_tracking_id: query.partner_tracking_id || query.gid || query.guid };
  }

  return param || {};
}

export function* takeLast(actionSelector, fn) {
  while (true) {
    const action = yield take(actionSelector);
    yield call(fn, action);
  }
}


/**
 * Helper to process API responses involving pagination
 * @param  {Func} method An SDK method
 * @param  {Object} params Params for the SDK method
 * @param {Object} options An action to handle the data fetch by the SDK method
 * @param {Func} options.pageDataAction An action to put over every page data
 * @param {Func} options.fullDataAction An action to put over the accumulated data of all pages
 */
export function* getAllPages(method, params, options = {}) {
  let page = 1;
  const { pageDataAction, fullDataAction } = options;
  const pages = [];

  while (true) {
    const data = yield call(method, { ...params, page });
    pages.push(data);
    page++;

    if (pageDataAction) {
      yield put(pageDataAction(data));
    }

    if (!data.next) {
      if (fullDataAction) {
        const fullData = pages.reduce(
          (accumulator, { results }) => accumulator.concat(results), []
        );
        yield put(fullDataAction(fullData));
      }
      return;
    }
  }
}
