import { put, select, call } from 'redux-saga/effects';
import { change } from 'redux-form';
import {
  BOOK_TRIP_FORM,
  COUPON_STATUS
} from 'app/constants';
import { APIError } from 'mz-sdk';
import { getCouponStatus, getCouponFieldValue } from 'app/sagas/selectors';
import { reportAddCoupon, reportInvalidCoupon } from 'app/sagas/logging/google';
import getPrice from 'app/sagas/watchBookTrip/watchUpdatePrice/getPrice';


const VALID_COUPON_STATUS = {
  wrongCode: false,
  disabled: false,
  showRefresh: false,
  showHint: false
};
const WRONG_COUPON_STATUS = {
  wrongCode: true,
  disabled: false,
  showRefresh: false,
  showHint: false,
  savedValue: ''
};
const ERROR_COUPON_STATUS = {
  wrongCode: false,
  disabled: false,
  showRefresh: true,
  showHint: true,
  savedValue: ''
};

export default function* addCoupon() {
  const status = yield select(getCouponStatus);
  yield put(change(BOOK_TRIP_FORM, COUPON_STATUS, {
    ...status,
    disabled: true
  }));

  try {
    const couponCode = yield select(getCouponFieldValue);
    const priceParams = {
      coupon_code: couponCode
    };

    yield call(getPrice, { priceParams });
    yield put(change(BOOK_TRIP_FORM, COUPON_STATUS, {
      ...VALID_COUPON_STATUS,
      savedValue: couponCode
    }));
    yield call(reportAddCoupon);
  } catch (e) {
    if (e instanceof APIError) {
      yield put(change(BOOK_TRIP_FORM, COUPON_STATUS, WRONG_COUPON_STATUS));
      yield call(reportInvalidCoupon);
    } else {
      yield put(change(BOOK_TRIP_FORM, COUPON_STATUS, ERROR_COUPON_STATUS));
    }
  }
}
