import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'translations';

import style from './style.css';


/**
 * Data field component for displaying unmodifiable data
 */
const DataField = ({
  value,
  label,
  labelPrefix,
  labelPrefixColor,
  className
}) => (
  <div className={classnames(className, style.field)} >
    <label className={style.label}>
      {labelPrefix &&
        <span
          className={style['label-prefix']}
          style={labelPrefixColor && { color: labelPrefixColor }}
        >
          <FormattedMessage {...labelPrefix} />
        </span>
      }
      <FormattedMessage {...label} />
    </label>
    <span>{value}</span>
  </div>
);

// TODO: move to Flow types
// DataField.propTypes = {
//   value: PropTypes.string.isRequired,
//   label: PropTypes.object.isRequired,
//   labelPrefix: PropTypes.object,
//   labelPrefixColor: PropTypes.string,
//   className: PropTypes.string
// };

export default DataField;
