import React from 'react';
import classnames from 'classnames';
import { Layout } from 'app/components/base/Layout';

import style from './style.css';

export const CheckMark = () => (
  /* eslint-disable */
  <svg width="8.775px" height="6.579px" viewBox="0 0 8.775 6.579">
    <path
      d="M7.917,0.081c-0.107-0.108-0.282-0.108-0.388,0L3.441,4.15c-0.107,0.109-0.282,0.109-0.388,0L1.261,2.326C1.208,2.272,1.139,2.245,1.069,2.245c-0.071,0-0.142,0.026-0.196,0.081L0.081,3.038C0.029,3.092,0,3.159,0,3.23c0,0.071,0.029,0.145,0.082,0.199l1.806,1.896C1.995,5.433,2.17,5.608,2.276,5.716l0.777,0.783c0.107,0.107,0.281,0.107,0.388,0l5.253-5.244c0.107-0.108,0.107-0.284,0-0.392L7.917,0.081z" />
  </svg>
  /* eslint-enable */
);

export const CircledCheckMark = () => (
  /* eslint-disable */
  <svg width="11.067px" height="11.067px" viewBox="0 0 11.067 11.067">
    <path
      d="M5.534,0C2.478,0,0,2.478,0,5.534c0,3.056,2.478,5.534,5.534,5.534s5.534-2.477,5.534-5.534C11.067,2.478,8.589,0,5.534,0zM9.109,4.409L5.14,8.378c-0.081,0.082-0.213,0.082-0.294,0L4.26,7.785C4.179,7.704,4.047,7.571,3.967,7.489L2.602,6.054C2.562,6.014,2.54,5.957,2.54,5.903c0-0.053,0.021-0.105,0.061-0.145L3.2,5.219C3.24,5.178,3.294,5.158,3.348,5.158c0.053,0,0.105,0.021,0.145,0.062l1.354,1.381c0.081,0.082,0.213,0.082,0.294,0L8.23,3.52c0.081-0.082,0.213-0.082,0.293,0L9.11,4.112C9.19,4.193,9.19,4.327,9.109,4.409z" />
  </svg>
  /* eslint-enable */
);

export const CrossMark = () => (
  /* eslint-disable */
  <svg width="6.583px" height="6.579px" viewBox="0 0 6.583 6.579">
    <path d="M1.176,6.586L3.292 4.471 5.407 6.586 6.583 5.41 4.468 3.295 6.583 1.18 5.407 0.003 3.292 2.119 1.176 0.003 0 1.18 2.115 3.295 0 5.41 z" />
  </svg>
  /* eslint-enable */
);

export const CircledCrossMark = () => (
  /* eslint-disable */
  <svg width="10.685px" height="10.685px" viewBox="0 0 10.685 10.685">
    <path
      d="M5.343,0C2.392,0,0,2.392,0,5.343s2.392,5.343,5.343,5.343s5.343-2.392,5.343-5.343S8.293,0,5.343,0zM7.985,7.041L7.041,7.985L5.343,6.287L3.644,7.985L2.7,7.041l1.698-1.698L2.7,3.644L3.644,2.7l1.698,1.698L7.041,2.7l0.944,0.944L6.287,5.343L7.985,7.041z" />
  </svg>
  /* eslint-enable */
);

const iconMap = {
  checked: {
    normal: CheckMark,
    circled: CircledCheckMark
  },
  unchecked: {
    normal: CrossMark,
    circled: CircledCrossMark
  }
};

const CheckedText = ({
  checked,
  text,
  altText,
  iconStyle = 'normal',
  className,
  ...props
}) => {
  const Icon = iconMap[checked ? 'checked' : 'unchecked'][iconStyle];
  return (
    <Layout
      direction="row"
      align="start center"
      className={classnames({
        [style.checked]: checked,
        [style.unchecked]: !checked
      }, className)}
      {...props}
    >
      <Icon />
      <span className={style.text} title={altText}>{text}</span>
    </Layout>
  );
};

// TODO: move to Flow types
// CheckedText.propTypes = {
//   checked: PropTypes.bool.isRequired,
//   text: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.object
//   ]).isRequired,
//   altText: PropTypes.string,
//   iconStyle: PropTypes.string,
//   className: PropTypes.string
// };

export default CheckedText;
