import { call, select } from 'redux-saga/effects';
import {
  getBookTripForm,
  getActiveCurrency,
  getBookTripParams,
  getAddedUpgrades,
  isBookingEmailTaken,
  getTripPrice
} from 'app/sagas/selectors';
import _ from 'lodash';
import config from 'config'
import { hook } from 'app/utils/hook';
import { preprocessTripObject } from 'app/utils/trip';
import { getPartnerTrackingParam } from 'app/sagas/utils';
import {
  CONSENT_EMAIL_FIELD_NAME,
  DEPARTING_TRIP_FIELD_NAME,
  RETURN_TRIP_FIELD_NAME,
  DEPARTING_FLIGHT_FIELD_NAME,
  RETURNING_FLIGHT_FIELD_NAME,
  DEPARTING_CRUISE_FIELD_NAME,
  DEPARTING_SHIP_FIELD_NAME,
  ALTERNATIVE_TIME_INDEX,
  RETURN_ALTERNATIVE_TIME_INDEX,
  SELECTED_TICKET_FIELD_NAME,
  SELECTED_RETURN_TICKET_FIELD_NAME,
  RIDE_DURATION_FIELD_NAME,
  GRATUITY_STATUS,
  FIRST_NAME_FIELD_NAME,
  LAST_NAME_FIELD_NAME,
  EMAIL_FIELD_NAME,
  PHONE_CODE_FIELD_NAME,
  PHONE_NUMBER_FIELD_NAME,
  SPECIAL_INSTRUCTIONS_FIELD_NAME,
  PASSWORD_FIELD_NAME,
  TA_CONFIRMATION_EMAIL_FIELD_NAME,
  UPGRADES_FIELD_NAME,
  TRAVELER_FIRST_NAME_FIELD_NAME,
  TRAVELER_LAST_NAME_FIELD_NAME,
  TRAVELER_EMAIL_FIELD_NAME,
  ACCOUNT_ID_STATUS,
  SAVE_TA_PROFILE_FIELD_NAME,
  SAVE_TA_CC_FIELD_NAME,
  SELECTED_CUSTOMER_PROFILE,
  ROUND_TRIP_MODE_VALUE,
  CUSTOMIZE_RESERVATION_DATA_HOOK,
  TRAVELER_FIELD_NAME,
  COUPON_STATUS,
  LOYALTY_MEMBER_NUMBER_FIELD_NAME,
  TRAIN_INFO_FIELD_NAME,
  RETURN_TRAIN_INFO_FIELD_NAME
} from 'app/constants';


export const getAutocompleteValue = (fieldValue) => {
  if (!fieldValue) return null;
  return fieldValue.value || fieldValue.display;
};

export const omitFieldCondition = (field) => {
  return (
    _.isNil(field) ||
    (typeof field === 'string' && field.trim() === '')
  );
};

export const getSaveProfileParams = (formValues) => {
  let params;
  const { id: selectedProfileId, uid } = formValues[SELECTED_CUSTOMER_PROFILE] || {};

  if (selectedProfileId) {
    params = {
      update_ta_profile_uid: uid || '',
      update_ta_profile_id: uid ? '' : selectedProfileId,
      update_ta_profile: formValues[SAVE_TA_PROFILE_FIELD_NAME],
      update_ta_profile_po: formValues[SAVE_TA_CC_FIELD_NAME]
    };
  } else {
    params = {
      create_ta_profile: formValues[SAVE_TA_PROFILE_FIELD_NAME],
      create_ta_profile_po: formValues[SAVE_TA_CC_FIELD_NAME]
    };
  }

  return params;
};

const getFlightData = (form, preparedTrip, field, prefix = '') => {
  const flight = form[field];
  return !flight ? {} : {
    [`${prefix}airline_iata_code`]: flight.airlineCode || flight.flightCode.substr(0, 2),
    [`${prefix}flight_number`]: flight.flightNumber
  };
};

const getTrainData = (form, field, prefix = '') => {
  const trainData = form[field];
  return !trainData ? {} : {
    [`${prefix}train_company`]: trainData.company.toLowerCase(),
    [`${prefix}train_number`]: trainData.train.train_number
  };
};

export default function* getReservationData() {
  const price = yield select(getTripPrice);
  const form = yield select(getBookTripForm);
  const emailTaken = yield select(isBookingEmailTaken);
  const saveProfileParams = getSaveProfileParams(form);

  const currency = yield select(getActiveCurrency);
  const params = yield select(getBookTripParams);
  const { partner_tracking_id } = yield call(getPartnerTrackingParam);

  const departingTrip = yield call(preprocessTripObject, form[DEPARTING_TRIP_FIELD_NAME]);
  const returnTrip = yield call(
    preprocessTripObject,
    form[RETURN_TRIP_FIELD_NAME],
    ROUND_TRIP_MODE_VALUE
  );

  const upgrades = form[UPGRADES_FIELD_NAME];

  const extra_pax_info = [];
  const { passengersCount } = departingTrip.details;
  // filter optional amenities to have only ids that were present in trip amenities
  const optional_amenities = getAddedUpgrades(upgrades);
  const extraPaxRequired = _.get(departingTrip, 'details.extraPaxRequired', null);

  if (form[TRAVELER_FIELD_NAME] || extraPaxRequired) {
    for (let i = 0; i < passengersCount - 1; i++) {
      const firstName = form[`${TRAVELER_FIRST_NAME_FIELD_NAME}-${i}`] || '';
      const lastName = form[`${TRAVELER_LAST_NAME_FIELD_NAME}-${i}`] || '';
      const email = form[`${TRAVELER_EMAIL_FIELD_NAME}-${i}`] || '';

      if (firstName || lastName || email) {
        extra_pax_info.push({
          email,
          first_name: firstName,
          last_name: lastName
        });
      }
    }
  }

  // Fields for registering the user
  const regData = {};
  if (!emailTaken) {
    regData.password = form[PASSWORD_FIELD_NAME];
  }

  const phoneCountryCodeName = ((
    form[PHONE_CODE_FIELD_NAME] &&
    form[PHONE_CODE_FIELD_NAME].code
  ) || '').toUpperCase();

  let couponCode = null;
  const couponStatus = form[COUPON_STATUS];
  if (couponStatus && couponStatus.savedValue && !couponStatus.wrongCode) {
    couponCode = couponStatus.savedValue;
  }

  // Add loyalty if it is defined
  const loyaltyData = {};
  if (form[LOYALTY_MEMBER_NUMBER_FIELD_NAME]) {
    loyaltyData.loyalty = { number: form[LOYALTY_MEMBER_NUMBER_FIELD_NAME] }
  }

  const reservationData = _.omitBy({
    ...regData,
    ...saveProfileParams,
    ...loyaltyData,
    ...getFlightData(form, departingTrip, DEPARTING_FLIGHT_FIELD_NAME),
    ...getFlightData(form, returnTrip, RETURNING_FLIGHT_FIELD_NAME, 'return_'),
    ...getTrainData(form, TRAIN_INFO_FIELD_NAME),
    ...getTrainData(form, RETURN_TRAIN_INFO_FIELD_NAME, 'return_'),
    ondemand: departingTrip.details.onDemand,
    google_analytics_client_id: window.gaClientId || '',
    domain: config.CUSTOM_BOOKING_DOMAIN || config.FALLBACK_DOMAIN || window.location.hostname,
    search_id: params.session_id,
    trip_id: params.trip_id,
    first_name: form[FIRST_NAME_FIELD_NAME],
    last_name: form[LAST_NAME_FIELD_NAME],
    email: form[EMAIL_FIELD_NAME],
    country_code_name: phoneCountryCodeName,
    phone_number: form[PHONE_NUMBER_FIELD_NAME],

    alternative_time_index: form[SELECTED_TICKET_FIELD_NAME]
      ? form[SELECTED_TICKET_FIELD_NAME].scheduleId
      : form[ALTERNATIVE_TIME_INDEX],

    return_alternative_time_index: form[SELECTED_RETURN_TICKET_FIELD_NAME]
      ? form[SELECTED_RETURN_TICKET_FIELD_NAME].scheduleId
      : form[RETURN_ALTERNATIVE_TIME_INDEX],

    cruise_line: getAutocompleteValue(form[DEPARTING_CRUISE_FIELD_NAME]),
    ship_name: form[DEPARTING_SHIP_FIELD_NAME],

    selected_ticket_id: form[SELECTED_TICKET_FIELD_NAME]
      ? form[SELECTED_TICKET_FIELD_NAME].ticketId
      : null,
    return_selected_ticket_id: form[SELECTED_RETURN_TICKET_FIELD_NAME]
      ? form[SELECTED_RETURN_TICKET_FIELD_NAME].ticketId
      : null,

    hourly_booking_duration: form[RIDE_DURATION_FIELD_NAME],
    coupon_code: couponCode,
    commission: price.bookingFeeValue,
    gratuity_percent: form[GRATUITY_STATUS].savedValue,
    customer_special_instructions: form[SPECIAL_INSTRUCTIONS_FIELD_NAME],
    extra_email: form[TA_CONFIRMATION_EMAIL_FIELD_NAME],
    currency,
    partner_tracking_id,
    optional_amenities,
    description: null, // todo not implemented yet
    ta_email: (form[ACCOUNT_ID_STATUS] || {}).savedValue,
    extra_pax_info,
    consent_for_contact: !!form[CONSENT_EMAIL_FIELD_NAME],
  }, omitFieldCondition);

  const finalReservationData = yield hook({
    name: CUSTOMIZE_RESERVATION_DATA_HOOK,
    args: [reservationData],
    defaultHandler: () => reservationData
  });

  return finalReservationData;
}
