import { put } from 'redux-saga/effects';
import { getLocaleFreePathname } from 'app/history'
import { setTripError, hidePickupTimeChangedNotification } from 'app/actions/book-trip';
import { removeAll as removeAllSubpages } from 'app/actions/subpages';
import { initialize } from 'redux-form';
import { initialValues as initialBTPFormValues } from 'app/pages/BookTripPage/builder';
import { BOOK_TRIP_FORM } from 'app/constants';

let prevPath = '';
export default function* leaveBookTrip(action) {
  // check if user leave BTP and set initialized flag to false
  // this is done to prevent bookTrip page initialization called more than once
  // when changed language or query of url
  const currentPath = getLocaleFreePathname(action.payload.location.pathname);

  if (prevPath.startsWith('/book') && !currentPath.startsWith('/book')) {
    // close opened modals
    yield put(removeAllSubpages(Date.now()));

    // clear BTP errors
    yield put(setTripError({
      errorCode: null,
      errorMessage: null,
      errorProps: null,
      errorPopup: false
    }));

    // clear time changed notification
    yield put(hidePickupTimeChangedNotification());

    // reset BTP form
    yield put(initialize(BOOK_TRIP_FORM, initialBTPFormValues));
  }

  prevPath = currentPath; // eslint-disable-line require-atomic-updates
}
