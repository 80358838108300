import { put, select } from 'redux-saga/effects';
import { change } from 'redux-form';
import {
  BOOK_TRIP_FORM,
  BOOKING_FEE_STATUS,
  BOOKING_FEE_FIELD_NAME
} from 'app/constants';
import { getBookingFeeStatus, getTripPrice } from 'app/sagas/selectors';


/**
 * Update the booking fee state based on the trip price
 */
export default function* updateBookingFee() {
  const status = yield select(getBookingFeeStatus);
  const price = yield select(getTripPrice);
  const newFee = price.bookingFeeValue ?? '';
  yield put(change(BOOK_TRIP_FORM, BOOKING_FEE_FIELD_NAME, newFee));
  yield put(change(BOOK_TRIP_FORM, BOOKING_FEE_STATUS, {
    ...status,
    savedValue: newFee
  }));
}
