import React from 'react';
import { FormattedMessage } from 'translations';
import { Layout, Flex } from 'app/components/base/Layout';
import classnames from 'classnames';
import moment from 'moment';
import _ from 'lodash';
import Icon from 'app/components/base/Icon';

import style from './style.css';


const messages = {
  unavailable: { id: 'TRIP_DETAILS.UNAVAILABLE' },
  nonStop: { id: 'TRIP_DETAILS.NON_STOP' }
};

/**
 * Component for dislaying schedule row in table with all schedules
 */
const TableItem = ({
  value, ticketTypes, isReturn, selectedTicket, className, onSelect, compact
}) => {
  return (
    <Layout
      key={value.hash}
      nowrap
      size="noshrink"
      direction="row"
      align={{
        'mobile': 'space-between center',
        '> mobile': 'start center'
      }}
      className={classnames(className)}
    >
      <Layout>
        <div className={style.table_header__row__field}>
          <span>{value.departureDatetime.format('HH:mm')}</span>
          <Icon family="mozio" name="schedule-arrow-icon" className={style.departureArrow} />
        </div>
        <div className={style.table_header__row__field}>
          <span>{value.arrivalDatetime.format('HH:mm')}</span>
        </div>
      </Layout>
      {!compact && (
        <Flex size="nogrow">
          <div className={style.table_header__row__field}>
            <span>
              {value.transfers > 0
                  ? value.transfers
                  : <FormattedMessage {...messages.nonStop} />
              }
            </span>
          </div>
        </Flex>
      )}
      {!compact && (
        <Flex size="nogrow">
          <div className={classnames(style.table_header__row__field, style.table_header__duration)}>
            <span>
              {
                moment.duration(value.duration.value, 'seconds').humanize()
              }
            </span>
          </div>
        </Flex>
      )}
      {
        ticketTypes.map((type, i) => {
          const ticket = value.tickets[type.ticketId];
          if (ticket) {
            const ticketValue = {
              ticketId: ticket.ticketId,
              scheduleId: ticket.scheduleId
            };
            return (
              <Flex key={type.ticketId} size={compact ? 'nogrow' : 'auto'}>
                <div className={style.table_header__row__ticket}>
                  <label>
                    <div className={style.table__row__ticketValue}>
                      <input
                        type="radio"
                        checked={_.isEqual(selectedTicket, ticketValue)}
                        onChange={() => onSelect(ticketValue, isReturn)}
                      />
                      <span className={style.table_header__ticket__price}>
                        {ticket.price.display}
                      </span>
                    </div>
                  </label>
                </div>
              </Flex>
            );
          }
          return (
            <Flex size="auto" key={i}>
              <div className={style.table_header__row__ticket}>
                <FormattedMessage {...messages.unavailable} />
              </div>
            </Flex>
          );
        })
      }
    </Layout>
  );
};

// TODO: move to Flow types
// TableItem.propTypes = {
//   isReturn: PropTypes.bool,
//   compact: PropTypes.bool,
//   onSelect: PropTypes.func,
//   value: PropTypes.object,
//   className: PropTypes.string,
//   selectedTicket: PropTypes.object,
//   ticketTypes: PropTypes.array
// };

export default TableItem;
