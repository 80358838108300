import {
  DO_SEARCH,
  RESET_SEARCH,
  SEARCH_RESULTS_SHOW_MORE,
  CANCEL_SEARCH,
  LOAD_SEARCH_FORM,
  LOAD_SEARCH_RESULTS,
  END_SEARCH,
  SAVE_SEARCH,
  SET_RECOMMENDED_TRIP,
  ADD_SEARCH_RESULTS,
  TOGGLE_SEARCH_CATEGORY,
  TOGGLE_SEARCH_CATEGORIES_ALL,
  SELECT_SEARCH_SORT_OPTION,
  TOGGLE_SEARCH_AMENITY,
  TOGGLE_SEARCH_TAG,
  TOGGLE_SEARCH_PROVIDER,
  TOGGLE_MEET_AND_GREET_FILTER,
  UPDATE_SEARCH_FILTERS,
  SWAP_LOCATIONS,
  OUTDATE_SEARCH,
  COPY_RESULT_URL,
  SHARE_RESULT_EMAIL,
  FILTER_SUITCASE_COUNT
} from 'app/constants';
import { createAction } from '../utils/redux';


export const doSearch = createAction(
  DO_SEARCH
);

export const resetSearch = createAction(
  RESET_SEARCH
);

export const cancelSearch = createAction(
  CANCEL_SEARCH
);

export const loadSearchForm = createAction(
  LOAD_SEARCH_FORM
);

export const loadSearchResults = createAction(
  LOAD_SEARCH_RESULTS
);

export const searchEnd = createAction(
  END_SEARCH
);

export const saveSearch = createAction(
  SAVE_SEARCH
);

export const searchSetRecommendedTrip = createAction(
  SET_RECOMMENDED_TRIP
);

export const searchAddResult = createAction(
  ADD_SEARCH_RESULTS
);

export const toggleCategory = createAction(
  TOGGLE_SEARCH_CATEGORY
);

export const selectSortOption = createAction(
  SELECT_SEARCH_SORT_OPTION
);

export const toggleAmenity = createAction(
  TOGGLE_SEARCH_AMENITY
);

export const toggleTag = createAction(
  TOGGLE_SEARCH_TAG
);

export const toggleMeetAndGreetFilter = createAction(
  TOGGLE_MEET_AND_GREET_FILTER
);

export const filterBySuitcaseCount = createAction(
  FILTER_SUITCASE_COUNT
);

export const toggleProvider = createAction(
  TOGGLE_SEARCH_PROVIDER
);

export const selectAllCategories = createAction(
  TOGGLE_SEARCH_CATEGORIES_ALL
);

export const updateSearchFilter = createAction(
  UPDATE_SEARCH_FILTERS
);

export const showMore = createAction(
  SEARCH_RESULTS_SHOW_MORE
);

export const swapLocations = createAction(
  SWAP_LOCATIONS
);

export const outdateSearch = createAction(
  OUTDATE_SEARCH
);

export const shareResultEmail = createAction(
  SHARE_RESULT_EMAIL
);

export const copyResultURL = createAction(
  COPY_RESULT_URL
);
