import React from 'react';
import { FormattedMessage } from 'translations';
import { Layout } from 'app/components/base/Layout';

import style from './style.css';


const messages = {
  close: { id: 'CLOSE' }
};

const InlineHint = ({
  message,
  onClose
}) => (
  <Layout direction="column" className={style.hint} key="message">
    <div>
      {message}
    </div>
    <div className={style.close} onClick={onClose}>
      <FormattedMessage {...messages.close} />
    </div>
  </Layout>
);

// TODO: move to Flow types
// InlineHint.propTypes = {
//   message: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.object
//   ]),
//   onClose: PropTypes.func
// };

export default InlineHint;
