import { call, put } from 'redux-saga/effects';
import { updateTrip, setTripError } from 'app/actions/book-trip';
import { parseUrlParams } from 'app/sagas/utils';
import objectToQueryString from 'mz-utils/objectToQueryString';
import { trackPriceJump } from 'app/sagas/logging/loggly'
import {
  TRIP_EXPIRED_ERROR_CODE,
  TRIP_UPDATED_ERROR_CODE,
  ON_DEMAND_CATEGORY
} from 'app/constants';
import researchTrip from './researchTrip';
import { SEARCH_PARAM_DATE_FORMAT } from 'app/utils/types';
import prepareTripUpdatedDialog from '../../watchChangePickupTime/prepareTripUpdatedDialog';
import getSearchParamsFromTrip, {
  getTripTimeParam,
  getTripReturnTimeParam
} from './getSearchParamsFromTrip';
import moment from 'moment';


/**
 * Helper to shift pickup time of expired trip
 * @param  {Moment} toDate    First date. This date should be after `fromDate`
 * @param  {Moment} fromDate  Second date to compare.
 * @param  {Moment} now       current date in proper TZ,
 *                            to compare shifted date and add Days if needed
 * @return {Moment}           Shifted date object
 */
const getShiftedTime = (toDate, fromDate, now) => {
  const diffInDays = toDate.diff(fromDate, 'days');
  const shiftedTime = fromDate.clone().add(diffInDays, 'days');
  // move to 1 more full day if it's still in the past
  if (shiftedTime.isBefore(now)) {
    shiftedTime.add(1, 'days');
  }
  return shiftedTime;
};


/**
 * Saga that processes result of trip research.
 * @param {Object} trip      Expired trip object
 */
export default function* processResearchTrip(trip) {
  // recalculate and shift pickup times if trip dates are in the past
  let researchTimeParams = { };
  const pickupTime = getTripTimeParam(trip);

  if (pickupTime) {
    const nowTripTZ = moment().utcOffset(pickupTime.value.utcOffset());
    const isPickupTimeExired = pickupTime.value.isBefore(nowTripTZ);

    if (isPickupTimeExired) {
      const isRoundTrip = trip.returnSteps && trip.returnSteps.length > 0;
      const shiftedPickupTime = getShiftedTime(nowTripTZ, pickupTime.value, nowTripTZ);

      researchTimeParams = {
        [pickupTime.paramName]: shiftedPickupTime.locale('en').format(SEARCH_PARAM_DATE_FORMAT)
      };

      if (isRoundTrip) {
        const returnPickupTime = getTripReturnTimeParam(trip);
        const tripDuration = returnPickupTime.value.diff(pickupTime.value);
        const shiftedReturnPickupTime = shiftedPickupTime.clone().add(tripDuration);

        researchTimeParams = {
          ...researchTimeParams,
          [returnPickupTime.paramName]: shiftedReturnPickupTime.locale('en')
            .format(SEARCH_PARAM_DATE_FORMAT)
        };
      }
    }
  }

  // params for new search that can be triggered from "Trip Expired" or "Price updated" dialogs
  const originalSearchParams = yield call(getSearchParamsFromTrip, trip);
  // apply shifted times to show user results instead of empty page because time were in the past
  const searchParams = {
    ...originalSearchParams,
    ...researchTimeParams
  };

  // make research
  const updatedTrip = yield call(
    researchTrip,
    trip.searchId,
    trip.id,
    trip.type === ON_DEMAND_CATEGORY,
    researchTimeParams
  );

  if (updatedTrip) {
    const urlParams = yield call(parseUrlParams);

    const updatedTripUrlParams = {
      ...urlParams,
      session_id: updatedTrip.searchId,
      trip_id: updatedTrip.id
    };

    const newBookTripUrl = `/book${objectToQueryString(updatedTripUrlParams)}`;
    // check prices
    const newPrice = parseFloat(updatedTrip.price.raw);
    const originalPrice = parseFloat(trip.price.raw);

    if (newPrice !== originalPrice) {
      // prepare dialog by filling form values to show pricebreakdown
      // passed updatedTtip object to avoid next calls to retrieve trip
      yield call(prepareTripUpdatedDialog, { updatedTrip });

      yield put(setTripError({
        errorCode: TRIP_UPDATED_ERROR_CODE,
        errorPopup: true,
        errorProps: { newBookTripUrl, searchParams }
      }));
      yield call(trackPriceJump, {
        originalSearchId: trip.searchId,
        originalTripId: trip.id,
        newSearchId: updatedTrip.searchId,
        newTripId: updatedTrip.id,
        oldPriceUsd: originalPrice,
        newPriceUsd: newPrice
      })
    } else {
      yield put(updateTrip({ newBookTripUrl, updatedTrip }));
    }
  } else {
    yield put(setTripError({
      errorCode: TRIP_EXPIRED_ERROR_CODE,
      errorPopup: true,
      errorProps: { searchParams }
    }));
  }
}
