import React from 'react';
import { getMainStep } from 'app/utils/trip';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import CN from 'classnames';
import { Layout } from 'app/components/base/Layout';
import FormInput from 'app/components/base/FormInput';
import InlineHint from 'app/components/base/InlineHint';
import LoadingSpinner from 'app/components/base/LoadingSpinner';
import InteractiveInfoBlock from 'app/components/base/InteractiveInfoBlock';
import { FormattedMessage } from 'translations';
import {
  BOOK_TRIP_FORM,
  DEPARTING_TRIP_FIELD_NAME,
  GRATUITY_FIELD_NAME,
  PRICE_FIELD_NAME,
  GRATUITY_STATUS,
  TRANSPORT_RAIL
} from 'app/constants';
import { closeGratuityHint } from 'app/actions/book-trip';

import style from './style.css';


// Constants
const messages = {
  title: { id: 'BOOK_TRIP.UPGRADES.INCLUDE_GRATUITY' },
  total: { id: 'BOOK_TRIP.UPGRADES.TOTAL_GRATUITY' },
  noGratuity: { id: 'BOOK_TRIP.UPGRADES.NO_GRATUITY' },
  hintMessage: { id: 'BOOK_TRIP.DEFAULT_PRICE_UPDATE_ERROR' }
};

const options = [
  { label: <FormattedMessage {...messages.noGratuity} />, value: '0' },
  { label: '10%', value: '10' },
  { label: '15%', value: '15' },
  { label: '20%', value: '20' }
];

/**
 * Container component that allos to select a gratuity amount
 * for the trip. Used in BTP.
 */
export class Gratuity extends React.Component {

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { onUpdate, updatedAt } = this.props;

    if (updatedAt !== nextProps.updatedAt && onUpdate) {
      onUpdate();
    }
  }

  render() {
    const {
      selected,
      gratuity,
      isGratuityAvailable,
      updating,
      showHint,
      onCloseHint,
      mobile,
      sidebarMode,
      className
    } = this.props;

    const modifiers = {
      [style['container--mobile']]: mobile,
      [style['container--sidebarMode']]: sidebarMode
    };

    const interactiveBlockModifiers = {
      [style['infoBlock--sidebarMode']]: sidebarMode
    };

    const flowDirection = mobile ? 'column' : 'row';
    const containerAlign = mobile ? 'start start' : 'space-between center';
    const optionsAlign = mobile ? 'start start' : 'space-between center';
    const totalAlign = mobile ? 'start center' : 'end center';
    const size = !mobile ? 'grow' : 'auto';

    if (!isGratuityAvailable) {
      return null;
    }

    return (
      <InteractiveInfoBlock type="white" className={CN(className, interactiveBlockModifiers)}>
        <Layout
          direction={flowDirection}
          align={containerAlign}
          size={size}
          className={CN(style.container, modifiers)}
          nowrap
        >
          <Layout direction={flowDirection} nowrap size={size}>
            {!sidebarMode && (
              <div className={style.title}>
                <FormattedMessage {...messages.title} />
              </div>
            )}

            <Layout
              className={style.options}
              direction={flowDirection}
              align={optionsAlign}
              size={size}
              nowrap
            >
              {options.map((amount, index) => (
                <Field
                  className={style.amount}
                  key={index}
                  name={GRATUITY_FIELD_NAME}
                  component={FormInput}
                  type="radio"
                  value={amount.value}
                  checked={amount.value === selected}
                  clean
                >
                  <span className={style.label}>{amount.label}</span>
                </Field>
              ))}
              {showHint &&
                <InlineHint
                  message={<FormattedMessage {...messages.hintMessage} />}
                  onClose={onCloseHint}
                />
              }
            </Layout>
          </Layout>

          {!sidebarMode && (
            <Layout className={style.totalContainer} align={totalAlign} size="noshrink">
              <FormattedMessage {...messages.total} />
              {updating
                ? <LoadingSpinner size="small" className={style.spinner} />
                : <span className={style.total}>{gratuity}</span>
              }
            </Layout>
          )}
        </Layout>
      </InteractiveInfoBlock>
    );
  }
}

// TODO: move to Flow types
// Gratuity.propTypes = {
//   selected: PropTypes.string,
//   gratuity: PropTypes.string,
//   isGratuityAvailable: PropTypes.bool,
//   updating: PropTypes.bool,
//   onCloseHint: PropTypes.func.isRequired,
//   showHint: PropTypes.bool,
//   mobile: PropTypes.bool,
//   sidebarMode: PropTypes.bool,
//   onUpdate: PropTypes.func,
//   updatedAt: PropTypes.number,
// };

Gratuity.defaultProps = {
  gratuity: '--'
};

export const selector = formValueSelector(BOOK_TRIP_FORM);
export const mapStateToProps = state => {
  const trip = selector(state, DEPARTING_TRIP_FIELD_NAME);
  const mainStep = getMainStep(trip);
  const price = selector(state, PRICE_FIELD_NAME);
  const { showHint, updating, updatedAt } = selector(state, GRATUITY_STATUS) || {};

  const isTrain = mainStep && mainStep.type === TRANSPORT_RAIL;
  const acceptsGratuity = mainStep && mainStep.bookingDetails.acceptsGratuity;
  const gratuityIncluded = mainStep && mainStep.bookingDetails.gratuityIncluded;

  const isGratuityAvailable = !gratuityIncluded && !isTrain && acceptsGratuity;

  return {
    selected: selector(state, GRATUITY_FIELD_NAME),
    gratuity: price ? price.gratuity : null,
    isGratuityAvailable,
    updating,
    showHint,
    updatedAt
  };
};

export default compose(
  connect(mapStateToProps, {
    onCloseHint: closeGratuityHint
  })
)(Gratuity);
