import _ from 'lodash';
import { call, select, put } from 'redux-saga/effects';
import { showErrorNotification } from 'app/sagas/utils';
import { setCurrency } from 'app/actions/app';
import { travelagent } from 'mz-sdk';
import { setTravelAgent } from 'app/actions/travel-agent';
import { setUser } from 'app/actions/user-session';
import { getUser } from 'app/sagas/selectors';
import {
  TA_PROFILE_TAX_ID,
  TA_PROFILE_COMMISSION,
  TA_PROFILE_CURRENCY,
  TA_PROFILE_COMMISSION_PERCENTS,
  TA_AGENT_ID_FIELD_NAME,
  TA_IATA_FIELD_NAME,
} from 'app/constants';


const isEmpty = val => val === '' || _.isNil(val);

export default function* submitTAProfile(action) {
  try {
    const { payload } = action;
    const commission = {
      commission: 0,
      commission_percentage: 0
    };

    if (payload[TA_PROFILE_COMMISSION_PERCENTS] > 0) {
      commission.commission_percentage = payload[TA_PROFILE_COMMISSION_PERCENTS];
    } else {
      commission.commission = payload[TA_PROFILE_COMMISSION];
    }

    const requestData = _.omitBy({
      ...commission,
      tax_id: payload[TA_PROFILE_TAX_ID],
      currency: (payload[TA_PROFILE_CURRENCY] || {}).code,
      external_agent_id: payload[TA_AGENT_ID_FIELD_NAME],
      iata_code: payload[TA_IATA_FIELD_NAME],
    }, isEmpty);

    const newTAProfile = yield call(travelagent.updateProfile, requestData);
    const user = yield select(getUser);
    yield put(setUser({ ...user, ...newTAProfile }));
    yield put(setTravelAgent(newTAProfile));
    yield put(setCurrency({ currency: newTAProfile.currency, changedByUser: true }));
    yield call(action.resolvePromise);
  } catch (error) {
    yield call(showErrorNotification, { error });
    yield call(action.rejectPromise);
  }
}
