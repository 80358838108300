import AbstractTransitionAnimator from './AbstractTransitionAnimator';
import animit from 'app/utils/animit';
import { createElement } from './utils';


/**
 * Slide animator for navigator transition.
 */
export default class SimpleSlideAnimator extends AbstractTransitionAnimator {
  constructor(options = {}) {
    super({
      duration: 0.3,
      timing: 'cubic-bezier(.1, .7, .4, 1)',
      delay: 0,
      ...options
    });

    this.blackMaskOpacity = 0.2;
    this.backgroundMask = createElement(`
      <div style="position: absolute; width: 100%; height: 100%;
        background-color: black; opacity: 0;"></div>
    `);
  }

  push(enterPage, leavePage, callback) {
    this.backgroundMask.remove();
    leavePage.parentElement.insertBefore(this.backgroundMask, leavePage.nextSibling);

    animit.runAll(
      animit(this.backgroundMask)
        .saveStyle()
        .queue({
          opacity: 0,
          transform: 'translate3d(0, 0, 0)'
        })
        .wait(this.delay)
        .queue({
          opacity: this.blackMaskOpacity
        }, {
          duration: this.duration,
          timing: this.timing
        })
        .restoreStyle()
        .queue(done => {
          this.backgroundMask.remove();
          done();
        }),

      animit(enterPage)
        .saveStyle()
        .queue({
          css: {
            transform: 'translate3D(100%, 0, 0)'
          },
          duration: 0
        })
        .wait(this.delay)
        .queue({
          css: {
            transform: 'translate3D(0, 0, 0)'
          },
          duration: this.duration,
          timing: this.timing
        })
        .restoreStyle(),

      animit(leavePage)
        .saveStyle()
        .queue({
          css: {
            transform: 'translate3D(0, 0, 0)'
          },
          duration: 0
        })
        .wait(this.delay)
        .queue({
          css: {
            transform: 'translate3D(-45%, 0px, 0px)'
          },
          duration: this.duration,
          timing: this.timing
        })
        .wait(0.2)
        .queue((done) => {
          callback();
          done();
        })
        .restoreStyle()
    );
  }

  pop(enterPage, leavePage, callback) {
    this.backgroundMask.remove();
    enterPage.parentNode.insertBefore(this.backgroundMask, enterPage.nextSibling);

    animit.runAll(
      animit(this.backgroundMask)
        .saveStyle()
        .queue({
          opacity: this.blackMaskOpacity,
          transform: 'translate3d(0, 0, 0)'
        })
        .wait(this.delay)
        .queue({
          opacity: 0
        }, {
          duration: this.duration,
          timing: this.timing
        })
        .restoreStyle()
        .queue(done => {
          this.backgroundMask.remove();
          done();
        }),

      animit(enterPage)
        .saveStyle()
        .queue({
          css: {
            transform: 'translate3D(-45%, 0px, 0px)',
            opacity: 0.9
          },
          duration: 0
        })
        .wait(this.delay)
        .queue({
          css: {
            transform: 'translate3D(0px, 0px, 0px)',
            opacity: 1.0
          },
          duration: this.duration,
          timing: this.timing
        })
        .restoreStyle(),

      animit(leavePage)
        .saveStyle()
        .queue({
          css: {
            transform: 'translate3D(0px, 0px, 0px)'
          },
          duration: 0
        })
        .wait(this.delay)
        .queue({
          css: {
            transform: 'translate3D(100%, 0px, 0px)'
          },
          duration: this.duration,
          timing: this.timing
        })
        .wait(0.2)
        .queue((done) => {
          callback();
          done();
        })
        .restoreStyle()
    );
  }
}
