import React from 'react';
import { FormattedMessage } from 'translations'
import { Layout } from 'app/components/base/Layout';
import VehicleModel from 'app/components/common/VehicleModel';
import ProviderName from '../../commons/ProviderName';
import config from 'config';

import style from './style.css';

const Heading = ({
  providerName,
  description,
  vehicleType,
  vehicleClass,
  vehicleClassName,
  experimentNewSupplierLabel,
  score,
  make,
  model
}) => {
  const vehicleName = vehicleType
    ? <FormattedMessage id={`VEHICLES.ID_${vehicleType}.NAME`} />
    : (description || '')

  return (
    <Layout direction="column" size="noshrink" nowrap>
      <ProviderName
        experimentNewSupplierLabel={experimentNewSupplierLabel}
        providerName={providerName}
        vehicleClass={vehicleClass}
        vehicleClassName={vehicleClassName}
        score={score}
      />
      {vehicleName && config.SHOW_VEHICLE_DETAILS &&
        <div className={style.description}>
          <div className={style.descriptionWrapper}>
            <span>{vehicleName}</span>
            <VehicleModel
              className={style.model}
              make={make}
              model={model}
              withSeparator
            />
          </div>
        </div>
      }
    </Layout>
  );
};

// TODO: move to Flow types
// Heading.propTypes = {
//   providerName: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.array
//   ]).isRequired,
//   description: PropTypes.string,

//   vehicleClass: PropTypes.string,
//   make: PropTypes.string,
//   model: PropTypes.string
// };

export default Heading;
