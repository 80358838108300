import { call, select, put } from 'redux-saga/effects';
import { setUser, setUserDataReady } from 'app/actions/user-session';
import { getLocation } from 'app/sagas/selectors';
import getUserData from './getUserData';
import { user, auth } from 'mz-sdk';

export default function* getUser() {
  try {
    const location = yield select(getLocation);
    const { query: { sso_token } } = location;

    if (sso_token) {
      yield call(auth.ssoToken, sso_token);
    }

    const result = yield call(user.getProfile);
    yield put(setUser(Object.assign(result, {
      email_verified: true
    })));

    yield call(getUserData);
    yield put(setUserDataReady())
  } catch (err) {
    yield put(setUser());
    yield put(setUserDataReady())
  }
}
