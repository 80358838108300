import React from 'react';
import { styled } from 'mz-ui-kit'
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  UpgradesContainer,
  mapDispatchToProps,
  mapStateToProps
} from 'app/components/book-trip/UpgradeYourTrip/commons/UpgradesContainer';
import { UPGRADE_IDS } from 'app/constants';


const Container = styled('div', {
  marginTop: '2em',
})

function CarbonOffsetAmenity(props) {
  const onlyCarbonAmenity = props.upgrades.filter(a => a.id === UPGRADE_IDS.CARBON_OFFSET)
  if (!onlyCarbonAmenity.length) return null

  return (
    <Container>
      <UpgradesContainer {...props} upgrades={onlyCarbonAmenity} />
    </Container>
  )
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(CarbonOffsetAmenity);
