import React from 'react';
import { Layout, Flex } from '../Layout';
import FooterLink from './FooterLink';
import classnames from 'classnames';
import config from 'config';
import { formatString } from 'app/utils/format';

import style from './style.css';

const MOZIO_WEB_LINKS = [
  ['FOOTER.PROVIDERS', 'https://www.mozio.com/provider'],
  ['FOOTER.RESORTS', 'https://www.mozio.com/guide/resort'],
  ['FOOTER.AIRPORTS', 'https://www.mozio.com/guide/airport'],
]

const LinksRow = ({ mobile, ...props }) => {
  const links = (config.FOOTER_CUSTOM_LINKS || [])
    .concat(!config.PARTNER_REF ? MOZIO_WEB_LINKS : [])
    .map(p => [formatString(p[0]), formatString(p[1])])
    .filter(p => p[0] && p[1]);

  return (
    <Layout
      className={classnames(style.links, { [style['mobile-links']]: mobile })}
      direction="row"
      align="end center"
      {...props}
    >
      <Flex size="grow" />
      {links.map((link, index) => (
        <FooterLink
          key={index}
          size="noshrink"
          href={link[1]}
          message={{ id: link[0] }}
        />
      ))}
    </Layout>
  );
};

// TODO: move to Flow types
// LinksRow.propTypes = {
//   mobile: PropTypes.bool
// };

export default LinksRow;
