// @flow
import * as React from 'react';
import { DataContainer, DataLabel } from './elements';

// types
type Props = {
  renderLabelPrefix?: () => any,
  renderLabel: () => any,
  renderValue: () => any
};

const DataField = ({
  renderLabel,
  renderValue,
  renderLabelPrefix
}: Props) => (
  <DataContainer>
    <DataLabel>
      {renderLabelPrefix && renderLabelPrefix()}
      {renderLabel()}
    </DataLabel>
    {renderValue()}
  </DataContainer>

);

export default DataField;
