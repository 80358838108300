import React, { Component } from 'react';
import _ from 'lodash';
import { eventToKey } from 'app/utils/listeners';
import { AUTOCOMPLETE_DEBOUNCE } from 'app/constants';
import { overwriteAirportDisplay } from 'app/utils/togglers';

const builder = (ComposedComponent) => {
  class AutocompleteInput extends Component {
    state = {
      options: [],
      frozen: false,
      focused: -1
    }

    UNSAFE_componentWillMount() {
      this.debounceFilter = _.debounce(value => {
        Promise.resolve(this.props.onFilter(value))
          .then((options = []) => {
            this.setState({
              options,
              focused: Math.min(this.state.focused, options.length - 1)
            });
          });
      }, this.props.debounce);
    }

    getDisplay = (option = {}) => {
      return this.props.template
        ? this.props.template(option)
        : overwriteAirportDisplay(option);
    }

    handleInputChange = (value) => {
      this.setState({
        frozen: false,
        focused: -1
      });
      this.props.input.onChange({ display: value });
      if (value.length > 2) {
        this.debounceFilter(value);
      }
    }

    handleChange = (option) => {
      this.setState({
        focused: -1,
        frozen: true
      });
      this.props.input.onChange({ display: overwriteAirportDisplay(option) });
      Promise.resolve(this.props.transform(option)).then((value) => {
        this.props.input.onChange(value);
      });
    }

    handleFocus = () => {
      if (this.inputNode) {
        this.inputNode.setSelectionRange(0, this.inputNode.value.length);
      }
      this.props.input.onFocus();
    }

    handleKeyDown = (event) => {
      const { options, focused } = this.state;
      const key = eventToKey(event);
      const currentOption = options[focused === -1 ? 0 : focused];

      if (key === 'ArrowUp') {
        this.setState({ focused: Math.max(focused - 1, 0) });
      } else if (key === 'ArrowDown') {
        this.setState({ focused: Math.min(focused + 1, options.length - 1) });
      } else if (key === 'Enter' && currentOption) {
        this.handleChange(currentOption);
        event.stopPropagation();
        event.preventDefault();
      } else if (key === 'Tab' && currentOption) {
        this.handleChange(currentOption);
      }
    }

    refTextInput = (node) => {
      this.inputNode = node;
    }

    render() {
      const { options, focused, frozen } = this.state;
      const { input, ...props } = this.props;

      const inputProps = {
        ...input,
        onKeyDown: this.handleKeyDown,
        onFocus: this.handleFocus,
        onChange: this.handleChange
      };

      return (
        <ComposedComponent
          {...props}
          input={inputProps}
          refInput={this.refTextInput}
          template={this.getDisplay}
          inputValue={overwriteAirportDisplay(input.value)}
          onInputChange={this.handleInputChange}
          options={options}
          focused={focused}
          frozen={frozen}
        />
      );
    }
  }

  // TODO: move to Flow types
  // AutocompleteInput.propTypes = {
  //   input: PropTypes.object.isRequired,
  //   onFilter: PropTypes.func.isRequired,
  //   transform: PropTypes.func,
  //   template: PropTypes.func,
  //   disabled: PropTypes.bool,
  //   debounce: PropTypes.number
  // };

  AutocompleteInput.defaultProps = {
    transform: option => option,
    debounce: AUTOCOMPLETE_DEBOUNCE,
    disabled: false
  };

  return AutocompleteInput;
};

export default builder;
