import React from 'react';
import { FormattedMessage } from 'translations';
import { Layout, Flex } from '../Layout';
import config from 'config';
import moment from 'moment';

import style from './style.css';

const CopyrightRow = (props) => (
  <Layout direction="row" align="start center" {...props} >
    <Flex
      className={style.copyright}
      size="noshrink"
    >
      <p className={style.copyright__text}>
        <span>&copy; {`${moment().format('YYYY')} ${config.EMAIL_SITE_NAME}`} </span>
        <span><FormattedMessage id="FOOTER.TRADEMARK" /></span>
      </p>
    </Flex>
  </Layout>
);

export default CopyrightRow;
