
const APPLE_TOUCH_REL = 'apple-touch-icon';

/**
 * Supported favicons for apple touch devices.
 * Assets found in /src/favicons
 */
const appleTouchIconFiles = [
  'apple-touch-icon-57x57.png',
  'apple-touch-icon-60x60.png',
  'apple-touch-icon-72x72.png',
  'apple-touch-icon-76x76.png',
  'apple-touch-icon-114x114.png',
  'apple-touch-icon-120x120.png',
  'apple-touch-icon-144x144.png',
  'apple-touch-icon-152x152.png',
  'apple-touch-icon-180x180.png'
];

/**
 * Supported favicons for web clients.
 * Assets found in /src/favicons
 */
const webFavicons = [
  'favicon-32x32.png',
  'favicon-96x96.png',
  'favicon-16x16.png'
];

/**
 * Generates objects for rendering the links for web and apple touch favicons.
 */
export const getFaviconLinks = () => {
  const faviconLinks = [];

  appleTouchIconFiles.forEach(filename => {
    faviconLinks.push({
      rel: APPLE_TOUCH_REL,
      sizes: filename.split('.')[0].split('-')[3],
      href: `/${filename}`
    });
  });

  webFavicons.forEach(filename => {
    faviconLinks.push({
      rel: 'icon',
      type: 'image/png',
      href: `/${filename}`,
      sizes: filename.replace('favicon-', '').replace('.png', '')
    });
  });

  return faviconLinks.concat(
    { rel: 'icon', type: 'image/png', href: '/android-chrome-192x192.png', sizes: '192x192' },
    { rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '#5bbad5' },
    { rel: 'manifest', href: '/manifest.json' },
  );
};

/**
 * Meta tags required for favicons on msie.
 * Resources located at /src/favicons.
 */
export const getFaviconMetaTags = () => {
  return [
    { name: 'msapplication-TileColor', value: '#da532c' },
    { name: 'msapplication-TileImage', value: '/mstile-144x144.png' },
    { name: 'theme-color', value: '#ffffff' }
  ];
};
