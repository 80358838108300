/**
 * An abstract transition animator that is doing nothing.
 * Use it as base (interface) for any animator implementations.
 */
export default class AbstractTransitionAnimator {
  constructor(options = {}) {
    const customOptions = {
      timing: 'linear',
      duration: '0.4',
      delay: '0',
      ...options
    };

    this.timing = customOptions.timing;
    this.duration = customOptions.duration;
    this.delay = customOptions.delay;
  }

  push(enterPage, leavePage, callback) {
    callback();
  }

  pop(enterPage, leavePage, callback) {
    callback();
  }
}
