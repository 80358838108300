import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'translations';
import { Field, formValueSelector } from 'redux-form';
import CouponInput from './CouponInput';
import { Layout, Flex } from 'app/components/base/Layout';
import { BOOK_TRIP_FORM, COUPON_STATUS, COUPON_CODE_FIELD_NAME } from 'app/constants';
import * as bookTripActions from 'app/actions/book-trip';
import config from 'config';

import style from './style.css';


export const mapStateToProps = (state, { formName = BOOK_TRIP_FORM }) => {
  const selector = formValueSelector(formName);

  const coupon_code = selector(state, COUPON_CODE_FIELD_NAME);
  const couponState = selector(state, COUPON_STATUS);
  return {
    coupon_code,
    validated: !!couponState.savedValue,
    ...couponState
  };
};

const messages = {
  coupon: { id: 'BOOK_TRIP.COUPON' }
};

/**
 * Component part of the BTP trip summary that allows the user to enter a coupon
 * to receive a discount in the booking price.
 */
const Coupon = (props) => {
  const { coupon_code, readOnly } = props;
  if ((readOnly && !coupon_code) || !config.SHOW_COUPON_FIELD) {
    return null;
  }

  return (
    <Layout direction="row" className={style.coupon} size="noshrink" nowrap>
      <Flex className={style.label} size="grow">
        <FormattedMessage {...messages.coupon} />
      </Flex>
      <Field
        component={CouponInput}
        name={COUPON_CODE_FIELD_NAME}
        {...props}
      />
    </Layout>
  );
};

// TODO: move to Flow types
// Coupon.propTypes = {
//   formName: PropTypes.string,
//   validated: PropTypes.bool,
//   wrongCode: PropTypes.bool,
//   showRefresh: PropTypes.bool,
//   showHint: PropTypes.bool,
//   disabled: PropTypes.bool,
//   readOnly: PropTypes.bool,
//   onAdd: PropTypes.func,
//   onRefresh: PropTypes.func,
//   onHintClose: PropTypes.func,
//   // form props
//   coupon_code: PropTypes.string
// };

export default connect(mapStateToProps, {
  onAdd: bookTripActions.addCoupon,
  onRefresh: bookTripActions.addCoupon,
  onHintClose: bookTripActions.closeCouponHint
})(Coupon);
