import React from 'react';
import { FormattedMessage } from 'translations';
import classnames from 'classnames';
import { Layout } from 'app/components/base/Layout';
import LoadingSpinner from 'app/components/base/LoadingSpinner';
import { isPriceEstimated } from 'app/utils/trip';
import style from './style.css';


const messages = {
  totalPrice: { id: 'BOOK_TRIP.TOTAL_PRICE' },
  taxesIncluded: { id: 'SEARCH_RESULTS.TAXES_INCLUDED' },
  estimatedPrice: { id: 'SEARCH_RESULTS.ESTIMATED_PRICE' }
};

/**
 * Presentational component part of the BTP trip summary that shows the
 * total price of a trip. May show additional information regarding price.
 * It can also show an optional currency conversion disclaimer for when
 * the currency is not supported. The message and price is received as props.
 */

const TotalPrice = ({
  type,
  finalPrice,
  currencySymbol,
  conversionMessage,
  conversionPrice,
  updating,
  mobile,
  dark,
  omitMeta
}) => {
  const isEstimated = isPriceEstimated(type);
  const modifiers = {
    [style['container--mobile']]: mobile,
    [style['container--dark']]: dark
  };

  return (
    <Layout direction="column" className={classnames(style.container, modifiers)}>
      <Layout align="space-between start">
        <Layout className={style.label} size="noshrink">
          <FormattedMessage {...messages.totalPrice} />
        </Layout>
        <Layout
          size="noshrink"
          direction="column"
          align="start end"
        >
          <div>
            {!!updating &&
              <LoadingSpinner size="small" className={style.spinner} />
            }
            {!omitMeta && (<span className={style.symbol}>{currencySymbol}</span>)}
            <span className={style.number} data-testid="final-price">{finalPrice}</span>
          </div>
          {isEstimated && <FormattedMessage {...messages.estimatedPrice} />}
          {!omitMeta && (<FormattedMessage {...messages.taxesIncluded} />)}
        </Layout>
      </Layout>
      {!omitMeta && conversionMessage &&
        <span className={style.disclaimer}>
          <span>{conversionMessage}</span>
          <span className={style.currency}>{conversionPrice}</span>
        </span>
      }
    </Layout>
  );
};

// TODO: move to Flow types
// TotalPrice.propTypes = {
//   finalPrice: PropTypes.oneOfType([
//     PropTypes.number,
//     PropTypes.string
//   ]),
//   currencySymbol: PropTypes.string,
//   conversionMessage: PropTypes.string,
//   conversionPrice: PropTypes.string,
//   updating: PropTypes.bool,
//   mobile: PropTypes.bool,
//   dark: PropTypes.bool,
//   omitMeta: PropTypes.bool
// };

export default TotalPrice;
