import { call, put } from 'redux-saga/effects';
import { providers } from 'mz-sdk';
import { setTermsAndConditions } from 'app/actions/book-trip';

export default function* watchTermsAndConditions(action) {
  try {
    const terms = yield call(
      providers.getTermsAndConditions,
      { endpoint: action.payload }
    );
    yield put(setTermsAndConditions(terms));
    yield call(action.resolvePromise);
  } catch (error) {
    yield put(setTermsAndConditions({ error }));
    yield call(action.rejectPromise);
  }
}
