export const defaultCurrencyCode = 'USD';

/*
* For each currency support level (1, 2, 3)
* returns the support level message with the
* respective currency code
*/

export const getSupportLevelMessage = currency => ({
  /* Charged in currency */
  1: {
    message: { id: 'PRICES.ALL_PRICES_IN' },
    value: currency.code
  },
  /* Charged in USD for amex only */
  2: {
    message: { id: 'PRICES.AMEX_RESTRICTION' },
    value: defaultCurrencyCode
  },
  /* Charged in USD */
  3: {
    message: { id: 'PRICES.ALL_PRICES_CHARGED_IN' },
    value: defaultCurrencyCode
  }
});

export const fullySupported = currency => currency.type === 1;
