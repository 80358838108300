import { createReducer } from '../utils/redux';
import { LOCATION_CHANGE, getCurrentLocale } from 'app/history'
import languages from 'app/languages';
import setConfig from 'mz-sdk/config'
import config from 'config'

import {
  SET_CURRENCY,
  INIT_APP
} from '../constants';

const initialState = {
  messages: {},
  locale: '',
  rtl: false,
  loading: true,
  currency: config.DEFAULT_CURRENCY,
};

const urlLocaleToAppLocale = (urlLocale) =>
  urlLocale
    .split('-')
    .map((v, i) => i > 0 ? v.toUpperCase() : v)
    .join('_')

const actionHandlers = {
  [LOCATION_CHANGE]: (state) => {
    const activeAppLocale = urlLocaleToAppLocale(getCurrentLocale())

    if (activeAppLocale !== state.locale) {
      const langObj = languages[activeAppLocale];
      const messLocale = langObj.messagesLocale || activeAppLocale;
      const messLocaleObj = languages[messLocale];

      setConfig({ LANGUAGE: messLocaleObj.apiLocale });

      // FIX: it's a temporary solution for https://mozio.atlassian.net/browse/DEV-1416
      // window.zE && window.zE('webWidget', 'setLocale', langObj.zendeskLocale);

      return { locale: activeAppLocale };
    }
  },
  [INIT_APP]: () => ({ loading: false }),
  [SET_CURRENCY]: (_, { currency }) => ({ currency }),
};

export default createReducer(initialState, actionHandlers);
