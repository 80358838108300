import React, { Component } from 'react';
import provider from './provider';
import _ from 'lodash';

const queryProps = ['align', 'direction', 'size', 'order'];

const connectScreenSize = (ComposedComponent) => {
  class LayoutComponent extends Component {
    componentDidMount() {
      const isResponsive = !ComposedComponent.isLayout || queryProps.some(key =>
        _.isPlainObject(this.props[key])
      );
      if (!isResponsive) return;
      this.unsubscribe = provider.subscribe(() => {
        this.forceUpdate();
      });
    }

    componentWillUnmount() {
      if (this.unsubscribe) this.unsubscribe();
    }

    render() {
      return (
        <ComposedComponent
          {...this.props}
          screenSize={provider.screenSize}
        />
      );
    }
  }

  return LayoutComponent;
};

export default connectScreenSize;
