import { createReducer } from '../utils/redux';

import {
  SELECT_TRIP_FOR_SRP_MAP,
  TOGGLE_SRP_MAP,
  SET_SRP_MAP_DIRECTIONS,
  SET_CACHED_DIRECTION,
  CLEAR_CACHED_DIRECTIONS,
  CLEAR_DIRECTIONS,
} from 'app/constants';

const initialState = {
  showMap: false,
  tripId: null,
  directions: [],
  cachedDirections: {},
};

const actionHandlers = {
  [SELECT_TRIP_FOR_SRP_MAP]: (_, { tripId }) => ({
    tripId
  }),
  [TOGGLE_SRP_MAP]: (state) => ({
    showMap: !state.showMap
  }),
  [SET_SRP_MAP_DIRECTIONS]: (state, { directions }) => ({
    directions
  }),
  [SET_CACHED_DIRECTION]: (state, { key, data }) => ({
    cachedDirections: {
      ...state.cachedDirections,
      [key]: data
    }
  }),
  [CLEAR_CACHED_DIRECTIONS]: () => ({
    cachedDirections: {},
    directions: [],
    tripId: null
  }),
  [CLEAR_DIRECTIONS]: () => ({
    directions: []
  }),
};

export default createReducer(initialState, actionHandlers);
