import React from 'react';
import { Layout } from 'app/components/base/Layout';
import Details from './Details';
import RouteSummary from './RouteSummary';
import Price from './Price';


export const TripBody = ({
  tripMode,
  trip,
  departureTime,
  arrivalTime,
  returnDepartureTime,
  returnArrivalTime
}) => (
  <Layout direction="column" nowrap>
    <Details {...trip.details} category={trip.heading && trip.heading.category} />
    <RouteSummary
      {...trip.routing}
      tripMode={tripMode}
      isHourlyRide={trip.details.isHourlyRide}
      hourlyDetails={trip.details.hourlyDetails}
      departureTime={departureTime}
      arrivalTime={arrivalTime}
      returnDepartureTime={returnDepartureTime}
      returnArrivalTime={returnArrivalTime}
    />
    <Price {...trip.pricing} />
  </Layout>
);

// TODO: move to Flow types
// TripBody.propTypes = {
//   trip: PropTypes.object,
//   scheduledDepartureDatetime: PropTypes.any,
//   scheduledArrivalDatetime: PropTypes.any
// };

export default TripBody;
