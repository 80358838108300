import { LOCATION_CHANGE } from 'app/history'


const initialRouterState = {
  location: window.location,
  action: 'PUSH',
}

/*
* This reducer will update the state with the most recent location history
* has transitioned to.
*/
export default (state = initialRouterState, { type, payload } = {}) => {
  if (type === LOCATION_CHANGE) {
    const { location, action, isFirstRendering } = payload

    // Don't update the state ref for the first rendering
    // to prevent the double-rendering issue on initilization
    return isFirstRendering
      ? state
      : { ...state, location, action }
  }

  return state
}
