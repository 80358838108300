import { styled } from 'mz-ui-kit'

export const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
  lineHeight: '16px'
});

export const Prefix = styled('div', {
  marginLeft: '4px'
});

export const Label = styled('div', {
  marginLeft: '4px'
});
