import React, { Component } from 'react';
import { compose } from 'redux';
import { connectSubpages } from 'app/hocs/subpageManagable';
import CommonPlannedReturn from '../commons/CommonPlannedReturn';
import ReturnDate from '../../ReturnDate/Mobile';

import style from './style.css';

/**
 * Stateless component for showing planned return trip component.
 * @return {Component}
 */
class MobilePlannedReturn extends Component {
  handleAdd = () => {
    const { subpages } = this.props;
    subpages.pushPage(ReturnDate);
  }

  render() {
    return (
      <span className={style.container}>
        <CommonPlannedReturn onClickAdd={this.handleAdd} mobile />
      </span>
    );
  }
}

// TODO: move to Flow types
// MobilePlannedReturn.propTypes = {
//   subpages: PropTypes.object.isRequired,
//   input: PropTypes.object
// };

export default compose(
  connectSubpages()
)(MobilePlannedReturn);
