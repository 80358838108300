import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Layout } from 'app/components/base/Layout';

// Import subcomponents
import PriceItem from './commons/PriceItem';
import Coupon from './commons/Coupon';
import UpgradesList from './commons/UpgradesList';
import BookingFee from './commons/BookingFee';
import TotalPrice from './commons/TotalPrice';

import { mapStateToProps } from './index';

import style from './style.css';


/**
 * Compact version of Price breakdown that is used in modal dialog
 * that is shown when trip has price update
 */
export const ReadOnlyPriceInfo = ({
  pricing,
  mobile,
  horizontal,
  formName,
  isTrain
}) => {
  const modifiers = {
    [style.mobile]: mobile,
    [style.horizontal]: horizontal,
    [style.train]: isTrain
  };

  return (
    <Layout
      direction="column"
      className={classnames(style.breakdown, modifiers)}
    >
      <PriceItem price={pricing.ridePrice} />
      <Coupon formName={formName} readOnly />
      <UpgradesList
        formName={formName}
        readOnly
        mobile={mobile}
      />
      <BookingFee allowChangeFee="false" formName={formName} />
      <TotalPrice {...pricing} mobile dark={horizontal} />
    </Layout>
  );
};

// TODO: move to Flow types
// ReadOnlyPriceInfo.propTypes = {
//   formName: PropTypes.string,
//   pricing: PropTypes.object.isRequired,
//   mobile: PropTypes.bool,
//   horizontal: PropTypes.bool,
//   isTrain: PropTypes.bool
// };

export default connect(mapStateToProps)(ReadOnlyPriceInfo);
