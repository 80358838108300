import { call, put, select } from 'redux-saga/effects';
import { initialize, change } from 'redux-form';
import {
  getBookingFeeFieldValue,
  getBookingFeeStatus
} from 'app/sagas/selectors';
import {
  BOOK_TRIP_UPDATED_MODAL_FORM,
  BOOKING_FEE_STATUS
} from 'app/constants';
import doSetupUpgrades from 'app/sagas/watchBookTrip/doSetupUpgrades';
import resolveFormValues from '../watchLoadBookTrip/resolveFormValues';
import getPrice from 'app/sagas/watchBookTrip/watchUpdatePrice/getPrice';
import getUpdatedTickets from './getUpdatedTickets';
import { showErrorNotification } from 'app/sagas/utils';

/**
 * This saga initializes BOOK_TRIP_UPDATED_MODAL_FORM form
 * with all values needed to display price breakdown in modal notification
 * @param {Object} options.updatedTrip Updated trip object.
 *                                     If used, session_id and trip_id will be ignored
 * @param {String} options.session_id  session_id of updated trip
 * @param {String} options.trip_id     Updated trip id
 */
export default function* prepareTripUpdatedDialog({ updatedTrip, session_id, trip_id }) {
  let formValues = yield call(resolveFormValues, {
    reloadTrip: true,
    updatedTrip,
    session_id,
    trip_id
  });

  // get tickets for scheduled ride case
  const updatedTickets = yield call(getUpdatedTickets, { updatedTrip });

  formValues = {
    ...formValues,
    ...updatedTickets
  };

  yield put(initialize(BOOK_TRIP_UPDATED_MODAL_FORM, formValues));

  // Update booking fee saved value based on user default
  const bookingFee = yield select(getBookingFeeFieldValue);
  const bookingFeeStatus = yield select(getBookingFeeStatus);
  yield put(change(BOOK_TRIP_UPDATED_MODAL_FORM, BOOKING_FEE_STATUS, {
    ...bookingFeeStatus,
    savedValue: bookingFee
  }));

  try {
    yield call(getPrice, undefined, BOOK_TRIP_UPDATED_MODAL_FORM);
  } catch (error) {
    yield call(showErrorNotification, { error });
  }

  yield call(doSetupUpgrades, BOOK_TRIP_UPDATED_MODAL_FORM);
}
