// @flow
import React from 'react';
import getTimeString from 'mz-utils/getTimeString';
import { FormattedMessage } from 'mz-intl';
import messages from '../../messages.intl';

// types
type Props = {
  minimum: number
};

const MinimumHint = ({ minimum }: Props) => {
  const minimumLabel = getTimeString({ hourDiff: minimum });
  return (
    <FormattedMessage
      message={messages.PRICE_FOR_MINIMUM}
      values={{ minimumHours: minimumLabel }}
    />
  );
};

export default MinimumHint;
