import React from 'react';
import { FormattedMessage } from 'translations';
import { Layout } from 'app/components/base/Layout';
import Button from 'mz-ui-kit/core/Button';
import Icon from 'app/components/base/Icon';

import style from './style.css';


const messages = {
  addToTrip: { id: 'BOOK_TRIP.UPGRADES.ADD_TO_TRIP_BUTTON_LABEL' },
  added: { id: 'BOOK_TRIP.UPGRADES.UPGRADE_ADDED_LABEL' }
};

/**
 * Component to show the `Add to trip` button of an upgrade item in the book-trip page
 */
const AddToTripButton = ({ className, onClick, added, loading, green }) => {
  return (
    <Layout size="noshrink" className={className}>
      <Button
        appearance={added ? 'flat' : 'general'}
        color={green ? 'green' : 'cta'}
        onClick={onClick}
        loading={loading}
        disabled={loading}
      >
        {!added && <FormattedMessage {...messages.addToTrip} />}
        {added &&
          <Layout align="end center" className={style.added}>
            <FormattedMessage {...messages.added} />
            <Icon className={style.check} name="sb-check" family="mozio" />
          </Layout>
        }
      </Button>
    </Layout>
  );
};

// TODO: move to Flow types
// AddToTripButton.propTypes = {
//   className: PropTypes.string,
//   onClick: PropTypes.func,
//   added: PropTypes.bool,
//   loading: PropTypes.bool
// };

export default AddToTripButton;
