import { isMobileBrowser } from 'app/utils/browser';
import LightboxRenderer from './LightboxRenderer';
import MobileScreenRenderer from './MobileScreenRenderer';


// Get renderer based on the browser
const detectedRenderer = isMobileBrowser
  ? MobileScreenRenderer
  : LightboxRenderer;

export default detectedRenderer;
