import React from 'react';
import CN from 'classnames'
import { FormattedMessage } from 'translations';
import InlineLabel from 'app/components/base/InlineLabel';
import config from 'config';

import style from './style.css'

const messages = {
  optional: { id: 'SEARCH_RESULTS.AMENITY_OPTIONAL_SUFFIX' }
};

const Amenity = ({ item, ...props }) => {
  if (!item.included && !config.UPGRADE_YOUR_TRIP_SHOW) return null;
  const text = item.included ? item.name : (
    <span>
      {item.name}&nbsp;<FormattedMessage {...messages.optional} />
    </span>
  );

  return (
    <InlineLabel
      {...props}
      className={CN(style.amenity, props.className)}
      icon={{ src: item.imageUrl }}
      text={text}
    />
  );
};

// TODO: move to Flow types
// Amenity.propTypes = {
//   item: PropTypes.shape({
//     imageUrl: PropTypes.string,
//     name: PropTypes.string
//   })
// };

export default Amenity;
