import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { connectSubpages } from 'app/hocs/subpageManagable';
import { compose } from 'redux';
import { FormattedMessage } from 'translations';
import { BOOK_TRIP_FORM, RETURN_DATETIME_FIELD_NAME } from 'app/constants';
import MobileScreen from 'app/components/base/MobileScreen';
import MobileDatetimePicker from 'app/components/base/MobileDatetimePicker';
import ReturnTrip from '../../ReturnTrip/Mobile';
import builder from '../builder';


// Message constants.
const messages = {
  cancel: { id: 'CANCEL' },
  titleLabel: { id: 'SEARCH_FORM.RETURN_LABEL' },
  setTime: { id: 'MOBILE_SEARCH_FORM.SET_RETURN_TIME' }
};

/**
 * Stateless component for showing return trip date/time picker screen.
 * It push trip booking screen when user select the date or just
 * clean up current subpages stack if user cancel the screen.
 * @return {Component}
 */
export class MobileReturnDate extends React.Component {
  handleClickCancel = () => {
    const { subpages } = this.props;
    subpages.popPage();
  }

  handleDateSelected = () => {
    const { subpages, onDateSelected } = this.props;
    onDateSelected();
    subpages.pushPage(ReturnTrip);
  }

  render() {
    const { minDate } = this.props;

    return (
      <MobileScreen
        title={<FormattedMessage {...messages.titleLabel} />}
        titleDone={<FormattedMessage {...messages.cancel} />}
        onClickDone={this.handleClickCancel}
      >
        <Field
          component={MobileDatetimePicker}
          name={RETURN_DATETIME_FIELD_NAME}
          timeTitle={<FormattedMessage {...messages.setTime} />}
          minDate={minDate}
          onSelect={this.handleDateSelected}
        />
      </MobileScreen>
    );
  }
}

// TODO: move to Flow types
// MobileReturnDate.propTypes = {
//   minDate: PropTypes.object,
//   subpages: PropTypes.object.isRequired,
//   onDateSelected: PropTypes.func
// };


// Connect to the form, because the scrren will be outside
// of the parent form component.
const formConfig = {
  form: BOOK_TRIP_FORM,
  destroyOnUnmount: false
};

export default compose(
  builder,
  connectSubpages(),
  reduxForm(formConfig)
)(MobileReturnDate);
