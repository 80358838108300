// @flow
import { styled } from 'mz-ui-kit';

export const Container = styled('div', {
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'space-between'
});

export const Title = styled('div', {
  fontSize: '14px'
});

export const Subtitle = styled('div', ({ $palette }) => ({
  fontSize: '10px',
  color: `${$palette('gray').darken(20)}`
}));

export const Duration = styled('div', {
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '20px',
  textAlign: 'right'
});
