import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'translations';
import Button from 'app/components/base/Button';
import Icon from 'app/components/base/Icon';
import MobileScreen from 'app/components/base/MobileScreen';
import { connectSubpages } from 'app/hocs/subpageManagable';
import {
  mapStateToProps
} from 'app/components/search-results/SearchResults/commons/TripsResultWithMap';
import SrpMap from 'app/components/search-results/SearchResults/commons/SrpMap';

import style from './style.css';


const messages = {
  title: { id: 'SEARCH_RESULTS.TRIP_SCREEN_TITLE' },
  hideMap: { id: 'SEARCH_RESULTS.MAP.HIDE_MAP' }
};

export class MapScreen extends React.Component {

  handleClickBack = () => {
    this.props.subpages.popPage();
  }

  render() {
    const {
      startLocation,
      endLocation,
      directions
    } = this.props;

    return (
      <MobileScreen
        title={<FormattedMessage {...messages.title} />}
        onClickBack={this.handleClickBack}
      >
        <SrpMap
          startCoords={startLocation}
          endCoords={endLocation}
          directions={directions}
        />
        <Button
          className={style.hideMapButton}
          onClick={this.handleClickBack}
          mapToggler
        >
          <FormattedMessage {...messages.hideMap} />
          <Icon name="close" large />
        </Button>
      </MobileScreen>
    );
  }
}

// TODO: move to Flow types
// MapScreen.propTypes = {
//   subpages: PropTypes.object.isRequired,
//   startLocation: PropTypes.object,
//   endLocation: PropTypes.object,
//   directions: PropTypes.array
// };


export default compose(
  connect(mapStateToProps),
  connectSubpages(),
)(MapScreen);
