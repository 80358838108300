import createMessages from 'mz-intl/create';
import createMessagesObject from '../createMessagesObject'
import corpSources from '../sources/corporate-corporate.sources-en_US.json'

export const DOMAIN = 'CORP'

export default createMessages(
  {
    domain: DOMAIN,
    resource: 'corporate-corporate',
    allowUnreviewed: true,
    resourceWithoutDomainPrefix: true,
  },
  createMessagesObject(corpSources)
)
