// @flow
import * as React from 'react';
import maybeMap from 'mz-utils/maybeMap';
import VehicleClass from '../VehicleClass';
import SupplierScore from '../SupplierScore'
import { ProviderHeader, Name, Badges, Badge } from './elements';

// Types
import type { VehicleClass as VehicleClassType } from 'mz-sdk/constants/vehicleClass'; // eslint-disable-line
import type { VehicleType as VehicleTypeType } from 'mz-sdk/constants/vehicleType'; // eslint-disable-line
export type Props = {
  providerName: Array<string> | ?string,
  vehicleClass?: VehicleClassType
};

/**
 * A component for showing provider name with vehicle class if provided
 * @return {React.Component}
 */
const ProviderName = ({
  realProviderName,
  providerName,
  vehicleClass,
  vehicleClassName,
  experimentNewSupplierLabel,
  score,
}: Props) => (
  <div data-provider={realProviderName || providerName}>
    <ProviderHeader>
      {maybeMap(providerName, (name, i) => (
        <Name key={i}>{name}</Name>
      ))}
    </ProviderHeader>
    <Badges>
      <Badge>
        <SupplierScore
          score={score}
          experimentNewSupplierLabel={experimentNewSupplierLabel}
        />
      </Badge>
      {!!vehicleClass && (
        <Badge>
          <VehicleClass
            vehicleClass={vehicleClass}
            vehicleClassName={vehicleClassName}
          />
        </Badge>
      )}
    </Badges>
  </div>
);

export default ProviderName;
