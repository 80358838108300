import React from 'react';
import { styled } from 'mz-ui-kit'
import { getScreenStyles } from 'mz-ui-kit/utils'
import { FormattedMessage } from 'mz-intl'
import messages from '../../../messages.intl'
import Description from './commons/Description';
import Price from './commons/Price';
import AddToTripButton from './commons/AddToTripButton';
import Image from './commons/Image';

const ItemContainer = styled('div', ({ $palette, $green }) => ({
  border: `1px solid ${$green ? $palette('green') : $palette('grey').lighten(35)}`,
  width: '100%',
  marginBottom: '1rem',
  borderRadius: '4px',
  padding: '0.7rem',
  display: 'flex',
  flexFlow: 'row nowrap',
  ...getScreenStyles('xs', {
    flexFlow: 'column nowrap',
  })
}))

const ContentRow = styled('div', {
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  flexGrow: 1,
})

const PricingRow = styled('div', ({ $palette }) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  flexShrink: 0,
  ...getScreenStyles('xs', {
    borderTop: `1px solid ${$palette('grey').lighten(40)}`,
    paddingTop: '0.7rem',
    marginTop: '0.7rem',
    justifyContent: 'flex-end',
  })
}))

const FeatureName = styled('span',  ({ $palette, $green }) => ({
  color: `${$palette($green ? 'green': 'text')}`,
  fontWeight: 'bold',
  marginLeft: '10px',
}))

const PriceWrapper = styled('div', {
  marginRight: '1rem',
  textAlign: 'right',
})

const PerRideText = styled('div', {
  fontSize: '0.9em',
  opacity: 0.5,
  lineHeight: '0.8em',
})

/**
 * Component to show an upgrade option in the Upgrade Your Trip section of the book-trip page
 */
const UpgradeItem = ({
  name,
  icon,
  imageUrl,
  description,
  price,
  included,
  loading,
  perRidePrice,
  onToggleAmenity,
  horizontal,
  green
}) => {

  return (
    <ItemContainer $green={green}>
      <ContentRow>
        <Image icon={icon} imageUrl={imageUrl} green={green} />
        <div>
          <FeatureName $green={green}>{name}</FeatureName>
          <Description
            horizontal={horizontal}
            description={description}
          />
        </div>
      </ContentRow>
      <PricingRow>
        <PriceWrapper>
          <Price {...price} />
          {!!perRidePrice && (
            <PerRideText>
              <FormattedMessage message={messages.PRICE_PER_RIDE} />
            </PerRideText>
          )}
        </PriceWrapper>
        <div>
          <AddToTripButton
            added={included}
            loading={loading}
            onClick={onToggleAmenity}
            green={green}
          />
        </div>
      </PricingRow>
    </ItemContainer>
  );
};


// TODO: move to Flow types
// UpgradeItem.propTypes = {
//   name: PropTypes.string.isRequired,
//   icon: PropTypes.string,
//   imageUrl: PropTypes.string,
//   description: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.array
//   ]),
//   price: PropTypes.object,
//   included: PropTypes.bool,
//   loading: PropTypes.bool,
//   horizontal: PropTypes.bool,

//   // Actions
//   onToggleAmenity: PropTypes.func
// };

export default UpgradeItem;
