import * as React from 'react';
import { FormattedHTMLMessage, getDefaultMessage } from 'translations';
import { REQUIRED_ERROR_TYPE } from 'app/constants'
import { Field } from 'redux-form';
import is from 'mz-utils/is';


const translateHtml = (messId) => {
  if (messId === REQUIRED_ERROR_TYPE) {
    return <FormattedHTMLMessage id="VALIDATION.REQUIRED" />
  } else if (getDefaultMessage({ id: messId })) {
    return <FormattedHTMLMessage id={messId} />
  } else if (is.string(messId)) {
    return <span dangerouslySetInnerHTML={{ __html: messId }} />
  }
  return messId
};

const TranslatedErrorWarning = ({ originalComponent, meta, ...restProps }) => (
  React.createElement(originalComponent, {
    ...restProps,
    meta: {
      ...meta,
      warning: meta.warning ? translateHtml(meta.warning) : '',
      error: meta.error ? translateHtml(meta.error) : ''
    }
  })
);

const TranslatedField = (props) => (
  <Field
    {...props}
    component={TranslatedErrorWarning}
    originalComponent={props.component}
  />
);

export default TranslatedField;
