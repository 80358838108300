import { actionChannel, fork } from 'redux-saga/effects';
import { TOGGLE_OPTIONAL_AMENITY } from 'app/constants';
import toggleAmenity from './toggleAmenity';
import { takeLatestChannelAction } from 'app/sagas/utils';


/**
 * Saga for actions related to meet & greet in the book-trip page
 */
export default function* watchToggleAmenity() {
  const requestChannel = yield actionChannel(TOGGLE_OPTIONAL_AMENITY);
  yield fork(takeLatestChannelAction, requestChannel, toggleAmenity);
}
