import {
  SEARCH_FORM_SESSION_STORAGE,
  REF_PARAM_SESSION_STORAGE
} from 'app/constants';


const createStorage = () => {
  const storage = {};
  return {
    setItem(key, value) {
      storage[key] = value || '';
    },
    getItem(key) {
      return storage[key] || null;
    },
    removeItem(key) {
      delete storage[key];
    },
    get length() {
      return Object.keys(storage).length;
    },
    key(i) {
      const keys = Object.keys(storage);
      return keys[i] || null;
    },
    clear() {
      Object.keys(storage).forEach(key => {
        delete storage[key];
      });
    }
  };
};

export const sessionStorage = window.sessionStorage || createStorage();
export const localStorage = window.localStorage || createStorage();

const getItemFromSessionStorage = (itemId) => {
  try {
    return JSON.parse(sessionStorage.getItem(itemId));
  } catch (err) {
    return null;
  }
};

const setItemToSessionStorage = (item, itemId) => {
  try {
    return sessionStorage.setItem(
      itemId,
      JSON.stringify(item)
    );
  } catch (err) {
    return false;
  }
};

export const getStoredSearch = () => getItemFromSessionStorage(SEARCH_FORM_SESSION_STORAGE);
export const setStoredSearch = (params) =>
  setItemToSessionStorage(params, SEARCH_FORM_SESSION_STORAGE);

export const getStoredRefParam = () => getItemFromSessionStorage(REF_PARAM_SESSION_STORAGE);
export const setStoredRefParam = (ref) =>
  setItemToSessionStorage(ref, REF_PARAM_SESSION_STORAGE);
