import React, { Component } from 'react';
import { FormattedWithChildren } from 'translations'
import FoldableDropdown from 'app/components/base/FoldableDropdown';
import StandardInput from 'app/components/base/StandardInput';
import OptionList from 'app/components/base/OptionList';
import { isNull } from 'app/utils/types';
import _ from 'lodash';

class OptionPicker extends Component {
  getSelectedOption() {
    const { options, comparator, input } = this.props;
    return options.find(option => {
      return comparator(option, input.value);
    });
  }

  handleChange = (option) => {
    this.props.input.onChange(option);
  }

  handleUnfold = () => this.props.input.onFocus();
  handleFold = () => this.props.input.onBlur(this.props.input.value);

  renderLabel() {
    const { input, template } = this.props;
    const rawValue = isNull(input.value) ? '' : template(input.value)

    return (
      <FormattedWithChildren message={rawValue}>
        {strValue => (
          <StandardInput
            {...this.props}
            input={{
              readOnly: true,
              value: strValue
            }}
          />
        )}
      </FormattedWithChildren>
    );
  }

  render() {
    const {
      template = item => item,
      options,
      id,
      input,
      disabled,
      forceEnabled,
      /* eslint-disable */
      icon,
      meta,
      onClick,
      /* eslint-enable */
      origin,
    } = this.props;

    const {
      value
    } = input;

    const noOption = !_.isNumber(value) && (_.isNil(value) || _.isEmpty(value));

    return (
      <FoldableDropdown
        onClick={onClick}
        origin={origin}
        id={id}
        onUnfold={this.handleUnfold}
        onFold={this.handleFold}
        disabled={disabled || ((options.length < 1 && !noOption) && !forceEnabled)}
        label={this.renderLabel()}
      >
        <OptionList
          value={this.getSelectedOption()}
          onChange={this.handleChange}
          options={options}
          template={template}
        />
      </FoldableDropdown>
    );
  }
}

// TODO: move to Flow types
// OptionPicker.propTypes = {
//   input: PropTypes.object.isRequired,
//   meta: PropTypes.object.isRequired,
//   disabled: PropTypes.bool,
//   options: PropTypes.array,
//   forceEnabled: PropTypes.bool,
//   id: PropTypes.string,
//   template: PropTypes.func,
//   comparator: PropTypes.func
// };

OptionPicker.defaultProps = {
  forceEnabled: false,
  options: [],
  comparator: (a, b) => (a === b)
};

export default OptionPicker;
