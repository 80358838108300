import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import { delay } from 'redux-saga';
import findPlaceById from 'app/sagas/watchSearchForm/findPlaceById';
import { places, flights, travelagent } from 'mz-sdk';
import { getAutocomplete } from '../selectors';
import countries from 'mz-ui-kit/form/CountryField/countries';
import {
  AUTOCOMPLETE_DEBOUNCE,
  AUTOCOMPLETE_TYPES
} from 'app/constants';
import {
  autocompleteLoading,
  autocompleteReceivedItems,
  autocompleteRetrievalFailed
} from 'app/actions/autocomplete';


// Constants
export const PLACE_TYPE_TO_ICON = {
  place: { family: 'mozio', name: 'to' },
  airport: { name: 'local_airport' },
  locality: { family: 'mozio', name: 'to' }
};
export const CLIENT_FILTERING_TYPES = {
  [AUTOCOMPLETE_TYPES.PHONE_CODE]: true,
  [AUTOCOMPLETE_TYPES.AIRLINE]: true,
  [AUTOCOMPLETE_TYPES.FLIGHT]: true,
  [AUTOCOMPLETE_TYPES.CRUISE]: true,
  [AUTOCOMPLETE_TYPES.TA_COMPANY]: true
};

/**
 * Targets(getters) for autocomplete
 * Each getter should return a list of objects where each object
 * should contain `display` field that will be printed in autocomplete field.
 * Also the object could have `icon` field with object `{ name, family }`
 * that will be passed to `Icon` component.
 * @type {Object}
 */
export const SUGGESTION_GETTERS = {
  /**
   * The `requestContext` can be empty or an object – place suggestion, which will
   * be used to get suggestion near the place.
   * @param  {String} searchQuery
   * @param  {Object} requestContext
   * @return {Array}
   */
  [AUTOCOMPLETE_TYPES.PLACE]: function* getPlaceSuggestions(searchQuery, requestContext) {
    const placeWithLat = (requestContext && requestContext.place_id)
      ? yield call(findPlaceById, requestContext.place_id)
      : null;
    const results = yield call(places.getSuggestions, searchQuery, placeWithLat);
    return (results || []).map((place) => ({
      ...place,
      icon: PLACE_TYPE_TO_ICON[place.type]
    }));
  },

  /**
   * Get All airlines
   * @param  {String} searchQuery
   * @param  {Object} requestContext
   * @return {Array}
   */
  [AUTOCOMPLETE_TYPES.AIRLINE]: function* getAirlines() {
    const results = yield call(flights.getAirlines);
    const airlines = results.map(airline => ({
      id: `airline-${airline.value}`,
      display: `${airline.display} (${airline.value})`,
      value: airline.value
    }));

    const sortedAirlines = airlines
      .slice()
      .sort((a, b) => a.display.localeCompare(b.display));

    return sortedAirlines;
  },

  /**
   * Get All cruise for active port
   * @param  {String} searchQuery
   * @param  {Object} requestContext
   * @return {Array}
   */
  [AUTOCOMPLETE_TYPES.CRUISE]: function getCruises() {
    const allLines = ['AIDA Cruises','American Cruise Lines','Azamara Club Cruises','Bahamas Paradise Cruise Line','Birka Line','Carnival Cruise Line','Celebrity Cruises','Celestyal Cruises','Compagnie du Ponant','Costa Cruises','Cruise & Maritime Voyages','Crystal Cruises','Cunard Line','Disney Cruise Line','Dream Cruises','Fred. Olsen Cruise Lines','FTI Cruises','Hansa Touristk','Hapag-Lloyd Cruises','Holland America Line','Hurtigruten','Majestic International Cruises','St Hilda Sea Adventures','The Majestic Line','Mano Maritime','MSC Cruises','Norwegian Cruise Line','Oceania Cruises','P&O Cruises','P&O Cruises Australia','Paul Gauguin Cruises','Pearl Seas Cruises','Phoenix Reisen','Plantours Kreuzfahrten','Poseidon Expeditions','Princess Cruises','Pullmantur Cruises','Quark Expeditions','Regent Seven Seas Cruises','Royal Caribbean International','Saga Cruises','Seabourn Cruise Line','SeaDream Yacht Club','Silversea Cruises','Star Cruises','Thomson Cruises','Transocean Tours','TUI Cruises','UnCruise Adventures','Viking Ocean Cruises','Windstar Cruises','Voyages to Antiquity','1AVista','AmaWaterways','American Cruise Lines','Aqua Expeditions','A-ROSA Flussschiff','Australian Pacific Touring (APT)','Avalon Waterways','CroisiEurope','Crystal Cruises','Emerald Waterways','European Waterways','Grand Circle Cruise Line','Great Lakes Cruise Company','Hansa Touristk','Lüftner Cruises','nicko cruises','Orthodox Cruise Company','Phoenix Reisen','Plantours Kreuzfahrten','Reisebüro Mittelthurgau','Riviera Travel','Scenic','Tauck River Cruises','The River Cruise Line','Transocean Tours','Uniworld River Cruises','Viking River Cruises','Vodohod']; // eslint-disable-line
    return allLines.map(x => ({
      display: x,
      value: x
    }));
  },

  /**
   * The `requestContext` an object – flight suggestion, which will
   * be used to get suggestion for certain airport.
   * @param  {String} searchQuery
   * @param  {Object} requestContext
   * @return {Array}
   */
  [AUTOCOMPLETE_TYPES.FLIGHT]: function* getFlights(searchQuery, requestContext) {
    const results = yield call(flights.getFlights, requestContext);

    return results.map(x => {
      const relevantAirportIataCode = requestContext.flight_direction === 'dep'
        ? x.destination
        : x.origin;

      return {
        id: `flight-${x.flightCode}`,
        display: `${x.flightCode} / ${x.time} / ${relevantAirportIataCode}`,
        value: x.flightNumber,
        time: x.time
      };
    });
  },

  /**
   * Get TA companies
   * @param  {String} searchQuery
   * @param  {Object} requestContext
   * @return {Array}
   */
  [AUTOCOMPLETE_TYPES.TA_COMPANY]: function* getTACompanies() {
    const results = yield call(travelagent.getCompanies);
    const companies = results.map(item => ({
      ...item,
      display: item.name,
      value: item.company_code
    }));
    const sortedResults = _.sortBy(companies, 'name');
    return sortedResults;
  },

  [AUTOCOMPLETE_TYPES.PHONE_CODE]: function getPhoneCodes() {
    return Object.keys(countries).map(key => {
      const { name, callingCode, flagCountryCode } = countries[key];
      return {
        display: `${name} (${callingCode})`,
        flagCode: flagCountryCode || key,
        value: key
      };
    });
  }
};

/**
 * Saga for processing autocomplete search input changes. It tries to
 * get suggestions by given target and field id. It also debounce caliing
 * of the API by 320 milliseconds.
 * @param  {String} payload.id    Name of autcomplete search field
 * @param  {String} payload.query   Current search query from input
 * @param  {String} payload.target  Type of suggestions target (place, airport, etc)
 * @param  {Object} payload.value   Current value of the input (suggestion object)
 * @param  {Function} payload.changer   Function to call to change suggestion field value
 * @param  {Object} payload.requestContext Object for passing to getter function
 */
export function* retrieveSuggestions({
  payload: {
    id, query, target, value, changer, requestContext,
    isRequestContextChanged: isComponentRequestContextChanged
  }
}) {
  // Do not retrive suggestions if suggestions already loaded for
  // client filtering suggester types
  const clientFiltering = CLIENT_FILTERING_TYPES[target];
  if (clientFiltering) {
    const { loadedOnce, requestContext: prevRequestContext } = yield select(getAutocomplete, id);
    const isRequestContextChanged = !_.isEqual(prevRequestContext, requestContext);
    // check if data is already loaded or if query for fetch data is not changed
    if (loadedOnce && !(isComponentRequestContextChanged || isRequestContextChanged)) {
      return;
    }
  }

  // Show loading indicator and debounce
  yield put(autocompleteLoading({ id }));
  yield call(delay, AUTOCOMPLETE_DEBOUNCE);

  // Get suggestion getter function
  const suggestionGetter = SUGGESTION_GETTERS[target];
  if (!suggestionGetter) {
    throw new Error(`There is no suggestions getter for target: ${target}`);
  }

  // Get suggestions
  try {
    const suggestions = yield call(suggestionGetter, query, requestContext);
    yield put(autocompleteReceivedItems({
      id, query, target, suggestions, changer, value, requestContext
    }));
  } catch (e) {
    const error = e && e.message;
    yield put(autocompleteRetrievalFailed({ error, id }));
  }
}

export default retrieveSuggestions;
