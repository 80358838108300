import { createReducer } from '../utils/redux';

import {
  GET_SUGGESTED_MEET_AND_GREET_TRIPS,
  SET_SUGGESTED_MEET_AND_GREET_TRIPS
} from 'app/constants';

const initialState = {
  error: null,
  trips: []
};

const actionHandlers = {

  [GET_SUGGESTED_MEET_AND_GREET_TRIPS]: () => ({
    error: null,
    trips: []
  }),

  [SET_SUGGESTED_MEET_AND_GREET_TRIPS]: (_, { trips, error }) => ({
    trips,
    error
  })

};

export default createReducer(initialState, actionHandlers);
