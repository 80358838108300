import React, { Component } from 'react';
import classnames from 'classnames';
import noop from 'lodash/noop';
import { Layout } from 'app/components/base/Layout';
import LoadingSpinner from 'app/components/base/LoadingSpinner';

import style from './style.css';

/**
  * General styled button
  * @return {Component}
  */
class Button extends Component {
  unmounted = false
  state = {
    disabled: false
  }

  componentWillUnmount() {
    this.unmounted = true
  }

  get loading() {
    const { spinner: { white, transparent } } = this.props;
    return (
      <Layout align="center center" className={style.spinner}>
        <LoadingSpinner
          nomargin
          size="small"
          white={white}
          transparent={transparent}
        />
      </Layout>
    );
  }

  resetState = () => {
    if (!this.unmounted) {
      this.setState({ disabled: false });
    }
  }

  handleClick = (event) => {
    if (this.props.disabled || this.state.disabled) return;
    const promise = Promise.resolve(this.props.onClick(event));
    if (this.props.unsafe) return;
    this.setState({ disabled: true });
    promise
      .then(this.resetState)
      .catch(this.resetState);
  }

  render() {
    const {
      // Modifiers
      primary, clear, small, large, round, rounded, outlined, bordered,
      transparent, nocaps, capitalize, tight, alt, disabled, freeWidth,
      bolder, loading, gray, mapToggler, stretch, active,

      // Others
      direction,
      className,
      children,
      ...props
    } = this.props;

    const modifiers = {
      [style.default]: !primary && !clear && !outlined && !bordered,
      [style.primary]: primary,
      [style.active]: active,
      [style.bordered]: bordered,
      [style.outlined]: outlined,
      [style.gray]: gray,
      [style.clear]: clear && !alt,
      [style.clearAlt]: clear && alt,
      [style.uppercase]: !nocaps,
      [style.capitalize]: capitalize,
      [style.disabled]: disabled || this.state.disabled,
      [style.stretch]: stretch,
      [style.small]: small,
      [style.large]: large,
      [style.tight]: tight,
      [style.round]: round,
      [style.mapToggler]: mapToggler,
      [style.bolder]: bolder,
      [style.rounded]: rounded,
      [style.transparent]: transparent,
      [style.freeWidth]: freeWidth,
      [style.loading]: loading
    };

    return (
      <Layout
        align="center center"
        {...props}
        onClick={this.handleClick}
        className={classnames(className, style.button, modifiers)}
      >
        <Layout
          className={style.content}
          size="noshrink"
          align="center center"
          nowrap
          direction={direction}
        >
          {children}
        </Layout>
        {loading && this.loading}
      </Layout>
    );
  }
}

// TODO: move to Flow types
// Button.propTypes = {
//   primary: PropTypes.bool,
//   disabled: PropTypes.bool,
//   gray: PropTypes.bool,
//   unsafe: PropTypes.bool,
//   outlined: PropTypes.bool,
//   nocaps: PropTypes.bool,
//   capitalize: PropTypes.bool,
//   alt: PropTypes.bool,
//   tight: PropTypes.bool,
//   bordered: PropTypes.bool,
//   stretch: PropTypes.bool,
//   small: PropTypes.bool,
//   large: PropTypes.bool,
//   round: PropTypes.bool,
//   rounded: PropTypes.bool,
//   mapToggler: PropTypes.bool,
//   bolder: PropTypes.bool,
//   clear: PropTypes.bool,
//   loading: PropTypes.bool,
//   transparent: PropTypes.bool,
//   freeWidth: PropTypes.bool,
//   className: PropTypes.string,
//   direction: PropTypes.string,
//   onClick: PropTypes.func,
//   children: PropTypes.node,
//   spinner: PropTypes.shape({
//     white: PropTypes.bool,
//     transparent: PropTypes.bool
//   })
// };

Button.defaultProps = {
  onClick: noop,
  disabled: false,
  primary: false,
  clear: false,
  small: false,
  large: false,
  round: false,
  bolder: false,
  rounded: false,
  outlined: false,
  bordered: false,
  transparent: false,
  nocaps: false,
  capitalize: false,
  tight: false,
  alt: false,
  freeWidth: false,
  spinner: {
    white: true,
    transparent: true
  }
};

export default Button;
