import {
  ADD_CREDIT_CARD,
  DELETE_CREDIT_CARD,
  GET_CREDIT_CARDS,
  SET_CREDIT_CARDS,
  UPDATE_CREDIT_CARD,
  SELECT_CREDIT_CARD,
  TOGGLE_THREE_D_SECURE_LIGHTBOX,
  THREE_D_SECURE_SHOW_IFRAME,
  THREE_D_SECURE_HIDE_IFRAME
} from '../constants';

import { createAction, createDeferredAction } from '../utils/redux';

export const getCreditCards = createDeferredAction(
  GET_CREDIT_CARDS
);

export const setCreditCards = createAction(
  SET_CREDIT_CARDS
);

export const addCreditCard = createDeferredAction(
  ADD_CREDIT_CARD
);

export const updateCreditCard = createDeferredAction(
  UPDATE_CREDIT_CARD
);

export const selectCreditCard = createAction(
  SELECT_CREDIT_CARD
);

export const deleteCreditCard = createDeferredAction(
  DELETE_CREDIT_CARD
);

export const toggleThreeDSecure = createAction(
  TOGGLE_THREE_D_SECURE_LIGHTBOX
);

export const show3DSFrame = createAction(
  THREE_D_SECURE_SHOW_IFRAME
);

export const hide3DSFrame = createAction(
  THREE_D_SECURE_HIDE_IFRAME
);
