import { formConfig as bookTripFormConfig } from 'app/pages/BookTripPage/builder';
import { actionTypes } from 'redux-form';
import {
  DEPARTING_PICKUP_FIELD_NAME,
  RETURNING_PICKUP_FIELD_NAME
} from 'app/constants';
import { CHANGE_PICKUP_TIME_SAGA } from '../restoreOriginalPickupTime';


const isPickupTimeChanged = (action) => {
  return (
    action.type === actionTypes.CHANGE &&
    action.meta.form === bookTripFormConfig.form &&
    (
      action.meta.field === DEPARTING_PICKUP_FIELD_NAME ||
      action.meta.field === RETURNING_PICKUP_FIELD_NAME
    ) &&
    action.source !== CHANGE_PICKUP_TIME_SAGA
  );
};

export default isPickupTimeChanged;
