import STEP_TYPE from 'mz-sdk/constants/stepType';


const stepBuilderMap = {
  [STEP_TYPE.WALK]: (step, { formatDistance }) => ({
    icon: 'walk',
    description: formatDistance(step.distance && step.distance.miles)
  }),
  default: step => ({
    description: step.provider && step.provider.name,
    from: step.from && (step.from.station || step.from.location),
    to: step.to && (step.to.station || step.to.location),
    main: step.main
  })
};

/**
 * Insert station name as additional step (just to display user in step sequence)
 */
const addStopStations = steps => {
  // add stop locations to list
  const stepsWithStations = steps.reduce((result, step) => {
    if (!step.main) {
      return [...result, step];
    }

    const walkingSteps = [step];
    const fromLocation = step.from;
    if (fromLocation) {
      const stepFromPoint = {
        description: fromLocation.name || fromLocation.address
      };
      walkingSteps.unshift(stepFromPoint);
    }

    const toLocation = step.to;
    if (toLocation) {
      const stepToPoint = {
        description: toLocation.name || fromLocation.address
      };
      walkingSteps.push(stepToPoint);
    }

    return [...result, ...walkingSteps];
  }, []);

  return stepsWithStations;
};

/**
 * Build steps structure for easy to consume by a component
 */
const getStepsSummary = (steps = [], {
  formatDistance,
  skipProviderDesription = true
}) => {
  const resultSteps = steps.map(step => {
    const stepBuilder = stepBuilderMap[step.type] || stepBuilderMap.default;
    return stepBuilder(step, { formatDistance });
  });

  if (resultSteps.length === 1) {
    return resultSteps;
  }

  // Add stations to the list
  const stepwWithStations = addStopStations(resultSteps);

  // Exclude main step if needed
  return stepwWithStations
    .filter(step => (skipProviderDesription ? !step.main : true));
};

export default getStepsSummary;
