import {
  GET_CREDIT_CARDS,
  ADD_CREDIT_CARD,
  SELECT_CREDIT_CARD,
  DELETE_CREDIT_CARD,
  UPDATE_CREDIT_CARD
} from 'app/constants';

import { fork } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga';
import fetchCreditCards from './fetchCreditCards';
import saveCreditCard from './saveCreditCard';
import selectCreditCard from './selectCreditCard';
import deleteCreditCard from './deleteCreditCard';

export default function* watchCreditCard() {
  yield fork(takeLatest, GET_CREDIT_CARDS, fetchCreditCards);
  yield fork(takeLatest, ADD_CREDIT_CARD, saveCreditCard);
  yield fork(takeLatest, UPDATE_CREDIT_CARD, saveCreditCard);
  yield fork(takeLatest, DELETE_CREDIT_CARD, deleteCreditCard);
  yield fork(takeLatest, SELECT_CREDIT_CARD, selectCreditCard);
}
