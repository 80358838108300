import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import { delay } from 'redux-saga';
import { CLIENT_FILTERING_TYPES } from './retrieveSuggestions';
import { getAutocomplete } from '../selectors';
import { autocompleteClientFiltered, autocompleteLoading } from 'app/actions/autocomplete';
import doFilter from './doFilter';


/**
 * Saga for processing autocomplete search input changes on client side.
 *
 * @param  {String} payload.id    Name of autcomplete search field
 * @param  {String} payload.value   Current value if search input
 * @param  {Object} payload.suggestions Array of suggestions
 */
export function* filterSuggestions({
  payload: { id, query, target, value }
}) {
  // Show loading indication and debounce
  // Loading here helps E2E tests identify when the result item
  // is clickable, making tests more stable
  yield put(autocompleteLoading({ id }));
  yield call(delay, 300);
  const { suggestions, loadedOnce } = yield select(getAutocomplete, id);

  // Work only with client filtering
  const clientFiltering = CLIENT_FILTERING_TYPES[target];
  if (!clientFiltering || !loadedOnce) {
    yield put(autocompleteLoading({ id, loading: false }));
    return;
  }

  // Finally filter suggestion
  if (value.uncomplete) {
    const filteredSuggestions = !_.isEmpty(query)
      ? yield call(doFilter, suggestions, query, target)
      : null;
    yield put(autocompleteClientFiltered({ id, filteredSuggestions }));
  } else {
    yield put(autocompleteClientFiltered({ id, filteredSuggestions: suggestions }));
  }
}

export default filterSuggestions;
