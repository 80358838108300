import { findMainStep, isScheduledTrip } from 'app/utils/trip';
import _ from 'lodash';

// return selected by default ticketId and scheduleId
export const getDefaultTicket = ticketTypes => {
  const initialDepartureTicket = ticketTypes.find(type => type.isDefault);

  let ticket = null;

  if (initialDepartureTicket) {
    ticket = {
      ticketId: initialDepartureTicket.ticketId,
      scheduleId: +initialDepartureTicket.defaultScheduleId || 0
    };
  }

  return ticket;
};

// return information about selected ticket and schedule
export const getTicketInfo = (trip, selectedTicket, isReturn = false) => {
  if (!selectedTicket) return null;

  const steps = isReturn ? trip.returnSteps : trip.steps;

  const { ticketTypes } = findMainStep(steps);
  const { ticketId, scheduleId } = selectedTicket;

  const ticketType = ticketTypes.find(type => type.ticketId === ticketId);

  if (!ticketType) return null;

  const scheduleInfo = ticketType.schedules[scheduleId];

  if (!scheduleInfo) return null;

  const ticketInfo = _.omit({
    ...ticketType,
    ...scheduleInfo
  }, 'schedules');

  return ticketInfo;
};

export const getSelectedTicket = (trip, isReturn = false) => {
  const isScheduledRide = isScheduledTrip(trip);
  if (!isScheduledRide) return null;

  if (isReturn && trip.returnTicket) return trip.returnTicket;
  if (isReturn && trip.returnSteps.length === 0) return null;
  if (trip.departureTicket) return trip.departureTicket;

  const ticketTypes = isReturn
    ? findMainStep(trip.returnSteps).ticketTypes
    : findMainStep(trip.steps).ticketTypes;

  return getDefaultTicket(ticketTypes);
};

// helper to prepare props that are used in schedule tabs
const getScheduleProps = steps => {
  const {
    provider,
    vehicle: { imageUrl },
    from: { location: { name } },
    departureDatetime,
    serviceHours,
    ticketTypes
  } = findMainStep(steps);

  const defaultTicket = getDefaultTicket(ticketTypes);
  const serviceHoursInfo = serviceHours
    ? `${serviceHours.startTime.format('HH:mm')} - ${serviceHours.endTime.format('HH:mm')}`
    : '';

  return {
    ticketTypes,
    serviceHoursInfo,
    titleLogo: imageUrl,
    providerName: provider.name,
    fromInfo: name,
    dateInfo: departureDatetime,
    title: [].concat(provider.name).join(' - '),
    selectedTicket: defaultTicket
  };
};

// helper for preparing props for TimeTableSelect component
const getTripScheduleProps = (
  trip, {
    isRoundTrip = false,
    selectedTicket,
    selectedReturnTicket
  } = {}
) => {
  const departureProps = getScheduleProps(trip.steps);
  const hasReturnSteps = trip.returnSteps && trip.returnSteps.length > 0;

  const returnProps = hasReturnSteps && getScheduleProps(trip.returnSteps);
  return {
    departureScheduleProps: {
      ...departureProps,
      selectedTicket: selectedTicket || departureProps.selectedTicket
    },
    returnScheduleProps: isRoundTrip && hasReturnSteps
      ? {
        ...returnProps,
        selectedTicket: selectedReturnTicket || returnProps.selectedTicket
      }
      : null
  };
};

export default getTripScheduleProps;
