import React from 'react';
import { connect } from 'react-redux';
import { hook } from 'app/utils/hook';
import Footer from 'app/components/base/Footer';
import { FOOTER_COMPONENT_HOOK } from 'app/constants';
import {
  getSupportPhones
} from 'app/sagas/selectors';


const mapStateToProps = state => {
  const { user } = state.userSession;
  return {
    supportPhones: getSupportPhones(state),
    userType: user ? user.user_type : null
  };
};

const FooterWrapper = (props) => {
  const FooterComponent = hook(FOOTER_COMPONENT_HOOK) || Footer;
  return <FooterComponent {...props} />;
};

export default connect(mapStateToProps, null)(FooterWrapper);
