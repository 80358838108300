// @flow
import { styled } from 'mz-ui-kit';


export const Container = styled('div', ({ $palette, $color }) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  border: `1px solid ${$palette($color)}`,
  color: `${$palette($color)}`,
  minHeight: '27px',
  borderRadius: '2px',
  alignItems: 'center',
  justifyContent: 'center',
  paddingRight: '0.5em',
  paddingLeft: '0.5em',
  height: 'initial',
  marginRight: '0.5em',
  ':not(:last-child)': {
    marginBottom: '0.5em',
  }
}));
