import {
  GET_RESERVATIONS,
  GET_PAST_RESERVATIONS,
  SET_RESERVATIONS,
  SET_PAST_RESERVATIONS,
  ADD_RESERVATIONS,
  ADD_PAST_RESERVATIONS,
  SET_RESERVATIONS_FILTER,
  SET_ACTIVE_RESERVATIONS_TAB
} from '../constants';

import { createAction, createDeferredAction } from '../utils/redux';

export const getReservations = createDeferredAction(
  GET_RESERVATIONS
);

export const setReservations = createAction(
  SET_RESERVATIONS
);

export const addReservations = createAction(
  ADD_RESERVATIONS
);

export const getPastReservations = createDeferredAction(
  GET_PAST_RESERVATIONS
);

export const setPastReservations = createAction(
  SET_PAST_RESERVATIONS
);

export const addPastReservations = createAction(
  ADD_PAST_RESERVATIONS
);

export const setReservationFilter = createAction(
  SET_RESERVATIONS_FILTER
);

export const setActiveReservationsTab = createAction(
  SET_ACTIVE_RESERVATIONS_TAB
);
