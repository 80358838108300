import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import TimeTableSelect from 'app/components/search-results/TimeTableSelect';
import DesktopLightbox from 'app/components/base/DesktopLightbox';
import { connectSubpages } from 'app/hocs/subpageManagable';
import {
  getUpdatedScheduleTrip,
  getUpdatedScheduleError,
  getUpdatedScheduleLoading
} from 'app/sagas/selectors';
import * as scheduledRidesActions from 'app/actions/scheduled-rides';
import getTripScheduleProps
  from 'app/components/search-results/TimeTableSelect/utils/getTripScheduleProps';


const mapStateToProps = (state, { trip }) => {
  const updatedScheduleTrip = getUpdatedScheduleTrip(state);
  let originTrip = updatedScheduleTrip || trip;
  // swap start end end location for return part of trip
  originTrip = {
    ...originTrip,
    returnSteps: originTrip.returnSteps
  };

  return {
    updatedTrip: updatedScheduleTrip,
    originTrip,
    error: getUpdatedScheduleError(state),
    loading: getUpdatedScheduleLoading(state)
  };
};

const mapDispatchToProps = {
  viewEarlierTimes: scheduledRidesActions.viewEarlierTimes,
  viewLaterTimes: scheduledRidesActions.viewLaterTimes
};


/**
 * Wrapper for schedules dialog. Controls modal and provides needed data
 */
export class TimetableLightbox extends Component {
  handleConfirm = (item, returnItem) => {
    this.props.onConfirm(item, returnItem);
    this.props.subpages.popPage();
  };

  handleClose = () => {
    const { subpages, onCloseDialog } = this.props;

    subpages.popPage();
    if (onCloseDialog) {
      onCloseDialog();
    }
  }

  render() {
    const {
      originTrip,
      isRoundTrip,
      isReturn,
      selectedTicket,
      selectedReturnTicket,
      // actions
      viewEarlierTimes,
      viewLaterTimes
    } = this.props;

    let tripForRender = originTrip;
    // for SRP use trip with departure and return in one dialog.
    // for BTP - only departure or return
    if (!isRoundTrip) {
      tripForRender = isReturn
      ? { steps: originTrip.returnSteps }
      : { steps: originTrip.steps };
    }

    const scheduleProps = getTripScheduleProps(tripForRender, {
      isRoundTrip,
      selectedTicket,
      selectedReturnTicket
    });

    return (
      <DesktopLightbox empty wide onClickClose={this.handleClose}>
        <TimeTableSelect
          {...this.props}
          {...scheduleProps}
          onConfirm={this.handleConfirm}
          onClose={this.handleClose}
          viewEarlierTimes={isReturnTab =>
            viewEarlierTimes({ isReturn: isReturn || isReturnTab, trip: originTrip })
          }
          viewLaterTimes={isReturnTab =>
            viewLaterTimes({ isReturn: isReturn || isReturnTab, trip: originTrip })
          }
        />
      </DesktopLightbox>
    );
  }
}

// TODO: move to Flow types
// TimetableLightbox.propTypes = {
//   subpages: PropTypes.object.isRequired,
//   onCloseDialog: PropTypes.func,
//   onConfirm: PropTypes.func,

//   originTrip: PropTypes.object,
//   updatedTrip: PropTypes.object,
//   ticketTypes: PropTypes.array,
//   selectedTicket: PropTypes.object,
//   selectedReturnTicket: PropTypes.object,
//   error: PropTypes.string,
//   loading: PropTypes.loading,

//   isReturn: PropTypes.bool,
//   isRoundTrip: PropTypes.bool,
//   // actions
//   viewEarlierTimes: PropTypes.func.isRequired,
//   viewLaterTimes: PropTypes.func.isRequired
// };

export default compose(
  connectSubpages(),
  connect(mapStateToProps, mapDispatchToProps)
)(TimetableLightbox);
