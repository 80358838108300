import { user } from 'mz-sdk';
import { call, select, put } from 'redux-saga/effects';
import { setUser } from 'app/actions/user-session';
import { getUser } from 'app/sagas/selectors';
import { showSuccessNotification, showErrorNotification } from 'app/sagas/utils';
import doSetLocaleCurrency from 'app/sagas/watchUserSession/doSetLocaleCurrency';
import { hook } from 'app/utils/hook';
import {
  PHONE_FIELD_NAME,
  DEFAULT_CURRENCY_FIELD_NAME,
  DEFAULT_LANGUAGE_FIELD_NAME,
  CURRENCY_OBJECT_FIELD_NAME,
  
  LANGUAGE_OBJECT_FIELD_NAME,
  TA_CALLING_CODE_FIELD_NAME,
  AFTER_USER_UPDATE_HOOK
} from 'app/constants';

export default function* updateUserInformation(action) {
  try {
    const formData = action.payload;
    const userData = {
      ...formData,
      [DEFAULT_CURRENCY_FIELD_NAME]: formData[CURRENCY_OBJECT_FIELD_NAME].code,
      [DEFAULT_LANGUAGE_FIELD_NAME]: formData[LANGUAGE_OBJECT_FIELD_NAME].value,
      [TA_CALLING_CODE_FIELD_NAME]: formData[PHONE_FIELD_NAME].country.code,
      [PHONE_FIELD_NAME]: formData[PHONE_FIELD_NAME].number,
    };
    const response = yield call(user.updateUserInformation, userData);
    const currentUser = yield select(getUser);
    yield put(setUser({ ...currentUser, ...response }));
    yield call(showSuccessNotification, {
      messageId: 'USER.INFORMATION_UPDATED'
    });
    yield call(doSetLocaleCurrency);
    yield hook({
      name: AFTER_USER_UPDATE_HOOK,
      defaultHandler: () => null,
      args: [response]
    });
    yield call(action.resolvePromise);
  } catch (error) {
    yield call(showErrorNotification, { error });
    yield call(action.rejectPromise);
  }
}
