// @flow
import { styled } from 'mz-ui-kit';

export const HeaderWrapper = styled('div', {
  zIndex: 6001 // value from zindex.css to overlap header value (6001):  $layout + 1
});

export const ContentWrapper = styled('div', {
  zIndex: 6000, // value from zindex.css to overlap header value (6000):  $layout
  display: 'flex',
  flexFlow: 'column nowrap',
  flexGrow: 1
});
