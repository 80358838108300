import { fork } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga';
import { KEEP_ORIGINAL_PICKUP_TIME } from 'app/constants';
import handleKeepOriginalTime from './handleKeepOriginalTime';


/**
 * Watch for KEEP_ORIGINAL_PICKUP_TIME action dispatch
 */
export default function* watchKeepOriginalTime() {
  yield fork(takeLatest, KEEP_ORIGINAL_PICKUP_TIME, handleKeepOriginalTime);
}
