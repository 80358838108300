import { call, put, fork, select } from 'redux-saga/effects';
import { hook } from 'app/utils/hook';
import getUser from 'app/sagas/watchUserSession/getUser';
import doSetLocaleCurrency from 'app/sagas/watchUserSession/doSetLocaleCurrency';
import redirectLoggedInUser from './redirectLoggedInUser';
import { initApp } from 'app/actions/app';
import { INIT_SAGA_HOOK } from 'app/constants';
import { initOthers, initStripe } from 'app/integrations';
import { getActiveLanguageObject } from 'app/sagas/selectors'
import { handleRefParam } from 'app/sagas/utils'

export default function* doInit() {
  yield [
    call(handleRefParam),
    call(initStripe),
    call(getUser)
  ];
  yield call(redirectLoggedInUser);
  yield call(doSetLocaleCurrency);
  yield hook(INIT_SAGA_HOOK);
  yield put(initApp());

  const langObj = yield select(getActiveLanguageObject)
  yield fork(initOthers, langObj.zendeskLocale);
}
